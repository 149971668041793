import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class GradeService {

  constructor(private httpService: HttpService, private apiService: ApiService) { }

  public getGrades() {
    return this.httpService.get(this.apiService.get("getGrades"));
  }
}
