import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/services/modal.service';
import { StudyMaterialService } from 'src/app/services/study-material.service';

@Component({
  selector: 'app-study-material-mobile-menu',
  templateUrl: './study-material-mobile-menu.component.html',
  styleUrls: ['./study-material-mobile-menu.component.scss']
})
export class StudyMaterialMobileMenuComponent implements OnInit {
  @Input() callerLandingPage: boolean = false;
  @Input() userTypeStudent: boolean;
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();
  public studyMaterialMenuList: any[];
  constructor(
    private router: Router,
    public studyMaterialService: StudyMaterialService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.getStudyMaterialMenu();
  }

  public getStudyMaterialMenu() {
    this.studyMaterialService.getStudyMaterialMenu().subscribe((response) => {
      this.studyMaterialMenuList = response.data.menu;
    });
  }


  public openTestattemptModal(testId: any) {
    //opening add mock test modal
    const modalRef = this.modalService.open(26, 11);
    modalRef.componentInstance.testId = testId;
  }

  public openStudyMaterialDetails(
    studyMaterialId: any,
    studyMaterial: any
  ) {
    let route: string = this.callerLandingPage
      ? "/study-material"
      : this.userTypeStudent
        ? "student-dashboard/study-material"
        : "teacher-dashboard/study-material";
    this.clicked.next(); //close offpage mobile menu
    if (studyMaterial.type === "Mock Test") {
      this.openTestattemptModal(studyMaterial.mock_test_id);
    } else {
      this.router.navigate([route, studyMaterialId], {
        state: { callerLandingPage: this.callerLandingPage },
      });
    }
  }

  public subMenu(e): void {
    let subMenu = e.target.nextSibling;
    subMenu.style.right = '0px';
  }

  public closeMenu(e): void {
    let parentMenu = e.target.parentNode.parentNode;
    parentMenu.style.right = '-320px';
  }
}
