import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { ConfirmationDialogService } from "src/app/services/confirmation-dialog.service";
import { ModalService } from "src/app/services/modal.service";
import { StudentService } from "src/app/services/student.service";
import { CartService } from "src/app/services/cart.service";
import { UtilityService } from 'src/app/services/utility.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: "app-course-list",
  templateUrl: "./course-list.component.html",
  styleUrls: ["./course-list.component.scss"],
})
export class CourseListComponent implements OnInit {
  public params: any = {
    page_size: 6,
    page: 1,
  };
  public callerDashboard: boolean;
  public totalPages: number;
  public maxLimitReached: boolean = false;
  public zeroSearchResults: boolean = false;
  public coursesCount: number = 0;
  public showLoadMoreButton: boolean = false;
  public searchTriggered: boolean = false;
  public showSearchTriggeredMessage: boolean = false;
  @ViewChild("searchButton") seacrhButton: ElementRef; //to close dropdown on search button fired

  public proposedCourses = [];
  constructor(
    private studentService: StudentService,
    private renderer: Renderer2,
    private cartService: CartService,
    private confirmDialogService: ConfirmationDialogService,
    private modalService: ModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private metaTagService: Meta, 
    private metaTitleService: Title
  ) {
    this.callerDashboard = activatedRoute.snapshot.data.fromDashboard;
  }

  ngOnInit(): void {

    //addd tags

      this.metaTagService.updateTag({ name: 'keywords', content: 'online learning platform' });
      this.metaTitleService.setTitle('Explore all Courses and Classes for Sessions ');
      this.metaTagService.updateTag({ name: 'description', content: 'Explore all the courses VIVID is offering as Pakistan’s best online learning platform till date.' });
    //api call to fetch random proposed courses
    this.getCourses();

    //listen to remove cart item event
    this.cartService.addRemoveCartIemObservable.subscribe((obj: any) => {
      // {course_id:1, added_to_cart:true}
      this.proposedCourses.forEach((course: any) => {
        if (obj.course_id === course.id) {
          course.added_to_cart = obj.added_to_cart;
        }
      });
    });
  }

  public getCourses() {
    //set course count to zero to hide course count upon api call
    this.coursesCount = 0;
    //to not show load more button upon api call
    this.showLoadMoreButton = false;
    this.studentService.getCourseList(this.params, this.callerDashboard).subscribe((response) => {
      //updating page number locally
      this.params.page += 1;
      //setting data array
      this.proposedCourses = [
        ...this.proposedCourses,
        ...response.data.courses.data,
      ];
      //setting total pages
      this.totalPages = response.data.courses.last_page;
      //setting max pages flag
      if (this.params.page > this.totalPages) {
        this.maxLimitReached = true;
        this.showLoadMoreButton = false;
      } else {
        this.showLoadMoreButton = true;
      }
      if (response.data.courses.total === 0) {
        //mesage manuplation on number of results
        this.zeroSearchResults = true;
        this.showLoadMoreButton = false;
      } else {
        this.zeroSearchResults = false;
        this.coursesCount = response.data.courses.total;
      }

      if (this.searchTriggered) {
        //close dropdown if search fired
        this.seacrhButton.nativeElement.click();
        this.searchTriggered = !this.searchTriggered;
      }
    });
  }

  public loadMore() {
    if (!this.maxLimitReached) {
      this.getCourses();
    } else {
      return;
    }
  }

  public jsonDecode(item) {
    return JSON.parse(item);
  }

  //function call on event emiited by search box
  public fetchSearchResults(searchData: any) {
    //initially hide loadmore button
    this.zeroSearchResults = false;

    //flag to show search results count
    this.searchTriggered = true;

    // to show no matching results mesage
    this.showSearchTriggeredMessage = true;

    if (searchData.grade != null) {
      this.params.grade = searchData.grade;
    } else {
      delete this.params["grade"];
    }
    if (searchData.subject != null) {
      this.params.subject = searchData.subject;
    } else {
      delete this.params["subject"];
    }
    if (searchData.month != null) {
      this.params.month = searchData.month;
    } else {
      delete this.params["month"];
    }
    if (searchData.keyword != null) {
      this.params.keyword = searchData.keyword;
    } else {
      delete this.params["keyword"];
    }
    //if any of search parameter provided then reseting page number parameter and sending api call
    if (
      searchData.grade ||
      searchData.subject ||
      searchData.month ||
      searchData.keyword
    ) {
      this.params.page = 1;
      //empty previous records
      this.proposedCourses = [];
      //calling api
      this.getCourses();
      //reseting max records flag
      this.maxLimitReached = false;
    }
  }

  public async addToCart(course) {
    if (!this.callerDashboard) {
      this.utilityService.setUserType("student");
      this.modalService.open(1, 1);
      return;
    }
    if (!course.available_seats) return;
    if (!moment(course.start_date).isBefore(moment())) {
      if (course.is_free) {
        this.studentService.enrollFreeCourse(course);
      } else {
        const modalRef = this.modalService.open(10, 2);
        modalRef.componentInstance.course = course;
        modalRef.componentInstance.step = 1; //1 for add to cart step
      }
    } else {
      const result = await this.confirmDialogService.getConfirmation(
        "Note",
        "This course has already started and you may have missed some of its sessions. Do you still want to enroll in this course ?"
      );
      if (!result) return;
      if (course.is_free) {
        this.studentService.enrollFreeCourse(course);
      } else {
        const modalRef = this.modalService.open(10, 2);
        modalRef.componentInstance.course = course;
        modalRef.componentInstance.step = 1; //1 for add to cart step
      }
    }
  }

  public navigateToCourseDetail(courseId) {
    this.router.navigate(["student-dashboard/course", courseId]);
  }
}
