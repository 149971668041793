<section class="partner-section">
  <div class="container">
    <!-- <h2 class="heading-2">Partners</h2> -->
    <div class="partner-box">
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem class="slide">
          <div class="img-container">
            <img src="assets/images/brand_logo_1.jpeg" alt="">
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="img-container">
            <img src="assets/images/brand_logo_2.png" alt="">
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="img-container">
            <img src="assets/images/schoollogo-UFS.svg" alt="">
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="img-container">
            <img src="assets/images/schoollogo-LongCalderwoodPrimarySchool.svg" alt="">
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="img-container">
            <img src="assets/images/schoollogo-aceyourOnlineClass.svg" alt="">
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="img-container">
            <img src="assets/images/schoollogo-bridge.svg" alt="">
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="img-container">
            <img src="assets/images/schoollogo-johnHopkins.svg" alt="">
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</section>
