import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ModalService } from 'src/app/services/modal.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public user; //current user object
  public isStudent: boolean;
  public courseRoute: string;
  public tuitionRoute: string;
  public tAndCRoute: string;
  public privacyPolicyRoute: string;
  public faqsRoute: string;
  public contactUsRoute: string;
  constructor(
    private modalService: ModalService,
    private authService: AuthService,
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getCurrentUser();
    this.isStudent = this.user?.type === "student" ? true : false;
    this.authService.userDataObservable.subscribe((user) => {
      this.user = user;
    });

    let baseRoute = this.user ? this.isStudent ? '/student-dashboard/' : '/teacher-dashboard/' : '/';
    this.courseRoute = baseRoute + 'courses';
    this.tuitionRoute = baseRoute + 'tuitions';
    this.tAndCRoute = baseRoute + 'terms-and-conditions'
    this.privacyPolicyRoute = baseRoute + 'privacy-policy'
    this.faqsRoute = baseRoute + 'faqs';
    this.contactUsRoute = baseRoute + 'contact-us';
  }

  public refresh() {
    window.location.reload();
  }

  public openRegistrationModal(userType: string): void {
    if (this.authService.isAuthenticated()) return; // if we have user object  in local storage don't open login modal
    this.utilityService.setUserType(userType);
    this.modalService.open(2, 1); // same component login for teacher and student
  }
}
