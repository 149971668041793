import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  constructor(
    private authSerice: AuthService,
    private router: Router,
    private metaTagService: Meta,
    private metaTitleService: Title) { }

  ngOnInit(): void {
    if (this.authSerice.isAuthenticated()) {
      //if authenticated user try to access landing page
      this.router.navigate(['student-dashboard']);
    }
    //add tags for SEO
    this.metaTagService.updateTag({ name: 'keywords', content: 'online learning' });
    this.metaTitleService.setTitle('VIVID | Best Online Learning Platform for all Classes');
    this.metaTagService.updateTag({ name: 'description', content: 'VIVID is the smartest and the best online learning platform for all classes in Pakistan. Revamp your education through online learning and teaching with VIVID!'});
  }

}
