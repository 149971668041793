<div class="modal-header pt-0 pb-0 pr-0">
    <button type="button" class="close" data-dismiss="modal" *ngIf="!attemptStarted && !isAttempted && !showResult"
      (click)="closeModal()">
      <span aria-hidden="true">
        <img src="assets/images/close.png" alt="" />
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="student-test-box">
      <div class="body-head">
        <div class="row">
          <div class="col-md-7 col-6 my-auto">
            <h3> {{ (!attemptStarted && !showResult) ? '' : 'Attempt Date: ' }}
              {{ ((!attemptStarted && !showResult) ? test?.due_date : currentDate) | dateFormat: "dayMonthYear" }}</h3>
          </div>
          <div class="col-md-5 col-6">
            <a class="action-btn cursor-pointer" *ngIf="!attemptStarted && !isAttempted && !showResult"
              (click)="startAttempt()">Start
              Now</a>
          </div>
        </div>
      </div>
  
      <div class="add-new-test">
        <div class="row test-detail">
          <div class="col-md-4">
            <div class="info">
              <div class="form-group">
                <span class="text-primary">Test Title</span>
                <p>{{ test?.title }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="info">
              <!-- <div class="form-group">
                <span class="text-primary">Duration</span>
                <p>{{ test?.duration }} mins</p>
              </div> -->
            </div>
          </div>
          <div class="col-md-4">
            <div class="info">
              <!-- <div class="form-group">
                <span class="text-primary">Status</span>
                <p>{{ test?.is_active ? "Active" : "In Active" }}</p>
              </div> -->
            </div>
            <div class="info">
              <div class="form-group">
                <!-- <span class="text-primary">Duration</span>
                <p>{{ test?.duration }} mins</p> -->
              </div>
            </div>
          </div>
        </div>
  
        <div class="content">
          <div class="total-Q" *ngIf="!showResult">
            <p>Total Questions: {{ test?.questions.length }}</p>
          </div>
  
          <div class="question-pane" *ngIf="attemptStarted">
            <div class="question">
              <form [formGroup]="questionFormGroup">
                <div class="form-group">
                  <h6 class="text-primary title">Question</h6>
                  <p [appMath]="currentQuestion.question" class="form-control question-field">
                  </p>
                  <span class="question-no">
                    {{ currentQuestionIndex + 1 }}
                  </span>
                </div>
  
                <div class="add-option-pane">
                  <h6 class="text-primary title">Answer</h6>
                </div>
                <div class="row">
                  <div class="col-md-6" *ngFor="let choice of currentQuestion.choices; let i = index">
                    <div class="form-group">
                      <p [appMath]="choice.choice" type="text" class="form-control question-field">
                      </p>
                      <div class="field-actions">
                        <div class="check-elem">
                          <div class="form-check">
                            <input class="form-check-input" type="radio" [formControl]="
                                questionFormGroup.controls.selected_choice_id
                              " value="{{ choice.id }}" [attr.name]="
                                questionFormGroup.controls.selected_choice_id
                              " [attr.id]="'question_' + i" />
                            <label class="form-check-label" [attr.for]="'question_' + i">
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="submit-pane">
                  <div class="row">
                    <div class="col-12">
                      <div class="btn-pane">
                        <a class="skip-action" (click)="skipQuestion()" *ngIf="!showFinish">Skip</a>
                        <a class="next-action" (click)="submitQuestion()" *ngIf="!showFinish">Next</a>
                        <a class="next-action" (click)="submitAttempt()" *ngIf="showFinish">Finish</a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="note">
                <p>Skipped question can be attempted during remaining time</p>
              </div>
  
              <div class="missing-attempts" *ngIf="skippedQuestionList.length !== 0">
                <label>Attempt skipped questions:</label>
                <ul class="list-unstyled">
                  <li *ngFor="let question of skippedQuestionList; let k = index">
                    <a class="cursor-pointer" (click)="showSkippedQuestion(question, k)">{{ question + 1 }}</a>
                  </li>
                  <!-- <li><a>2</a></li>
                    <li><a>3</a></li>
                    <li><a>4</a></li>
                    <li><a>5</a></li> -->
                </ul>
              </div>
            </div>
          </div>
  
          <div class="result" *ngIf="showResult">
            <div class="total-score">
              <label>Your Marks</label>
              <h4>{{ result?.obtained_marks }}</h4>
            </div>
            <ul class="list-unstyled">
              <li>
                <p>
                  Total Questions:<span>{{ result?.quiz.total_questions }} </span>
                </p>
              </li>
              <li>
                <p>
                  Attempted Questions:<span>{{
                    result?.attempted_questions
                  }}</span>
                </p>
              </li>
              <li>
                <p>
                  Time:<span>{{ result?.time_taken }} mins</span>
                </p>
              </li>
            </ul>
            <div class="action-btn">
              <a class="skip-action cursor-pointer" (click)="downloadTest(testAttemptId)">Download</a>
              <a class="next-action cursor-pointer" (click)="closeModal(true)">Close</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- confirmation before closing window  due to shortage of time doing this and as not route is changed while openiing the test attempt modal-->
  <script>
    window.onbeforeunload = function (e) {
      e = e || window.event;
  
      // For IE and Firefox prior to version 4
      if (e) {
        e.returnValue = 'Sure?';
      }
  
      // For Safari
      return 'Sure?';
    };
  </script>