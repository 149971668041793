import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ListService } from 'src/app/services/list.service';
import { StudentService } from 'src/app/services/student.service';

@Component({
  selector: 'app-courses-menu',
  templateUrl: './courses-menu.component.html',
  styleUrls: ['./courses-menu.component.scss']
})
export class CoursesMenuComponent implements OnInit {
  public menuList: any[];
  public subMenuOneList: any[];
  public subMenuTwoList: any[];
  public subMenuThreeList: any[];
  public activeMenu: string;
  public activeSubOneMenu: string;
  public activeSubTwoMenu: string;
  public activeSubThreeMenu: string;
  public activeCourse: string;

  public categoryCourseList: any[];
  public levelCourseList: any[];
  public subLevelOneCourseList: any[];
  public subLevelTwoCourseList: any[];

  constructor(
    private studentService: StudentService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getCourseMenu();
  }

  public getCourseMenu() {
    this.studentService.getCourseMenu(false).subscribe((response) => {
      this.menuList = response.data.menu;
    });
  }
  
  public menuSelected(
    subMenu: any[],
    courseList: any[],
    activeSubMenuId: string,
    level: number,
    event: any
  ) {
    event.stopPropagation(); //prevent menu from closing
    // this.studyMaterialList = studyMaterialList;
    switch (level) {
      case 1:
        this.subMenuOneList = subMenu;
        this.subMenuTwoList = [];
        this.subMenuThreeList = [];
        this.activeMenu = activeSubMenuId;
        this.categoryCourseList = courseList;
        this.levelCourseList = [];
        this.subLevelOneCourseList = [];
        this.subLevelTwoCourseList = [];
        this.activeSubOneMenu = "";
        this.activeSubTwoMenu = "";
        this.activeSubThreeMenu = "";
        break;
      case 2:
        this.subMenuTwoList = subMenu;
        this.activeSubOneMenu = activeSubMenuId;
        this.levelCourseList = courseList;
        this.subLevelOneCourseList = [];
        this.subLevelTwoCourseList = [];
        this.activeSubTwoMenu = "";
        this.activeSubThreeMenu = "";
        break;
      case 3:
        this.subMenuThreeList = subMenu; //filter out record who don't have sub menu and direct sub menus;;
        this.activeSubTwoMenu = activeSubMenuId;
        this.subLevelOneCourseList = courseList;
        this.subLevelTwoCourseList = [];
        this.activeSubThreeMenu = "";
        break;
      case 4: //get study material list
        this.activeSubThreeMenu = activeSubMenuId;
        this.subLevelTwoCourseList = courseList;
        break;
      default:
        break;
    }
  }

  public openCourseDetails(
    courseId: any,
    activeId: string,
    level: number
  ) {
    // this.activeMenu = this.activeSubOneMenu = this.activeSubTwoMenu = "";
    // this.subMenuOneList = this.subMenuTwoList = this.subMenuThreeList = this.lastLevelCourseList = [];
    switch (level) {
      case 1:
        this.activeSubOneMenu = "";
        this.activeSubTwoMenu = "";
        this.activeSubThreeMenu = "";
        this.subLevelOneCourseList = [];
        this.subLevelTwoCourseList = [];
        break;
      case 2:
        this.activeSubTwoMenu = "";
        this.activeSubThreeMenu = "";
        this.subLevelTwoCourseList = [];
        break;
      case 3:
        this.activeSubThreeMenu = "";
        break;
    }
    this.activeCourse = activeId;
    this.router.navigate(['/course', courseId], {
      state: { callerLandingPage: true },
    });
  }

}
