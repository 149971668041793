<div class="modal-header pb-0">
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">
      <img src="assets/images/close.png" alt="" />
    </span>
  </button>
</div>
<div class="modal-body pt-1">
  <div class="student-login-box">
    <div class="student-login">
      <h3 class="">
        {{ userType === "instructor" ? "Teacher" : (userType | titlecase) }}
        Login
      </h3>
      <form [formGroup]="loginFormGroup" (submit)="onSubmit()">
        <div class="login-box mb-4">
          <div class="form-label-group mb-0">
            <input
              type="email"
              placeholder="email"
              formControlName="email"
              class="form-control"
              (keypress)="checkSpaceInput($event)"
            />
            <label for="email">Email</label>
            <div>
              <span
                class="text-danger"
                *ngIf="
                  loginFormControl.email.touched &&
                  loginFormControl.email.errors?.required
                "
              >
                Please provide email.
              </span>
              <span
                class="text-danger"
                *ngIf="
                  loginFormControl.email.touched &&
                  (loginFormControl.email.errors?.email ||
                    loginFormControl.email.errors?.pattern)
                "
              >
                Please provide a valid email.
              </span>
            </div>
          </div>
          <div class="form-label-group password-box mb-0">
            <input
              [type]="showPassword ? 'text' : 'password'"
              placeholder="password"
              formControlName="password"
              class="form-control"
            />
            <label for="pwd">Password</label>
            <a (click)="showPassword = !showPassword" class="eye-icon">
              <img
                src="../../../../assets/images/hide.svg"
                alt="eye"
                [hidden]="showPassword"
              />
              <img
                src="../../../../assets/images/show.svg"
                alt="eye"
                [hidden]="!showPassword"
              />
            </a>
            <div>
              <span
                class="text-danger"
                *ngIf="
                  loginFormControl.password.touched &&
                  loginFormControl.password.errors?.required
                "
              >
                Please provide password.
              </span>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-gradient btn-block mb-4">
          Login
        </button>
      </form>
      <div class="d-flex justify-content-between flex-wrap px-3">
        <a
          (click)="openRegistrationModal()"
          class="text-success cursor-pointer registor"
          >Register</a
        >
        <a
          (click)="openForgotPasswordModal()"
          class="text-success cursor-pointer forget"
          >Forgot Password?
        </a>
      </div>
    </div>
    <div class="reg-account">
      <h5 class="text-center">Or login with:</h5>
      <div class="action-btns d-flex justify-content-center align-items-center">
        <!-- <a
          (click)="openRegistrationModal()"
          class="btn btn-sm btn-register btn-outline-success"
          >Register</a
        > -->
        <!-- <span>Or login with:</span> -->
        <a
          class="btn btn-social btn-sm btn-outline-success text-success mr-3"
          (click)="socialLogin('facebook')"
        >
          <svg
            id="Group_938"
            data-name="Group 938"
            xmlns="http://www.w3.org/2000/svg"
            width="11.709"
            height="23.417"
            viewBox="0 0 11.709 23.417"
          >
            <g id="Group_489" data-name="Group 489">
              <g id="Group_490" data-name="Group 490">
                <path
                  id="Path_684"
                  data-name="Path 684"
                  d="M135.318,8.05V5.123a1.464,1.464,0,0,1,1.464-1.464h1.464V0h-2.927a4.39,4.39,0,0,0-4.391,4.391V8.05H128v3.659h2.927V23.417h4.391V11.709h2.927l1.464-3.659Z"
                  transform="translate(-128)"
                  fill="#2592ff"
                />
              </g>
            </g>
          </svg>
        </a>

        <a
          class="btn btn-social btn-sm btn-outline-success text-success"
          (click)="socialLogin('google')"
          style="position: relative;"
        >
          <app-google-signin (loginWithGoogle)="googleSignin($event)"></app-google-signin>
          <svg
            version="1.1"
            width="19.514"
            height="19.879"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background: new 0 0 512 512;"
            xml:space="preserve"
          >
            <path
              style="fill: #fbbb00;"
              d="M113.47,309.408L95.648,375.94l-65.139,1.378C11.042,341.211,0,299.9,0,256
       c0-42.451,10.324-82.483,28.624-117.732h0.014l57.992,10.632l25.404,57.644c-5.317,15.501-8.215,32.141-8.215,49.456
       C103.821,274.792,107.225,292.797,113.47,309.408z"
            />
            <path
              style="fill: #518ef8;"
              d="M507.527,208.176C510.467,223.662,512,239.655,512,256c0,18.328-1.927,36.206-5.598,53.451
       c-12.462,58.683-45.025,109.925-90.134,146.187l-0.014-0.014l-73.044-3.727l-10.338-64.535
       c29.932-17.554,53.324-45.025,65.646-77.911h-136.89V208.176h138.887L507.527,208.176L507.527,208.176z"
            />
            <path
              style="fill: #28b446;"
              d="M416.253,455.624l0.014,0.014C372.396,490.901,316.666,512,256,512
       c-97.491,0-182.252-54.491-225.491-134.681l82.961-67.91c21.619,57.698,77.278,98.771,142.53,98.771
       c28.047,0,54.323-7.582,76.87-20.818L416.253,455.624z"
            />
            <path
              style="fill: #f14336;"
              d="M419.404,58.936l-82.933,67.896c-23.335-14.586-50.919-23.012-80.471-23.012
       c-66.729,0-123.429,42.957-143.965,102.724l-83.397-68.276h-0.014C71.23,56.123,157.06,0,256,0
       C318.115,0,375.068,22.126,419.404,58.936z"
            />
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
        </a>
      </div>
    </div>
  </div>
</div>
