<section class="our-results-section">
  <div class="container">
    <div class="row title-box">
      <div class="col-lg-3">
        <h2 class="text-dark heading-2">Our Results</h2>
      </div>
      <div class="col-lg-9">
        <p class="text-dark font-normal">TALEEMLY promises an education method that reflects excellent results. All students have different needs and TALEEMLY identifies this major concern. Hence, our teachers approach all students with a different mindset. It’s time you stop worrying about scoring a good grade, TALEEMLY focuses on effective teaching methods as results will follow!</p>
      </div>
    </div>
    <div class="card">
      <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
          <!-- <li data-target="#carouselExampleCaptions" data-slide-to="2"></li> -->
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="row">
              <div class="col-12 col-xl-6 order-2">
                <div class="carousel-caption">
                  <h3>TALEEMLY Inspires Performance</h3>
                  <h1 class="text-primary">
                    90% Benchmark!
                  </h1>
                  <p class="text-secondary">Our students are top performers, be it Matriculation, Intermediate or O/A levels!</p>
                </div>
              </div>
              <div class="col-12 col-xl-6">
                <div class="img-box">
                  <div class="img-content text-center">
                    <div class="img-container">
                      <img src="assets/images/man-with-gogle (1).png" class="d-block" alt="...">
                    </div>
                    <p class="my-0 font-medium">Ada Khan</p>
                    <span class="text-primary">95%</span>
                  </div>
                  <div class="img-content text-center">
                    <div class="img-container">
                      <img src="assets/images/man-with-gogle (2).png" class="d-block" alt="...">
                    </div>
                    <p class="my-0 font-medium">Abdur Rehman</p>
                    <span class="text-primary">91%</span>
                  </div>
                  <div class="img-content text-center">
                    <div class="img-container">
                      <img src="assets/images/man-with-gogle (3).png" class="d-block" alt="...">
                    </div>
                    <p class="my-0 font-medium">Noor Ul Huda</p>
                    <span class="text-primary">92%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-12 col-xl-6 order-2">
                <div class="carousel-caption">
                  <h3>TALEEMLY Inspires Performance</h3>
                  <h1 class="text-primary">
                    90% Benchmark!
                  </h1>
                  <p class="text-secondary">The teachers on TALEEMLY are very collaborative and always encouraged me to ask questions. It’s just a great online platform to score good grades.</p>
                </div>
              </div>
              <div class="col-12 col-xl-6">
                <div class="img-box">
                  <div class="img-content text-center">
                    <div class="img-container">
                      <img src="assets/images/Student_Feedback_thumbnail_2.png" class="d-block" alt="...">
                    </div>
                    <p class="my-0 font-medium">Ahmed Chaudhry</p>
                    <span class="text-primary">90%</span>
                  </div>
                  <div class="img-content text-center">
                    <div class="img-container">
                      <img src="assets/images/Student_Feedback_thumbnail_3.png" class="d-block" alt="...">
                    </div>
                    <p class="my-0 font-medium">Abu Ubaida Rao</p>
                    <span class="text-primary">92%</span>
                  </div>
                  <div class="img-content text-center">
                    <div class="img-container">
                      <img src="assets/images/Student_Feedback_thumbnail_4.png" class="d-block" alt="...">
                    </div>
                    <p class="my-0 font-medium">Taimur Ahmed</p>
                    <span class="text-primary">93%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="carousel-item">
            <div class="row">
              <div class="col-12 col-xl-6">
                <div class="carousel-caption">
                  <h3>VIVID Inspires Performance</h3>
                  <h1 class="text-primary">
                    <img src="assets/images/star.png" class="mr-2" alt="">
                    90% Benchmark!
                    <img src="assets/images/star.png" class="ml-2" alt="">
                  </h1>
                  <p class="text-secondary">Our students are top performers, be it Matriculation, Intermediate or O/A levels!</p>
                </div>
              </div>
              <div class="col-12 col-xl-6">
                <div class="img-box">
                  <div class="img-content text-center">
                    <div class="img-container">
                      <img src="assets/images/man-with-gogle (1).png" class="d-block" alt="...">
                    </div>
                    <p class="my-0 font-medium">Ada Khan</p>
                    <span class="text-primary">95%</span>
                  </div>
                  <div class="img-content text-center">
                    <div class="img-container">
                      <img src="assets/images/man-with-gogle (2).png" class="d-block" alt="...">
                    </div>
                    <p class="my-0 font-medium">Abdur Rehman</p>
                    <span class="text-primary">91%</span>
                  </div>
                  <div class="img-content text-center">
                    <div class="img-container">
                      <img src="assets/images/man-with-gogle (3).png" class="d-block" alt="...">
                    </div>
                    <p class="my-0 font-medium">Noor Ul Huda</p>
                    <span class="text-primary">92%</span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

        </div>
      </div>
    </div>
  </div>
</section>
