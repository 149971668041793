import { Component, OnInit } from "@angular/core";
import { Meta, Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: "app-faqs",
  templateUrl: "./faqs.component.html",
  styleUrls: ["./faqs.component.scss"],
})
export class FaqsComponent implements OnInit {
  public faqsArray = [
    {
      id: '1',
      question: "How do I make an account on VIVID?",
      answer:
        "Creating an account on VIVID is super easy, all you need is a valid email address and a mobile number through which you can create an account by signing up.",
    },

    {
      id: '2',
      question:
        "Where can I find details of courses or classes being offered by VIVID?",
      answer:
        `Please click on this <a routerLink="/courses">link</a> to find relevant details of the classes being offered.`,
    },
    {
      id: '3',
      question: "How do I sign up for a course?",
      answer:
        "Go into your account and look up all the courses being offered. Select the relevant one and get yourself enrolled!",
    },

    {
      id: '4',
      question: "Is there any way I can see replay of a live session?",
      answer: "Yes, all live sessions will be recorded and made available.",
    },

    {
      id: '5',
      question: "Is there an option to download past live sessions?",
      answer:
        "No, there is no download option for past live sessions, however all courses will be available for online viewing on VIVID.",
    },

    {
      id: '6',
      question: "What classes does VIVID offer?",
      answer: "VIVID offers teaching in Matric, FA/F.Sc and GCE (O & A Levels). ",
    },

    {
      id: '7',
      question: "Do you offer one on one sessions?",
      answer:
        "Yes, one on one sessions are also available with our teachers. Contact us to schedule a session.",
    },

    {
      id: '8',
      question: "Where can we see details regarding teachers on VIVID?",
      answer: `Please check out this <a routerLink="/teachers">link</a> to see details regarding our teachers.`,
    },
    {
      id: '9',
      question: "Do you have study material or classroom notes?",
      answer:
        "All live sessions have separate classroom notes, you will be provided a classroom note for each class that you attend. A general study guide is also available for each course and can be downloaded from our website.",
    },

    {
      id: '10',
      question: " Can I get a Refund for a class?",
      answer: "You can view our refund policy here:",
    },

    {
      id: '11',
      question: " Do you offer free or trial classes?",
      answer:
        "Yes, we do offer trial classes. Contact us today to get yourself enrolled in trial classes.",
    },
  ];
  public user; //current user object
  public isStudent: boolean;
  public courseRoute: string;
  public teachersRoute: string;
  public privacyPolicyRoute: string;
  constructor(
    private authService: AuthService,
    private metaTagService: Meta, 
    private metaTitleService: Title
  ) {
    this.user = this.authService.getCurrentUser();
    this.isStudent = this.user?.type === "student" ? true : false;
    this.authService.userDataObservable.subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit(): void {
    //add tags for SEO

    this.metaTagService.updateTag({ name: 'keywords', content: 'online learning' });
    this.metaTitleService.setTitle('Frequently Asked Question | Pakistan’s Best Smart Learning Platform');
    this.metaTagService.updateTag({ name: 'description', content: 'Get answers for your concerns in FAQ’s section.' });
    let baseRoute = this.user ? this.isStudent ? '/student-dashboard/' : '/teacher-dashboard/' : '/';
    this.courseRoute = baseRoute + 'courses';
    this.privacyPolicyRoute = baseRoute + 'privacy-policy';
    this.teachersRoute = baseRoute + 'teachers';
    // if (this.user && !this.isStudent) {
    // this.faqsArray.splice(1, 1); //delete courses faqs incase of teacher
    // }
  }
}


