import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() callerTeacherRegistration: boolean = false;
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {

  }
  public closeModal() {
    this.activeModal.close(true);
  }
}
