<div class="modal-header pb-0">
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">
      <img src="assets/images/close.png" alt="" />
    </span>
  </button>
</div>
<div class="modal-body pt-1">
  <div class="student-login-box last-step done-registration">
    <div class="student-login mb-0">
      <h3>
        You are done !
      </h3>
      <p class="font-light subtitle-text">
        Provide your class and institution information.
      </p>
      <form [formGroup]="registrationFinalStepFormGroup" (submit)="onSubmit()">
        <div class="login-box mb-4">
          
          <div class="form-group floating-label select-box pb-3 mb-0">
            <select class="form-control floating-select" formControlName="grade" id="grade" onclick="this.setAttribute('value', this.value);" value="">
              <option value="" disabled>Select Grade</option>
              <option *ngFor="let grade of gradesList" [ngValue]="grade">{{
                grade.name
              }}</option>
            </select>
            <label for="grade" class="label-grade">Grade</label>
            <div class="error">
              <span
                class="text-danger"
                *ngIf="
                  registrationFinalStepFormControl.grade.touched &&
                  registrationFinalStepFormControl.grade.errors?.required
                "
              >
                Please select grade.
              </span>
            </div>
          </div>

          <div class="form-group floating-label select-box pb-3 mb-0">
            
            <select class="form-control floating-select" formControlName="board" id="Board" onclick="this.setAttribute('value', this.value);" value="">
              <option value="" disabled>Select Board</option>
              <option *ngFor="let board of boardsList" [ngValue]="board">{{
                board.name
              }}</option>
            </select>
            
            <label for="Board" class="label-grade">Board</label>
            <div class="error">
              <span
                class="text-danger"
                *ngIf="
                  registrationFinalStepFormControl.board.touched &&
                  registrationFinalStepFormControl.board.errors?.required
                "
              >
                Please select board.
              </span>
            </div>
          </div>
          <div class="form-label-group pb-3 mb-0">
            <input
              type="text"
              placeholder="Enter School"
              formControlName="schoolName"
              readonly
              onfocus="this.removeAttribute('readonly');"
              onblur="this.setAttribute('readonly','');"
              class="form-control"
              id="School"
            />
            <label for="School">School</label>
            <div>
              <span
                class="text-danger"
                *ngIf="
                  registrationFinalStepFormControl.schoolName.touched &&
                  registrationFinalStepFormControl.schoolName.errors?.required
                "
              >
                Please provide school name.
              </span>
              <span
                class="text-danger"
                *ngIf="
                  registrationFinalStepFormControl.schoolName.touched &&
                  registrationFinalStepFormControl.schoolName.errors?.pattern
                "
              >
                Please provide a valid school name.
              </span>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-gradient btn-block mb-4">
          Done
        </button>
      </form>
    </div>
  </div>

</div>
