<div class="modal-header pb-0">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">
      <img src="assets/images/close.png" alt="" />
    </span>
  </button>
</div>
<div class="modal-body pt-1">
  <div class="student-login-box cont-registration">
    <div class="student-login">
      <h3 class="">Provide Mobile Number</h3>
      <form [formGroup]="socialMediaRegistrationFormGroup" (submit)="onSubmit()">
        <!-- <div class="radio-box p-3 text-center">
                        <span class="registor-as">Register as</span>
                        <span class="radio ml-2">
                            <label>
                                <input type="radio" formControlName="registerAs" value="student"> Student
                            </label>
                        </span>
                        <span class="radio radio-2 ml-2">
                            <label>
                                <input type="radio" formControlName="registerAs" value="teacher">
                                Teacher
                            </label>
                        </span>
                    </div> -->
        <div class="login-box mb-4">
          <div class="form-label-group">
            <input type="text" formControlName="phone" placeholder="Enter Number" class="form-control"
              (focus)="addPrefix()" (keypress)="checkPhoneInput($event)" id="number" maxlength="17" />
            <label for="number">Mobile Number</label>
            <div>
              <span class="text-danger" *ngIf="
                  socialMediaRegistrationFormControls.phone.touched &&
                  socialMediaRegistrationFormControls.phone.errors?.required
                ">
                Phone Number is required
              </span>
              <span class="text-danger" *ngIf="
                  socialMediaRegistrationFormControls.phone.touched &&
                  socialMediaRegistrationFormControls.phone.errors?.pattern
                ">
                Enter a Valid Number (+923211234567)
              </span>
              <span class="text-danger" *ngIf="
                  socialMediaRegistrationFormControls.phone.touched &&
                  socialMediaRegistrationFormControls.phone.errors?.alreadyTaken
                ">
                {{
                  socialMediaRegistrationFormControls.phone.errors.alreadyTaken
                }}
              </span>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-gradient btn-block mb-4">
          Continue
        </button>
      </form>
    </div>
  </div>
</div>