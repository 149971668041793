<div class="content-wrapper">
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="title-pane">
                        <h2 class="title">
                            {{ callerDemo ? callerTuitionRequest ? 'Request 1 on 1 Tuition' : 'Request a demo' : 'Contact Us' }}
                        </h2>
                        <p>{{callerTuitionRequest ? 'Feel free to send your personalized session request!' : 'Feel free to drop by or call to say Hello!' }} </p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <form [formGroup]="contactUsFormGroup" (submit)="onSubmit()" class="contact-form">
                        <div class="form-group">
                            <label for="exampleInputSubject">Name</label>
                            <input type="text" formControlName="name" class="form-control" id="exampleInputSubject"
                                aria-describedby="emailHelp" placeholder="Enter Name">
                            <span class="text-danger" *ngIf="
                                contactFormControls.name.touched &&
                                contactFormControls.name.errors?.required
                              ">
                                Please provide name.
                            </span>
                            <span class="text-danger" *ngIf="
                            contactFormControls.name.touched &&
                            (contactFormControls.name.errors?.minlength ||
                            contactFormControls.name.errors?.maxlength) &&
                        !contactFormControls.name.errors?.pattern
                        ">
                                Name must be between 2 to 50 letters.
                            </span>
                            <span class="text-danger" *ngIf="
                        contactFormControls.name.touched &&
                        contactFormControls.name.errors?.pattern
                      ">
                                Please provide a valid name.
                            </span>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputSubject">Subject</label>
                            <input type="text" formControlName="subject" class="form-control" id="exampleInputSubject"
                                aria-describedby="emailHelp" placeholder="Enter subject">
                            <span class="text-danger" *ngIf="
                                contactFormControls.subject.touched &&
                                contactFormControls.subject.errors?.required
                              ">
                                Please provide subject.
                            </span>
                            <span class="text-danger" *ngIf="
                            contactFormControls.subject.touched &&
                            (contactFormControls.subject.errors?.minlength ||
                            contactFormControls.subject.errors?.maxlength) &&
                        !contactFormControls.subject.errors?.pattern
                        ">
                                Subject must be between 2 to 50 letters.
                            </span>
                            <span class="text-danger" *ngIf="
                        contactFormControls.subject.touched &&
                        contactFormControls.subject.errors?.pattern
                      ">
                                Please provide a valid subject.
                            </span>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPhone">Phone</label>
                            <input type="text" formControlName="phone" class="form-control" id="exampleInputPhone"
                                aria-describedby="emailHelp" placeholder="Enter phone number">
                            <span class="text-danger" *ngIf="
                                contactFormControls.phone.touched &&
                                contactFormControls.phone.errors?.required
                              ">
                                Please provide mobile number.
                            </span>
                            <span class="text-danger" *ngIf="
                                contactFormControls.phone.touched &&
                                contactFormControls.phone.errors?.pattern
                              ">
                                Please provide a valid mobile no (e.g. +923212496812)
                            </span>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail">Email</label>
                            <input type="email" formControlName="email" class="form-control" id="exampleInputEmail"
                                aria-describedby="emailHelp" placeholder="Enter email">
                            <span class="text-danger" *ngIf="
                  contactFormControls.email.touched &&
                  contactFormControls.email.errors?.required
                ">
                                Please provide email.
                            </span>
                            <span class="text-danger" *ngIf="
                  contactFormControls.email.touched &&
                  (contactFormControls.email.errors?.email ||
                    contactFormControls.email.errors?.pattern)
                ">
                                Please provide a valid email.
                            </span>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputMessage">Message</label>
                            <textarea formControlName="message" class="form-control message-input"
                                id="exampleInputMessage" rows="3" placeholder="Enter your message"></textarea>
                            <span class="text-danger" *ngIf="
                                contactFormControls.message.touched &&
                                contactFormControls.message.errors?.required
                              ">
                                Please provide message.
                            </span>
                        </div>
                        <button type="submit" class="btn btn-primary btn-sm">Submit</button>
                    </form>
                </div>

                <div class="col-lg-4 offset-lg-1">
                    <div class="contact-list-pane">
                        <ul class="contact-list">
                            <li><a target="_blank"
                                    href="https://www.google.com/maps/search/97-D,+Commercial+Broadway,+Phase+8,+DHA,+Lahore/@31.4864303,74.4203008,14z/data=!3m1!4b1">
                                    <span class="icon">
                                        <img src="../../../../assets/images/house.svg" alt="">
                                    </span>
                                    97-D, Commercial Broadway, Phase 8, DHA, Lahore
                                </a></li>
                            <li><a class="no-event">
                                    <span class="icon">
                                        <img src="../../../../assets/images/contact-phone.svg" alt="">
                                    </span>
                                    042 - 3713 5041
                                </a></li>


                            <li><a href="mailto:info@goTALEEMLY.pk">
                                    <span class="icon">
                                        <img src="../../../../assets/images/email.svg" alt="">
                                    </span>
                                    <!-- info@goTALEEMLY.pk -->
                                </a></li>
                            <li><a class="no-event">
                                    <span class="icon">
                                        <img src="../../../../assets/images/calendar-contact.svg" alt="">
                                    </span>
                                    Monday to Friday
                                </a></li>
                            <li><a class="no-event">
                                    <span class="icon">
                                        <img src="../../../../assets/images/clock.svg" alt="">
                                    </span>
                                    0900 to 1700 Hours
                                </a></li>
                        </ul>
                    </div>

                </div>

                <div class="col-12 happy-line">
                    <p>Happy Online Learning!</p>
                </div>
            </div>
        </div>
    </div>
</div>
