<section class="teachers-section">
  <div class="teachers-box">
    <div class="container">
      <div class="title-box">
        <h2 class="text-primary heading-2">Learn with the Best Teachers Online</h2>
        <p>Pakistan’s best online learning platform is incomplete without its amazing teachers!</p>
        <p>Our teachers have the experience, passion and commitment of teaching students in a personalized manner. Take
          a peek into our teaching roster below:</p>
      </div>
      <div class="teachers">
        <div class="dropdown">
          <a class="btn btn-outline-primary btn-view" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" #searchButton>Advanced Search</a>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <app-search-box (searchEvent)="fetchSearchResults($event)" [callerTuition]="false"
              [callerLandingPageTeachers]="true"></app-search-box>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-12 col-md-6 col-xl-3" *ngFor="let instructor of instructorsList">
            <div class="img-container">
              <img [lazyLoad]="instructor.instructor_profile.profile_picture || 'assets/images/avatar.svg'"
                class="d-block" alt="">
              <div class="img-overlay">
                <div class="overlay-text d-flex justify-content-between align-items-center">
                  <div>
                    <h5 class="text-white">{{instructor.name}}</h5>
                    <span ngbTooltip="{{ instructor.subjects }}" placement="top right"
                      [disableTooltip]="instructor.subjects.length <= 15">{{instructor.subjects | shortText:15}}</span>
                  </div>
                  <p class="text-primary mb-0">
                    <img src="assets/images/Icon-ionic-ios-star.svg" class="star-icon" alt="">
                    {{instructor.instructor_profile.rating}}</p>
                </div>
              </div>
            </div>
            <div class="teacher-content pl-1 pr-1">
              <div class="d-flex justify-content-between">
                <p class="mb-0 mt-2">Education: </p>
                <p class="mb-0 mt-2">{{instructor.instructor_profile.qualification.title}}</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="mb-0">Specialization: </p>
                <p class="mb-0" ngbTooltip="{{ instructor.instructor_profile.specialization }}" placement="top"
                  [disableTooltip]="instructor.instructor_profile.specialization.length <= 10">
                  {{instructor.instructor_profile.specialization | shortText:10}}</p>
              </div>
              <!-- <div class="d-flex justify-content-between">
                <p class="mb-1">Grades: </p>
                <p class="mb-1" ngbTooltip="{{ instructor.grades }}" placement="top"
                [disableTooltip]="instructor.grades.length <= 18">{{ instructor.grades | shortText:18}}</p>
              </div> -->
              <div class="experience bg-lightGreyColor p-2 d-flex justify-content-between">
                <span class="font-bold font-small" ngbTooltip="{{ instructor.instructor_profile.teaching_experience }}"
                  placement="top"
                  [disableTooltip]="instructor.instructor_profile.teaching_experience.length <= 12">Experience: &nbsp;
                  {{instructor.instructor_profile.teaching_experience | shortText:12}}</span>
                <span class="text-primary font-small">{{instructor.instructor_profile.city}}</span>
              </div>
            </div>
          </div>
          <!-- <div class="col-12 col-md-6 col-xl-3">
            <div class="img-container">
              <img src="../../../../assets/images/two.png" class="d-block" alt="">
              <div class="img-overlay">
                <div class="overlay-text d-flex justify-content-between align-items-center">
                  <div>
                    <h5 class="text-white">Roberto Tremo</h5>
                    <span>Maths</span>
                  </div>
                  <p class="text-primary mb-0">4.95</p>
                </div>
              </div>
            </div>
            <div class="teacher-content pl-1 pr-1">
              <div class="d-flex justify-content-between">
                <p class="mb-0 mt-2">Education: </p>
                <p class="mb-0 mt-2">Masters</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="mb-0">Specialization: </p>
                <p class="mb-0">Physics</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="mb-1">Grades: </p>
                <p class="mb-1">9th, 10th</p>
              </div>
              <div class="experience bg-lightGreyColor p-2 d-flex justify-content-between">
                <span class="font-bold font-small">Experience:3 years</span>
                <span class="text-primary font-small">Lahore</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-3">
            <div class="img-container">
              <img src="../../../../assets/images/three.png" class="d-block" alt="">
              <div class="img-overlay">
                <div class="overlay-text d-flex justify-content-between align-items-center">
                  <div>
                    <h5 class="text-white">Pauline Johns</h5>
                    <span>Pysics</span>
                  </div>
                  <p class="text-primary mb-0">4.95</p>
                </div>
              </div>
            </div>
            <div class="teacher-content pl-1 pr-1">
              <div class="d-flex justify-content-between">
                <p class="mb-0 mt-2">Education: </p>
                <p class="mb-0 mt-2">Masters</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="mb-0">Specialization: </p>
                <p class="mb-0">Physics</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="mb-1">Grades: </p>
                <p class="mb-1">9th, 10th</p>
              </div>
              <div class="experience bg-lightGreyColor p-2 d-flex justify-content-between">
                <span class="font-bold font-small">Experience:3 years</span>
                <span class="text-primary font-small">Lahore</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-3">
            <div class="img-container">
              <img src="../../../../assets/images/four.png" class="d-block" alt="">
              <div class="img-overlay">
                <div class="overlay-text d-flex justify-content-between align-items-center">
                  <div>
                    <h5 class="text-white">Ruby Smith</h5>
                    <span>Chemistry</span>
                  </div>
                  <p class="text-primary mb-0">4.95</p>
                </div>
              </div>
            </div>
            <div class="teacher-content pl-1 pr-1">
              <div class="d-flex justify-content-between">
                <p class="mb-0 mt-2">Education: </p>
                <p class="mb-0 mt-2">Masters</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="mb-0">Specialization: </p>
                <p class="mb-0">Physics</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="mb-1">Grades: </p>
                <p class="mb-1">9th, 10th</p>
              </div>
              <div class="experience bg-lightGreyColor p-2 d-flex justify-content-between">
                <span class="font-bold font-small">Experience:3 years</span>
                <span class="text-primary font-small">Lahore</span>
              </div>
            </div>
          </div> -->
          <p class="p-3" *ngIf="zeroSearchResults && !showSearchTriggeredMessage">
            Sorry! we cannot find any instructor
          </p>
          <p class="p-3 text-not-found" *ngIf="zeroSearchResults && showSearchTriggeredMessage">
            No matching result found
          </p>
        </div>
        <!-- <div class="row">
          <div class="col-12 col-md-6 col-xl-3">
            <div class="img-container">
              <img src="../../../../assets/images/one.png" class="d-block" alt="">
              <div class="img-overlay">
                <div class="overlay-text d-flex justify-content-between align-items-center">
                  <div>
                    <h5 class="text-white">Ruby Garcia</h5>
                    <span>English</span>
                  </div>
                  <p class="text-primary mb-0">4.95</p>
                </div>
              </div>
            </div>
            <div class="teacher-content pl-1 pr-1">
              <div class="d-flex justify-content-between">
                <p class="mb-0 mt-2">Education: </p>
                <p class="mb-0 mt-2">Masters</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="mb-0">Specialization: </p>
                <p class="mb-0">Physics</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="mb-1">Grades: </p>
                <p class="mb-1">9th, 10th</p>
              </div>
              <div class="experience bg-lightGreyColor p-2 d-flex justify-content-between">
                <span class="font-bold font-small">Experience:3 years</span>
                <span class="text-primary font-small">Lahore</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-3">
            <div class="img-container">
              <img src="../../../../assets/images/two.png" class="d-block" alt="">
              <div class="img-overlay">
                <div class="overlay-text d-flex justify-content-between align-items-center">
                  <div>
                    <h5 class="text-white">Roberto Tremo</h5>
                    <span>Maths</span>
                  </div>
                  <p class="text-primary mb-0">4.95</p>
                </div>
              </div>
            </div>
            <div class="teacher-content pl-1 pr-1">
              <div class="d-flex justify-content-between">
                <p class="mb-0 mt-2">Education: </p>
                <p class="mb-0 mt-2">Masters</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="mb-0">Specialization: </p>
                <p class="mb-0">Physics</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="mb-1">Grades: </p>
                <p class="mb-1">9th, 10th</p>
              </div>
              <div class="experience bg-lightGreyColor p-2 d-flex justify-content-between">
                <span class="font-bold font-small">Experience:3 years</span>
                <span class="text-primary font-small">Lahore</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-3">
            <div class="img-container">
              <img src="../../../../assets/images/three.png" class="d-block" alt="">
              <div class="img-overlay">
                <div class="overlay-text d-flex justify-content-between align-items-center">
                  <div>
                    <h5 class="text-white">Pauline Johns</h5>
                    <span>Pysics</span>
                  </div>
                  <p class="text-primary mb-0">4.95</p>
                </div>
              </div>
            </div>
            <div class="teacher-content pl-1 pr-1">
              <div class="d-flex justify-content-between">
                <p class="mb-0 mt-2">Education: </p>
                <p class="mb-0 mt-2">Masters</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="mb-0">Specialization: </p>
                <p class="mb-0">Physics</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="mb-1">Grades: </p>
                <p class="mb-1">9th, 10th</p>
              </div>
              <div class="experience bg-lightGreyColor p-2 d-flex justify-content-between">
                <span class="font-bold font-small">Experience:3 years</span>
                <span class="text-primary font-small">Lahore</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-3">
            <div class="img-container">
              <img src="../../../../assets/images/four.png" class="d-block" alt="">
              <div class="img-overlay">
                <div class="overlay-text d-flex justify-content-between align-items-center">
                  <div>
                    <h5 class="text-white">Ruby Smith</h5>
                    <span>Chemistry</span>
                  </div>
                  <p class="text-primary mb-0">4.95</p>
                </div>
              </div>
            </div>
            <div class="teacher-content pl-1 pr-1">
              <div class="d-flex justify-content-between">
                <p class="mb-0 mt-2">Education: </p>
                <p class="mb-0 mt-2">Masters</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="mb-0">Specialization: </p>
                <p class="mb-0">Physics</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="mb-1">Grades: </p>
                <p class="mb-1">9th, 10th</p>
              </div>
              <div class="experience bg-lightGreyColor p-2 d-flex justify-content-between">
                <span class="font-bold font-small">Experience:3 years</span>
                <span class="text-primary font-small">Lahore</span>
              </div>
            </div>
          </div>
        </div> -->
        <div class="action-btn text-center">
          <a class="btn btn-outline-secondary btn-load" (click)="loadMore()"
            *ngIf="!maxLimitReached && !zeroSearchResults && showLoadMoreButton">Load more</a>
        </div>
      </div>
    </div>
  </div>
  <app-become-teacher></app-become-teacher>
</section>