import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
  @Input() sessionTitle: string;
  @Input() videoLectureSource: string;
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  closeModal() {
    this.activeModal.close()
  }
}
