<section class="mock-test-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <div class="content-box">
          <h2 class="text-dark">Our Test System:</h2>
          <p class="text-dark">Do you hate taking tests? TALEEMLY makes tests or quizzes a fun activity!</p>
          <p class="text-dark">TALEEMLY ensures a steady system for online learning. We conduct tests on a chapter basis as well as weekly so that all students are well-equipped with knowledge. Our tests are designed in such a way that the focus of a student is understanding the concepts, rather than rote memorization. Our tests hence look for ways that can improve a student’s comprehension and performance.</p>
          <!-- <a href="#" class="btn btn-gradient btn-sample">Get Sample Papers</a> -->
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="img-box">
          <img src="assets/images/Student Banner.png" class="img-fluid" alt="Mock">
          <!-- <a href="#" class="play-vid">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="37.402"
              height="46.75" viewBox="0 0 37.402 46.75">
              <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                  <stop offset="0" stop-color="#1e72ef" />
                  <stop offset="0.778" stop-color="#09a15a" />
                  <stop offset="1" stop-color="#006721" />
                </linearGradient>
              </defs>
              <path id="Icon_ionic-ios-play" data-name="Icon ionic-ios-play"
                d="M9,7.961V52.284a1.141,1.141,0,0,0,1.709,1.052L45.843,31.175a1.256,1.256,0,0,0,0-2.089L10.709,6.924A1.131,1.131,0,0,0,9,7.961Z"
                transform="translate(-9 -6.751)" fill="url(#linear-gradient)" />
            </svg>
          </a> -->
        </div>
      </div>
    </div>
  </div>
</section>
