<div class="one-on-one-list">
  <div class="one-on-one-list-box">
    <div class="container">
      <app-title-box *ngIf="callerDashboard"></app-title-box>
      <div class="title-box">
        <h3 class="heading-3">
          1 on 1 Tuition
          <span class="font-light">(Special Classes)
            <span class="text-primary font-medium" *ngIf="coursesCount && !zeroSearchResults">
              {{ coursesCount }} result(s) found</span></span>
        </h3>
        <div class="d-flex advance-search-box">
          <a [routerLink]="[callerDashboard ? '/student-dashboard/proposed-courses' : '']" class="btn-back">
            <svg xmlns="http://www.w3.org/2000/svg" width="15.691" height="10.465" viewBox="0 0 15.691 10.465">
              <path id="Icon_ionic-ios-arrow-round-forward" data-name="Icon ionic-ios-arrow-round-forward"
                d="M17.882,11.452a.712.712,0,0,0-.005,1l3.314,3.319H8.578a.709.709,0,0,0,0,1.417H21.185L17.871,20.51a.717.717,0,0,0,.005,1,.706.706,0,0,0,1-.005l4.491-4.524h0a.8.8,0,0,0,.147-.223.676.676,0,0,0,.055-.273.71.71,0,0,0-.2-.5l-4.491-4.524A.694.694,0,0,0,17.882,11.452Z"
                transform="translate(23.566 21.717) rotate(180)" fill="#2592ff" />
            </svg>
          </a>
          <div class="dropdown">
            <a class="btn btn-outline-primary btn-view" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" #searchButton>Advanced Search</a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <app-search-box (searchEvent)="fetchSearchResults($event)" [callerLandingPageTeachers]="false"
                [callerTuition]="true" [callerDashboard]="callerDashboard"></app-search-box>
            </div>
          </div>
        </div>
      </div>
      <div class="special-class-box">
        <div class="row pt-5">
          <!-- <div class="col-12 col-md-6 col-xl-3">
            <a [routerLink]="['/subject-details']">
              <div class="img-container">
                <img src="assets/images/one.png" class="d-block" alt="" />
                <div class="img-overlay">
                  <div
                    class="overlay-text d-flex justify-content-between align-items-center"
                  >
                    <h5 class="text-white">Ruby Garcia</h5>
                    <p class="text-primary mb-0">4.95</p>
                  </div>
                </div>
              </div>
            </a>
            <div class="content-box">
              <div
                class="d-flex justify-content-between align-items-center mb-2"
              >
                <h6>Physics</h6>
                <span>1 Month</span>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <span>20 sessions</span>
                <a href="#">
                  <img src="assets/images/Icon-ionic-md-time.svg" alt="" />
                </a>
              </div>
            </div>
            <div class="action-btn">
              <a href="#" class="btn btn-sm btn-gradient">
                Enroll Now
              </a>
              <a href="#" class="btn btn-sm btn-secondary">
                PKR 52,000
              </a>
            </div>
          </div> -->
          <div class="col-12 col-md-6 col-xl-3 mb-5 pb-5" *ngFor="let tuition of proposedTuitions">
            <a [routerLink]="['../tuition', tuition.id]">
              <div class="img-container">
                <img [lazyLoad]="
                    tuition.classes[0].instructor_profile_picture || 'assets/images/avatar.svg'
                  " class="d-block" alt="" />
                <div class="img-overlay">
                  <div class="overlay-text d-flex justify-content-between align-items-center">
                    <h5 class="text-white">
                      {{ tuition.classes[0].instructor_name }}
                    </h5>
                    <p class="text-primary mb-0">
                      <img src="assets/images/Icon-ionic-ios-star.svg" class="star-icon" alt="" />
                      {{ tuition.classes[0].instructor_rating }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <div class="content-box">
              <div class="row mb-2 pb-0">
                <h6 class="col-7 my-auto" ngbTooltip="{{ tuition.title }}" placement="top"
                  [disableTooltip]="tuition.title.length < 12" (click)="navigateToCourseDetail(tuition.id)">
                  {{ tuition.classes[0].title | shortText: 12 }}
                </h6>
                <span class="col-5 my-auto text-center">{{
                    getDurationOfCourse(tuition.start_date, tuition.end_date)
                  }}
                </span>
                <p class="mt-0 mb-0 text-lightDark subjects">
                  <span> {{ tuition.classes[0].subject.name }}</span>
                </p>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <span>{{ tuition.classes[0].total_sessions }} Session(s)</span>
                <div>
                  <span class="dropdown">
                    <a id="startDate" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <img src="assets/images/Icon-ionic-md-time.svg" class="time-icon" alt="" />
                    </a>
                    <div class="dropdown-menu" aria-labelledby="startDate">
                      <p class="text-success my-0">
                        Start Date:
                        {{ tuition.start_date | dateFormat: "fullDate" }}
                      </p>
                      <p class="text-success mb-0">
                        End Date:
                        {{ tuition.end_date | dateFormat: "fullDate" }}
                      </p>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="action-btn">
              <a (click)="addToCart(tuition)" [ngClass]="{ disabled: tuition?.added_to_cart }"
                class="btn btn-sm btn-gradient">
                Enroll Now
              </a>
              <a href="#" class="btn inactive-link btn-sm btn-secondary">
                {{tuition.is_free ? 'FREE' : 'PKR ' + (tuition.price | number)}}
              </a>
            </div>
          </div>
        </div>
        <p class="p-3 text-not-found" *ngIf="zeroSearchResults && showSearchTriggeredMessage">
          No matching result found
        </p>
        <p class="p-3" *ngIf="zeroSearchResults && !showSearchTriggeredMessage">
          Sorry! we cannot find any proposed tuitions related to your grade
        </p>
        <div class="text-center">
          <a (click)="loadMore()" *ngIf="!maxLimitReached && !zeroSearchResults && showLoadMoreButton"
            class="btn btn-outline-primary btn-load">
            Load more
            <svg xmlns="http://www.w3.org/2000/svg" class="ml-2" width="11.918" height="6.814"
              viewBox="0 0 11.918 6.814">
              <defs>
                <style>
                  .a {
                    fill: rgba(61, 64, 87, 0.4);
                  }
                </style>
              </defs>
              <path class="a"
                d="M12.149,16.006l4.506-4.51a.848.848,0,0,1,1.2,0,.859.859,0,0,1,0,1.206l-5.106,5.109a.85.85,0,0,1-1.174.025L6.436,12.707a.852.852,0,0,1,1.2-1.206Z"
                transform="translate(-6.188 -11.246)" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>