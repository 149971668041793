import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

const URLS: any = {
  // authentication end points
  login: { path: "identity/auth/token" },

  logout: { path: "identity/logout" },

  facebookLogin: { path: "identity/facebook/auth" },

  googleLogin: { path: "identity/google/auth" },

  refreshToken: { path: "identity/refresh/token" },

  forgotPassword: { path: "identity/forgot/password" },

  forgotPasswordVerify: { path: "identity/forgot/verify" },

  forgotPasswordChange: { path: "identity/forgot/change" },

  resendOTP: { path: "identity/resend/otp" },

  changePassword: { path: "identity/change/password" },

  //social media additional screen (phone no and type endpoint)
  socialMediaRegister: { path: "identity/social/register" },

  //registration end points

  register: { path: "identity/student/register" },
  //teacher registration first step
  teacherRegister: { path: "identity/instructor/register" },

  registerVerifyPhone: { path: "identity/verify/phone" },

  registerProfile: { path: "identity/register/profile" },

  instructorRegisterProfile: { path: "identity/register/instructor/profile" },

  //if user register through social media
  socailMediaRegister: { path: "identity/register/type" },

  //boards related end points
  getBoards: { path: "course/boards/list" }, //base identity path need to change

  //grade related end points
  getGrades: { path: "course/grades/list" }, //base identity path need to change

  //new student dashboard data end points
  getDashboardData: { path: "course/courses/dashboard" },

  //tuition related endpoints
  getTuitionDetail: { path: "course/courses" },

  getTuitionList: { path: "course/courses/category/1-on-1" },

  //getQualificationsList
  getQualifications: { path: "identity/qualifications" },

  //getSubjectsList
  getSubjects: { path: "course/subjects/list" },

  //course related endpoints
  getCourseDetail: { path: "course/courses" },

  getCourseList: { path: "course/courses/category/group" },

  //subjects end points
  getSubjectsList: { path: "course/subjects/list" },

  //update student profile
  updateStudentProfile: { path: "identity/student/profile" },

  //update instructor profile
  updateInstructorProfile: { path: "identity/instructor/profile" },

  //update user profile picture
  updateUserProfilePicture: { path: "repository/profile/picture" },

  //upload instructor resume
  uploadResumeInstructor: { path: "repository/instructor/resume" },

  //cart related endpoinst
  getCartItems: { path: "cart/items" },

  postCartItem: { path: "cart/item" },

  deleteCartItem: { path: "cart/item" },

  getUpdatedCartItems: { path: "cart/checkout" },

  checkoutCartItems: { path: "cart/checkout" },

  //enrolled student API's

  //get courses and one on one tuitions that are active
  getDashboardCourses: { path: "course/enrolled/dashboard" },
  //get todays sessions
  getDashboardSessions: { path: "lecture/dashboard" },
  getTodaysSessions: { path: "lecture/today/courses" },
  //get upcoming sessions
  getUpcomingSessions: { path: "lecture/upcoming/courses" },
  //get courses and one on one tuitions that are active/inactive
  getEnrolledCourses: { path: "course/enrolled/courses" },
  //get enrolled course detal
  getEnrolledCourseDetail: { path: "course/enrolled" },

  //get enrolled course's class homework
  getStudentHomeworkList: { path: "assignment/course" },
  //notification end point
  getNotifications: { path: "notification-client/socket.io" },

  //get categories i.e courses/one=on-one
  getCategories: { path: "course/categories" },

  //get upcoming sessions in enrolled course details page
  getSubjectUpcomingSessions: { path: "lecture/course" },

  //get notifications from db
  getNotificationsFromDb: { path: "notification/notifications" },
  readNotification: { path: "notification/notification/read" },

  //teacher dashboard endpoints
  getAllClasses: { path: "course/instructor/classes" },

  //get all sessions in class detail sessions tab
  getClassSessions: { path: "lecture/instructor/course" },

  //get syllabus list
  getSyllabusList: { path: "course/instructor/syllabi" },
  //update syllabus
  updateSyllabus: { path: "course/classes" },

  //post feedback
  postFeedbackToStudent: { path: "lecture/attendance" },

  //attach notes to sessions
  addSessionNotes: { path: "repository/file/upload" },
  //delete notes of session
  deleteSessionNotes: { path: "repository/file/remove" },
  //get home work listing
  getHomeworkList: { path: "assignment/instructor/assignments" },

  //addHomeworkFile
  addHomeworkFile: { path: "repository/file/upload/orphan" },
  //add new homework
  addHomework: { path: "assignment/assignment" },
  //get homework
  getHomework: { path: "assignment/assignment" },

  //upload student homework
  uploadHomework: { path: "repository/assignment/solution" },

  //submit homework feedback file
  uploadHomeworkFeedbackFile: { path: "repository/file/upload" },
  //submit homework feedback
  submitHomeworkFeedback: { path: "assignment/instructor/feedback" },

  //getInstructorActiveClasses
  getInstructorActiveClasses: { path: "course/instructor/dashboard/classes" },

  //teacher dashboard data
  getInstructorTodayAndUpcomingSessions: {
    path: "lecture/instructor/dashboard",
  },

  //test related endpoints
  addNewTest: { path: "quiz/quizzes" },
  deleteQuestion: { path: "quiz/questions" },
  getTest: { path: "quiz/quizzes" },
  addNewQuestion: { path: "quiz/questions" },
  getInstructorTestListing: { path: "quiz/" },
  getStudentTestListing: { path: "quiz/" },

  //submit attempt test
  attemptTest: { path: "quiz/attempt/start" },
  submitQuestion: { path: "quiz/attempt/question" },
  submitTest: { path: "quiz/attempt/submit" },
  getAttemptedTest: { path: "quiz/quizzes/attempt" },

  //get test pdf
  getAttemptedTestPDF: { path: "quiz/attempt/" },

  //mock test attempt endpoints
  getMockTest: { path: "quiz/mock-test" },
  attemptMockTest: { path: "quiz/mock-attempt/start" },
  submitMockTestQuestion: { path: "quiz/mock-attempt/question" },
  submitMockTest: { path: "quiz/mock-attempt/submit" },
  getAttemptedMockTestPDF: { path: "quiz/mock-attempt/" },

  //my schedules
  getTeacherSchedule: { path: "lecture/instructor/myschedule/" },
  getStudentSchedule: { path: "lecture/student/myschedule/" },
  //for schedule screen
  getEnrolledCourseList: { path: "course/student/mycourseslist" },
  getEnrolledClassList: { path: "course/student/myclasseslist" },
  //for teacher my classes list
  getTeacherClassesList: { path: "course/instructor/myclasseslist" },

  // get student profile
  getStudentProfile: { path: "identity/student/profile" },
  getInstructorProfile: { path: "identity/instructor/profile" },

  //study material end points
  getStudentMaterialMenu: { path: "study_material/menu" },
  getStudentMaterial: { path: "study_material/material/" },

  //rate instructor
  rateInstructor: { path: "lecture/rate-instructor" },

  //get base url
  baseUrl: { path: "" },

  //landing page end points
  //get course plans
  getCoursePlans: { path: "course/list" },
  //get available classes
  getAvailableClasses: { path: "course/classes/list" },
  //get instructor
  getInstructorsList: { path: "identity/instructors-list" },
  //get top rated instructors
  getTopRatedInstructors: { path: "identity/instructors-top-list" },

  //proposed courses for landing pages
  getProposedCoursesLandingPage: { path: "course/public/group" },

  //proposed tuitions for landing pages
  getProposedTuitionsLandingPage: { path: "course/public/1-on-1" },

  //course details for landing pages
  getCourseLandingPage: { path: "course/public" },

  //study material end points
  getCoursesMenu: { path: "course/menu/group" },
  getTuitionsMenu: { path: "course/menu/1-on-1" },

  //contact us / request demo
  contactUs: { path: "notification/contact-us/mail" },
  requestDemo: { path: "notification/contact-us/demo" },

  //update session title
  updateSessionTitle: { path: "lecture/lectures" },

  //order related 
  addNewOrder: { path: "order/add" },
  cancelOrder: { path: "order/cancel" },
  getOrderDetails: { path: "order/details" },
  verifyForeePayOrder: { path: "order/foreepay/postprocessing" },


  //enrollFreeCourse
  enrollFreeCourse: { path: "course/enroll/free" },

  //get student purchases
  getStudentPurchases: { path: "order/orders" },

  //get public url
  getPublicUrl: { path: "repository/user/file-url" }
};

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private apiUrl: string = "";

  constructor() {
    this.apiUrl = environment.api_url;
  }

  public get(name: string): string {
    return `${this.apiUrl}${URLS[name].path}`;
  }
}
