import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {
  constructor(private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  //on subscribe
  public subscribeNewsletter() {
    //api call to subscribe news letter
    this.toastr.success('You have been subscribed successfully', 'Success');
  }

}
