import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private metaTagService: Meta, private metaTitleService: Title) { }

  ngOnInit(): void {
    //add tags for SEO

    this.metaTagService.updateTag({ name: 'keywords', content: 'vivid online learning' });
    this.metaTitleService.setTitle('Privacy Policy | VIVID Smart Learning');
    this.metaTagService.updateTag({ name: 'description', content: 'Checkout privacy policy for vivid online learning platform.' });
  }
}

