import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TeacherService } from 'src/app/services/teacher.service';
import { ToastrService } from 'ngx-toastr';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-session-feedback',
  templateUrl: './session-feedback.component.html',
  styleUrls: ['./session-feedback.component.scss']
})
export class SessionFeedbackComponent implements OnInit {
  @Input() studentId: string;
  @Input() feedback: string;
  @Output() updateFeedback: EventEmitter<string> = new EventEmitter();

  //editor instance
  public Editor = ClassicEditor;
  public feedbackFormGroup: FormGroup = new FormGroup({
    'feedbackFormControl': new FormControl(null, [Validators.required])
  });
  constructor(private activeModal: NgbActiveModal, private utilityService:UtilityService, private teacherService: TeacherService, private toastr: ToastrService) { }

  ngOnInit(): void {
    console.log(this.feedback)
    this.feedbackFormGroup.controls.feedbackFormControl.patchValue(this.feedback);
  }

  public closeModal() {
    this.activeModal.close();
  }


  //api call to submit feedback against a particular student
  public submitFeedback() {
    this.feedbackFormGroup.markAllAsTouched();
    if (this.feedbackFormGroup.invalid) return;
    const data = {
      feedback: this.feedbackFormGroup.controls.feedbackFormControl.value
    };
    this.teacherService.postFeedback(this.studentId, data).subscribe(response => {
      this.updateFeedback.next(data.feedback);
      this.toastr.success('Feedback Sent Successfully', 'Success');
      this.closeModal();
    })
  }

  public textEditorChange(event) {
    this.utilityService.textEditorChange(this.feedbackFormGroup.controls.feedbackFormControl, 4, 1000);
  }
}
