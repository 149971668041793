import { Injectable } from '@angular/core';
// import { AuthService } from "angularx-social-login";
// import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider } from "@abacritt/angularx-social-login";

@Injectable({
  providedIn: 'root'
})
export class SocialMediaService {
  public isUserFromSocialMedia: boolean = false;
  constructor(
    private authService: SocialAuthService,
  ) {
    // this.authService.authState.subscribe(user => {
    //   debugger
    //   if (user) {
    //     this.isUserFromSocialMedia = true;
    //   } else {
    //     this.isUserFromSocialMedia = false;
    //   }
    // });
  }

  public loginWithFacebook() {
    return this.authService.signIn(FacebookLoginProvider.PROVIDER_ID); // angular social login package methods
  }

  // public loginWithGoogle() {
  //   return this.authService.signIn(GoogleLoginProvider.PROVIDER_ID); // angular social login package methods
  // }

  public loginWithGoogle(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.authService.authState.subscribe(user => {
        let userData: any = {};
        if (user) {
          userData = user;
          this.isUserFromSocialMedia = true;
        } else {
          this.isUserFromSocialMedia = false;
        }
        resolve(userData);
      }, error => {
        reject(error);
      });
    });
  }

  googleSignin(googleWrapper: any) {
    googleWrapper.click();
  }

  public signOut(): void {
    if (this.isUserFromSocialMedia) {
      this.authService.signOut();
    }
  }
}
