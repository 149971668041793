import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-class-details-unenrolled',
  templateUrl: './class-details-unenrolled.component.html',
  styleUrls: ['./class-details-unenrolled.component.scss']
})
export class ClassDetailsUnenrolledComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
