import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ModalService } from 'src/app/services/modal.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-register-free',
  templateUrl: './register-free.component.html',
  styleUrls: ['./register-free.component.scss']
})
export class RegisterFreeComponent implements OnInit {
  @Input() forTuitionRequest: boolean;
  public user; //current user object
  public isStudent: boolean;
  constructor(
    private modalService: ModalService,
    private authService: AuthService,
    private utilityService: UtilityService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getCurrentUser();
    this.isStudent = this.user?.type === "student" ? true : false;
    this.authService.userDataObservable.subscribe((user) => {
      this.user = user;
    });
  }
  public openRegistrationModal(userType: string): void {
    if (this.forTuitionRequest) {
      let baseRoute = this.user ? '/student-dashboard/' : '/';
      this.router.navigate([baseRoute + 'contact-us'], { queryParams: { callerDemo: true, callerTuitionReq: true } });
      return;
    }
    if (this.authService.isAuthenticated()) return; // if we have user object  in local storage don't open login modal
    this.utilityService.setUserType(userType);
    this.modalService.open(2, 1); // same component login for teacher and student
  }
}
