<section class="lms-achievement-section">
  <div class="container">
    <div class="title-box">
      <h3  class="text-dark">TALEEMLY’s Mission</h3>
      <p class="text-dark">TALEEMLY strives for a balanced ecosystem of online learning. Our online learning platform is designed to make your education a priority through Smart Learning.</p>
    </div>
    <div class="stats">
      <div class="stat-box">
        <h1 class="heading-1">100+</h1>
        <span class="text-primary">Course</span>
      </div>
      <div class="stat-box">
        <h1 class="heading-1">1500+</h1>
        <span class="text-primary">Enroll Students</span>
      </div>
      <div class="stat-box">
        <h1 class="heading-1">500+</h1>
        <span class="text-primary">Expert Instructors</span>
      </div>
      <div class="stat-box">
        <h1 class="heading-1">2000+</h1>
        <span class="text-primary">Profile Review</span>
      </div>
    </div>
  </div>
</section>
