import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpService } from './http.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeacherService {
  public updateStatsSubject: Subject<boolean> = new Subject();
  public updateStatsObservable: Observable<boolean> = this.updateStatsSubject.asObservable();
  constructor(private apiService: ApiService, private http: HttpService) { }

  //get all classes of instructor in my classes screen
  public getInstructorAllClasses(params: any): Observable<any> {
    return this.http.get(this.apiService.get("getAllClasses"), params);
  }

  //update teacher profile
  public updateProfile(data: any) {
    return this.http.post(`${this.apiService.get('updateInstructorProfile')}`, data);
  }

  //get class details in class details screen
  public getClassDetails(classId: string, courseId: string): Observable<any> {
    return this.http.get(`${this.apiService.get("getAllClasses")}/${classId}/${courseId}`);
  }

  //get teacher class sessions in class details screen
  public getClassSessions(classId: string, courseId: string): Observable<any> {
    return this.http.get(`${this.apiService.get("getClassSessions")}/${courseId}/class/${classId}`);
  }

  //get syllabus list for syllabus tab
  public getSyllabusList(gradeId: string, subjectId: string): Observable<any> {
    return this.http.get(`${this.apiService.get("getSyllabusList")}/${gradeId}/${subjectId}`);
  }

  //update syllabus
  public updateSyllabus(classId: string, data: any): Observable<any> {
    return this.http.patch(`${this.apiService.get("updateSyllabus")}/${classId}/syllabus`, data);
  }

  //post feedback to student for paticular  session
  public postFeedback(attendanceId: string, data: any): Observable<any> {
    return this.http.patch(`${this.apiService.get("postFeedbackToStudent")}/${attendanceId}/feedback`, data);
  }

  //upload notes of particular session
  public uploadSessionNotes(data: FormData): Observable<any> {
    return this.http.post(this.apiService.get("addSessionNotes"), data, false, {
      skipContentType: "true"
    });
  }

  //delete notes of particular session
  public deleteSessionNotes(params: any): Observable<any> {
    return this.http.patch(this.apiService.get("deleteSessionNotes"), {}, params);
  }

  //get home work listing for particular class
  public getHomeworkList(classId: string, courseId: string) {
    return this.http.get(`${this.apiService.get('getHomeworkList')}/${courseId}/${classId}`);
  }


  //upload homework file
  public uploadHomeworkFile(data: FormData): Observable<any> {
    return this.http.post(this.apiService.get("addHomeworkFile"), data, false, {
      skipContentType: "true"
    });
  }

  //add homework
  public createHomework(data: any): Observable<any> {
    return this.http.post(`${this.apiService.get("addHomework")}`, data)
  }

  //get homework details
  public getHomework(courseId: any): Observable<any> {
    return this.http.get(`${this.apiService.get("getHomework")}/${courseId}`);
  }

  //submimt homework  feedback file
  public uploadHomeworkFeedbackFile(data: FormData): Observable<any> {
    return this.http.post(this.apiService.get("uploadHomeworkFeedbackFile"), data, false, {
      skipContentType: "true"
    });
  }

  //submimt homework  feedback
  public submitHomeworkFeedback(data: any): Observable<any> {
    return this.http.post(this.apiService.get("submitHomeworkFeedback"), data);
  }


  //dashboard endpoints


  //get teacher dashboard data
  public getTodaysAndUpcomingSessions(): Observable<any> {
    return this.http.get(this.apiService.get("getInstructorTodayAndUpcomingSessions"));
  }


  //get active classes
  public getActiveClasses(): Observable<any> {
    return this.http.get(this.apiService.get("getInstructorActiveClasses"));
  }

  //update session title
  public updateSessionTitle(data: any, sessionId:any): Observable<any> {
    return this.http.patch(`${this.apiService.get("updateSessionTitle")}/${sessionId}/title`, data);
  }
}
