import { Injectable } from '@angular/core';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {

  constructor(private modalServie: ModalService) { }

  //get confirmation
  public async getConfirmation(title: string, message: string, yesString: string = "Yes", noString: string = "No", showNoButton: boolean = true) {
    const modalRef = this.modalServie.open(12, 3, { size: 'lg', backdrop: 'static', static: true, backdropClass: 'confirm-modal-backdrop' });
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.yesString = yesString;
    modalRef.componentInstance.noString = noString;
    modalRef.componentInstance.showNoButton = showNoButton;
    modalRef.componentInstance.modalRef = modalRef;
    const confirmationResult = await modalRef.result.then(result => { return result });
    return confirmationResult ? true : false;
  }
}
