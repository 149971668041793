import { Component, OnInit } from "@angular/core";
import { LandingPageService } from "src/app/services/landing-page.service";
import * as moment from "moment";
@Component({
  selector: "app-recommended-course",
  templateUrl: "./recommended-course.component.html",
  styleUrls: ["./recommended-course.component.scss"],
})
export class RecommendedCourseComponent implements OnInit {
  public availableClasses: any[];
  constructor(private landingPageService: LandingPageService) {
    landingPageService.getAvailableClasses().subscribe((classes) => {
      this.availableClasses = classes.data;
      this.availableClasses.map((classs) => {
        classs.duration = this.getClassDuration(classs);
      });
    });
  }

  ngOnInit(): void {}

  public getClassDuration(classs: any) {
    let startTime = moment(classs.start_time, 'HH:mm:ss');
    let endTime = moment(classs.end_time, 'HH:mm:ss');
    return endTime.diff(startTime,'minutes');
  }
}
