<div class="container">
  <div class="course-details-box">
    <app-title-box *ngIf="callerDashboard"></app-title-box>
    <div class="course-detail">
      <div class="title-box d-flex justify-content-between align-items-center flex-wrap">
        <h3 class="heading-3">{{ course?.title }}</h3>
        <a [routerLink]="[callerDashboard ? '/student-dashboard/proposed-courses' : '/courses']"
          class="btn btn-outline-primary btn-back">Back to Courses</a>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="back-img">
            <div class="img-container ml-lg-auto">
              <img [src]="course?.media" alt="" />
            </div>
          </div>
        </div>
        <div class="col-lg-8 pl-4">
          <div class="details">
            <div class="title d-flex justify-content-between align-items-center flex-wrap">
              <span class="total-session-text">Total Sessions: {{ course?.total_sessions }}</span>
              <div>
                <a (click)="addToCart(course)" [ngClass]="{ disabled: course?.added_to_cart }"
                  class="btn btn-sm btn-gradient mr-2">
                  Enroll Now
                </a>
                <a href="#" class="btn inactive-link btn-sm btn-secondary">
                  {{course.is_free ? 'FREE' : 'PKR ' + (course.price | number)}}
                </a>
              </div>
            </div>
            <p class="main-paragraph" [innerHTML]="course?.description"></p>
            <div class="course-information d-flex justify-content-between flex-wrap">
              <div class="media">
                <img class="mr-2" src="assets/images/Icon-ionic-md-time.svg" alt="" />
                <div class="media-body">
                  <p class="text-success my-0">
                    Start Date: {{ course?.start_date | dateFormat:'fullDate' }}
                  </p>
                  <p class="text-success">
                    End Date:&nbsp;&nbsp; {{  course?.end_date | dateFormat:'fullDate' }}
                  </p>
                </div>
              </div>
              <div class="media">
                <img class="mr-2" src="assets/images/group.svg" alt="" />
                <div class="media-body">
                  <p class="text-primary my-0">
                    Maximum Seats: {{ course?.total_seats }}
                  </p>
                  <p class="text-primary">
                    Available Seats:&nbsp; {{ course?.available_seats }}
                  </p>
                </div>
              </div>
              <div class="media">
                <img class="mr-2" src="assets/images/Icon material-group.svg" alt="" />
                <div class="media-body">
                  <p class="text-secondary my-0">
                    {{ course.category.category_type === '1 on 1' ? '1 on 1 Tuition' : 'Group Sessions'}}</p>
                </div>
              </div>
            </div>
            <div class="subjects">
              <ul *ngIf="isCourse" class="nav nav-pills subject-pills nav-justified" id="pills-tab" role="tablist">
                <!-- [ngClass]="{ 'active': (classId)? course?.classes.indexOf(class) === 0 }" -->

                <li class="nav-item" *ngFor="let class of course?.classes">
                  <a class="nav-link" [ngClass]="{
                      active: getClassPillActiveStatus(
                        class,
                        course?.classes.indexOf(class) === 0
                      )
                    }" (click)="updateQueryParams(class.id, 1)" id="{{ class.id }}" data-toggle="pill"
                    href="#pill-{{ class.id }}" role="tab" aria-controls="physicsSub" aria-selected="true">
                    <h6 class="my-0">{{ class?.subject.name }}</h6>
                  </a>
                </li>
              </ul>

              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade" [ngClass]="{
                    'show active': getClassPillActiveStatus(
                      class,
                      course?.classes.indexOf(class) === 0
                    )
                  }" id="pill-{{ class.id }}" role="tabpanel" [attr.aria-labelledby]="'pill-' + course.id"
                  *ngFor="let class of course?.classes">
                  <div class="subject-info">
                    <ul class="nav nav-pills nav-pills-inner  nav-pills-list nav-justified mb-3"
                      id="pills-tab-{{ class.id }}" role="tablist">
                      <li class="nav-item" *ngFor="let levelTwoPill of levelTwoPills">
                        <a class="nav-link active" [ngClass]="{
                            active: getLevelTwoPillStatus(
                              tabId,
                              levelTwoPill.index,
                              class.id
                            )
                          }" (click)="
                            updateQueryParams(class.id, levelTwoPill.index)
                          " [attr.id]="levelTwoPill.id + class.id" data-toggle="pill" [href]="
                            levelTwoPill.href + class.id + '-' + course.id
                          " role="tab" [attr.aria-controls]="levelTwoPill.id + class.id" [attr.aria-selected]="
                            getLevelTwoPillStatus(
                              tabId,
                              levelTwoPill.index,
                              class.id
                            )
                          ">{{ levelTwoPill.name }}</a>
                      </li>
                    </ul>
                    <div class="tab-content inner-content" id="pills-tabContent">
                      <div class="tab-pane fade" [ngClass]="{
                          'show active': getLevelTwoPillStatus(
                            tabId,
                            1,
                            class.id
                          )
                        }" id="classDetails-{{ class.id }}-{{ course.id }}" role="tabpanel"
                        [attr.aria-label]="'classDetails-tab-' + class.id">
                        <div class="row">
                          <div class="col-md-3">
                            <div class="img-container">
                              <img [lazyLoad]="class?.instructor_profile_picture || 'assets/images/avatar.svg'"
                                class="d-block" alt="" />
                              <div class="img-overlay">
                                <div class="overlay-text">
                                  <p class="text-primary mb-0">
                                    <img src="assets/images/Icon-ionic-ios-star.svg" class="start-icon" alt="">
                                    {{class.instructor_rating}}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-9 pl-md-0">
                            <div class="instructor-info">
                              <div class="instructor info-box">
                                <p class="text-primary">Instructor</p>
                                <h5>{{ class.instructor_name }}</h5>
                              </div>
                              <div class="status info-box">
                                <p class="text-primary">Status</p>
                                <h5>{{ course.status }}</h5>
                              </div>
                            </div>
                            <div class="session-info">
                              <div class="info-box">
                                <p class="text-primary">Session Days</p>
                                <h5>
                                  <span *ngFor="let day of jsonDecode(class.pivot.days); index as i">{{
                                  day | compactDayName
                                }}<span *ngIf="i<jsonDecode(class.pivot.days).length-1">, </span>
                                  </span>
                                </h5>
                              </div>
                              <div class="info-box">
                                <p class="text-primary">Total Sessions</p>
                                <h5>{{ class.total_sessions }}</h5>
                              </div>
                              <div class="info-box">
                                <p class="text-primary">Session Time</p>
                                <h5>
                                  {{ class.start_time | changeTimeFormat12Hour }} -
                                  {{ class.end_time | changeTimeFormat12Hour}}
                                </h5>
                              </div>
                            </div>
                            <div class="checks d-flex justify-content-between flex-wrap pr-5">
                              <p>
                                Live Interaction sessions
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                  <path id="Icon_awesome-check-circle" data-name="Icon awesome-check-circle"
                                    d="M26.562,13.563a13,13,0,1,1-13-13A13,13,0,0,1,26.562,13.563Zm-14.5,6.883L21.7,10.8a.839.839,0,0,0,0-1.186L20.518,8.429a.839.839,0,0,0-1.186,0l-7.866,7.866L7.793,12.622a.839.839,0,0,0-1.186,0L5.421,13.808a.839.839,0,0,0,0,1.186l5.452,5.452A.839.839,0,0,0,12.059,20.446Z"
                                    transform="translate(-0.563 -0.563)" fill="#347ff0" />
                                </svg>
                              </p>
                              <p>
                                Notes, Assignments
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                  <path id="Icon_awesome-check-circle" data-name="Icon awesome-check-circle"
                                    d="M26.562,13.563a13,13,0,1,1-13-13A13,13,0,0,1,26.562,13.563Zm-14.5,6.883L21.7,10.8a.839.839,0,0,0,0-1.186L20.518,8.429a.839.839,0,0,0-1.186,0l-7.866,7.866L7.793,12.622a.839.839,0,0,0-1.186,0L5.421,13.808a.839.839,0,0,0,0,1.186l5.452,5.452A.839.839,0,0,0,12.059,20.446Z"
                                    transform="translate(-0.563 -0.563)" fill="#347ff0" />
                                </svg>
                              </p>
                            </div>
                            <div class="checks d-flex justify-content-between flex-wrap pr-5">
                              <p>
                                Engaging Quizzes & Polls
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                  <path id="Icon_awesome-check-circle" data-name="Icon awesome-check-circle"
                                    d="M26.562,13.563a13,13,0,1,1-13-13A13,13,0,0,1,26.562,13.563Zm-14.5,6.883L21.7,10.8a.839.839,0,0,0,0-1.186L20.518,8.429a.839.839,0,0,0-1.186,0l-7.866,7.866L7.793,12.622a.839.839,0,0,0-1.186,0L5.421,13.808a.839.839,0,0,0,0,1.186l5.452,5.452A.839.839,0,0,0,12.059,20.446Z"
                                    transform="translate(-0.563 -0.563)" fill="#347ff0" />
                                </svg>
                              </p>
                              <p>
                                 Instant Doubt Solving
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                  <path id="Icon_awesome-check-circle" data-name="Icon awesome-check-circle"
                                    d="M26.562,13.563a13,13,0,1,1-13-13A13,13,0,0,1,26.562,13.563Zm-14.5,6.883L21.7,10.8a.839.839,0,0,0,0-1.186L20.518,8.429a.839.839,0,0,0-1.186,0l-7.866,7.866L7.793,12.622a.839.839,0,0,0-1.186,0L5.421,13.808a.839.839,0,0,0,0,1.186l5.452,5.452A.839.839,0,0,0,12.059,20.446Z"
                                    transform="translate(-0.563 -0.563)" fill="#347ff0" />
                                </svg>
                              </p>
                            </div>
                            <div class="checks d-flex justify-content-between flex-wrap pr-5">
                              <p>
                                Replays of Past Sessions
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                  <path id="Icon_awesome-check-circle" data-name="Icon awesome-check-circle"
                                    d="M26.562,13.563a13,13,0,1,1-13-13A13,13,0,0,1,26.562,13.563Zm-14.5,6.883L21.7,10.8a.839.839,0,0,0,0-1.186L20.518,8.429a.839.839,0,0,0-1.186,0l-7.866,7.866L7.793,12.622a.839.839,0,0,0-1.186,0L5.421,13.808a.839.839,0,0,0,0,1.186l5.452,5.452A.839.839,0,0,0,12.059,20.446Z"
                                    transform="translate(-0.563 -0.563)" fill="#347ff0" />
                                </svg>
                              </p>
                              <p>
                                Free Study Material
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                                  <path id="Icon_awesome-check-circle" data-name="Icon awesome-check-circle"
                                    d="M26.562,13.563a13,13,0,1,1-13-13A13,13,0,0,1,26.562,13.563Zm-14.5,6.883L21.7,10.8a.839.839,0,0,0,0-1.186L20.518,8.429a.839.839,0,0,0-1.186,0l-7.866,7.866L7.793,12.622a.839.839,0,0,0-1.186,0L5.421,13.808a.839.839,0,0,0,0,1.186l5.452,5.452A.839.839,0,0,0,12.059,20.446Z"
                                    transform="translate(-0.563 -0.563)" fill="#347ff0" />
                                </svg>
                              </p>
                            </div>
                          </div>
                        </div>
                        <!-- <app-class-details
                          *ngIf="tabId === 1 && this.classId === class.id"
                          [attendanceList]="attendanceList"
                          [enrollment]="course?.enrollments[0]"
                          [classDetails]="class"
                          [courseStatus]="course.status"
                        >
                        </app-class-details> -->
                      </div>
                      <div class="tab-pane fade" [ngClass]="{
                          'show active': getLevelTwoPillStatus(
                            tabId,
                            2,
                            class.id
                          )
                        }" id="syllabus-{{ class.id }}-{{ course.id }}" role="tabpanel"
                        [attr.aria-label]="'syllabus-' + class.id">
                        <app-sylabus-details *ngIf="tabId === 2" [syllabusDetails]="[
                            class.syllabus,
                            class.syllabus_desc,
                            class.subject?.name
                          ]"></app-sylabus-details>
                      </div>
                      <div class="tab-pane fade" [ngClass]="{
                          'show active': getLevelTwoPillStatus(
                            tabId,
                            3,
                            class.id
                          )
                        }" id="sessions-{{ class.id }}-{{ course.id }}" role="tabpanel"
                        [attr.aria-label]="'sessions-tab-' + class.id">
                        <app-session-details *ngIf="tabId === 3" [class]="class">
                        </app-session-details>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="tab-pane fade" [ngClass]="{
                    'show active': course.classes.indexOf(class) === 0
                  }" id="pill-{{ class.id }}" role="tabpanel" aria-labelledby="pill-physics-tab"
                  *ngFor="let class of course?.classes">
                  <div class="subject-info"> -->

              <!-- </div> -->
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>