import { Injectable } from "@angular/core";
import { Socket } from "ngx-socket-io";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject } from "rxjs";
import { Md5 } from "ts-md5/dist/md5";
import { environment } from "../../environments/environment";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";
import { HttpService } from "./http.service";
import * as moment from "moment";
import { tap } from "rxjs/operators";
import { SocketNamespaceService } from './socket-namespace.service';
@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private user = this.authService.getCurrentUser();
  private notificationList: any[] = [];
  private socketNameSpace: SocketNamespaceService;
  private socket: any;
  private notificationDataSubject: Subject<object> = new Subject();
  public notificationDataObservable: Observable<
    object
  > = this.notificationDataSubject.asObservable();
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private http: HttpService,
    private toastr: ToastrService
  ) {

    this.connectSocket();

    authService.userDataObservable.subscribe(user => {
      this.user = user;
      this.connectSocket();
    });
    this.connect();
  }

  public connectSocket() {
    this.socket = new SocketNamespaceService({
      url: environment.api_url,
      options: {
        path: "/notification-client/socket.io",
        reconnectionDelay: 5000,
        reconnectionAttempts: 1,
        query: {
          token: Md5.hashStr(this.user.id.toString()),
        },
      },
    });
  }

  //listen to real time notificaitons
  public listenNotifications() {
    this.socket.on(
      `notify-channel-${Md5.hashStr(
        this.authService.getCurrentUserId().toString()
      )}`,
      (notification) => {
        let data = this.jsonDecode(notification);
        data.fromDb = false;
        if (data.payload.type === 10) {
          this.user.student_profile.is_enrolled_to_course = 1;
          this.authService.setCurrentUser(this.user);
        }
        data.created_at_time = moment(data.created_at).local().format("h:mm A");
        if (!(this.user.type in data.payload.metadata)) return;
        if (
          this.notificationList.find(
            (notification) => notification.id === data.id
          )
        )
          return;
        this.notificationDataSubject.next(data);
        this.notificationList.push(data);
        this.toastr.info(data.payload.notification, "Notification", {
          positionClass: "toast-top-right",
        });
      }
    );
  }

  public connect() {
    // this.socket.connect();
    this.listenNotifications();
  }

  public disconnect() {
    this.socket.disconnect();
    this.socket.removeAllListeners();
    this.socket.ioSocket.disconnect();
  }

  public getNotificationsFromDb(): Observable<any> {
    this.notificationList = [];
    return this.http.get(this.apiService.get("getNotificationsFromDb")).pipe(
      tap((notification) => {
        notification.data.forEach((element) => {
          // let payload = this.jsonDecode(element.payload);
          // element.payload = payload;
          element.fromDb = true;
          element.created_at_time = moment(element.created_at)
            .local()
            .format("h:mm A");
          // this.notificationDataSubject.next(element);
          this.notificationList.push(element);
        });
        return notification.data;
      })
    );
  }

  public readNotification(data: any) {
    return this.http.post(this.apiService.get("readNotification"), data);
  }

  public jsonDecode(item) {
    return JSON.parse(item);
  }

  public getNotificationByCourseIdClassId(courseId: any, classId: any) {
    return this.notificationList?.filter((notification: any) => {
      return (
        notification?.payload?.metadata?.course_id === courseId &&
        notification?.payload?.metadata?.class_id === classId
      );
    });
  }

  public getAllNotifications() {
    return this.notificationList;
  }
}
