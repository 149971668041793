import { Component, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { StudentService } from "src/app/services/student.service";

@Component({
  selector: "app-rating-modal",
  templateUrl: "./rating-modal.component.html",
  styleUrls: ["./rating-modal.component.scss"],
})
export class RatingModalComponent implements OnInit {
  @Input() class: any;
  @Input() sessionId: any;
  @Output() onRatingSubmitted: Subject<any> = new Subject();
  public rating: any;
  public selectedRatingLevel: number = 0;
  public stars: any[] = [
    { value: 5, title: "5 stars" },
    { value: 4, title: "4 stars" },
    { value: 3, title: "3 stars" },
    { value: 2, title: "2 stars" },
    { value: 1, title: "1 star" },
  ];
  constructor(
    private activeModal: NgbActiveModal,
    private studentService: StudentService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  public closeModal() {
    this.activeModal.close();
  }

  public onRatingClicked(ratingNumber: number) {
    this.selectedRatingLevel = ratingNumber;
  }

  public onRatingSubmit() {
    if (!this.selectedRatingLevel)
      return this.toastr.error("Please select atleast one star", "Error");
    let data = {
      instructor_id: this.class.instructor_id,
      lecture_id: this.sessionId,
      rating: this.selectedRatingLevel,
    };
    this.studentService.rateInstructor(data).subscribe((response) => {
      this.toastr.success(
        "Your rating has been submitted successfully!",
        "Success"
      );
      this.activeModal.close();
      this.onRatingSubmitted.next(response.data);
    });
  }
}
