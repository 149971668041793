<div class="container">
  <div class="course-details-box">
    <app-title-box *ngIf="callerDashboard"></app-title-box>
    <div class="course-detail">
      <div class="title-box d-flex justify-content-between align-items-center flex-wrap">
        <h3 class="heading-3">
          {{ tuition?.title }}
          <span class="font-light">(1 on 1)</span>
        </h3>
        <a [routerLink]="[ callerDashboard ? '/student-dashboard/proposed-courses': '/tuitions']" class="btn btn-outline-primary btn-back">Back to Courses</a>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="back-img">
            <div class="img-container ml-lg-auto">
              <img [lazyLoad]="tuition?.media" alt="" />
            </div>
          </div>
        </div>
        <div class="col-lg-8 pl-4">
          <div class="details">
            <div class="title d-flex justify-content-between align-items-center flex-wrap">
              <span class="total-session-text">Total Sessions: {{tuition?.total_sessions}}</span>
              <div>
                <a (click)="addToCart(tuition)" [ngClass]="{ disabled: tuition?.added_to_cart }" class="btn btn-sm btn-gradient mr-2">
                  Enroll Now
                </a>
                <a href="#" class="btn inactive-link btn-sm btn-secondary">
                  {{tuition.is_free ? 'FREE' : 'PKR ' + (tuition.price | number)}}
                </a>
              </div>
            </div>
            <p class="main-paragraph" [innerHTML]="tuition?.description"></p>
            <div class="course-information d-flex flex-wrap justify-content-around">
              <div class="media mr-3">

                <div class="media-body ml-4 mr-3">
                  <p class="text-success my-0">
                    <fa-icon [icon]="bookIcon"></fa-icon>
                    {{ tuition?.classes[0]?.subject?.name }}
                  </p>
                </div>
              </div>
              <div class="media">
                <img class="mr-2" src="assets/images/Icon-ionic-md-time.svg" alt="" />
                <div class="media-body">

                  <p class="text-success my-0">
                    Start Date: {{ tuition?.start_date | dateFormat:'fullDate' }}
                  </p>
                  <p class="text-success">
                    End Date:&nbsp;&nbsp; {{ tuition?.end_date | dateFormat:'fullDate' }}
                  </p>
                </div>
              </div>
              <!-- <div class="media">
                <img class="mr-2" src="assets/images/group.svg" alt="" />
                <div class="media-body" *ngIf="tuition.category.slug!=='courses'">
                  <p class="text-primary my-0">
                    Maximum Seats: {{ tuition?.total_seats }}
                  </p>
                  <p class="text-primary">
                    Available Seats: {{ tuition?.available_seats }}
                  </p>
                </div>
              </div> -->
              <div class="media ml-5">
                <img class="mr-2" src="assets/images/Icon material-group.svg" alt="" />
                <div class="media-body">
                  <p class="text-secondary my-0">1 on 1 Tuition</p>
                </div>
              </div>
            </div>
            <div class="subjects">
              <div class="subject-info">
                <div class="row">
                  <div class="col-md-3">
                    <div class="img-container">
                      <img [lazyLoad]="tuition?.classes[0].instructor_profile_picture || 'assets/images/avatar.svg'" class="d-block" alt="" />
                      <div class="img-overlay">
                        <div class="overlay-text">
                          <p class="text-primary mb-0">
                            <img src="assets/images/Icon-ionic-ios-star.svg" class="start-icon" alt="">
                            {{tuition?.classes[0].instructor_rating}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-9 pl-md-0">
                    <div class="instructor-info">
                      <div class="instructor info-box">
                        <p class="text-primary">Instructor</p>
                        <h5>{{ tuition?.classes[0].instructor_name }}</h5>
                      </div>
                      <div class="status info-box">
                        <p class="text-primary">Status</p>
                        <h5>
                          {{
                            tuition?.status
                          }}
                        </h5>
                      </div>
                    </div>
                    <div class="session-info">
                      <div class="info-box">
                        <p class="text-primary">Session Days</p>
                        <h5><span *ngFor="let day of jsonDecode(tuition?.classes[0].pivot.days); index as i">{{
                          day | compactDayName
                        }}<span *ngIf="i<jsonDecode(tuition?.classes[0].pivot.days).length-1">, </span>
                          </span>
                        </h5>
                      </div>
                      <div class="info-box">
                        <p class="text-primary">Total Sessions</p>
                        <h5>{{ tuition?.total_sessions }}</h5>
                      </div>
                      <div class="info-box">
                        <p class="text-primary">Session Time</p>
                        <h5>
                          {{ tuition?.classes[0].start_time | changeTimeFormat12Hour }} -
                          {{ tuition?.classes[0].end_time | changeTimeFormat12Hour}}
                        </h5>
                      </div>
                    </div>
                    <div class="checks d-flex justify-content-between flex-wrap pr-5">
                      <p>
                        Live Interaction sessions
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                          <path id="Icon_awesome-check-circle" data-name="Icon awesome-check-circle"
                            d="M26.562,13.563a13,13,0,1,1-13-13A13,13,0,0,1,26.562,13.563Zm-14.5,6.883L21.7,10.8a.839.839,0,0,0,0-1.186L20.518,8.429a.839.839,0,0,0-1.186,0l-7.866,7.866L7.793,12.622a.839.839,0,0,0-1.186,0L5.421,13.808a.839.839,0,0,0,0,1.186l5.452,5.452A.839.839,0,0,0,12.059,20.446Z"
                            transform="translate(-0.563 -0.563)" fill="#347ff0" />
                        </svg>
                      </p>
                      <p>
                        Notes, Assignments
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                          <path id="Icon_awesome-check-circle" data-name="Icon awesome-check-circle"
                            d="M26.562,13.563a13,13,0,1,1-13-13A13,13,0,0,1,26.562,13.563Zm-14.5,6.883L21.7,10.8a.839.839,0,0,0,0-1.186L20.518,8.429a.839.839,0,0,0-1.186,0l-7.866,7.866L7.793,12.622a.839.839,0,0,0-1.186,0L5.421,13.808a.839.839,0,0,0,0,1.186l5.452,5.452A.839.839,0,0,0,12.059,20.446Z"
                            transform="translate(-0.563 -0.563)" fill="#347ff0" />
                        </svg>
                      </p>
                    </div>
                    <div class="checks d-flex justify-content-between flex-wrap pr-5">
                      <p>
                        Engaging Quizzes & Polls
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                          <path id="Icon_awesome-check-circle" data-name="Icon awesome-check-circle"
                            d="M26.562,13.563a13,13,0,1,1-13-13A13,13,0,0,1,26.562,13.563Zm-14.5,6.883L21.7,10.8a.839.839,0,0,0,0-1.186L20.518,8.429a.839.839,0,0,0-1.186,0l-7.866,7.866L7.793,12.622a.839.839,0,0,0-1.186,0L5.421,13.808a.839.839,0,0,0,0,1.186l5.452,5.452A.839.839,0,0,0,12.059,20.446Z"
                            transform="translate(-0.563 -0.563)" fill="#347ff0" />
                        </svg>
                      </p>
                      <p>
                         Instant Doubt Solving
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                          <path id="Icon_awesome-check-circle" data-name="Icon awesome-check-circle"
                            d="M26.562,13.563a13,13,0,1,1-13-13A13,13,0,0,1,26.562,13.563Zm-14.5,6.883L21.7,10.8a.839.839,0,0,0,0-1.186L20.518,8.429a.839.839,0,0,0-1.186,0l-7.866,7.866L7.793,12.622a.839.839,0,0,0-1.186,0L5.421,13.808a.839.839,0,0,0,0,1.186l5.452,5.452A.839.839,0,0,0,12.059,20.446Z"
                            transform="translate(-0.563 -0.563)" fill="#347ff0" />
                        </svg>
                      </p>
                    </div>
                    <div class="checks d-flex justify-content-between flex-wrap pr-5">
                      <p>
                        Replays of Past Sessions
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                          <path id="Icon_awesome-check-circle" data-name="Icon awesome-check-circle"
                            d="M26.562,13.563a13,13,0,1,1-13-13A13,13,0,0,1,26.562,13.563Zm-14.5,6.883L21.7,10.8a.839.839,0,0,0,0-1.186L20.518,8.429a.839.839,0,0,0-1.186,0l-7.866,7.866L7.793,12.622a.839.839,0,0,0-1.186,0L5.421,13.808a.839.839,0,0,0,0,1.186l5.452,5.452A.839.839,0,0,0,12.059,20.446Z"
                            transform="translate(-0.563 -0.563)" fill="#347ff0" />
                        </svg>
                      </p>
                      <p>
                        Free Study Material
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                          <path id="Icon_awesome-check-circle" data-name="Icon awesome-check-circle"
                            d="M26.562,13.563a13,13,0,1,1-13-13A13,13,0,0,1,26.562,13.563Zm-14.5,6.883L21.7,10.8a.839.839,0,0,0,0-1.186L20.518,8.429a.839.839,0,0,0-1.186,0l-7.866,7.866L7.793,12.622a.839.839,0,0,0-1.186,0L5.421,13.808a.839.839,0,0,0,0,1.186l5.452,5.452A.839.839,0,0,0,12.059,20.446Z"
                            transform="translate(-0.563 -0.563)" fill="#347ff0" />
                        </svg>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
