<div class="confirm-modal">
  <div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p [innerHTML]="message"></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-danger btn-custom" (click)="closeModal(true)">
      {{yesString}}
    </button>
    <button *ngIf="showNoButton" type="button" class="btn btn-sm btn-secondary btn-custom" (click)="closeModal(false)">
      {{noString}}
    </button>
  </div>
</div>