import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModalService } from "src/app/services/modal.service";
import { StudentService } from "src/app/services/student.service";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import * as moment from "moment";
import { ConfirmationDialogService } from "src/app/services/confirmation-dialog.service";
import { CartService } from "src/app/services/cart.service";
import { UtilityService } from "src/app/services/utility.service";

@Component({
  selector: "app-one-to-one-tuition-detail",
  templateUrl: "./one-to-one-tuition-detail.component.html",
  styleUrls: ["./one-to-one-tuition-detail.component.scss"],
})
export class OneToOneTuitionDetailComponent implements OnInit {
  public bookIcon = faBook;
  private tuitionId: any;
  public tuition: any;
  public callerDashboard: boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private cartService: CartService,
    private studentService: StudentService,
    private confirmDialogService: ConfirmationDialogService,
    private modalService: ModalService
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.tuitionId = +params["id"];
      this.studentService
        .getTuitionDetail(this.tuitionId, this.callerDashboard)
        .subscribe((response) => {
          this.tuition = response.data;
        });
    });
    this.callerDashboard = activatedRoute.snapshot.data.fromDashboard;
  }

  ngOnInit(): void {
    //listen to remove cart item event
    this.cartService.addRemoveCartIemObservable.subscribe((obj: any) => {
      // {course_id:1, added_to_cart:true}
      if (obj.course_id === this.tuitionId) {
        this.tuition.added_to_cart = obj.added_to_cart;
      }
    });
  }

  public jsonDecode(item) {
    return JSON.parse(item);
  }

  public async addToCart(course) {
    if (!this.callerDashboard) {
      //if called from landing page then open login modal
      this.utilityService.setUserType("student");
      this.modalService.open(1, 1);
      return;
    }
    if (!course.available_seats) return;
    if (!moment(course.start_date).isBefore(moment())) {
      if (course.is_free) {
        this.studentService.enrollFreeCourse(course);
      } else {
        const modalRef = this.modalService.open(10, 2);
        modalRef.componentInstance.course = course;
        modalRef.componentInstance.step = 1; //1 for add to cart step
      }
    } else {
      const result = await this.confirmDialogService.getConfirmation(
        "Note",
        "This course has already started and you may have missed some of its sessions. Do you still want to enroll in this course ?"
      );
      if (!result) return;
      if (course.is_free) {
        this.studentService.enrollFreeCourse(course);
      } else {
        const modalRef = this.modalService.open(10, 2);
        modalRef.componentInstance.course = course;
        modalRef.componentInstance.step = 1; //1 for add to cart step
      }
    }
  }
}
