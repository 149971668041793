<!-- <h2>About Works</h2> -->
<section>
    <div class="about-content">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-7">
                    <div class="content">
                        <h2 class="title">
                            About Us
                        </h2>
                        <h4>
                            Our Philosophy
                        </h4>
                        <p>
                            TALEEMLY represents the passion that lies in each individual for continuous growth and the yearn for learning. By definition, TALEEMLY signifies a powerful force that makes a person’s mind clearer in knowledge comprehension. Keeping this in mind, TALEEMLY stands for knowledge that enables you to climb the ladder of success with a clear vision.
                        </p>
                        <p>
                            We know the limitations of offline learning where every single year countless talented students fail to achieve their true potential. Our philosophy isn’t just to empower students but it is to provide an online medium that can transform a student’s path to success. Teaching methods when offline have limitations and aren’t exactly suitable for all kinds of students. TALEEMLY offers a path that promises a compact learning model with teachers of the highest quality.
                        </p>
                        <p>
                            Our core philosophy is to assist in this transformation as we believe that both students and teachers need a platform which can nourish the very experience of learning. This is where we claim the term, ‘Smart Learning’. As in the 21st century, the one who learns smart wins the race!
                        </p>
                        <h4>
                            What is TALEEMLY?
                        </h4>
                        <p>
                            TALEEMLY is Pakistan’s first smart learning platform that plans to revolutionize the online learning experience. TALEEMLY offers teachers of the highest caliber in the country for students all over the world. TALEEMLY is spearheaded by a talented group of individuals that firmly believe in smart learning and how it can transform the lives of students.
                        </p>
                        <p>
                            We have over 100+ teachers that have decades of experience in teaching students. Our teachers are also our core strength as they’re the foundation TALEEMLY is built on. Our online learning platform acts as a bridge between students and teachers. From live sessions with a teacher to one on one sessions, TALEEMLY knows the ins and outs of an education that promises a bright future.
                        </p>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-5">
                    <div class="media">
                        <img src="../../../../assets/images/about-us.svg" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-choose-lms></app-choose-lms>
