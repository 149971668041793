<a class="first-anchor" (click)="subMenu($event)">Free Study Material</a>
<ul class="submenu">
  <li class="title-menu">Free Study Material</li>
  <li class="go-back"><a (click)="closeMenu($event)">Back</a></li>
  <!-- level one -->
  <li class="item-submenu" *ngFor="let menu of studyMaterialMenuList">
    <a (click)="subMenu($event)">{{menu.title}}</a>
    <ul class="submenu">
      <li class="title-menu">{{menu.title}}</li>
      <li class="go-back"><a (click)="closeMenu($event)">Back</a></li>

      <li class="item-submenu" *ngFor="let menu of menu.sub_menu">
        <!-- level 2-->
        <a (click)="subMenu($event)">{{menu.title}}</a>
        <ul class="submenu">
          <li class="title-menu">{{menu.title}}</li>
          <li class="go-back"><a (click)="closeMenu($event)">Back</a></li>
          <li class="item-submenu" *ngFor="let menu of menu.sub_menu">
            <!-- level 3-->
            <a (click)="subMenu($event)">{{menu.title}}</a>
            <ul class="submenu">
              <li class="title-menu">{{menu.title}}</li>
              <li class="go-back"><a (click)="closeMenu($event)">Back</a></li>
              <li class="item-submenu" *ngFor="let menu of menu.sub_menu">
                <!-- level 4-->
                <a (click)="subMenu($event)">{{menu.title}}</a>
                <ul class="submenu">
                  <li class="title-menu">{{menu.title}}</li>
                  <li class="go-back"><a (click)="closeMenu($event)">Back</a></li>
                  <li><a (click)="openStudyMaterialDetails(material.id, material)"
                      *ngFor="let material of menu.direct_study_materials">{{material.title}}</a></li>
                </ul>
              </li>
              <li><a (click)="openStudyMaterialDetails(material.id, material)"
                  *ngFor="let material of menu.direct_study_materials">{{material.title}}</a></li>
            </ul>
          </li>
          <li><a (click)="openStudyMaterialDetails(material.id, material)"
              *ngFor="let material of menu.direct_study_materials">{{material.title}}</a></li>
        </ul>
      </li>
      <li><a (click)="openStudyMaterialDetails(material.id, material)"
          *ngFor="let material of menu.direct_study_materials">{{material.title}}</a></li>
    </ul>
  </li>
</ul>