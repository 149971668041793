import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import {
//   SocialLoginModule,
//   AuthServiceConfig,
//   GoogleLoginProvider,
//   FacebookLoginProvider
// } from "angularx-social-login";

// import { SocialLoginModule, GoogleLoginProvider, FacebookLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';

// import { config } from "../../../config";

// let configuration = new AuthServiceConfig([ //old based on angular 9//
// let configuration = [//new based on angular 17//
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider(config.google_app_id) //google app client id 
//   },
//   {
//     id: FacebookLoginProvider.PROVIDER_ID,
//     provider: new FacebookLoginProvider(config.facebook_app_id) //facebook app id
//   }
// ]
// ]);

// export function provideConfig() { //old based on angular 9//
//   return configuration;
// }

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    // SocialLoginModule, //old based on angular 9//
  ],
  // providers: [
  //   {
  //     provide: 'SocialAuthServiceConfig',
  //     useValue: {
  //       autoLogin: false,
  //       providers: [
  //         {
  //           id: GoogleLoginProvider.PROVIDER_ID,
  //           provider: new GoogleLoginProvider(
  //             'clientId'
  //           )
  //         },
  //         {
  //           id: FacebookLoginProvider.PROVIDER_ID,
  //           provider: new FacebookLoginProvider('clientId')
  //         }
  //       ],
  //       onError: (err) => {
  //         console.error(err);
  //       }
  //     } as SocialAuthServiceConfig,
  //   }
  // ],

  //old based on angular 9//
  // providers: [
  //   {
  //     provide: AuthServiceConfig,
  //     useFactory: provideConfig
  //   }
  // ],

})
export class SocialMediaModule { }
