import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-choose-lms',
  templateUrl: './choose-lms.component.html',
  styleUrls: ['./choose-lms.component.scss']
})
export class ChooseLmsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
