import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  constructor(private metaTagService: Meta, private metaTitleService: Title) { }

  ngOnInit(): void {
    //add tags for SEO
      this.metaTagService.updateTag({ name: 'keywords', content: 'vivid online learning' });
      this.metaTitleService.setTitle('Terms and Conditions | VIVID Smart Learning');
      this.metaTagService.updateTag({ name: 'description', content: 'checkout our terms and conditions for learning with vivid online learning platforms.' });
  }

}
