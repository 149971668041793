<div class="modal-header pb-0">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">
      <img src="assets/images/close.png" alt="" />
    </span>
  </button>
</div>
<div class="modal-body pt-1">
  <div class="student-login-box cont-registration">
    <div class="student-login border-0 pb-0">
      <h3 class="">Forgot Password</h3>
      <form [formGroup]="forgotPasswordFormGroup" (submit)="onSubmit()">
        <div class="login-box">
          <div class="form-label-group">
            <input type="text" formControlName="phone" placeholder="Enter Number" class="form-control" id="number"
              (focus)="addPrefix()" (keypress)="checkPhoneInput($event)" maxlength="17" />
            <label for="number">Mobile Number</label>
            <div>
              <span class="text-danger" *ngIf="
                  forgotPasswordFormControls.phone.touched &&
                  forgotPasswordFormControls.phone.errors?.required
                ">
                Please provide mobile number.
              </span>
              <span class="text-danger" *ngIf="
                  forgotPasswordFormControls.phone.touched &&
                  forgotPasswordFormControls.phone.errors?.pattern
                ">
                Please provide a valid mobile no (e.g. +923212496812).
              </span>
              <span class="text-danger" *ngIf="
                  forgotPasswordFormControls.phone.touched &&
                  forgotPasswordFormControls.phone.errors?.notRegistered
                ">
                The mobile no you provided doesn't match our records. Please
                check & try again.
                <!-- {{forgotPasswordFormControls.phone.errors?.notRegistered}} -->
              </span>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-gradient btn-block mb-4">
          Send
        </button>
        <a class="text-success pl-2 cursor-pointer back-login-text" (click)="openLoginModal()">
          <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="15.691" height="10.465"
            viewBox="0 0 15.691 10.465">
            <path id="Icon_ionic-ios-arrow-round-forward" data-name="Icon ionic-ios-arrow-round-forward"
              d="M17.882,11.452a.712.712,0,0,0-.005,1l3.314,3.319H8.578a.709.709,0,0,0,0,1.417H21.185L17.871,20.51a.717.717,0,0,0,.005,1,.706.706,0,0,0,1-.005l4.491-4.524h0a.8.8,0,0,0,.147-.223.676.676,0,0,0,.055-.273.71.71,0,0,0-.2-.5l-4.491-4.524A.694.694,0,0,0,17.882,11.452Z"
              transform="translate(23.566 21.717) rotate(180)" fill="#347FF0" />
          </svg>
          Back to Login</a>
      </form>
    </div>
  </div>
</div>