<div class="modal-header pb-0">
  <button type="button" class="close" (click)="closeModal()">
    <img _ngcontent-dnc-c114="" src="assets/images/close.png" alt="" />
  </button>
</div>
<div class="modal-body pt-1">
  <h5 class="modal-title mb-2" id="exampleModalLongTitle">
    {{ callerFeedback ? 'Teacher Feedback' :  callerSessionNotes ? 'Download Notes' : 'Download Homework'}}</h5>
  <div class="upload-notes">
    <div class="pb-3 upload-btn-wrapper">
      <div class="d-flex file-name mb-2">
        <label class="text-secondary ml-2">
          <!-- file icon depending upon file extension -->
          <fa-icon [class]="this.fileIcon" [icon]="['fas',this.fileIcon]"></fa-icon>
          {{ homeworkFeedbackFileName }}
        </label>
      </div>
      <a download [href]="homeworkFeedbackFileUrl" target="_blank" class="btn btn-outline-primary" type="button"
        (click)="$event.target.blur()">
        Download Now
      </a>
    </div>
  </div>
</div>