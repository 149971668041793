<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLongTitle">Download Notes</h5>
  <button type="button" class="close" (click)="closeModal()">
    <img src="assets/images/close.png" alt="">
  </button>
</div>
<div class="modal-body">
  <a  class="text-secondary">
    <div class="pl-2 mb-2 d-flex">
      <!-- <img src="assets/images/Icon awesome-file-pdf.svg" class="notes-link" alt="" /> -->
      <fa-icon [icon]="['fas', this.fileIcon]"></fa-icon>
      <p class="my-auto pl-2">{{fileName || 'File'}}</p>
    </div>
  </a>
  <div class="d-flex align-items-center download-pane">
    <a [href]="notesLink" [download]="notesLink" target="_blank" class="btn btn-outline-primary btn-download bg-white">Download
      Now</a>
    <!-- <span class="text-primary pl-3">Download Complete</span> -->
  </div>
</div>