import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from 'src/app/services/utility.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import { TeacherService } from 'src/app/services/teacher.service';

@Component({
  selector: 'app-math-text-editor',
  templateUrl: './math-text-editor.component.html',
  styleUrls: ['./math-text-editor.component.scss']
})
export class MathTextEditorComponent implements OnInit {

  @Input() option: string;
  @Input() title: string;
  @Output() updateOption: EventEmitter<string> = new EventEmitter()
  //editor instance
  public Editor = ClassicEditor;
  public feedbackFormGroup: FormGroup = new FormGroup({
    'feedbackFormControl': new FormControl(null, [Validators.required])
  });
  constructor(private activeModal: NgbActiveModal, private utilityService: UtilityService, private teacherService: TeacherService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.feedbackFormGroup.controls.feedbackFormControl.patchValue(this.option);
  }

  public closeModal() {
    this.activeModal.close();
  }

  //emit event to parent component
  public onOptionUpdate() {
    this.feedbackFormGroup.markAllAsTouched();
    if (this.feedbackFormGroup.invalid) return;
    this.updateOption.next(this.feedbackFormGroup.controls.feedbackFormControl.value);
    this.closeModal();
  }

}
