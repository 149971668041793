<div class="modal-header pb-0">
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">
      <img src="assets/images/close.png" alt="" />
    </span>
  </button>
</div>
<div class="modal-body pt-1">
  <div class="student-login-box">
    <div class="student-login mb-0">
      <h3 class="">Reset Password</h3>
      <form [formGroup]="resetPasswordFormGroup" (submit)="onSubmit()">
        <div class="login-box mb-4">
          <div class="form-label-group password-box pb-3 mb-0">
            <input
              [type]="showPassword ? 'text' : 'password'"
              placeholder="password"
              id="pwd"
              formControlName="password"
              class="form-control"
            />
            <label for="pwd">New Password</label>
            <a (click)="showPassword = !showPassword" class="eye-icon">
              <img
                src="../../../../assets/images/hide.svg"
                *ngIf="!showPassword"
                alt="eye"
              />
              <img
                src="../../../../assets/images/show.svg"
                *ngIf="showPassword"
                alt="eye"
              />
            </a>
            <div class="validation-text">
              <span
                class="text-danger"
                *ngIf="
                  resetPasswordFormControl.password.touched &&
                  resetPasswordFormControl.password.errors?.required
                "
              >
                Please provide Password.
              </span>
              <span
                class="text-danger"
                *ngIf="
                  resetPasswordFormControl.password.touched &&
                  (resetPasswordFormControl.password.errors?.minlength ||
                    resetPasswordFormControl.password.errors?.maxLength ||
                    resetPasswordFormControl.password.errors?.pattern)
                "
              >
                Password must contain 8-20 characters including letters, numbers
                & special characters.
              </span>
            </div>
          </div>
          <div class="form-label-group password-box pb-3 mb-0">
            <input
              [type]="showConfirmPassword ? 'text' : 'password'"
              placeholder="password"
              id="cpwd"
              formControlName="confirmPassword"
              class="form-control"
            />
            <label for="cpwd">Confirm Password</label>
            <a
              (click)="showConfirmPassword = !showConfirmPassword"
              class="eye-icon"
            >
              <img
                src="../../../../assets/images/hide.svg"
                *ngIf="!showConfirmPassword"
                alt="eye"
              />
              <img
                src="../../../../assets/images/show.svg"
                *ngIf="showConfirmPassword"
                alt="eye"
              />
            </a>
            <div class="validation-text">
              <span
                class="text-danger"
                *ngIf="
                  resetPasswordFormControl.confirmPassword.touched &&
                  resetPasswordFormControl.confirmPassword.errors?.required
                "
              >
                Please provide Confirm Password.
              </span>
              <span
                class="text-danger"
                *ngIf="
                  resetPasswordFormControl.confirmPassword.touched &&
                  resetPasswordFormGroup.errors?.notSame &&
                  resetPasswordFormControl.confirmPassword.value
                "
              >
                The specified passwords do not match.
              </span>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-gradient btn-block mb-4">
          Submit
        </button>
      </form>
    </div>
  </div>
</div>
