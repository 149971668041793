import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-title-box',
  templateUrl: './title-box.component.html',
  styleUrls: ['./title-box.component.scss']
})
export class TitleBoxComponent implements OnInit {
  public user;
  public message = ''; //
  public greetings = '';
  constructor(private authService: AuthService) {
    this.user = this.authService.getCurrentUser();
    this.setMessage(this.user);
  }

  ngOnInit(): void {
    //listenning to user object changes
    this.authService.userDataObservable.subscribe(user => {
      this.user = user;
      this.setMessage(this.user);
    });
  }

  public setMessage(user) {
    if (user.type === 'student') {// setting message if user is student
      this.message = user.student_profile?.is_enrolled_to_course ? 'You are performing well. Go ahead to your daily studies plan and attend classes on time.' : 'You are not enrolled in any course or a session. Subscribe to a course plan and get the best online study experience.';
    } else {//setting message if user is teacher
      this.message = 'Go ahead with your daily teaching plan and conduct your classes.'
    }
  }

  public getGreeting() {
    var today = new Date();
    var curHr = today.getHours();
    if (curHr < 12) {
      this.greetings = 'Good Morning';
    } else if (curHr < 18) {
      this.greetings = 'Good Afternoon';
    } else {
      this.greetings = 'Good Evening';
    }
    return this.greetings;
  }
}
