
  <app-hero-section></app-hero-section>
  <app-packages></app-packages>
  <app-register-free [forTuitionRequest]="true"></app-register-free>
  <!-- <app-recommended-course></app-recommended-course> -->
  <app-choose-lms></app-choose-lms>
  <app-register-free [forTuitionRequest]="false"></app-register-free>
  <app-top-rated-teacher></app-top-rated-teacher>
  <app-become-teacher></app-become-teacher>
  <app-lms-achievements></app-lms-achievements>
  <app-our-results></app-our-results>
  <app-enroll-student-feedback></app-enroll-student-feedback>
  <app-mock-tests></app-mock-tests>
  <app-partners></app-partners>
 