import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { TestService } from "src/app/services/test.service";
import { ToastrService } from "ngx-toastr";
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  FormControlName,
  AbstractControl,
} from "@angular/forms";
import { UtilityService } from "src/app/services/utility.service";
import { ConfirmationDialogService } from "src/app/services/confirmation-dialog.service";
import * as moment from "moment";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import _ from "lodash"; // Import the entire lodash library
// import { OWL_DATE_TIME_FORMATS } from "ng-pick-datetime";
import { OWL_DATE_TIME_FORMATS } from "ng-pick-datetime-ex";
import { Subject } from "rxjs";
import { PaginationService } from 'ngx-pagination';
import { ModalService } from 'src/app/services/modal.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MathType from '@wiris/mathtype-ckeditor5/src/plugin';


const MY_MOMENT_FORMATS = {
  datePickerInput: { year: "numeric", month: "long", day: "numeric" },
  timePickerInput: { hour: "numeric", minute: "numeric", hour12: false },
  monthYearLabel: "MMM YYYY",
  dateA11yLabel: "LL",
  monthYearA11yLabel: "MMMM YYYY",
};
@Component({
  selector: "app-add-new-test",
  templateUrl: "./add-new-test.component.html",
  styleUrls: ["./add-new-test.component.scss"],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }, PaginationService],
})
export class AddNewTestComponent implements OnInit {
  @Input() classDetails: any;
  @Input() testId: any;
  @Input() editMode: any;
  @Input() courseEndDate: any;
  @Input() courseStartDate: any;
  //refresh test list
  @Output() onTestAddedChanged: Subject<object> = new Subject<object>();
  public date = moment();
  public timePickerStartAt = new Date(
    this.date.get("year"),
    this.date.get("month"),
    this.date.get("D"),
    0,
    30
  );
  public timePickerMaxTime = new Date(
    this.date.get("year"),
    this.date.get("month"),
    this.date.get("D"),
    12,
    0
  );
  public timePickerMinTime = new Date(
    this.date.get("year"),
    this.date.get("month"),
    this.date.get("D"),
    0,
    5
  );
  public courseEndDatee;
  public datee = moment();
  public minDate;
  public maxDate;
  public totalMarks: number = 0;
  public totalQuestions: number = 0;
  public currentPageNo: number = 1;
  public questionList: any[] = [];
  public currentQuestion: any;
  public baseQuestionObj = {
    question: null,
    quiz_id: "",
    marks: null,
    correct_choice_index: 0,
  };
  public showQuestionStructure: boolean = false;
  //multiple choice question structure
  public multiplechoiceQuestion = {
    ...this.baseQuestionObj,
    type: "Multiple Choice",
    choices: [null, null, null, null],
  };
  //true false question structure
  public trueFalseQuestion = {
    ...this.baseQuestionObj,
    type: "True/False",
    choices: ["True", "False"],
  };
  public questionTypeList: any = [
    { value: "Multiple Choice", name: "Multiple Choice" },
    { value: "True/False", name: "True/False" },
  ];

  public testInfoFormGroup: FormGroup = new FormGroup({
    title: new FormControl(null, [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(250),
      Validators.pattern(this.utilityService.leadingTrailingSpaces),
    ]),
    due_date: new FormControl(null),
    duration: new FormControl(null, Validators.required),
  });
  public questionTypeFormGroup: FormGroup = new FormGroup({
    type: new FormControl(null, [Validators.required]),
    points: new FormControl(null, [
      Validators.required,
      Validators.max(100),
      Validators.pattern(this.utilityService.numbersleadingTrailingSpaces),
    ]),
  });

  public multipleChoiceQuestionFormGroup: FormGroup = new FormGroup({});
  public trueFalseQuestionFormGroup: FormGroup = new FormGroup({});
  //editor instance
  public Editor = ClassicEditor;
  //  public mathData = '<p><math xmlns="http://www.w3.org/1998/Math/MathML"><mroot><mi>b</mi><mi>a</mi></mroot></math></p>';
  public mathData = '<p><math xmlns="http://www.w3.org/1998/Math/MathML"><mfenced close="]" open="["><mtable><mtr><mtd><mn>2</mn></mtd><mtd><mn>4</mn></mtd><mtd><mn>5</mn></mtd></mtr><mtr><mtd><mi>a</mi></mtd><mtd><mi>s</mi></mtd><mtd><mi>a</mi><mi>s</mi></mtd></mtr><mtr><mtd><mi>s</mi></mtd><mtd><mi>d</mi></mtd><mtd><mi>f</mi></mtd></mtr></mtable></mfenced></math></p>';
  // public mathData = '<b>sasasasasasa</b>';

  //duration 24 hour fix
  @ViewChild("duration", { static: false }) duration: ElementRef;
  constructor(
    private testService: TestService,
    private toastr: ToastrService,
    private utilityService: UtilityService,
    private confirmDialogService: ConfirmationDialogService,
    private activeModal: NgbActiveModal,
    private modalService: ModalService
  ) { }

  //if test is opened in edit mode
  ngOnInit(): void {
    //initialize course end date
    this.courseEndDatee = moment(this.courseEndDate);
    this.maxDate = new Date(
      this.courseEndDatee.get("year"),
      this.courseEndDatee.get("month"),
      this.courseEndDatee.get("D"),
      0,
      0
    );
    //if course start date is ahead of day after tomorrow then min date will be course start date
    if (this.datee < moment(this.courseStartDate)) {
      let minDate = moment(this.courseStartDate);
      this.minDate = new Date(
        minDate.get("year"),
        minDate.get("month"),
        minDate.get("D"),
        0,
        0
      );
    } else {
      //if course start
      this.minDate = new Date(
        this.datee.get("year"),
        this.datee.get("month"),
        this.datee.get("D"),
        0,
        0
      );
    }
    if (this.editMode) this.getTestDetails(this.testId);
  }

  //check points info
  public checkPointsInput(event) {
    //return only numbers
    return (event.charCode >= 48 && event.charCode <= 57) || event.key === ".";
  }

  //test info form controls
  get testInfoFormControls() {
    return this.testInfoFormGroup.controls;
  }
  //get question type form controls
  get questionTypeFormControls() {
    return this.questionTypeFormGroup.controls;
  }

  //on option add
  public onOptionDelete(questionObjIndex: number, choiceIndex: number) {
    //removing choice
    let questionObj = this.questionList[questionObjIndex];
    if (questionObj.choices.length > 2) {
      this.choices.removeAt(choiceIndex);
      questionObj.choices.splice(choiceIndex, 1);
    } else {
      this.toastr.error("Choices cannot be less than two", "Error");
    }
  }
  //on option add
  public onOptionAdd(questionObjIndex: number) {
    let questionObj = this.questionList[questionObjIndex];
    if (questionObj.choices.length < 4) {
      this.choices.push(
        new FormControl(null, [
          Validators.required,
          Validators.pattern(this.utilityService.leadingTrailingSpacesForTest),
        ])
      );
      questionObj.choices.push(null);
    } else {
      this.toastr.error("Choices cannot be greater than four", "Error");
    }
  }
  // on add question or edit
  public onQuestionTypeFormSubmit() {
    this.questionTypeFormGroup.markAsTouched();
    if (!this.questionTypeFormGroup.valid) return;

    this.showQuestionStructure = true;
    let choices = {};
    this.multipleChoiceQuestionFormGroup = new FormGroup({
      question: new FormControl(null, [
        Validators.required,
        Validators.pattern(this.utilityService.leadingTrailingSpacesForTest),
      ]),
      choices: new FormArray([], this.onDuplicateIPFormArray.bind(this)),
      correct_choice_index: new FormControl("0", Validators.required),
    });
    if (this.questionTypeFormControls.type.value === "Multiple Choice") {
      this.currentQuestion = this.multiplechoiceQuestion;
      this.questionList.push(_.cloneDeep(this.multiplechoiceQuestion)); //if user selected to add multiple choice question
      this.multiplechoiceQuestion.choices.forEach((choice, i) => {
        choices["option_" + i] = new FormControl(choice, [
          Validators.required,
          Validators.pattern(this.utilityService.leadingTrailingSpacesForTest),
        ]);
        // choices['option_' + i] = new FormControl(choice, Validators.required);
        this.choices.push(choices["option_" + i]);
      });
    } else {
      //true false
      this.currentQuestion = this.trueFalseQuestion;
      this.questionList.push(_.cloneDeep(this.trueFalseQuestion)); // if user selected to add true false
      this.trueFalseQuestion.choices.forEach((choice, i) => {
        choices["option_" + i] = new FormControl(
          { value: choice, disabled: true },
          [
            Validators.required,
            Validators.pattern(this.utilityService.leadingTrailingSpacesForTest),
          ]
        );
        this.choices.push(choices["option_" + i]);
      });
    }

    this.currentPageNo = this.questionList.length;
  }

  public initFormGroup(question) {
    //question form group
    this.currentQuestion = question; //setting current question to hide add new question button
    this.questionTypeFormControls.type.setValue(question.type);
    this.questionTypeFormControls.points.setValue(question.marks);

    this.showQuestionStructure = true;
    // this.questionList.push(_.cloneDeep(question));
    let choices = {};
    this.multipleChoiceQuestionFormGroup = new FormGroup({
      question: new FormControl(question.question, [
        Validators.required,
        Validators.pattern(this.utilityService.leadingTrailingSpacesForTest),
      ]),
      choices: new FormArray([], this.onDuplicateIPFormArray.bind(this)),
      correct_choice_index: new FormControl(
        question.correct_choice_index.toString(),
        Validators.required
      ),
    });
    question.choices.forEach((choice, i) => {
      choices["option_" + i] = new FormControl(
        question.type === "True/False"
          ? { value: choice, disabled: true }
          : choice,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.utilityService.leadingTrailingSpacesForTest),
        ])
      );
      this.choices.push(choices["option_" + i]);
    });
  }

  get choices(): FormArray {
    return this.multipleChoiceQuestionFormGroup.get("choices") as FormArray;
  }

  public async onTestInfoSave(
    doPublish: boolean,
    callerQuestion: boolean = false
  ) {
    // if question called then donot close dialog
    if (doPublish) {
      this.testInfoFormControls.due_date.setValidators([Validators.required]);
      this.testInfoFormControls.due_date.updateValueAndValidity();
      this.testInfoFormControls.duration.setValidators([Validators.required]);
      this.testInfoFormControls.duration.updateValueAndValidity();
    } else {
      this.testInfoFormControls.due_date.setValidators(null);
      this.testInfoFormControls.due_date.updateValueAndValidity();
      this.testInfoFormControls.duration.setValidators(null);
      this.testInfoFormControls.duration.updateValueAndValidity();
    }

    if (
      !this.questionList.find((element) => "id" in element) &&
      !callerQuestion &&
      doPublish
    ) {
      //if user have a saved question or not
      this.toastr.error(
        "Please add atleast one question before publishing.",
        "Error"
      );
      return;
    }

    this.testInfoFormGroup.markAllAsTouched();
    if (!this.testInfoFormGroup.valid) return;

    const data: any = {
      title: this.testInfoFormControls.title.value,
      due_date: this.testInfoFormControls.due_date.value
        ? moment(this.testInfoFormControls.due_date.value).format("YYYY-MM-DD")
        : null,
      duration: moment
        .duration(
          moment(this.testInfoFormControls.duration.value).format("HH:mm")
        )
        .asMinutes(),
      course_id: this.classDetails.courses[0]?.id,
      course_name: this.classDetails.courses[0]?.title,
      class_id: this.classDetails.id,
      class_name: this.classDetails.title,
      subject_id: this.classDetails.subject.id,
      subject_name: this.classDetails.subject.name,
      is_published: doPublish ? 1 : 0,
    };
    //if quiz already created then edit call
    let editMode: boolean = this.testId ? true : false;
    if (editMode) {
      // if being edited then send id in data
      data.id = this.testId;
    }
    return new Promise((resolve, reject) => {
      this.testService.addNewTest(data).subscribe(
        (response) => {
          this.testId = response.data.id;

          if (!callerQuestion) {
            this.toastr.success(
              editMode
                ? doPublish
                  ? "Test is published successfully."
                  : "Test is updated successfully."
                : doPublish
                  ? "Test is published successfully."
                  : "Test is added successfully.",
              "Success"
            );
          }
          resolve(true); //if all things went well in quiz save call
          //IF QUESTION NOT CALLED
          if (!callerQuestion) {
            this.onTestAddedChanged.next({
              fetchList: true,
              updateStats: doPublish,
            });
            this.activeModal.close();
          }
        },
        (error) => {
          if (error.error?.error_details?.title) {
            this.testInfoFormControls.title.setErrors({ title: true });
          }
          if (error.error?.error_details?.due_date) {
            this.testInfoFormControls.title.setErrors({
              due_date: error.error?.error_details?.due_date,
            });
          }
          resolve(false); //if all things not went well in quiz save call like due date and title
        }
      );
    });
  }
  //on saving editing question
  public async onQuestionSubmit(saveAndAddNewQuestion: boolean = true) {
    console.log(this.multipleChoiceQuestionFormGroup);
    this.multipleChoiceQuestionFormGroup.markAllAsTouched();
    if (!this.multipleChoiceQuestionFormGroup.valid) return;
    //test info not saved yet
    let quizIdAvailable: boolean;
    if (this.questionList.length === 1 && !this.editMode) {
      let result: any;
      result = await this.onTestInfoSave(false, true);
      if (!result) return;
    }
    //if question is being edited
    let question = this.questionList[this.currentPageNo - 1];
    let editMode: boolean = false;
    let questionId: any = null;
    if ("id" in question) {
      questionId = question.id;
      editMode = true;
    }

    const data = {
      quiz_id: this.testId,
      marks: this.questionTypeFormControls.points.value,
      type: this.questionTypeFormControls.type.value,
      question: this.multipleChoiceQuestionFormGroup.controls.question.value,
      choices: this.multipleChoiceQuestionFormGroup.controls.choices.value,
      correct_choice_index: this.multipleChoiceQuestionFormGroup.controls
        .correct_choice_index.value,
    };
    return new Promise((resolve, reject) => {
      this.testService.addNewQuestion(data, editMode, questionId).subscribe(
        (response) => {
          this.questionList = response.data.questions;
          this.totalQuestions = response.data.total_questions;
          this.totalMarks = response.data.total_marks;
          // if (!editMode) {
          // this.questionTypeFormGroup.reset();
          // this.showQuestionStructure = false;
          // this.currentPageNo += 1;
          // }
          this.toastr.success(
            editMode
              ? "Question is updated successfully."
              : "Question is added successfully.",
            "Success"
          );
          this.currentQuestion = this.questionList[this.currentPageNo - 1];
          //change question choices object to single string value
          this.questionList.map((question) => {
            question.correct_choice_index = (question.choices as any[]).findIndex(
              (choice) => choice.is_correct === 1
            );
            question.choices = question.choices.map((choicce) => {
              choicce = choicce.choice;
              return choicce;
            });
            return question;
          });
          if (saveAndAddNewQuestion) this.onAddNewQuestion(); //if user clicked save and add new question
          resolve(true);
        },
        (error) => {
          if (error.error.error_details.question.length) {
            this.multipleChoiceQuestionFormGroup.controls.question.setErrors({
              question: error.error.error_details.question[0],
            });
          }
          if (error.error.error_details.due_date) {
            this.testInfoFormControls.due_date.setErrors({
              due_date: error.error.error_details.due_date,
            });
          }
          resolve(false);
        }
      );
    });
  }

  public getTestDetails(testId: any) {
    this.testService.getTestDetails(testId).subscribe((response) => {
      let test = response.data;
      this.totalMarks = test.total_marks;
      this.totalQuestions = test.total_questions;
      this.testInfoFormControls.title.setValue(test.title);
      this.testInfoFormControls.due_date.setValue(test.due_date);
      if (test.duration) {
        //if user selected duration earlier
        let duration = moment()
          .startOf("day")
          .add(test.duration, "minutes")
          .format("HH:mm");
        let durationString: string[] = duration.toString().split(":"); //getting hours and minutes separate
        this.testInfoFormControls.duration.setValue(
          new Date(
            this.date.get("year"),
            this.date.get("month"),
            this.date.get("D"),
            Number(durationString[0]),
            Number(durationString[1])
          )
        );
        this.correctTime();
      }
      this.questionList = test.questions;
      this.questionList.map((question) => {
        question.correct_choice_index = (question.choices as any[]).findIndex(
          (choice) => choice.is_correct === 1
        );
        question.choices = question.choices.map((choicce) => {
          choicce = choicce.choice;
          return choicce;
        });
        return question;
      });
      //initializing form group with first question
      if (this.questionList.length)
        this.initFormGroup(this.questionList[this.currentPageNo - 1]);
    });
  }

  public async closeModal() {
    if (
      !this.testInfoFormGroup.pristine ||
      !this.questionTypeFormGroup.pristine ||
      !this.multipleChoiceQuestionFormGroup.pristine
    ) {
      //if user changed something ehm
      const result = await this.confirmDialogService.getConfirmation(
        "Exit",
        "Canceling will discard all your unsaved changes. Are you sure?"
      );
      if (!result) return;
      this.activeModal.close();
      this.onTestAddedChanged.next({
        fetchList: true,
        updateStats: false,
      });
    } else {
      this.activeModal.close();
      this.onTestAddedChanged.next({
        fetchList: true,
        updateStats: false,
      });
    }
  }

  // delete question
  public async deleteQuestion(selectedQuestion, pageNumber) {
    let question = { ...selectedQuestion }; //copy of question object
    const result = await this.confirmDialogService.getConfirmation(
      "Delete",
      "Are you sure? This action cannot be reverted"
    );
    if (!result) return;

    let nextPageNumber =
      pageNumber === this.questionList.length ? pageNumber - 1 : pageNumber; //if deleting on last indexof array
    this.questionList.splice(pageNumber - 1, 1); //delete from local array
    // this.onPageNoChange(pageNumber-2);
    // console.log("pagenumber:" + pageNumber);
    if (this.questionList.length > 0) {
      // console.log("next page number :" + nextPageNumber, this.questionList[nextPageNumber - 1]);
      this.currentPageNo = nextPageNumber; //page number change init the pre question
      this.initFormGroup(this.questionList[nextPageNumber - 1]);
    } else {
      this.questionTypeFormGroup.reset();
      this.showQuestionStructure = false;
    }

    if (!("id" in question)) return; //if question is not already posted to data base

    this.testService.deleteQuestion(question.id).subscribe((response) => {
      this.totalQuestions -= 1;
      this.totalMarks -= Number(question.marks);
      this.toastr.success("Question deleted successfully", "Success");
    });
  }

  // on paginator change
  public onPageNoChange(pageNumber) {
    let currentQuestion = this.questionList[this.currentPageNo - 1];
    if (!("id" in currentQuestion)) {
      //saving un saved form if user is changing page without saving
      currentQuestion.question = this.multipleChoiceQuestionFormGroup.controls.question.value;
      currentQuestion.correct_choice_index = this.multipleChoiceQuestionFormGroup.controls.correct_choice_index.value;
      currentQuestion.choices = this.multipleChoiceQuestionFormGroup.controls.choices.value;
      currentQuestion.marks = this.questionTypeFormControls.points.value;
      currentQuestion.type = this.questionTypeFormControls.type.value;
    }
    this.currentPageNo = pageNumber;
    this.initFormGroup(this.questionList[pageNumber - 1]);
  }

  //on add new question
  public onAddNewQuestion() {
    this.questionTypeFormGroup.reset();
    this.showQuestionStructure = false;
  }

  // update time picker view
  updateStartAtView() {
    if (!this.testInfoFormControls.duration.value) return; //if picker is closed by clicking somewhere else other then set
    let time: any[] = moment(this.testInfoFormControls.duration.value)
      .format("HH:mm")
      .split(":");
    this.timePickerStartAt = new Date(
      this.date.get("year"),
      this.date.get("month"),
      this.date.get("D"),
      Number(time[0]),
      Number(time[1])
    );
    this.correctTime();
  }

  // check duplicate values in option of question
  public onDuplicateIPFormArray(choiceArray: FormArray) {
    choiceArray.controls.forEach((control: FormControl, controlIndex) => {
      let duplicateIndex = choiceArray.controls.findIndex((item, index) => {
        return (
          item.value === control.value &&
          control.value !== null &&
          controlIndex !== index
        );
      });
      if (duplicateIndex !== -1) {
        control.setErrors({ duplicateChoice: true });
      } else {
        if (control.hasError("duplicateChoice")) {
          delete control.errors["duplicateChoice"];
          control.updateValueAndValidity();
        }
      }
    });
  }

  //dirty fix of time
  correctTime() {
    if (this.duration.nativeElement.value.startsWith("24:")) {
      this.duration.nativeElement.value = this.duration.nativeElement.value.replace(
        "24:",
        "00:"
      ); //update view value
    }
  }
  //disable key press on due date and duration
  keypressEvent($event) {
    return false;
  }

  //open text editor for special math equations
  public openTextEditor(i: AbstractControl, title:string = 'Option Title', openEditor:boolean = true) {
    if(!openEditor) return;
    const modalRef = this.modalService.open(27, 9);
    modalRef.componentInstance.option = i.value;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.updateOption.subscribe(
      (updatedOption) => {
        //updating notes link locally in session list
        this.decodeEntities(updatedOption);
        i.setValue(updatedOption);
      });
  }

  decodeEntities(encodedString) {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = encodedString;
    console.log(textArea.value)
    return textArea.value;
  }
}
