import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared/shared.module';
// import { CookieService } from "angular2-cookie/services/cookies.service";
import { CookieService } from 'ngx-cookie-service';
import { TeacherRegistrationFinalStepComponent } from 'src/app/components/auth/teacher-registration-final-step/teacher-registration-final-step.component';
import { LoginComponent } from 'src/app/components/auth/login/login.component';
import { RegistrationComponent } from 'src/app/components/auth/registration/registration.component';
import { ForgotPasswordComponent } from 'src/app/components/auth/forgot-password/forgot-password.component';
import { OtpVerificationComponent } from 'src/app/components/auth/otp-verification/otp-verification.component';
import { RegistrationFinalStepComponent } from 'src/app/components/auth/registration-final-step/registration-final-step.component';
import { NewPasswordComponent } from 'src/app/components/auth/new-password/new-password.component';
import { SocialMediaRegisterComponent } from 'src/app/components/auth/social-media-register/social-media-register.component';
import { GoogleSigninComponent } from "../../components/auth/google-signin/google-signin.component";


@NgModule({
  declarations: [LoginComponent, RegistrationComponent, ForgotPasswordComponent, OtpVerificationComponent, RegistrationFinalStepComponent, NewPasswordComponent, SocialMediaRegisterComponent, TeacherRegistrationFinalStepComponent],
  imports: [
    CommonModule,
    SharedModule,
    GoogleSigninComponent,
  ],
  exports: [LoginComponent, RegistrationComponent],
  // entryComponents: [LoginComponent, RegistrationComponent, ForgotPasswordComponent, OtpVerificationComponent, RegistrationFinalStepComponent, NewPasswordComponent, SocialMediaRegisterComponent, TeacherRegistrationFinalStepComponent],
  providers:[CookieService]
})
export class AuthModule { }
