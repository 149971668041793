import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalService } from 'src/app/services/modal.service';
import { StudyMaterialService } from "src/app/services/study-material.service";

@Component({
  selector: "app-study-material",
  templateUrl: "./study-material.component.html",
  styleUrls: ["./study-material.component.scss"],
})
export class StudyMaterialComponent implements OnInit {
  @Input() callerLandingPage: boolean = false;
  @Input() userTypeStudent: boolean;
  public menuList: any[];
  public subMenuOneList: any[];
  public subMenuTwoList: any[];
  public subMenuThreeList: any[];
  public subMenuFourList: any[];
  public activeMenu: string;
  public activeSubOneMenu: string;
  public activeSubTwoMenu: string;
  public activeSubThreeMenu: string;
  public activeStudyMaterial: string;

  public categoryStudyMaterialList: any[];
  public subLevelOneStudyMaterialList: any[];
  public subLevelTwoStudyMaterialList: any[];
  public lastLevelStudyMaterialList: any[];

  constructor(
    private studyMaterialService: StudyMaterialService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.getStudyMaterialMenu();
  }

  public getStudyMaterialMenu() {
    this.studyMaterialService.getStudyMaterialMenu().subscribe((response) => {
      this.menuList = response.data.menu;
      // this.menuList = this.filterRecords(this.menuList);
    });
  }

  //filter records who don't have submenu and direct study materials
  public filterRecords(recordsArray: any[], lastLevel: boolean = false) {
    if (!recordsArray?.length) return [];
    return recordsArray.filter((record) => {
      return record?.direct_study_materials?.length || record?.sub_menu?.length;
    });
  }

  public menuSelected(
    subMenu: any[],
    studyMaterialList: any[],
    activeSubMenuId: string,
    level: number,
    event: any
  ) {
    event.stopPropagation(); //prevent menu from closing
    // this.studyMaterialList = studyMaterialList;
    switch (level) {
      case 1:
        this.subMenuOneList = subMenu;
        this.activeMenu = activeSubMenuId;
        this.categoryStudyMaterialList = studyMaterialList;
        this.subLevelOneStudyMaterialList = [];
        this.subLevelTwoStudyMaterialList = [];
        this.subMenuTwoList = [];
        this.subMenuThreeList = [];
        this.lastLevelStudyMaterialList = [];
        this.activeSubOneMenu = "";
        this.activeSubTwoMenu = "";
        this.activeSubThreeMenu = "";
        break;
      case 2:
        this.subMenuTwoList = subMenu;
        this.activeSubOneMenu = activeSubMenuId;
        this.subLevelOneStudyMaterialList = studyMaterialList;
        this.subLevelTwoStudyMaterialList = [];
        this.subMenuThreeList = [];
        this.lastLevelStudyMaterialList = [];
        this.activeSubTwoMenu = "";
        this.activeSubThreeMenu = "";
        break;
      case 3:
        this.subMenuThreeList = subMenu; //filter out record who don't have sub menu and direct sub menus;;
        this.activeSubTwoMenu = activeSubMenuId;
        this.subLevelTwoStudyMaterialList = studyMaterialList;
        this.lastLevelStudyMaterialList = [];
        this.activeSubThreeMenu = "";
        break;
      case 4: //get study material list
        this.subMenuFourList = subMenu; //filter out record who don't have sub menu and direct sub menus;;
        this.activeSubThreeMenu = activeSubMenuId;
        this.lastLevelStudyMaterialList = studyMaterialList;
        break;
      default:
        break;
    }
  }

  public openStudyMaterialDetails(
    studyMaterialId: any,
    activeId: string,
    level: number,
    studyMaterial: any
  ) {
    let route: string = this.callerLandingPage
      ? "/study-material"
      : this.userTypeStudent
        ? "student-dashboard/study-material"
        : "teacher-dashboard/study-material";
    // this.activeMenu = this.activeSubOneMenu = this.activeSubTwoMenu = "";
    // this.subMenuOneList = this.subMenuTwoList = this.subMenuThreeList = this.lastLevelStudyMaterialList = [];
    switch (level) {
      case 1:
        this.activeSubOneMenu = "";
        this.activeSubTwoMenu = "";
        this.activeSubThreeMenu = "";
        this.subLevelOneStudyMaterialList = [];
        this.subMenuThreeList = [];
        this.lastLevelStudyMaterialList = [];
        break;
      case 2:
        this.activeSubTwoMenu = "";
        this.activeSubThreeMenu = "";
        this.subMenuThreeList = [];
        this.lastLevelStudyMaterialList = [];
        break;
      case 3:
        this.activeSubThreeMenu = "";
        this.lastLevelStudyMaterialList = [];
        break;
    }
    this.activeStudyMaterial = activeId;
    console.log(studyMaterial);
    if (studyMaterial.type === "Mock Test") {
      this.openTestattemptModal(studyMaterial.mock_test_id);
    } else {
      this.router.navigate([route, studyMaterialId], {
        state: { callerLandingPage: this.callerLandingPage },
      });
    }



  }

  public openTestattemptModal(testId: any) {
    //opening add mock test modal
    const modalRef = this.modalService.open(26, 11);
    modalRef.componentInstance.testId = testId;
  }
}
