<div class="view-course-box">
  <div class="container">
    <app-title-box *ngIf="callerDashboard"></app-title-box>
    <div class="purposed-title d-flex justify-content-between align-items-center flex-wrap">
      <h3 class="heading-3">
        Proposed Courses <span class="font-light">(Group Classes)</span><span class="text-primary font-medium"
          *ngIf="coursesCount && !zeroSearchResults">
          {{ coursesCount }} result(s) found</span>
      </h3>
      <div class="d-flex advance-search-box">
        <a [routerLink]="[callerDashboard ? '/student-dashboard/proposed-courses' : '']" class="btn-back">
          <svg xmlns="http://www.w3.org/2000/svg" width="15.691" height="10.465" viewBox="0 0 15.691 10.465">
            <path id="Icon_ionic-ios-arrow-round-forward" data-name="Icon ionic-ios-arrow-round-forward"
              d="M17.882,11.452a.712.712,0,0,0-.005,1l3.314,3.319H8.578a.709.709,0,0,0,0,1.417H21.185L17.871,20.51a.717.717,0,0,0,.005,1,.706.706,0,0,0,1-.005l4.491-4.524h0a.8.8,0,0,0,.147-.223.676.676,0,0,0,.055-.273.71.71,0,0,0-.2-.5l-4.491-4.524A.694.694,0,0,0,17.882,11.452Z"
              transform="translate(23.566 21.717) rotate(180)" fill="#2592ff"></path>
          </svg>
        </a>
        <div class="dropdown">
          <a class="btn btn-outline-primary btn-view" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" #searchButton>Advanced Search</a>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <app-search-box (searchEvent)="fetchSearchResults($event)" [callerLandingPageTeachers]="false"
              [callerTuition]="false" [callerDashboard]="callerDashboard"></app-search-box>
          </div>
        </div>
      </div>
    </div>
    <div class="view-course">
      <div class="row">
        <div class="col-lg-4 col-sm-6 col-12" *ngFor="let course of proposedCourses">
          <div class="course-pane">
            <div class="text-lg-right course">
              <div class="back-img">
                <a [routerLink]="['../course', course.id]">
                  <div class="img-container">
                    <img src="{{ course.media }}" alt="" />
                  </div>
                </a>
              </div>
            </div>
            <div class="course-content">
              <div class="course-heading">
                <h6 (click)="navigateToCourseDetail(course.id)" class="mb-0 cursor-pointer"
                  ngbTooltip="{{ course.title }}" placement="top" [disableTooltip]="course.title.length < 20">
                  {{ course.title | shortText: 20 }}
                </h6>
                <p class="mt-0">
                  <span *ngFor="
                      let class of course?.classes;
                      count as length;
                      index as current
                    ">{{ class.subject.name
                    }}<span *ngIf="current < length - 1">, </span></span>
                </p>
              </div>
              <div class="d-flex justify-content-between bg-lightGreyColor p-2">
                <p class="mb-0 font-bold">
                  {{ course.total_sessions }} Session(s)
                </p>
                <div>
                  <span class="dropdown">
                    <a href="#" id="startDate" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <img src="assets/images/Icon-ionic-md-time.svg" class="mr-3" alt="" />
                    </a>
                    <div class="dropdown-menu" aria-labelledby="startDate">
                      <p class="text-success my-0">
                        Start Date:
                        {{ course.start_date | dateFormat: "fullDate" }}
                      </p>
                      <p class="text-success mb-0">
                        End Date: {{ course.end_date | dateFormat: "fullDate" }}
                      </p>
                    </div>
                  </span>
                  <span class="dropdown seats">
                    <a href="#" id="group" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <img src="assets/images/group.svg" alt="" />
                    </a>
                    <div class="dropdown-menu" aria-labelledby="group">
                      <p class="text-primary my-0">
                        Maximum Seats: {{ course.total_seats }}
                      </p>
                      <p class="text-primary my-0">
                        Available Seats: {{ course.available_seats }}
                      </p>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="enroll d-flex justify-content-between align-items-center flex-wrap">
              <a [ngClass]="{ disabled: course?.added_to_cart }" (click)="addToCart(course)"
                class="btn btn-sm btn-gradient">
                Enroll Now
              </a>
              <a href="" class="btn inactive-link btn-sm btn-secondary">
                {{course.is_free ? 'FREE' : 'PKR ' + (course.price | number)}}
              </a>
            </div>
          </div>
        </div>
        <p class="p-3" *ngIf="zeroSearchResults && !showSearchTriggeredMessage">
          Sorry! we cannot find any proposed tuitions related to your grade
        </p>
      </div>
      <p class="p-3 text-not-found" *ngIf="zeroSearchResults && showSearchTriggeredMessage">
        No matching result found
      </p>

      <div class="text-center">
        <a (click)="loadMore()" *ngIf="!maxLimitReached && !zeroSearchResults && showLoadMoreButton"
          class="btn btn-outline-primary btn-load">Load more
          <svg xmlns="http://www.w3.org/2000/svg" class="ml-2" width="11.918" height="6.814" viewBox="0 0 11.918 6.814">
            <defs>
              <style>
                .a {
                  fill: rgba(61, 64, 87, 0.4);
                }
              </style>
            </defs>
            <path class="a"
              d="M12.149,16.006l4.506-4.51a.848.848,0,0,1,1.2,0,.859.859,0,0,1,0,1.206l-5.106,5.109a.85.85,0,0,1-1.174.025L6.436,12.707a.852.852,0,0,1,1.2-1.206Z"
              transform="translate(-6.188 -11.246)" />
          </svg>
        </a>
      </div>
    </div>
  </div>
</div>