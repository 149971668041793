<section class="enroll-student-section">
  <div class="container">
    <h2 class="text-dark">Enroll Student's Feedback</h2>
    <div class="row">
      <div class="col-lg-4 col-md-4">
        <div class="img-container smile-grl">
          <img src="assets/images/student-feedback.png" class="img-fluid smile-grl-img" alt="">
        </div>
      </div>
      <div class="col-lg-8 col-md-8">
        <img src="assets/images/pause2.svg" class="pause-icon" alt="...">
        <div class="slider-box">
          <div id="feedBackCarousel" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#feedBackCarousel" data-slide-to="0" class="active"></li>
              <li data-target="#feedBackCarousel" data-slide-to="1"></li>
              <li data-target="#feedBackCarousel" data-slide-to="2"></li>
              <li data-target="#feedBackCarousel" data-slide-to="3"></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <p class="carasoul-item-text">
                  TALEEMLY allowed me to expand my horizons in my education. I was able to connect with teachers and
                  utilize my time efficiently. It’s just the best place to study online!
                </p>
                <div class="student-info">
                  <div>
                    <h3 class="heading-3">Ada Khan</h3>
                    <p class="font-light">Grade 10, Mathematics</p>
                  </div>
                  <div class="d-none">
                    <div class="img-container">
                      <img src="assets/images/Mask-Group-5.png" class="d-block" alt="">
                      <p class="font-light mt-2">Ada Khan, Grade 10 </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <p class="carasoul-item-text">
                  TALEEMLY allowed me to take control over my weak points and improve them gradually. It provided me concise way to learn effectively. I love VIVID!
                </p>
                <div class="student-info">
                  <div>
                    <h3 class="heading-3">Ahmed Chaudhry</h3>
                    <p class="font-light">1st Year, Biology</p>
                  </div>
                  <div class="d-none">
                    <div class="img-container">
                      <img src="assets/images/Mask-Group-1.png" class="d-block" alt="">
                      <p class="font-light mt-2">Ahmed Chaudhry, Biology</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <p class="carasoul-item-text">
                  TALEEMLY allowed me to expand my horizons in my education. I was able to connect with teachers and
                  utilize my time efficiently. It’s just the best place to study online!
                </p>
                <div class="student-info">
                  <div>
                    <h3 class="heading-3">Abu Ubaida Rao</h3>
                    <p class="font-light">2nd Year, Mathematics</p>
                  </div>
                  <div class="d-none">
                    <div class="img-container">
                      <img src="assets/images/Mask-Group-2.png" class="d-block" alt="">
                      <p class="font-light mt-2">Abu Ubaida Rao, Mathematics</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <p class="carasoul-item-text">
                  The teachers on TALEEMLY are very collaborative and always encouraged me to ask questions. It’s just a great online platform to score good grades.
                </p>
                <div class="student-info">
                  <div>
                    <h3 class="heading-3">Taimur Ahmed</h3>
                    <p class="font-light">2nd Year, Chemistry</p>
                  </div>
                  <div class="d-none">
                    <div class="img-container">
                      <img src="assets/images/Mask-Group-3.png" class="d-block" alt="">
                      <p class="font-light mt-2">Taimur Ahmed, Chemisty</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <a class="carousel-control-prev" href="#feedBackCarousel" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a> -->
            <a class="carousel-control-next d-none" href="#feedBackCarousel" role="button" data-slide="next">
              <img src="assets/images/ionic-ios-arrow-dropleft-circle.svg" alt="">
              <!-- <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span> -->
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
