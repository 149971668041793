<div class="content-wrapper">
    <div class="privacy-content">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="content">
                    <h2 class="title">Data Deletion Instructions</h2>
                    <p>According to the Facebook Apps and Websites Platform rules, we have to provide User Data Deletion Callback URL or Data Deletion Instructions URL.
                        If you want to delete your activities for the Vivid App, you can remove them by following the instructions below:
                    </p>
                    <ol>
                        <li>
                            Go to Your Facebook Account’s Settings.
                        </li>
                        <li>
                            Next, go to “Apps and Websites” where you will see all of the activity related to apps and websites that are linked to your Facebook account.
                        </li>
                        <li>
                            Select the option box for 'Vivid' and click on the “Remove” button.
                        </li>
                        <li>
                            Mark the option boxes according to your preference and click on the “Remove” button.
                        </li>
                        <li>
                            You’re done! You have successfully removed Vivid from your activities.
                        </li>
                    </ol>

                    
                    <!-- <h4 class="text-green">Contact us</h4>
                    <p><b>Feel free to drop by or call to say</b> Hello!</p>
                    <div class="bottom">
                        <p>GO VIVID
                            97-D, Commercial Broadway, Phase 8, DHA, Lahore</p>
                        
                        <p><b>Phone:</b> 042 - 3713 5041</p>
                        <p><b>Email:</b> info@govivid.pk</p>
                        <p><b>Days:</b> Monday to Friday</p>
                        <p><b>Time:</b> 0900 to 1700 Hours</p>
                    </div>
                    
                    <p>&nbsp;</p>
                    <p><b>Happy Online Learning!</b></p> -->
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
