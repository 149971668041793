import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { ModalService } from 'src/app/services/modal.service';
import { UserService } from 'src/app/services/user.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-social-media-register',
  templateUrl: './social-media-register.component.html',
  styleUrls: ['./social-media-register.component.scss']
})
export class SocialMediaRegisterComponent implements OnInit {

  @Input() email: string;

  public socialMediaRegistrationFormGroup: FormGroup = new FormGroup({
    // 'registerAs': new FormControl("student", [Validators.required]),
    'phone': new FormControl(null, [Validators.required, Validators.pattern(this.utilityService.phoneNumberRegex)]),
  });
  constructor(
    private utilityService: UtilityService,
    private userService: UserService,
    private modalService: ModalService,
    private activeModal: NgbActiveModal,
    private confirmDialogService: ConfirmationDialogService,

  ) { }

  ngOnInit(): void {
  }

  get socialMediaRegistrationFormControls() {
    return this.socialMediaRegistrationFormGroup.controls;
  }

  //form submit function
  public onSubmit() {
    //show client side validation errors
    this.socialMediaRegistrationFormGroup.markAllAsTouched();
    if (this.socialMediaRegistrationFormGroup.invalid) return;
    //data object
    const data = {
      'type': this.utilityService.getUserType(),
      'phone': this.utilityService.addSpacesInPhoneNo(this.socialMediaRegistrationFormControls.phone.value),
      'email': this.email
    };

    //api call
    this.userService.registerSocialMediaUser(data).subscribe(response => {
      //close active modal
      this.activeModal.close();
      //open otp verification component
      let modalRef;
      //wait for closing model to complete animation
      setTimeout(() => {
        modalRef = this.modalService.open(6, 1);
        //setting data for the otp verification componennt
        modalRef.componentInstance.email = data.email;
        modalRef.componentInstance.caller = 'registration';
        modalRef.componentInstance.phoneNo = data.phone;
      }, 1000);

    },
      (error: HttpErrorResponse) => {
        //showing server side validation errors on specific form fields
        if ('phone' in error.error.error_details) {
          this.socialMediaRegistrationFormGroup.controls['phone'].setErrors({ 'alreadyTaken': error.error.error_details.phone });
        }
      }
    );
  }

  //close modal
  public async closeModal() {
    const result = await this.confirmDialogService.getConfirmation('Exit', 'Are you sure you want to exit ?');
    if (result) {//if user select yes on confirmation
      this.activeModal.close();
    }
  }

  //allow user to only input numbers + and spaces
  public checkPhoneInput(event) {
    return this.utilityService.allowNumberAndSpaceOnly(event);
  }

  public addPrefix(){
    if(!this.socialMediaRegistrationFormGroup.controls.phone.value){
      this.socialMediaRegistrationFormGroup.controls.phone.setValue("+92");
    }
  }
}
