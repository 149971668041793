<div class="content-wrapper">
    <div class="privacy-content">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="content">
                     <h2 class="title">Privacy Policy</h2>
                     <p>Welcome students, parents, teachers, principals, administrators and other people who love
                         learning and education! This document sets out our Privacy Policy relating to our online
                         educational platform GOVIVID Pakistan.
                    </p>

                    <h4>How do we collect personal data?</h4>
                    <p>We collect personal data of students, teachers, administrators and other people who use GOVIVID.
                        An individual registers on our platform and provides us with personal data.
                    </p>

                    <h4>What do we do with personal data?</h4>
                    <p>In general, we use personal data to track the usage and performance of individuals on our educational
                        platform and to improve our solutions and tailor our solutions to their requirements and to offer
                        them solutions that may be useful to them.
                    </p>

                    <h4>Do we make personal data public or provide it to third parties?</h4>
                    <p>We do not make public or provide to any third party any personal data obtained through our educational
                        platform except that:
                    </p>

                    <ol>
                        <li>We will provide personal data as may be required by law or government orders;</li>
                        <li>We will share personal data if you consent to sharing such data;</li>
                        <li>We may use personal data to improve services to you, communicate with you and offer solutions to
                            you and to provide profile-based advertising or marketing solutions and to enroll you in programs
                            run by us; and</li>
                        <li>In cases where you enter into a program run by us that involves provision or publication of
                            personal data, for example, in a competition leaderboard, or to obtain prizes, or win scholarships,
                            then your personal data may be provided or publicized to the extent required under such program.
                        </li>
                    </ol>

                    <h4>Children’s Privacy</h4>
                    <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable 
                        information from children under 13. In the case we discover that a child under 13 has provided us with personal 
                        information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that 
                        your child has provided us with personal information, please contact us so that we will be able to do necessary 
                        actions.
                    </p>

                    <h4>Changes in Privacy Policy</h4>
                    <p>The GOVIVID maintains the rights to make changes in the privacy policy if need be. The changes will be made
                        public and made known to our readers through website. You are advised to review our privacy policy on a
                        regular basis.
                    </p>

                    <h4>Refund Policy</h4>
                    <p>GOVIVID believes in full customer satisfaction. Our refund policy is based on the customer requirement.
                        If a customer is not satisfied with the learning he/she may contact the web administrator within 3 days
                        of signing up with the program for a full refund. Only 50% refund will be applicable if it is requested
                        after the first 2 session. Beyond that GOVIVID will not be liable for any refund.
                    </p>

                    
                    <!-- <h4 class="text-green">Contact us</h4>
                    <p><b>Feel free to drop by or call to say</b> Hello!</p>
                    <div class="bottom">
                        <p>GO VIVID
                            97-D, Commercial Broadway, Phase 8, DHA, Lahore</p>
                        
                        <p><b>Phone:</b> 042 - 3713 5041</p>
                        <p><b>Email:</b> info@govivid.pk</p>
                        <p><b>Days:</b> Monday to Friday</p>
                        <p><b>Time:</b> 0900 to 1700 Hours</p>
                    </div>
                    
                    <p>&nbsp;</p>
                    <p><b>Happy Online Learning!</b></p> -->
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
