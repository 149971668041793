import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from '../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class ErrorIntercept implements HttpInterceptor {
  constructor(
    private toastr: ToastrService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          // client-side error
        } else {
          this.spinner.hide();
          // server-side error
          if (error.error && error.error.error_details) {
            // get first error message from model state
            let errorMsg: string = '';
            Object.keys(error.error.error_details).forEach((key) => {
              errorMsg += error.error.error_details[key] + '<br>'; //using break because ngx-toast takes input as html
            });
            // const errorMsg = error.error.error_details[Object.keys(error.error.error_details)[0]];

            if (errorMsg) {
              // show error message provided by API or show default
              // this.toastr.error(errorMsg);
            }
          }
          else {
            // show error message provided by API or show default
            if (error.status !== 401) {
              if (error.status === 404) {
                // this.toastr.error('Resource not found.', "Error");
                this.router.navigate(['/not-found']);
              } else if(error.status === 403){ //accessing forbidden information
                this.router.navigate(['/not-found']);
              } else {
                this.toastr.error((error.error && error.error.message) || 'Something Went Wrong.', "Error");
              }
            } else {
              if (error.status === 401) {
                if (error.error) { // if error has details
                  // this.toastr.error((error.error && error.error.message) || 'Something Went Wrong.', "Error");
                } else {
                  this.toastr.error('Your session expired.', "Error");
                  this.authService.logoutRedirect();
                }
              }
            }
          }

          // If user is unauthorized redirect to login view
          if (error.status === 401) {
            // this.toastr.error('Your session expired.', "Error");
            this.authService.logoutRedirect();

          }
        }

        // throw error
        return throwError(error);
      })
    );
  }
}
