<div class="modal-header pb-0">
  <button type="button" class="close" (click)="closeModal()">
    <span aria-hidden="true">
      <img src="assets/images/close.png" alt="" />
    </span>
  </button>
</div>
<div class="modal-body pt-1">
  <div class="student-login-box cont-registration">
    <div class="student-login">
      <h3 class="">{{ title }}</h3>
      <form [formGroup]="otpVerificationFormGroup" (submit)="onSubmit()">
        <div class="login-box">
          <div class="form-label-group">
            <input pattern="[1-9][0-9]{3}" type="text" formControlName="otp" placeholder="1234" class="form-control"
              (keypress)="checkNumber($event)" id="otp" />
            <label for="otp">Enter OTP</label>
            <div>
              <span class="text-danger" *ngIf="
                  otpVerificationFormControls.otp.touched &&
                  otpVerificationFormControls.otp.errors?.required ||
                  otpVerificationFormControls.otp.errors?.minlength ||
                    otpVerificationFormControls.otp.errors?.maxlength ||
                    otpVerificationFormControls.otp.errors?.pattern
                ">
                Please enter a valid code(1234).
              </span>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-gradient btn-block">
          Continue
        </button>
        <p class="otp-text">
          OTP has been sent at <span class="text-success">{{ phoneNo | removePhoneNumberSpaces }}</span>
        </p>
      </form>
    </div>
    <div class="otp-text">
      <span>OTP not received?
        <a (click)="resendOTP()" class="text-success cursor-pointer pl-2">Send again <img
            src="assets/images/arrow-forward.svg" alt="" /> </a></span>
    </div>
  </div>
</div>