import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { ApiService } from "./api.service";
import { HttpService } from "./http.service";
import { NotificationService } from "./notification.service";
import { SocialMediaService } from "./social-media.service";
import { UtilityService } from "./utility.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private userDataSubject: Subject<object> = new Subject();
  public userDataObservable: Observable<object> = this.userDataSubject.asObservable();

  private sockectDisconnectSubject: Subject<boolean> = new Subject();
  public sockectDisconnectObservable: Observable<boolean> = this.sockectDisconnectSubject.asObservable();

  constructor(
    private http: HttpService,
    private apiService: ApiService,
    private router: Router,
    private socialLoginService: SocialMediaService,
    private utilityService: UtilityService,
    private toastr: ToastrService
  ) { }

  public loginUser(loginData: any): Observable<any> {
    return this.http.post(this.apiService.get("login"), loginData).pipe(
      tap((response) => {
        if (response.data.user.type !== 'student') return; //if logged in user is not a teacher
        if (response.data.user.student_profile) return; //if student profile is not null
        response.data.student_profile = {
          grade_id: null,
          grade_name: null,
          board_id: null,
          board_name: null,
          date_of_birth: null,
          gender: null,
          school_name: null,
          address: null,
          city: null,
          state: null,
          country: null,
          zip_code: null,
          guardian_name: null,
          guardian_mobile: null,
          guardian_email: null,
          guardian_relation: null,
        };
        return response.data;
      })
    );
  }

  public loginUserFacebook(loginData: any): Observable<any> {
    return this.http.post(
      this.apiService.get("facebookLogin"),
      loginData,
      true
    );
  }

  public loginUserGoogle(loginData: any): Observable<any> {
    return this.http.post(this.apiService.get("googleLogin"), loginData, true);
  }

  public updatePassword(data: any): Observable<any> {
    return this.http.post(this.apiService.get("updatePassword"), data);
  }

  public logoutUser(): Observable<any> {
    return this.http.delete(this.apiService.get("logout"));
  }

  public forgotPassword(data: any): Observable<any> {
    return this.http.post(this.apiService.get("forgotPassword"), data);
  }

  public verifyOTP(data: any, type: string): Observable<any> {
    //data contains the code and type is for registration and forgot password
    if (type === "registration") {
      return this.http.post(this.apiService.get("registerVerifyPhone"), data);
    } else {
      return this.http.post(this.apiService.get("forgotPasswordVerify"), data);
    }
  }

  public resendOTP(data: any): Observable<any> {
    //data contains the PHONE|EMAIL and type
    return this.http.post(this.apiService.get("resendOTP"), data);
  }

  public resetPassword(data: any): Observable<any> {
    return this.http.post(this.apiService.get("forgotPasswordChange"), data);
  }

  public changePassword(data: any): Observable<any> {
    return this.http.post(this.apiService.get("changePassword"), data);
  }

  public isAuthenticated(): boolean {
    // get token from browser local storage and return
    const token = localStorage.getItem("jwt");

    // if token available user is authenticated otherwise not
    return token ? true : false;
  }

  public getAuthToken(): string {
    // get token from browser local storage and return
    return localStorage.getItem("jwt");
  }

  public setAuthToken(authToken: string): void {
    // set token in browser local storage
    localStorage.setItem("jwt", authToken);
  }

  public setCurrentUser(user: any): void {
    localStorage.setItem("user", JSON.stringify(user));

    // if (user.type === "student" && user.student_profile === null) { //if user is student and his profile is null
    //   user.student_profile = {
    //     grade_id: null,
    //     grade_name: null,
    //     board_id: null,
    //     board_name: null,
    //     date_of_birth: null,
    //     gender: null,
    //     school_name: null,
    //     address: null,
    //     city: null,
    //     state: null,
    //     country: null,
    //     zip_code: null,
    //     guardian_name: null,
    //     guardian_mobile: null,
    //     guardian_email: null,
    //     guardian_relation: null,
    //   };
    // }
    this.userDataSubject.next(user);
  }

  public getCurrentUser() {
    // get user from local storeage
    const user = localStorage.getItem("user");
    if (user) {
      // returned JSON parsed user object
      return JSON.parse(user);
    }
  }

  public getCurrentUserId() {
    // get user from local storeage
    const user = localStorage.getItem("user");
    if (user) {
      // returned JSON parsed user object
      let jsonUser = JSON.parse(user);
      return jsonUser.id;
    }
  }

  public logoutRedirect(): void {
    //disconnect socket
    this.sockectDisconnectSubject.next(true);
    // remove items from local storage
    localStorage.clear();

    // show login screen
    this.router.navigate([""]);

    //get social media logout
    // console.log(this.utilityService.getSocialMediaRegistrationFlag());
    // if (this.utilityService.getSocialMediaRegistrationFlag()) {
    this.utilityService.setSocialMediaRegistrationFlag(false);
    this.utilityService.socialMediaUserData = null;
    this.socialLoginService.signOut();
    // }
  }

  public setUserDataToken(user: any): void {
    // set token in local storage
    this.setAuthToken(user.data.access_token);
    //show first time login approval screen for teacher case
    if (
      user.data.user.first_time_login === true &&
      user.data.user.type === "instructor"
    ) {
      this.toastr.success(
        "Congratulations! Your profile has been approved by admin"
      );
    }
    //set current user
    this.setCurrentUser(user.data.user);
    //navigate to dashboard on successfull login
    let route =
      user.data.user.type === "student"
        ? "student-dashboard/enrolled-student"
        : "teacher-dashboard";
    this.router.navigate([route]);
  }

  public checkUserType(userType: string): object {
    // const type = checkStudent ? 'student' : 'teacher';
    const user = this.getCurrentUser();
    return {
      userType: user?.type,
      sameUser: user?.type === userType ? true : false,
    };
  }

  //get student profile
  public getStudentProfile(): Observable<any> {
    return this.http.get(this.apiService.get("getStudentProfile")).pipe(
      tap((response) => {
        if (response.data.student_profile) return; //if student profile is not null
        response.data.student_profile = {
          grade_id: null,
          grade_name: null,
          board_id: null,
          board_name: null,
          date_of_birth: null,
          gender: null,
          school_name: null,
          address: null,
          city: null,
          state: null,
          country: null,
          zip_code: null,
          guardian_name: null,
          guardian_mobile: null,
          guardian_email: null,
          guardian_relation: null,
        };
        return response.data;
      })
    );
  }

  //get instructor profile
  public getInstructorProfile(): Observable<any> {
    return this.http.get(this.apiService.get("getInstructorProfile"));
  }
}
