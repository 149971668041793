<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLongTitle">
    Teacher Feedback
  </h5>
  <button type="button" class="close" (click)="closeModal()">
    <img _ngcontent-pqa-c149="" src="assets/images/close.png" alt="">
  </button>
</div>
<div class="modal-body">
  <div class="content-editor">
    <p [innerHTML]="feedback"></p>
  </div>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-gradient btn-send">Send</button>
  </div> -->