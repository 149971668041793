<header class="dashboard-header">
  <nav class="navbar navbar-expand-xl navbar-light bg-lightGreyColor custom-navbar navbar-me">
    <a class="navbar-brand ml-3" (click)="refresh()">
      <img src="assets/images/brand2.svg" class="brand-name" alt="VIVID" />
    </a>
    <button class="navbar-toggler" type="button" data-toggle="offcanvas" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
      (click)="menuButton()">
      <div id="nav-icon2">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>

    <div class="offcanvas-collapse navbar-collapse" [ngClass]="openNav ? 'open' : 'offcanvas-collapse'"
      id="navbarSupportedContent">
      <ul class="navbar-nav align-items-xl-center">
        <li class="nav-item">
          <a class="nav-link text-right close-offcanvas-menu" (click)="openNav = !openNav" href="#">
            <img src="assets/images/close.png" alt="" />
          </a>
        </li>
        <li class="nav-item nav-item-user" [routerLinkActive]="['active']">
          <a class="nav-link pr-2 dropdown-toggle drop-link" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" (click)="toggledropdown()" #dropdownMenu>
            <img [lazyLoad]="
                user.student_profile?.profile_picture != null
                  ? user.student_profile?.profile_picture
                  : 'assets/images/avatar.svg'
              " class="user-img-menu" alt="" />
            {{ user.name | titlecase }}
          </a>
          <div class="dropdown-menu custom-dropdown" aria-labelledby="navbarDropdown" #dropdownSibling>
            <div class="drop-list">
              <div class="menu-list">
                <ul class="menu-list-inner">
                  <li>
                    <a [routerLink]="['my-profile']">My Profile</a>
                  </li>
                  <li class="">
                    <a [routerLink]="['my-purchases']">My Purchases</a>
                  </li>
                  <li>
                    <a (click)="onLogout()">Logout</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item" *ngIf="isStudent && !user.student_profile?.is_enrolled_to_course"
          [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['proposed-courses']">Dashboard</a>
        </li>
        <li class="nav-item" *ngIf="isStudent && user.student_profile?.is_enrolled_to_course"
          [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['enrolled-student']">Dashboard</a>
        </li>
        <li class="nav-item" *ngIf="!isStudent" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['sessions']">Dashboard</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']" *ngIf="isStudent">
          <a class="nav-link" [routerLink]="['my-courses']">My Courses</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']" *ngIf="!isStudent">
          <a class="nav-link" [routerLink]="['my-classes']">My Classes</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['my-schedules']">My Schedules</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            Free Study Material
          </a>
          <div class="dropdown-menu custom-dropdown" aria-labelledby="navbarDropdown">
            <app-study-material [callerLandingPage]="false" [userTypeStudent]="isStudent"></app-study-material>
          </div>
        </li>
      </ul>
    </div>
    <ul class="navbar-nav nav-user ml-auto align-items-xl-center">
      <li class="nav-item">
        <a class="btn btn-gradient btn-sm btn-add-course"
          *ngIf="isStudent && user.student_profile?.is_enrolled_to_course" [routerLink]="['proposed-courses']">Add
          Course</a>
      </li>
      <li class="nav-item">
        <div class="dropdown notification-dropdown cart-dropdown" *ngIf="isStudent">
          <a class="nav-link pr-2 cart-icon" href="#" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <svg xmlns="http://www.w3.org/2000/svg" width="36.261" height="25.899" viewBox="0 0 36.261 25.899">
              <path id="Icon_zocial-cart" data-name="Icon zocial-cart"
                d="M-2.07,6.169A1.449,1.449,0,0,1-1.649,5.1,1.445,1.445,0,0,1-.581,4.68H7.028a1.484,1.484,0,0,1,.923.308,1.438,1.438,0,0,1,.534.826l.777,3.173h21.82l.228-.68A1.339,1.339,0,0,1,32.7,7.27a1,1,0,0,1,.421.065,1.394,1.394,0,0,1,.777.518,1.414,1.414,0,0,1,.291.874,1.809,1.809,0,0,1-.065.421l-3.82,12.724a1.393,1.393,0,0,1-.518.777,1.423,1.423,0,0,1-.874.291h-18.1a1.439,1.439,0,0,1-.923-.323,1.512,1.512,0,0,1-.534-.81L5.862,7.626H-.581a1.464,1.464,0,0,1-1.052-.421A1.382,1.382,0,0,1-2.07,6.169ZM9.974,11.932l2.007,8.029H27.813l.064-.29,2.332-7.738H9.974Zm1.36,15.67a2.841,2.841,0,0,1,.89-2.1,2.924,2.924,0,0,1,2.121-.874,2.978,2.978,0,0,1,0,5.956,2.923,2.923,0,0,1-2.121-.873A2.845,2.845,0,0,1,11.333,27.6Zm2.1-13.144h5.374v2.978H14.084Zm7.932,2.979.032-2.978h5.245l-.777,2.978Zm1.2,10.166a2.842,2.842,0,0,1,.891-2.1,2.921,2.921,0,0,1,2.121-.874,2.978,2.978,0,0,1,0,5.956,2.921,2.921,0,0,1-2.121-.873A2.842,2.842,0,0,1,22.568,27.6Z"
                transform="translate(2.07 -4.68)" fill="#bcbccb" />
            </svg>
            <!-- <img
              src="assets/images/Notification Badge.svg"
              *ngIf="showCartBadge"
              class="notification-badge"
              alt=""
            /> -->
            <span class="notification-badge badge badge-pill badge-success">{{
              cartCount || ""
            }}</span>
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <p class="dropdown-header text-center text-white notification-head bg-success">
              My Cart
            </p>
            <div class="dropdown-menu-inner">
              <app-cart (showBadge)="cartItemsChanged($event)"></app-cart>
            </div>
          </div>
        </div>
      </li>
      <li class="nav-item">
        <div class="dropdown notification-dropdown">
          <a class="nav-link notification-icon" href="#" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
              <path id="Notification_Icon" data-name="Notification Icon"
                d="M9.375,21.875h6.249a3.125,3.125,0,0,1-6.249,0ZM1.562,20.313a1.562,1.562,0,0,1,0-3.125h.782A6.8,6.8,0,0,0,4.687,12.5V7.813A7.738,7.738,0,0,1,12.5,0a7.737,7.737,0,0,1,7.812,7.813V12.5a6.8,6.8,0,0,0,2.344,4.688h.782a1.562,1.562,0,1,1,0,3.125Z"
                fill="#bcbccb" />
            </svg>
            <!-- <img
              src="assets/images/Notification Badge.svg"
              *ngIf="showNotificationBadge"
              class="notification-badge"
              alt=""
            /> -->
            <span class="notification-badge badge badge-pill badge-success">{{
              notificationCount || ""
            }}</span>
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <p class="dropdown-header text-center text-white notification-head bg-success">
              Notifications
            </p>
            <div class="dropdown-menu-inner">
              <a class="dropdown-item" [ngClass]="{ 'bg-grey': !notification['web_read_at'] }"
                (click)="navigateToNotification(notification)" *ngFor="
                  let notification of notificationList
                    | slice: 0:notificationLimit
                ">{{ notification["payload"]?.notification }}</a>
              <a class="dropdown-item text-center inactive-link" *ngIf="!notificationList?.length">You don’t have any
                notifications yet</a>
              <!-- <a class="dropdown-item" href="#"
                >Hey, shahbaz 12 new notifications</a
              >
              <a class="dropdown-item" href="#">Something else here</a> -->
            </div>
            <a class="dropdown-item text-success text-center" *ngIf="notificationList?.length > 5" (click)="
                seeAllNotifications(
                  $event,
                  notificationLimit > 5 ? true : false
                )
              ">{{ notificationLimit > 5 ? "See Less" : "See All" }}</a>
          </div>
        </div>
      </li>
      <li class="nav-item dropdown hide-item-tab">
        <a class="nav-link pr-2 dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          {{ user.name | titlecase }}
        </a>
        <div class="dropdown-menu profile-dropdown" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" [routerLink]="['my-profile']">My Profile</a>
          <a class="dropdown-item" *ngIf="isStudent" [routerLink]="['/student-dashboard/my-purchases']">My Purchases</a>
          <a class="dropdown-item" (click)="onLogout()">Logout</a>
        </div>
      </li>
      <li class="nav-item hide-item-tab">
        <a class="nav-link avatar" [routerLink]="['my-profile']">
          <div class="img-container">
            <img [lazyLoad]="
                user[isStudent ? 'student_profile' : 'instructor_profile']
                  ?.profile_picture != null
                  ? user[isStudent ? 'student_profile' : 'instructor_profile']
                      ?.profile_picture
                  : 'assets/images/avatar.svg'
              " alt="" />
          </div>
        </a>
      </li>
    </ul>

    <!-- off canvas multilevel menu -->
    <nav class="navegacion mble-menu">
      <ul class="menu">
        <li class="title-menu text-left">
          <img [lazyLoad]="
              user.student_profile?.profile_picture != null
                ? user.student_profile?.profile_picture
                : 'assets/images/avatar.svg'
            " class="user-img-menu ml-4" alt="" />
          {{ user.name | titlecase }}
        </li>
        <li  class="nav-item">
          <a class="nav-link" (click)="menuButton()" [routerLink]="['my-profile']">My Profile</a>
        </li>
        <li class="nav-item" *ngIf="isStudent && !user.student_profile?.is_enrolled_to_course"
        [routerLinkActive]="['active']">
        <a (click)="menuButton()" class="nav-link" [routerLink]="['proposed-courses']">Dashboard</a>
      </li>
      <li class="nav-item" *ngIf="isStudent && user.student_profile?.is_enrolled_to_course"
        [routerLinkActive]="['active']">
        <a (click)="menuButton()" class="nav-link" [routerLink]="['enrolled-student']">Dashboard</a>
      </li>
      <li class="nav-item" *ngIf="!isStudent" [routerLinkActive]="['active']">
        <a (click)="menuButton()" class="nav-link" [routerLink]="['sessions']">Dashboard</a>
      </li>
      <li class="nav-item" [routerLinkActive]="['active']" *ngIf="isStudent">
        <a (click)="menuButton()" class="nav-link" [routerLink]="['my-courses']">My Courses</a>
      </li>
      <li class="nav-item" [routerLinkActive]="['active']" *ngIf="!isStudent">
        <a (click)="menuButton()" class="nav-link" [routerLink]="['my-classes']">My Classes</a>
      </li>
        <li><a (click)="menuButton()" [routerLink]="['my-schedules']">My Schedules</a></li>
        <li>
          <app-study-material-mobile-menu (clicked)="menuButton()" [callerLandingPage]="false" [userTypeStudent]="isStudent">
          </app-study-material-mobile-menu>
        </li>
        <li><a (click)="menuButton()" class="dropdown-item" *ngIf="isStudent" [routerLink]="['/student-dashboard/my-purchases']">My
            Purchases</a></li>
        <li><a (click)="onLogout()">Logout</a></li>
      </ul>
    </nav>
  </nav>
</header>