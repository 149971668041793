import { Component, OnInit } from "@angular/core";
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from "@angular/router";
import { StudyMaterialService } from "src/app/services/study-material.service";

@Component({
  selector: "app-study-material-content",
  templateUrl: "./study-material-content.component.html",
  styleUrls: ["./study-material-content.component.scss"],
})
export class StudyMaterialContentComponent implements OnInit {
  public callerLandingPage: boolean;
  private studyMaterialId: any;
  public studyMaterial: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private studyMaterialService: StudyMaterialService,
    private metaTagService: Meta, 
    private metaTitleService: Title
  ) {
    // this.studyMaterialId = this.activatedRoute.snapshot.params["id"];
    this.activatedRoute.params.subscribe((params) => {
      this.studyMaterialId = +params["id"];
      this.studyMaterialService
        .getStudyMaterial(this.studyMaterialId)
        .subscribe((response) => {
          this.studyMaterial = response.data;
        });
    });

    this.callerLandingPage = router?.getCurrentNavigation()?.extras?.state?.callerLandingPage;
  }

  ngOnInit(): void {
    //add tags for SEO
    this.metaTagService.updateTag({ name: 'keywords', content: 'VIVID smart learning, free study material' });
    this.metaTitleService.setTitle('Get Access to Free Study Material from Class-1 to Intermediate');
    this.metaTagService.updateTag({ name: 'description', content: 'Students can find all the study material free of cost on vivid smart learning platform.' });
  }
}
