import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { ModalService } from 'src/app/services/modal.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
  @Input() phone: string;
  @Input() otp: string;
  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  //form instantiation
  public resetPasswordFormGroup: FormGroup = new FormGroup({
    'password': new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(this.utilityService.passwordRegex)]), //four digit otp password
    'confirmPassword': new FormControl(null, [Validators.required]), //four digit otp password
  }, { validators: this.utilityService.confirmValidator.bind(this) });

  constructor(
    private modalService: ModalService,
    private authService: AuthService,
    private toastr: ToastrService,
    private utilityService: UtilityService,
    private activeModal: NgbActiveModal,
    private confirmDialogService: ConfirmationDialogService

  ) { }

  ngOnInit(): void { }

  get resetPasswordFormControl() {
    return this.resetPasswordFormGroup.controls;
  }

  public onSubmit() {
    //if user tries to submit form without filling all field correctly
    this.resetPasswordFormGroup.markAllAsTouched();
    if (this.resetPasswordFormGroup.invalid) return;
    //data object
    const data = {
      'phone': this.phone,
      'otp': this.otp,
      'password': this.resetPasswordFormControl.password.value,
      'password_confirmation': this.resetPasswordFormControl.confirmPassword.value
    };
    //api call to reset password
    this.authService.resetPassword(data).subscribe(response => {
      //success screen
      this.toastr.success('Password reset successfully', 'Success');
      //close active modal
      this.activeModal.close();
      //wait for closing model to complete animation
      setTimeout(async () => {
        //open success message component
        await this.modalService.getSuccessModal('Password Change Success', 'Your Password has been changed.');
        this.modalService.open(1, 1);
      }, 1000);

    })
  }

  //close modal
  public async closeModal() {
    const result = await this.confirmDialogService.getConfirmation('Exit', 'Are you sure you want to exit ?');
    if (result) {//if user select yes on confirmation
      this.activeModal.close();
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class
  }
}
