<a class="first-anchor" (click)="subMenu($event)">{{fetchGroupTuitionMenucallerTuition ? '1 on 1 Tuition' : 'Courses'}}</a>
<ul class="submenu">
  <li class="title-menu">{{fetchGroupTuitionMenucallerTuition ? '1 on 1 Tuition' : 'Courses'}}</li>
  <li class="go-back"><a (click)="closeMenu($event)">Back</a></li>
  <!-- level one -->
  <li class="item-submenu" *ngFor="let menu of courseMenuList">
    <a (click)="subMenu($event)">{{menu.title}}</a>
    <ul class="submenu">
      <li class="title-menu">{{menu.title}}</li>
      <li class="go-back"><a (click)="closeMenu($event)">Back</a></li>

      <li class="item-submenu" *ngFor="let menu of menu.sub_menu">
        <!-- level 2-->
        <a (click)="subMenu($event)">{{menu.title}}</a>
        <ul class="submenu">
          <li class="title-menu">{{menu.title}}</li>
          <li class="go-back"><a (click)="closeMenu($event)">Back</a></li>
          <li class="item-submenu" *ngFor="let menu of menu.sub_menu">
            <!-- level 3-->
            <a (click)="subMenu($event)">{{menu.title}}</a>
            <ul class="submenu">
              <li class="title-menu">{{menu.title}}</li>
              <li class="go-back"><a (click)="closeMenu($event)">Back</a></li>
              <li class="item-submenu" *ngFor="let menu of menu.sub_menu">
                <!-- level 4-->
                <a (click)="subMenu($event)">{{menu.title}}</a>
                <ul class="submenu">
                  <li class="title-menu">{{menu.title}}</li>
                  <li class="go-back"><a (click)="closeMenu($event)">Back</a></li>
                  <li><a (click)="navigateToCourse(course.id)"
                      *ngFor="let course of menu.direct_courses">{{course.title}}</a></li>
                </ul>
              </li>
              <li><a (click)="navigateToCourse(course.id)"
                  *ngFor="let course of menu.direct_courses">{{course.title}}</a></li>
            </ul>
          </li>
          <li><a (click)="navigateToCourse(course.id)" *ngFor="let course of menu.direct_courses">{{course.title}}</a>
          </li>
        </ul>
      </li>
      <li><a (click)="navigateToCourse(course.id)" *ngFor="let course of menu.direct_courses">{{course.title}}</a></li>
    </ul>
  </li>
</ul>