<section class="study-material-section">
    <div class="container">
        <app-title-box *ngIf="!callerLandingPage"></app-title-box>
    </div>
    <div class="description-section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="description">
                        <h2 class="material-title">
                            {{studyMaterial?.title}}
                        </h2>
                        <p class="grade">
                            {{studyMaterial?.category?.title}}
                        </p>
                        <p class="description-text" [innerHTML]="studyMaterial?.description"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="material-download courses-section">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3" *ngFor="let file of studyMaterial?.study_material_files">
                    <div _ngcontent-mfm-c252="" class="card">
                        <div class="card-header">
                            <h6 _ngcontent-mfm-c252="">{{file.media_name}}</h6>
                        </div>
                        <div _ngcontent-mfm-c252="" class="card-body">
                            <a class="link-download cursor-pointer" [href]="file.media_url" target="_blank">
                                Download
                                <svg class="ml-2" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                                    <path id="Icon_awesome-arrow-alt-circle-up" data-name="Icon awesome-arrow-alt-circle-up" d="M.563,8.062a7.5,7.5,0,1,1,7.5,7.5A7.5,7.5,0,0,1,.563,8.062Zm8.831,3.508V8.062h2.144a.363.363,0,0,0,.257-.62L8.32,3.986a.36.36,0,0,0-.511,0L4.331,7.443a.363.363,0,0,0,.257.62H6.732v3.508a.364.364,0,0,0,.363.363H9.03A.364.364,0,0,0,9.393,11.571Z" transform="translate(15.563 15.563) rotate(180)" fill="#f55a5a"/>
                                  </svg>
                                  
                                <!-- <img src="assets/images/Icon-ionic-md-notifications-outline.svg" alt="" class="ml-2"> -->
                            </a>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div _ngcontent-mfm-c252="" class="card">
                        <div _ngcontent-mfm-c252="" class="card-header">
                            <h6 _ngcontent-mfm-c252="">Standard sample papers 2019</h6>
                        </div>
                        <div _ngcontent-mfm-c252="" class="card-body">
                            <a _ngcontent-mfm-c252="" class="link-download">
                                Download
                                <img _ngcontent-mfm-c252="" src="assets/images/Icon-ionic-md-notifications-outline.svg" alt="" class="ml-2">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div _ngcontent-mfm-c252="" class="card">
                        <div _ngcontent-mfm-c252="" class="card-header">
                            <h6 _ngcontent-mfm-c252="">Standard sample papers 2019</h6>
                        </div>
                        <div _ngcontent-mfm-c252="" class="card-body">
                            <a _ngcontent-mfm-c252="" class="link-download">
                                Download
                                <img _ngcontent-mfm-c252="" src="assets/images/Icon-ionic-md-notifications-outline.svg" alt="" class="ml-2">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div _ngcontent-mfm-c252="" class="card">
                        <div _ngcontent-mfm-c252="" class="card-header">
                            <h6 _ngcontent-mfm-c252="">Standard sample papers 2019</h6>
                        </div>
                        <div _ngcontent-mfm-c252="" class="card-body">
                            <a _ngcontent-mfm-c252="" class="link-download">
                                Download
                                <img _ngcontent-mfm-c252="" src="assets/images/Icon-ionic-md-notifications-outline.svg" alt="" class="ml-2">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div _ngcontent-mfm-c252="" class="card">
                        <div _ngcontent-mfm-c252="" class="card-header">
                            <h6 _ngcontent-mfm-c252="">Standard sample papers 2019</h6>
                        </div>
                        <div _ngcontent-mfm-c252="" class="card-body">
                            <a _ngcontent-mfm-c252="" class="link-download">
                                Download
                                <img _ngcontent-mfm-c252="" src="assets/images/Icon-ionic-md-notifications-outline.svg" alt="" class="ml-2">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div _ngcontent-mfm-c252="" class="card">
                        <div _ngcontent-mfm-c252="" class="card-header">
                            <h6 _ngcontent-mfm-c252="">Standard sample papers 2019</h6>
                        </div>
                        <div _ngcontent-mfm-c252="" class="card-body">
                            <a _ngcontent-mfm-c252="" class="link-download">
                                Download
                                <img _ngcontent-mfm-c252="" src="assets/images/Icon-ionic-md-notifications-outline.svg" alt="" class="ml-2">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div _ngcontent-mfm-c252="" class="card">
                        <div _ngcontent-mfm-c252="" class="card-header">
                            <h6 _ngcontent-mfm-c252="">Standard sample papers 2019</h6>
                        </div>
                        <div _ngcontent-mfm-c252="" class="card-body">
                            <a _ngcontent-mfm-c252="" class="link-download">
                                Download
                                <img _ngcontent-mfm-c252="" src="assets/images/Icon-ionic-md-notifications-outline.svg" alt="" class="ml-2">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div _ngcontent-mfm-c252="" class="card">
                        <div _ngcontent-mfm-c252="" class="card-header">
                            <h6 _ngcontent-mfm-c252="">Standard sample papers 2019</h6>
                        </div>
                        <div _ngcontent-mfm-c252="" class="card-body">
                            <a _ngcontent-mfm-c252="" class="link-download">
                                Download
                                <img _ngcontent-mfm-c252="" src="assets/images/Icon-ionic-md-notifications-outline.svg" alt="" class="ml-2">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div _ngcontent-mfm-c252="" class="card">
                        <div _ngcontent-mfm-c252="" class="card-header">
                            <h6 _ngcontent-mfm-c252="">Standard sample papers 2019</h6>
                        </div>
                        <div _ngcontent-mfm-c252="" class="card-body">
                            <a _ngcontent-mfm-c252="" class="link-download">
                                Download
                                <img _ngcontent-mfm-c252="" src="assets/images/Icon-ionic-md-notifications-outline.svg" alt="" class="ml-2">
                            </a>
                        </div>
                    </div>
                </div> -->
            </div>
            <!-- <div class="row">
                <div class="col-12">
                    <div class="load-more-pane text-center mb-5">
                        <button class="btn btn-outline-primary btn-view ">
                            Load more
                        </button>
                    </div>
                </div>
            </div> -->
        </div>
    </div>

</section>