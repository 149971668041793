<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLongTitle">
    {{sessionTitle}}
  </h5>
  <button type="button" class="close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<vg-player>
  <vg-scrub-bar>
    <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
    <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
  </vg-scrub-bar>

  <vg-controls>
    <vg-play-pause></vg-play-pause>
    <vg-playback-button></vg-playback-button>
    <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>
    <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>
    <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
    <vg-mute></vg-mute>
    <vg-volume></vg-volume>

    <vg-fullscreen></vg-fullscreen>
  </vg-controls>
  <video #media [vgMedia]="media" id="singleVideo" preload="auto">
    <source [src]="videoLectureSource"
      type="video/mp4" />
  </video>
</vg-player>