import { Component, OnInit } from "@angular/core";
import { AuthService } from 'src/app/services/auth.service';
import { LandingPageService } from "src/app/services/landing-page.service";
import { ModalService } from 'src/app/services/modal.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: "app-packages",
  templateUrl: "./packages.component.html",
  styleUrls: ["./packages.component.scss"],
})
export class PackagesComponent implements OnInit {
  public coursePlanList: any[];
  private nextButton: HTMLElement;
  constructor(
    private landingPageService: LandingPageService,
    private modalService: ModalService,
    private authService: AuthService,
    private utilityService: UtilityService
  ) {
    landingPageService.getCoursePlans().subscribe((plans) => {
      this.coursePlanList = plans.data;
    });
  }

  ngOnInit(): void { }
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    arrows: true,
    infinite: true,
    centerPadding: "10px",
    prevArrow:
      '<button class="slide-btn slide-btn-left border-0 rounded-circle slick-prev" id="prev"><svg xmlns="http://www.w3.org/2000/svg" width="32.192" height="56.31" viewBox="0 0 32.192 56.31"><path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M33.738,34.341,12.432,13.052a4.024,4.024,0,0,1,5.7-5.683L42.271,31.491a4.016,4.016,0,0,1,.117,5.549L18.149,61.33a4.024,4.024,0,0,1-5.7-5.683Z" transform="translate(43.443 62.503) rotate(180)" fill="#3d4057" opacity="0.1"/></svg></button>',
    nextArrow:
      '<button class="slide-btn slide-btn-right border-0 rounded-circle slick-prev" id="next"><svg xmlns="http://www.w3.org/2000/svg" width="32.192" height="56.31" viewBox="0 0 32.192 56.31"><path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M33.738,34.341,12.432,13.052a4.024,4.024,0,0,1,5.7-5.683L42.271,31.491a4.016,4.016,0,0,1,.117,5.549L18.149,61.33a4.024,4.024,0,0,1-5.7-5.683Z" transform="translate(-11.251 -6.194)" fill="#3d4057" opacity="0.1"/></svg></button>',
    responsive: [
      {
        breakpoint: 993,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "10px",
          // centerPadding: $('.slick').find('.single-slide-whatever-you-named-it').outerWidth() / 2;
          // centerPadding: document.getElementsByClassName("slick").find("slick-slide").outerWidth() / 2;
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // centerPadding: '60px',
          // centerMode: true,
        },
      },
    ],
  };

  ngAfterViewInit() {
  }

  public openLoginModal(userType: string): void {
    if (this.authService.isAuthenticated()) return; // if we have user object  in local storage don't open login modal
    this.utilityService.setUserType(userType);
    this.modalService.open(1, 1); // same component login for teacher and student
  }
}
