<form [formGroup]="searchFormGroup" (submit)="onSubmit()">
  <div (click)="$event.stopPropagation()" class="advance-search" *ngIf="showFilters">
    <div class="select-box">
      <select
        class="form-control"
        required="{{
          callerLandingPageTeachers ? 
          !(
                SearchBoxControls.subject.value ||
                SearchBoxControls.qualification.value ||
                SearchBoxControls.experience.value
              )
              ? true
              : false          
              :
          !callerTuition
            ? !(
                SearchBoxControls.subject.value ||
                SearchBoxControls.month.value ||
                SearchBoxControls.keyword.value
              )
              ? true
              : false
            : !(
                SearchBoxControls.subject.value ||
                SearchBoxControls.month.value ||
                SearchBoxControls.keyword.value ||
                SearchBoxControls.time.value !== null
              )
            ? true
            : false
        }}"
        formControlName="grade"
        [ngClass]="{ 'form-control-one-on-one': callerTuition }"
      >
        <option [ngValue]="null" selected>Search by Grade</option>
        <option *ngFor="let grade of gradeList" [ngValue]="grade.id">{{
          grade.name
        }}</option>
      </select>
    </div>
    <div class="select-box">
      <select
        class="form-control"
        required="{{
          callerLandingPageTeachers ? 
          !(
                SearchBoxControls.grade.value ||
                SearchBoxControls.experience.value ||
                SearchBoxControls.qualification.value
              )
              ? true
              : false
              :
          !callerTuition
            ? !(
                SearchBoxControls.grade.value ||
                SearchBoxControls.month.value ||
                SearchBoxControls.keyword.value
              )
              ? true
              : false
            : !(
                SearchBoxControls.grade.value ||
                SearchBoxControls.month.value ||
                SearchBoxControls.keyword.value ||
                SearchBoxControls.time.value !== null
              )
            ? true
            : false
        }}"
        formControlName="subject"
        [ngClass]="{ 'form-control-one-on-one': callerTuition }"
      >
        <option [ngValue]="null" selected>Search by Subject</option>
        <option *ngFor="let subject of subjectList" [ngValue]="subject.id">{{
          subject.name
        }}</option>
      </select>
    </div>
    <div class="select-box" *ngIf="!callerLandingPageTeachers">
      <select
        class="form-control"
        required="{{
          !callerTuition
            ? !(
                SearchBoxControls.grade.value ||
                SearchBoxControls.subject.value ||
                SearchBoxControls.keyword.value
              )
              ? true
              : false
            : !(
                SearchBoxControls.grade.value ||
                SearchBoxControls.subject.value ||
                SearchBoxControls.keyword.value ||
                SearchBoxControls.time.value !== null
              )
            ? true
            : false
        }}"
        formControlName="month"
        [ngClass]="{ 'form-control-one-on-one': callerTuition }"
      >
        <option [ngValue]="null" selected>Search by Month</option>
        <option *ngFor="let month of monthsList" [ngValue]="month.number">{{
          month.name
        }}</option>
      </select>
    </div>
    <div class="select-box" *ngIf="callerTuition">
      <select
        class="form-control"
        [ngClass]="{ 'form-control-one-on-one': callerTuition }"
        formControlName="time"
        id="exampleFormControlSelect1"
        required="{{
          !(
            SearchBoxControls.subject.value ||
            SearchBoxControls.month.value ||
            SearchBoxControls.keyword.value ||
            SearchBoxControls.grade.value
          )
            ? true
            : false
        }}"
      >
        <option [ngValue]="null">Search by Time</option>
        <option *ngFor="let time of timeList" [ngValue]="time.time">{{
          time.time_bracket
        }}</option>
      </select>
    </div>
    <div class="search-bar" *ngIf="!callerLandingPageTeachers">
      <input
        type="text"
        class="form-control"
        placeholder="Search by Keyword"
        [ngClass]="{ 'form-control-one-on-one': callerTuition }"
        formControlName="keyword"
        required="{{
          !callerTuition
            ? !(
                SearchBoxControls.subject.value ||
                SearchBoxControls.month.value ||
                SearchBoxControls.grade.value
              )
              ? true
              : false
            : !(
                SearchBoxControls.subject.value ||
                SearchBoxControls.month.value ||
                SearchBoxControls.grade.value ||
                SearchBoxControls.time.value !== null
              )
            ? true
            : false
        }}"
      />
    </div>
    <div class="select-box" *ngIf="callerLandingPageTeachers">
      <select
        class="form-control"
        required="{{
          !(
                SearchBoxControls.grade.value ||
                SearchBoxControls.subject.value ||
                SearchBoxControls.qualification.value
              )
              ? true
              : false
        }}"
        formControlName="experience"
      >
        <option [ngValue]="null" selected>Search by Experience</option>
        <option *ngFor="let experience of experienceList" [ngValue]="experience.name">{{
          experience.name
        }}</option>
      </select>
    </div>
    <div class="select-box" *ngIf="callerLandingPageTeachers">
      <select
        class="form-control"
        required="{{
            !(
                  SearchBoxControls.grade.value ||
                  SearchBoxControls.subject.value ||
                  SearchBoxControls.experience.value
                )
                ? true
                : false
        }}"
        formControlName="qualification"
        [ngClass]="{ 'form-control-one-on-one': callerTuition }"
      >
        <option [ngValue]="null" selected>Search by Qualification</option>
        <option *ngFor="let qualification of qualificationList" [ngValue]="qualification.id">{{
          qualification.title
        }}</option>
      </select>
    </div>
    <button
      type="button"
      class="btn-icon mr-2"
      title="Reset Filters"
      (click)="resetSearchFilters($event)"
    >
      <img src="../../../../assets/images/icons8-reset-24.png" />
    </button>
    <button
      type="submit"
      [disabled]="searchFormGroup.invalid"
      class="btn btn-sm-gradient"
      data-toggle="dropdown"
      [ngClass]="{ 'btn-one-on-one': callerTuition }"
    >
      Search
    </button>
  </div>
</form>
