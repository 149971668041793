import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lms-achievements',
  templateUrl: './lms-achievements.component.html',
  styleUrls: ['./lms-achievements.component.scss']
})
export class LmsAchievementsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
