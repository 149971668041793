<div class="modal-header pb-0">
  <button type="button" class="close" (click)="closeModal()">
    <span aria-hidden="true">
      <img src="assets/images/close.png" alt="" />
    </span>
  </button>
</div>
<div class="modal-body pt-1">
  <div class="student-login-box register">
    <div class="student-login pb-0 mb-0">
      <h3 class="">Register</h3>
      <form [formGroup]="registrationFormGroup" autocomplete="new-password" (submit)="onSubmit()">
        <div class="login-box mb-4">
          <div class="form-label-group mb-0">
            <input type="text" placeholder="Name" formControlName="name" class="form-control" id="fname"
              aria-describedby="fname" />
            <label for="fname">Full Name</label>
            <div>
              <span class="text-danger" *ngIf="
                  registerFormControl.name.touched &&
                  registerFormControl.name.errors?.required
                ">
                Please provide name.
              </span>
              <span class="text-danger" *ngIf="
              registerFormControl.name.touched &&
              (registerFormControl.name.errors?.minlength ||
              registerFormControl.name.errors?.maxlength) &&
          !registerFormControl.name.errors?.pattern
          ">
                Name must be between 2 to 50 letters.
              </span>
              <span class="text-danger" *ngIf="
          registerFormControl.name.touched &&
          registerFormControl.name.errors?.pattern
        ">
                Please provide a valid name.
              </span>
            </div>
          </div>
          <div class="form-label-group mb-0">
            <input type="email" placeholder="Email" formControlName="email" class="form-control"
              (keypress)="checkSpaceInput($event)" autocomplete="off" />
            <label for="register-email">Email</label>
            <div>
              <span class="text-danger" *ngIf="
                  registerFormControl.email.touched &&
                  registerFormControl.email.errors?.required
                ">
                Please provide email.
              </span>
              <span class="text-danger" *ngIf="
                  registerFormControl.email.touched &&
                  (registerFormControl.email.errors?.email ||
                    registerFormControl.email.errors?.pattern)
                ">
                Please provide a valid email.
              </span>
              <span class="text-danger" *ngIf="
                  registerFormControl.email.touched &&
                  registerFormControl.email.errors?.alreadyTaken
                ">
                {{ registerFormControl.email.errors.alreadyTaken }}
              </span>
            </div>
          </div>
          <div class="form-label-group mb-0">
            <input type="text" maxlength="17" placeholder="Mobile Number" formControlName="phone" class="form-control"
              id="mobile" (focus)="addPrefix()" (keypress)="checkPhoneInput($event)" autocomplete="off" />
            <label for="mobile">Mobile Number</label>
            <div>
              <span class="text-danger" *ngIf="
                  registerFormControl.phone.touched &&
                  registerFormControl.phone.errors?.required
                ">
                Please provide mobile number.
              </span>
              <span class="text-danger" *ngIf="
                  registerFormControl.phone.touched &&
                  registerFormControl.phone.errors?.pattern
                ">
                Please provide a valid mobile no (e.g. +923212496812)
              </span>
              <span class="text-danger" *ngIf="
                  registerFormControl.phone.touched &&
                  registerFormControl.phone.errors?.alreadyTaken
                ">
                {{ registerFormControl.phone.errors.alreadyTaken }}
              </span>
            </div>
          </div>
          <div class="form-label-group password-box mb-0">
            <input [type]="showPassword ? 'text' : 'password'" placeholder="Create Password" formControlName="password"
              class="form-control" (keypress)="checkSpaceInput($event)" autocomplete="off" />
            <label for="register-cpwd">Create Password</label>
            <a (click)="showPassword = !showPassword" class="eye-icon">
              <img src="../../../../assets/images/hide.svg" alt="eye" [hidden]="showPassword" />
              <img src="../../../../assets/images/show.svg" alt="eye" [hidden]="!showPassword" />
            </a>
            <div class="validation-text">
              <span class="text-danger" *ngIf="
                registerFormControl.password.touched &&
                registerFormControl.password.errors?.required
              ">
                Please provide password.
              </span>
              <span class="text-danger" *ngIf="
                registerFormControl.password.touched &&
                (registerFormControl.password.errors?.minlength ||
                  registerFormControl.password.errors?.maxLength ||
                  registerFormControl.password.errors?.pattern)
              ">
                Password must contain 8-20 characters including letters, numbers &
                special characters.
              </span>
            </div>
          </div>
          <div class="form-label-group password-box mb-0">
            <input [type]="showConfirmPassword ? 'text' : 'password'" placeholder="Confirm Password"
              formControlName="confirmPassword" class="form-control" id="cnpwd" (keypress)="checkSpaceInput($event)" />
            <label for="cnpwd">Confirm Password</label>
            <a (click)="showConfirmPassword = !showConfirmPassword" class="eye-icon">
              <img src="../../../../assets/images/hide.svg" alt="eye" [hidden]="showConfirmPassword" />
              <img src="../../../../assets/images/show.svg" alt="eye" [hidden]="!showConfirmPassword" />
            </a>
            <div class="validation-text">
              <span class="text-danger" *ngIf="
                registerFormControl.confirmPassword.touched &&
                registerFormControl.confirmPassword.errors?.required
              ">
                Please provide confirm password.
              </span>
              <span class="text-danger" *ngIf="
                registerFormControl.confirmPassword.touched &&
                registrationFormGroup.errors?.notSame &&
                registerFormControl.confirmPassword.value
              ">
                The specified passwords do not match.
              </span>
            </div>
          </div>
        </div>
        <div class="pl-4 pr-2 mb-4 cont-text">
          <p>
            By continuing, You agree with TALEEMLY Terms of Use & Privacy Policy.
          </p>
        </div>
        <button type="submit" class="btn btn-gradient btn-block mb-4">
          Register
        </button>
        <div class="text-center d-flex justify-content-center align-items-center">
          <h5 class="mr-3 mb-0">Already have an account?</h5>
          <a (click)="openLoginModal()" class="text-success reg-text">Login</a>
        </div>
      </form>
    </div>
  </div>
</div>
