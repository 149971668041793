import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModalService } from "src/app/services/modal.service";
import { StudentService } from "src/app/services/student.service";
import * as moment from "moment";
import { ConfirmationDialogService } from "src/app/services/confirmation-dialog.service";
import { CartService } from "src/app/services/cart.service";
import { UtilityService } from 'src/app/services/utility.service';
@Component({
  selector: "app-course-detail",
  templateUrl: "./course-detail.component.html",
  styleUrls: ["./course-detail.component.scss"],
})
export class CourseDetailComponent implements OnInit {
  private courseId: any;
  public course: any;
  public isCourse: boolean = true;
  public callerDashboard: boolean;
  public classId: any = 0;
  public tabId: any = 0;
  public levelTwoPills: any[] = [
    { index: 1, id: "classDetails-tab-", href: "#classDetails-", name: "Class Details" },
    { index: 2, id: "slybus-tab-", href: "#syllabus-", name: "Syllabus" },
    { index: 3, id: "sessions-tab-", href: "#sessions-", name: "Sessions" }
  ];
  constructor(
    private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private studentService: StudentService,
    private confirmDialogService: ConfirmationDialogService,
    private cartService: CartService,
    private modalService: ModalService
  ) {
    this.callerDashboard = activatedRoute.snapshot.data.fromDashboard;
    this.activatedRoute.params.subscribe((params) => {
      this.courseId = +params["id"];
      this.studentService
        .getCourseDetail(this.courseId, this.callerDashboard)
        .subscribe((response) => {
          this.course = response.data;
        });
    });
  }

  ngOnInit(): void {
    //listen to remove cart item event
    this.cartService.addRemoveCartIemObservable.subscribe((obj: any) => {
      // {course_id:1, added_to_cart:true}
      if (obj.course_id === this.courseId) {
        console.log("Observed :", obj);
        this.course.added_to_cart = obj.added_to_cart;
      }
    });
  }

  public async addToCart(course) {
    if (!this.callerDashboard) {
      this.utilityService.setUserType("student");
      this.modalService.open(1, 1);
      return;
    }
    if (!course.available_seats) return;
    if (!moment(course.start_date).isBefore(moment())) {
      if (course.is_free) {
        this.studentService.enrollFreeCourse(course);
      } else {
        const modalRef = this.modalService.open(10, 2);
        modalRef.componentInstance.course = course;
        modalRef.componentInstance.step = 1; //1 for add to cart step
      }
    } else {
      const result = await this.confirmDialogService.getConfirmation(
        "Note",
        "This course has already started and you may have missed some of its sessions. Do you still want to enroll in this course ?"
      );
      if (!result) return;
      if (course.is_free) {
        this.studentService.enrollFreeCourse(course);
      } else {
        const modalRef = this.modalService.open(10, 2);
        modalRef.componentInstance.course = course;
        modalRef.componentInstance.step = 1; //1 for add to cart step
      }
    }
  }

  public jsonDecode(item) {
    return JSON.parse(item);
  }

  //active the subject tab upon notification redirect
  public getClassPillActiveStatus(classs: any, isFirstIndex: boolean) {
    if (this.classId) {
      return this.classId === classs.id || false;
    } else {
      console.log(isFirstIndex);
      return isFirstIndex;
    }
  }
  //active the subject details tabs upon notification redirect
  public getLevelTwoPillStatus(
    tabId: number,
    indexOfPill: number,
    pillClassId: number
  ) {
    if (pillClassId === this.classId) {
      // if (indexOfPill === 2 && (tabId === 8 || tabId === 7)) {
      //   return true;
      // } else if (indexOfPill === 3 && (tabId === 5 || tabId === 3 || tabId === 1)) {
      //   return true;
      // } else if (indexOfPill === 4 && (tabId === 4 || tabId === 2 || tabId === 6)) {
      //   return true;
      // } else if (indexOfPill === 0 || indexOfPill === 1) {
      //   return false;
      // }
      // console.log("indexofPill :" +indexOfPill, "tadId:"+ tabId);
      if (indexOfPill === tabId) {
        return true;
      } else {
        return false;
      }
    } else {
      return indexOfPill === 1 ? true : false;
    }
  }
  public updateQueryParams(classsId: number, tabNumber: number) {
    this.classId = classsId;
    this.tabId = tabNumber;
  }
}
