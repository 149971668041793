import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { StudentService } from 'src/app/services/student.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { FormControl, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/services/utility.service';
import { TeacherService } from 'src/app/services/teacher.service';

@Component({
  selector: 'app-homework-feedback',
  templateUrl: './homework-feedback.component.html',
  styleUrls: ['./homework-feedback.component.scss']
})
export class HomeworkFeedbackComponent implements OnInit {
  @Input() submission: any;
  @Input() totalMarks: any;
  @Output() onHomeworkFeedbackSubmit: EventEmitter<object> = new EventEmitter();
  public homeworkFeedbackFile: string;
  public homeworkFeedbackFilePath: string;
  public randomFormId: string;
  public formData: FormData = new FormData();
  public homeworkMarks: FormControl = new FormControl(null, [Validators.required, Validators.pattern(this.utilityService.numbersleadingTrailingSpacesV2), this.maxMarksValidator.bind(this)]);
  public homeworkFeedbackFileInput: FormControl = new FormControl(null, [Validators.required]);
  public fileIcon: any;
  @ViewChild('file', { static: false }) feedbackFile: any;

  constructor(
    private activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private studentService: StudentService,
    private confirmDialogService: ConfirmationDialogService,
    private utilityService: UtilityService,
    private teacherService: TeacherService) { }

  ngOnInit(): void {
    //random form id
    this.randomFormId = '_' + Math.random().toString(36).substr(2, 9);
  }

  public async closeModal() {
    if (!(this.homeworkMarks.value || this.homeworkFeedbackFileInput.value)) return this.activeModal.close();
    const result = await this.confirmDialogService.getConfirmation('Discard feedback', 'Changes you made so far will not be saved. Are you sure you exit ?');
    if (result) {//if user select yes on confirmation
      this.activeModal.close();
    }
  }

  public onHomeworkSelected(event) {
    let file = event.target.files[0];
    this.homeworkFeedbackFile = event.target.files[0].name;

    //get file icon
    this.getFileIcon();

    if (!file) return;
    let fileType = file.type.toLowerCase();
    //check file type
    if (!this.utilityService.checkHomeworkFile(fileType)) {
      this.onFileRemove();
      this.toastr.error('File type not supported.', 'Error');
      return;
    }

    // const formData: FormData = new FormData();
    this.formData.append('upload_file', file, file.name);
    this.formData.append('entity_id', this.submission.id);
    this.formData.append('upload_type', 'INSTRUCTOR_ASSIGNMENT_FEEDBACK');
    this.formData.append('rand_form_id', this.randomFormId);
    //send api call to upload sesion notes
    this.teacherService.uploadHomeworkFeedbackFile(this.formData).subscribe(response => {
      this.homeworkFeedbackFilePath = response.data.file_path;
    },error=>{
      this.onFileRemove();
      this.toastr.error('File type not supported.', 'Error');
      return;
    });
  }

  //if user delete selected file
  public async onFileRemove() {
    //get confirmation before deleting selected file
    // const result = await this.confirmDialogService.getConfirmation('Delete Homework', 'Are you sure to delete selected file?');
    // if (result) {
      this.homeworkFeedbackFile = null;
      this.homeworkFeedbackFilePath = null;
      this.feedbackFile.nativeElement.value = '';
      this.homeworkFeedbackFileInput.reset();
      this.fileIcon = null;
    // } else {
    //   return;
    // }
  }

  //on feedback submit
  public onSave() {
    this.homeworkMarks.markAsTouched();
    this.homeworkFeedbackFileInput.markAsTouched();
    if (this.homeworkMarks.invalid || this.homeworkFeedbackFileInput.invalid) return;
    const data = {
      "submission_id": this.submission.id,
      "marks": this.homeworkMarks.value
    }
    //send api call to upload sesion notes
    this.teacherService.submitHomeworkFeedback(data).subscribe(response => {
      //updating notes link in session listing
      this.toastr.success("Feedback submitted successfully.", "Success");
      this.onHomeworkFeedbackSubmit.next({ fileUrl: this.homeworkFeedbackFilePath, marks: this.homeworkMarks.value });
      this.activeModal.close();
    });
  }

  //check marks input
  public checkMarksInput(event) {
    // return this.utilityService.checkMarksInput(event);
    return (event.charCode >= 48 && event.charCode <= 57) || event.key === '.';
  }

  //marks validator
  public maxMarksValidator(control: FormControl): { [key: string]: boolean } | null { // here we have the 'passwords' group
    // console.log(control)
    if (control.value > this.totalMarks) {
      return { maxMarks: true };
    } else {
      return null;
    }
  }


  public getFileIcon() {
    switch (this.homeworkFeedbackFile.split('.').pop()) {
      case 'docx':
        this.fileIcon = 'file-word';
        break;
      case 'doc':
        this.fileIcon = 'file-word';
        break;
      case 'pdf':
        this.fileIcon = 'file-pdf';
        break;
      case 'ppt':
        this.fileIcon = 'file-powerpoint';
        break;
      case 'pptx':
        this.fileIcon = 'file-powerpoint';
        break;
      case 'xls':
        this.fileIcon = 'file-excel';
        break;
      case 'xlsx':
        this.fileIcon = 'file-excel';
        break;
      default:
        break;
    }
  }
}
