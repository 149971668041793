<section class="choose-lms-section">
  <div class="container">
    <div class="choose-lms">
      <div class="row">
        <div class="col-md-6 col-sm-12 img-box">
          <div class="video-box">
            <div class="video-pane">
              <div class="video-pane-inner">
                <img src="assets/images/study-group.jpg" class="img-fluid" alt="" >
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5 col-sm-12 ml-auto list-container">
          <div class="content-box">
            <div class="content">
              <h3 class="text-primary heading-3">How can TALEEMLY</h3>
              <h2 class="heading-2">be your Best Friend?</h2>
              <!-- <div class="media my-4">
                <img src="assets/images/vivid infographic.png">
              </div> -->
              <p class="sub-para mb-4">
                Join our online classes for students and reap the following benefits:
              </p>
              <ul class="offering">
                <li><img src="assets/images/check-blue.svg" class="mr-4" alt=""><span>Expertly Drafted online classes</span></li>
                <!-- <li><img src="assets/images/check.png" class="mr-4" alt=""> <span>Experienced Teachers</span></li> -->
                <!-- <li><img src="assets/images/check.png" class="mr-4" alt=""> <span>Free Access to Study Material </span></li> -->
                <li><img src="assets/images/check-blue.svg" class="mr-4" alt=""> <span>Experienced Teachers</span></li>
                <li><img src="assets/images/check-blue.svg" class="mr-4" alt=""> <span>Free Access to Study Material</span></li>
                <li><img src="assets/images/check-blue.svg" class="mr-4" alt=""> <span>Free Demo Sessions</span></li>
                <li><img src="assets/images/check-blue.svg" class="mr-4" alt=""> <span>Student Assessment Metrics</span></li>
                <li class="mb-0"><img src="assets/images/check-blue.svg" class="mr-4" alt=""> <span>100+ Online Courses</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
