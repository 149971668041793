import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TeacherService } from 'src/app/services/teacher.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { HttpParams } from '@angular/common/http';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-upload-notes',
  templateUrl: './upload-notes.component.html',
  styleUrls: ['./upload-notes.component.scss']
})
export class UploadNotesComponent implements OnInit {
  @Input() notesLink: string;
  @Input() notesFileName: string;
  @Input() sessionId: string;
  @Output() updateNotesLink: EventEmitter<object> = new EventEmitter();
  public sessionNotesAdded: boolean;
  public sessionFileInput: FormControl = new FormControl(null, [Validators.required]);
  @ViewChild('file', { static: false }) feedbackFile: any;
  public fileIcon: any;
  public formData: FormData = new FormData();
  constructor(private activeModal: NgbActiveModal, private toastr: ToastrService, private teacherService: TeacherService, private confirmDialogService: ConfirmationDialogService) { }

  ngOnInit(): void {
    this.getFileIcon();
    if (this.notesFileName && this.notesLink && !this.sessionFileInput.touched) {
      this.sessionNotesAdded = true;
      this.sessionFileInput.setValidators(null);
      this.sessionFileInput.updateValueAndValidity();
    }
  }

  public async closeModal() {
    if (this.sessionFileInput.pristine) {
      this.activeModal.close(); return;
    } else {
      const result = await this.confirmDialogService.getConfirmation('Exit', 'Are you sure you want to exit ?');
      if (result) {//if user select yes on confirmation
        this.activeModal.close();
      }
    }
  }

  public onNotesSelected(event) {
    let file = event.target.files[0];
    this.notesFileName = event.target.files[0].name;
    if (!file) return;
    let fileType = file.type.toLowerCase();
    //check file type
    if (!(
      fileType === "application/pdf" ||
      fileType === "application/msword" ||
      fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      fileType === "application/kswps"
    )) {
      this.toastr.error('File type not supported.', 'Error');
      return;
    }

    //get file icon
    this.getFileIcon();
    //   "upload_file": "Upload file of type ppt, pptx, pdf, doc, docx, Max Size: 8 MB",
    // "entity_id": "11231",
    // "upload_type": "SESSION_NOTE,SESSION_FEEDBACK"

    this.formData = new FormData();
    this.formData.append('upload_file', file, file.name);
    this.formData.append('entity_id', this.sessionId);
    this.formData.append('upload_type', 'SESSION_NOTE');
  }

  //if user delete selected file
  public async onFileRemove() {
    //get confirmation before deleting selected file
    const result = await this.confirmDialogService.getConfirmation('Delete Notes', 'Are you sure to delete selected file?');
    if (result) {
      // if (this.notesLink) {
        //api call to delete notes if notes are already attached
        // const params = new HttpParams().set('file_path', this.notesLink).set('upload_type', 'SESSION_NOTE');
        // this.teacherService.deleteSessionNotes(params).subscribe(response => {
          //updating notes link in session listing
          // this.updateNotesLink.next({ link: null, fileName: null });
          this.notesLink = null;
        // });
      // }
      this.sessionFileInput.reset();
      this.sessionFileInput.setValidators(Validators.required);
      this.sessionFileInput.updateValueAndValidity();
      this.notesFileName = null;
      this.formData = new FormData();
    } else {
      return;
    }
  }

  public onSave() {
    this.sessionFileInput.markAsTouched();
    if (!this.sessionFileInput.valid) return;
    if (!this.formData.has('entity_id') && this.notesLink && this.notesFileName) {
      this.activeModal.close();
      return;
    }
    this.teacherService.uploadSessionNotes(this.formData).subscribe(response => {
      this.toastr.success("Notes Uploaded Successfully.", "Success");
      this.notesLink = response.data.file_path;
      //updating notes link in session listing
      this.updateNotesLink.next({ link: this.notesLink, fileName: this.notesFileName });
      this.activeModal.close();
    })
  }

  public getFileIcon() {
    if (!this.notesFileName) return;
    switch (this.notesFileName.split('.').pop()) {
      case 'docx':
        this.fileIcon = 'file-word';
        break;
      case 'doc':
        this.fileIcon = 'file-word';
        break;
      case 'pdf':
        this.fileIcon = 'file-pdf';
        break;
      case 'ppt':
        this.fileIcon = 'file-powerpoint';
        break;
      case 'pptx':
        this.fileIcon = 'file-powerpoint';
        break;
      case 'xls':
        this.fileIcon = 'file-excel';
        break;
      case 'xlsx':
        this.fileIcon = 'file-excel';
        break;
      default:
        break;
    }
  }
}
