import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-sylabus-details',
  templateUrl: './sylabus-details.component.html',
  styleUrls: ['./sylabus-details.component.scss']
})
export class SylabusDetailsComponent implements OnInit {
  @Input() syllabusDetails: any;
  public syllabusDescription: string = ' ';
  public syllabusTitle: string = ' ';
  public syllabusLink: string = ' ';
  public classTitle: string = ' ';
  public fileName: string;
  constructor() {

  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.syllabusTitle = this.syllabusDetails[0].title;
    this.syllabusLink = this.syllabusDetails[0].file;
    this.syllabusDescription = this.syllabusDetails[1];
    this.classTitle = this.syllabusDetails[2];
    this.fileName = this.syllabusDetails[0].file_name;

  }

}
