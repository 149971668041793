import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule, Meta } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { ServiceWorkerModule } from '@angular/service-worker';
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
// import { NgxUsefulSwiperModule } from "ngx-useful-swiper";
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AboutUsComponent } from "./components/main-dashboard/about-us/about-us.component";
import { ContactUsComponent } from './components/main-dashboard/contact-us/contact-us.component';
import { FaqsComponent } from "./components/main-dashboard/faqs/faqs.component";
import { LandingPageStudyMaterialComponent } from "./components/main-dashboard/landing-page-study-material/landing-page-study-material.component";
import { BecomeTeacherComponent } from "./components/main-dashboard/landing-page/become-teacher/become-teacher.component";
import { ChooseLmsComponent } from "./components/main-dashboard/landing-page/choose-lms/choose-lms.component";
import { CoursesMenuComponent } from './components/main-dashboard/landing-page/courses-menu/courses-menu.component';
import { EnrollStudentFeedbackComponent } from "./components/main-dashboard/landing-page/enroll-student-feedback/enroll-student-feedback.component";
import { HeaderComponent } from "./components/main-dashboard/landing-page/header/header.component";
import { HeroSectionComponent } from "./components/main-dashboard/landing-page/hero-section/hero-section.component";
import { LandingPageLayoutComponent } from "./components/main-dashboard/landing-page/landing-page-layout/landing-page-layout.component";
import { LandingComponent } from "./components/main-dashboard/landing-page/landing/landing.component";
import { LmsAchievementsComponent } from "./components/main-dashboard/landing-page/lms-achievements/lms-achievements.component";
import { MockTestsComponent } from "./components/main-dashboard/landing-page/mock-tests/mock-tests.component";
import { OurResultsComponent } from "./components/main-dashboard/landing-page/our-results/our-results.component";
import { PackagesComponent } from "./components/main-dashboard/landing-page/packages/packages.component";
import { PartnersComponent } from "./components/main-dashboard/landing-page/partners/partners.component";
import { RecommendedCourseComponent } from "./components/main-dashboard/landing-page/recommended-course/recommended-course.component";
import { TopRatedTeacherComponent } from "./components/main-dashboard/landing-page/top-rated-teacher/top-rated-teacher.component";
import { TuitionMenuComponent } from './components/main-dashboard/landing-page/tuition-menu/tuition-menu.component';
import { PrivacyPolicyComponent } from './components/main-dashboard/privacy-policy/privacy-policy.component';
import { TeacherLandingPageComponent } from "./components/main-dashboard/teacher-landing-page/teacher-landing-page.component";
import { TermsAndConditionsComponent } from './components/main-dashboard/terms-and-conditions/terms-and-conditions.component';
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { ErrorIntercept } from "./interceptors/error.interceptor";
import { MyHttpInterceptor } from "./interceptors/http.interceptor";
import { AuthModule } from "./modules/auth/auth.module";
import { SharedModule } from "./modules/shared/shared/shared.module";
import { SocialMediaModule } from "./modules/social-media/social-media.module";
import { ApiService } from "./services/api.service";
import { AuthGuardService } from "./services/auth-guard.service";
import { MathService } from './services/math.service';
import { ModalService } from "./services/modal.service";
import { NotificationService } from "./services/notification.service";
import { DataDeletionComponent } from './components/main-dashboard/data-deletion/data-deletion.component';
// start of added for the social login//
import { SocialAuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { config } from "../config";
//End of added for the social login//
@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    HeaderComponent,
    HeroSectionComponent,
    PackagesComponent,
    RecommendedCourseComponent,
    ChooseLmsComponent,
    TopRatedTeacherComponent,
    BecomeTeacherComponent,
    LmsAchievementsComponent,
    OurResultsComponent,
    EnrollStudentFeedbackComponent,
    MockTestsComponent,
    PartnersComponent,
    TeacherLandingPageComponent,
    LandingPageStudyMaterialComponent,
    LandingPageLayoutComponent,
    AboutUsComponent,
    FaqsComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    CoursesMenuComponent,
    TuitionMenuComponent,
    ContactUsComponent,
    DataDeletionComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    RouterModule,
    AuthModule,
    CKEditorModule,
    SocialMediaModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-right",
      enableHtml: true,
    }),
    BrowserAnimationsModule,
    NgbModalModule,
    AppRoutingModule,
    // NgxUsefulSwiperModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    ApiService,
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercept,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    ModalService,
    NotificationService,
    Meta,
    MathService,
    // start of added for the social login//
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(config.google_app_id)
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(config.facebook_app_id)
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
    // End of added for the social login//
  ],

  bootstrap: [AppComponent],
})
export class AppModule { }
