<section class="become-teacher-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 joining-detail">
        <div class="title-box">
          <h3 class="heading-3">Join TALEEMLY <span class="text-primary">as a Teacher</span></h3>
          <p>If you adore the art of teaching and have what it takes to be a part of TALEEMLY, then start teaching students all over the world today! </p>
        </div>
        <div class="offering">
          <div class="icon-box">
            <div class="img-container">
              <img src="assets/icons/Group 972.svg" alt="">
            </div>
            <p>Teach Online Today</p>
          </div>
          <div class="icon-box">
            <div class="img-container">
              <img src="assets/icons/Group 973.svg" alt="">
            </div>
            <p>Connect with Students</p>
          </div>
          <div class="icon-box">
            <div class="img-container">
              <img src="assets/icons/Group 974.svg" alt="">
            </div>
            <p>Teach with no Time Constraints</p>
          </div>
          <div class="icon-box">
            <div class="img-container">
              <img src="assets/icons/Group 975.svg" alt="">
            </div>
            <p>Be a Part of TALEEMLY</p>
          </div>
        </div>
        <div class="apply-btn mt-4">
          <a (click)="openRegistrationModal('instructor')" class="btn btn-primary">Apply Now</a>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 img-box">
        <div class="img-container vivid-img laptop-img-box">
          <img src="assets/images/Teacher Banner.png" class="img-fluid laptop-img" alt="">
        </div>
      </div>
    </div>
  </div>
</section>
