import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { StudentService } from 'src/app/services/student.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-upload-homework',
  templateUrl: './upload-homework.component.html',
  styleUrls: ['./upload-homework.component.scss']
})
export class UploadHomeworkComponent implements OnInit {
  public allowedFileTypes: string[] = ["application/pdf", "application/msword", "application/kswps", "application/kset", "application/vnd.ms-powerpoint", "application/msword", "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
  @Input() homeworkId: any;
  @Output() onHomeworkSubmit: EventEmitter<boolean> = new EventEmitter();
  public homeworkFileName: string;
  public formData: FormData = new FormData();
  public fileIcon: any;
  public homeworkFileFormGroup: FormGroup = new FormGroup({
    'homeworkFile': new FormControl(null, Validators.required)
  });
  @ViewChild('file', { static: false }) homeworkFile: any;
  constructor(
    private activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private studentService: StudentService,
    private confirmDialogService: ConfirmationDialogService,
    private utilityService: UtilityService) { }

  ngOnInit(): void {
    // let fileNameArray = this.notesLink.split('/');
    // //extracting filename from notes link
    // this.fileName = fileNameArray[fileNameArray.length - 1];
  }

  public async closeModal() {
    // const result = await this.confirmDialogService.getConfirmation('Exit', 'Are you sure you want to exit ?');
    // if (result) {//if user select yes on confirmation
    this.activeModal.close();
    // }
  }

  public onHomeworkSelected(event) {
    let file = event.target.files[0];
    console.log(file);
    if (!file) return;
    let fileType = file.type.toLowerCase();
    //check file type
    if (!this.utilityService.checkHomeworkFile(fileType)) {
      this.homeworkFileName = null;
      this.homeworkFile.nativeElement.value = '';
      this.homeworkFileFormGroup.controls.homeworkFile.reset();
      this.toastr.error('File type not supported.', 'Error');
      return;
    }
    this.homeworkFileName = event.target.files[0].name;
    //show appropriate file icon
    this.getFileIcon();
    //   "upload_file": "Upload file of type ppt, pptx, pdf, doc, docx, Max Size: 8 MB",
    // "entity_id": "11231",
    // "upload_type": "SESSION_NOTE,SESSION_FEEDBACK"

    // const formData: FormData = new FormData();
    this.formData.append('solution', file, file.name);
    this.formData.append('assignment_id', this.homeworkId);
  }

  //if user delete selected file
  public async onFileRemove() {
    //get confirmation before deleting selected file
    // const result = await this.confirmDialogService.getConfirmation('Delete Homework', 'Are you sure to delete selected file?');
    // if (result) {
    this.homeworkFileName = null;
    this.fileIcon = null;
    this.homeworkFile.nativeElement.value = '';
    this.homeworkFileFormGroup.controls.homeworkFile.reset();
    // } else {
    //   return;
    // }
  }

  public onSave() {
    this.homeworkFileFormGroup.markAllAsTouched();
    if (this.homeworkFileFormGroup.invalid) return;
    //send api call to upload sesion notes
    this.studentService.submitHomework(this.formData).subscribe(response => {
      this.toastr.success("Homework Submitted Successfully.", "Success");
      // this.notesLink = response.data.file_path;
      //updating notes link in session listing
      this.onHomeworkSubmit.next(true);
      this.activeModal.close();
    },
      error => {
        //homework due date passsed
        this.toastr.error(error.error.message, 'Error');
      });
  }

  public getFileIcon() {
    switch (this.homeworkFileName.split('.').pop()) {
      case 'docx':
        this.fileIcon = 'file-word';
        break;
      case 'doc':
        this.fileIcon = 'file-word';
        break;
      case 'pdf':
        this.fileIcon = 'file-pdf';
        break;
      case 'ppt':
        this.fileIcon = 'file-powerpoint';
        break;
      case 'pptx':
        this.fileIcon = 'file-powerpoint';
        break;

      default:
        break;
    }
  }
}
