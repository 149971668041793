<div class="modal-header pb-0">
  <button type="button" class="close" (click)="closeModal()">
    <img _ngcontent-dnc-c114="" src="assets/images/close.png" alt="">
  </button>
</div>
<div class="modal-body pt-1">
  <h5 class="modal-title" id="exampleModalLongTitle">
    Upload Notes
  </h5>
  <div class="upload-notes">
    <div class="pb-3 upload-btn-wrapper">
      <button class="btn btn-outline-primary" type="button" (click)="file.click(); $event.target.blur()">
        {{notesFileName?'Upload New Notes':'Upload Notes'}}
      </button>
      <input [formControl]=" sessionFileInput" type="file" name="myfile" (change)="onNotesSelected($event)" hidden
        type="file" accept=".doc, .docx, application/pdf" #file />
      <div class="d-flex file-name" *ngIf="notesFileName">
        <label class="text-secondary ml-2">
          <a [href]="notesLink" download target="_blank">
            <fa-icon [icon]="['fas', this.fileIcon]"></fa-icon>
            {{ notesFileName }}
          </a>
        </label>
        <img src="assets/images/icon-thick-cross.svg" class="cancel-icon ml-4" alt="" title="Delete Selected File"
          (click)="onFileRemove()" />
      </div>
      <span class="text-danger d-block" *ngIf="
          sessionFileInput.touched &&
          sessionFileInput.errors?.required
        ">
        Please upload the session notes.
      </span>
    </div>
  </div>
  <button type="button" class="btn btn-gradient btn-send" (click)="onSave()">Save</button>
</div>