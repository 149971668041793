import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpService,
    private apiService: ApiService) { }

  public registerUser(data: any, student: boolean): Observable<any> {
    let apiName: string = student ? 'register' : 'teacherRegister';
    return this.http.post(this.apiService.get(apiName), data);
  }

  public registerUserProfile(data: any): Observable<any> {
    return this.http.post(this.apiService.get("registerProfile"), data);
  }

  public registerSocialMediaUser(data: any): Observable<any> {
    return this.http.post(this.apiService.get("socialMediaRegister"), data);
  }

  //upload user profile picture
  public updateUserProfilePicture(data: any): Observable<any> {
    return this.http.post(this.apiService.get("updateUserProfilePicture"), data, false, {
      skipContentType: "true",
    });
  }

  //upload instructor resume
  public uploadInstructorResume(data: FormData): Observable<any> {
    return this.http.post(this.apiService.get("uploadResumeInstructor"), data, false, {
      skipContentType: "true"
    });
  }

  //register instrutor profile
  public registerInstructorProfile(data: any): Observable<any> {
    return this.http.post(this.apiService.get("instructorRegisterProfile"), data);
  }

}
