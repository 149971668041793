import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ApiService } from "./api.service";
import { Observable, Subject } from "rxjs";
import { CartService } from './cart.service';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: "root",
})
export class StudentService {
  public updateStatsSubject: Subject<boolean> = new Subject();
  public updateStatsObservable: Observable<
    boolean
  > = this.updateStatsSubject.asObservable();
  constructor(
    private httpService: HttpService,
    private apiService: ApiService,
    private cartService: CartService,
    private toastr: ToastrService,
    private modalService: ModalService
  ) { }

  public getNewUserDashboardData() {
    return this.httpService.get(this.apiService.get(`getDashboardData`));
  }

  //get tuition details
  public getTuitionDetail(tuitionId: string, dashboardEndPoint: boolean) {
    return this.httpService.get(
      `${this.apiService.get(
        dashboardEndPoint ? "getTuitionDetail" : "getCourseLandingPage"
      )}/${tuitionId}`
    );
  }

  //get course details
  public getCourseDetail(courseId: string, dashboardEndPoint: boolean) {
    return this.httpService.get(
      `${this.apiService.get(
        dashboardEndPoint ? "getCourseDetail" : "getCourseLandingPage"
      )}/${courseId}`
    );
  }

  //get course details
  public getCourseList(queryParams: any, dashboardEndPoint: boolean) {
    return this.httpService.get(
      `${this.apiService.get(
        dashboardEndPoint ? "getCourseList" : "getProposedCoursesLandingPage"
      )}`,
      queryParams
    );
  }

  //get course details
  public getTuitionList(queryParams: any, dashboardEndPoint: boolean) {
    return this.httpService.get(
      `${this.apiService.get(
        dashboardEndPoint ? "getTuitionList" : "getProposedTuitionsLandingPage"
      )}`,
      queryParams
    );
  }

  //update student profile
  public updateProfile(data: any) {
    return this.httpService.post(
      `${this.apiService.get("updateStudentProfile")}`,
      data
    );
  }

  //enrolled student dashboad fetch courses and one on one tuitoions
  public getEnrolledActiveCourses() {
    return this.httpService.get(this.apiService.get("getDashboardCourses"));
  }
  //enrolled student todays sessions
  public getTodaysSesions() {
    return this.httpService.get(this.apiService.get("getTodaysSessions"));
  }

  //enrolled student todays sessions
  public getDashboardSesions() {
    return this.httpService.get(this.apiService.get("getDashboardSessions"));
  }

  //enrrolled student upcoming sessions
  public getUpcomingSessions() {
    return this.httpService.get(this.apiService.get("getUpcomingSessions"));
  }

  //my courses tab all enrolled courses
  public getAllEnrolledCourses(queryParams: any) {
    return this.httpService.get(
      this.apiService.get("getEnrolledCourses"),
      queryParams
    );
  }

  //get enrolled course details
  public getEnrolledCourseDetails(courseId: number) {
    return this.httpService.get(
      `${this.apiService.get("getEnrolledCourseDetail")}/${courseId}`
    );
  }

  //get categories of courses
  public getCategories() {
    return this.httpService.get(this.apiService.get("getCategories"));
  }

  public getAttendance(
    courseId: number,
    courseAttendance: boolean,
    attendanceList: any[],
    classId?: number
  ) {
    let attended = 0,
      total = 0;
    if (attendanceList.length) {
      attendanceList.forEach((record) => {
        if (courseAttendance) {
          if (record.course_id === courseId) {
            total += record.total;
            attended += record.attended;
          }
        } else {
          if (record.course_id === courseId && record.class_id === classId) {
            total += record.total;
            attended += record.attended;
          }
        }
      });
      if (total > 0 && attended > 0) {
        return ((attended / total) * 100).toFixed(0);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  public getSubjectSpecificUpcomingSessions(courseId: number, classId: number) {
    return this.httpService.get(
      `${this.apiService.get(
        "getSubjectUpcomingSessions"
      )}/${courseId}/class/${classId}`
    );
  }

  //get homework list
  public getHomeworkList(courseId: any, classId: any) {
    return this.httpService.get(
      `${this.apiService.get(
        "getStudentHomeworkList"
      )}/${courseId}/class/${classId}`
    );
  }

  //submit homework
  public submitHomework(data: any): Observable<any> {
    return this.httpService.post(
      this.apiService.get("uploadHomework"),
      data,
      false,
      {
        skipContentType: "true",
      }
    );
  }

  //rate instructor after session
  public rateInstructor(data: any): Observable<any> {
    return this.httpService.post(this.apiService.get("rateInstructor"), data);
  }
  //get course menu
  public getCourseMenu(tuitionCaller: boolean): Observable<any> {
    return this.httpService.get(
      this.apiService.get(tuitionCaller ? "getTuitionsMenu" : "getCoursesMenu")
    );
  }



  //enroll free course
  public enrollFreeCourse(course: any) {
    const data = { course_id: course.id };
    let courses: any = [];
    this.cartService.enrollFreeCourse(data).subscribe(response => {
      let courseAsItem = this.cartService.addCourseToCart(course, true);
      courses.push(courseAsItem);
      const modalRef = this.modalService.open(10, 2);
      modalRef.componentInstance.courses = courses;
      modalRef.componentInstance.step = 3; //1 for add to cart step
    }, error => {
      // this.toastr.error(error.error.message, 'Error');
    });
  }

  //get student purchases
  public getStudentPurchases(queryParams: any): Observable<any> {
    return this.httpService.get(`${this.apiService.get('getStudentPurchases')}`, queryParams);
  }
}
