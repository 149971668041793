import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient) { }

  public get(url: string, params?: HttpParams): Observable<any> {
    if (params) {
      return this.httpClient.get(url, { params });
    } else {
      return this.httpClient.get(url);
    }
  }
  public put(url: string, body: any): Observable<any> {
    return this.httpClient.put(url, body);
  }

  public post(url: string, body: any, observeResponse: boolean = false, headers?: any): Observable<any> {
    if (observeResponse) {
      return this.httpClient.post(url, body, { headers: headers, observe: 'response' });
    } else {
      return this.httpClient.post(url, body, { headers: headers });
    }
  }

  public delete(url: string, params?: HttpParams): Observable<any> {

    if (params) {
      console.log("params", params)
      return this.httpClient.delete(url, { params: params });
    } else {
      return this.httpClient.delete(url);
    }
  }

  public patch(url: string, body: any, params?: HttpParams): Observable<any> {
    if (params) {
      return this.httpClient.patch(url, body, { params: params });
    } else {
      return this.httpClient.patch(url, body);
    }
  }
}
