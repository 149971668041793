<div
  class="course-names d-flex align-items-center"
  *ngFor="let item of cartList"
  (click)="$event.stopPropagation()"
>
  <div
    class="course-name-inner d-flex justify-content-between align-items-center mr-3"
  >
    <div class="mr-2 ml-3 pt-1" *ngIf="item.course_category === 'Group'">
      <p class="course-name font-bold mb-0">
        {{ item.course_title }}
      </p>
      <p class="my-0 subject-name d-block">
        {{ item.course_subjects }}
      </p>
    </div>
    <div class="mr-2 ml-3 pt-3" *ngIf="item.course_category === '1 on 1'">
      <p class="my-0 course-name font-bold">
        {{ item.course_category }}
      </p>
      <p class="course-name font-bold mb-0">
        {{ item.course_title }}
      </p>
      <p class="my-0 subject-name d-block">
        {{ item.course_subjects }}
        <span
          >({{
            getDurationOfCourse(item.course_start_date, item.course_end_date)
          }})</span
        >
      </p>
      <p class="my-0 subject-name d-block">
        Prof. {{ item.course_instructor }}
      </p>
    </div>
    <a href="#" class="btn btn-sm btn-custom btn-secondary">
      PKR {{ item.course_price | number }}
    </a>
  </div>
  <a
    class="cursor-pointer"
    title="Remove"
    (click)="removeCourseFromCart(item.id, item.course_id)"
  >
    <img src="assets/images/Icon awesome-window-close.svg" alt="" />
  </a>
</div>
<div class="course-names" (click)="$event.stopPropagation()">
  <div
    class="course-name-inner d-flex justify-content-between align-items-center mr-3"
    *ngIf="cartList.length"
  >
    <div class="mr-2 ml-3">
      <p class="my-0 text-primary">Total Amount</p>
    </div>
    <a href="#" class="btn btn-sm btn-custom btn-primary">
      PKR {{ totalAmount | number }}
    </a>
  </div>
  <div *ngIf="!cartList.length">
    <div class="mr-2 ml-3 text-center">
      <p class="my-0 empty-cart">Your Cart is Empty</p>
    </div>
  </div>
</div>
<a
  class="dropdown-item text-success text-center cursor-pointer p-3"
  *ngIf="cartList.length"
  (click)="openProceedToPayModal()"
  >Proceed to Checkout
  <svg
    class="forward-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="15.691"
    height="10.465"
    viewBox="0 0 15.691 10.465"
  >
    <path
      id="Icon_ionic-ios-arrow-round-forward"
      data-name="Icon ionic-ios-arrow-round-forward"
      d="M17.882,11.452a.712.712,0,0,0-.005,1l3.314,3.319H8.578a.709.709,0,0,0,0,1.417H21.185L17.871,20.51a.717.717,0,0,0,.005,1,.706.706,0,0,0,1-.005l4.491-4.524h0a.8.8,0,0,0,.147-.223.676.676,0,0,0,.055-.273.71.71,0,0,0-.2-.5l-4.491-4.524A.694.694,0,0,0,17.882,11.452Z"
      transform="translate(23.566 21.717) rotate(180)"
    />
  </svg>
</a>
