<div class="modal-header pb-0">
    <button type="button" class="close" (click)="closeModal()">
        <span aria-hidden="true">
            <img src="assets/images/close.png" alt="" />
        </span>
    </button>
</div>
<div class="modal-body pt-1">
    <div class="rating-box">
        <div class="rating">
            <h3 class="text-center">
                Teacher Feedback
            </h3>
            <div class="rating-stars">
                <div class="rate">
                    <ng-container  *ngFor="let star of stars">
                    <input type="radio" id="{{star.title}}" name="star" value="{{star.value}}" (click)="onRatingClicked(star.value)" [checked]="star.value<=selectedRatingLevel" />
                    <label for="{{star.title}}" title="{{star.title}}"></label>
                </ng-container>
                </div>
                <p class="message mt-3">
                    Kindly Share your Feedback
                </p>
            </div>
            <div class="btn-pane">
                <a class="btn btn-gradient ng-star-inserted" (click)="onRatingSubmit()" >Submit</a>
            </div>
        </div>
    </div>
</div>