<section class="courses-banner-section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h3 class="text-primary">
                    Related Courses
                </h3>
                <div class="text-center" *ngIf="coursePlanList?.length === 0">
                    There is no new course available for now!
                </div>
                <div class="slider-pane courses-banner-slider" *ngIf="coursePlanList?.length > 0">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                        <div ngxSlickItem class="slide" *ngFor="let coursePlan of coursePlanList">
                            <div class="card">
                                <div class="card-header">
                                    <h6 class="cursor-pointer" (click)="navigateToCourseDetail(coursePlan.id)">
                                        {{coursePlan.title}}</h6>
                                    <!-- <p class="subjects">
                                        <span *ngFor="
                                          let class of coursePlan?.classes;
                                          count as length;
                                          index as current
                                        ">{{ class.subject.name
                                        }}<span *ngIf="current < length - 1">, </span></span>
                                    </p> -->
                                </div>
                                <div class="card-body">
                                    <!-- <p>
                                        {{ coursePlan.total_sessions }} Session(s)
                                    </p> -->
                                    <table class="details-table">
                                        <tbody>
                                            <tr>
                                                <td class="label">
                                                    <strong>
                                                        Grade:
                                                    </strong>
                                                </td>
                                                <td class="value">
                                                    <span>
                                                        {{coursePlan.grade.name}}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="label">
                                                    <strong>
                                                        Type:
                                                    </strong>
                                                </td>
                                                <td class="value">
                                                    <span>
                                                        {{ coursePlan.category.category_type === '1 on 1' ? '1 on 1 Tuition' : 'Group Sessions'}}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="label">
                                                    <strong>
                                                        Start Date:
                                                    </strong>
                                                </td>
                                                <td class="value">
                                                    <span>
                                                        {{coursePlan.start_date | dateFormat:'dayMonthYear'}}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="label">
                                                    <strong>
                                                        Amount:
                                                    </strong>
                                                </td>
                                                <td class="value">
                                                    <span>
                                                        {{coursePlan.is_free ? 'FREE' : 'PKR ' + (coursePlan.price | number)}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
        </div>
    </div>
</section>