import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Meta, Title } from '@angular/platform-browser';
import { _ } from 'core-js';
import { LandingPageService } from "src/app/services/landing-page.service";

@Component({
  selector: "app-teacher-landing-page",
  templateUrl: "./teacher-landing-page.component.html",
  styleUrls: ["./teacher-landing-page.component.scss"],
})
export class TeacherLandingPageComponent implements OnInit {
  public instructorsList: any[] = [];
  public params: any = {
    page_size: 8,
    page: 1,
  };
  public totalPages: number;
  public maxLimitReached: boolean = false;
  public zeroSearchResults: boolean = false;
  public instructorsCount: number = 0;
  public showLoadMoreButton: boolean = false;
  public searchTriggered: boolean = false;
  public showSearchTriggeredMessage: boolean = false;
  @ViewChild("searchButton") seacrhButton: ElementRef; //to close dropdown on search button fired

  constructor(private landingPageService: LandingPageService, private metaTagService: Meta, private metaTitleService: Title) {
    //add seo tags

    this.metaTagService.updateTag({ name: 'keywords', content: 'Teach Online' });
    this.metaTitleService.setTitle('Interested in Teaching Online? Get Registered with VIVID');
    this.metaTagService.updateTag({ name: 'description', content: 'If you adore the art of teaching and have what it takes to be a part of VIVID, then start teaching students all over the world today with VIVID.' });

  }

  ngOnInit(): void {
    this.getInstructors();
  }

  public getInstructors() {
    //set course count to zero to hide course count upon api call
    this.instructorsCount = 0;
    //to not show load more button upon api call
    this.showLoadMoreButton = false;
    this.landingPageService
      .getInstructorsList(this.params)
      .subscribe((response) => {
        //updating page number locally
        this.params.page += 1;
        //setting data array
        this.instructorsList = [...this.instructorsList, ...response.data.data];
        //setting total pages
        this.totalPages = response.data.last_page;
        //setting max pages flag
        if (this.params.page > this.totalPages) {
          this.maxLimitReached = true;
          this.showLoadMoreButton = false;
        } else {
          this.showLoadMoreButton = true;
        }
        if (response.data.total === 0) {
          //mesage manuplation on number of results
          this.zeroSearchResults = true;
          this.showLoadMoreButton = false;
        } else {
          this.zeroSearchResults = false;
          this.instructorsCount = response.data.total;
        }

        if (this.searchTriggered) {
          //close dropdown if search fired
          this.seacrhButton.nativeElement.click();
          this.searchTriggered = !this.searchTriggered;
        }
        this.instructorsList.map((instructor) => {
          instructor.grades = this.landingPageService.getString(
            instructor,
            false,
            true
          );
          instructor.subjects = this.landingPageService.getString(
            instructor,
            true,
            true
          );
        });
      });
  }

  public loadMore() {
    if (!this.maxLimitReached) {
      this.getInstructors();
    } else {
      return;
    }
  }

  public jsonDecode(item) {
    return JSON.parse(item);
  }

  //function call on event emiited by search box
  public fetchSearchResults(searchData: any) {
    //initially hide loadmore button
    this.zeroSearchResults = false;

    //flag to show search results count
    this.searchTriggered = true;

    // to show no matching results mesage
    this.showSearchTriggeredMessage = true;

    if (searchData.grade != null) {
      this.params.grade = searchData.grade;
    } else {
      delete this.params["grade"];
    }
    if (searchData.subject != null) {
      this.params.subject = searchData.subject;
    } else {
      delete this.params["subject"];
    }
    if (searchData.experience != null) {
      this.params.experience = searchData.experience;
    } else {
      delete this.params["experience"];
    }
    if (searchData.qualification != null) {
      this.params.qualification = searchData.qualification;
    } else {
      delete this.params["qualification"];
    }
    //if any of search parameter provided then reseting page number parameter and sending api call
    // if (
    //   searchData.grade ||
    //   searchData.subject ||
    //   searchData.experience ||
    //   searchData.qualification
    // ) {
    this.params.page = 1;
    //empty previous records
    this.instructorsList = [];
    //calling api
    this.getInstructors();
    //reseting max records flag
    this.maxLimitReached = false;
    // }
  }
}
