import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';
import { ModalService } from 'src/app/services/modal.service';
import { AuthService } from 'src/app/services/auth.service';
import { StudentService } from 'src/app/services/student.service';
import { Router } from '@angular/router';
import { StudyMaterialService } from 'src/app/services/study-material.service';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: { '(document:click)': 'onClick($event)' },
})
export class HeaderComponent implements OnInit {
  public dropdowns: any[] = [

    {
      moveUp: false,
    },
    {
      moveUp1: false,
    },
    {
      moveUp2: false,
    },
  ]

  moveUp = false;
  moveUp1 = false;
  moveUp2 = false;
  showCarret = false;
  status = false;

  constructor(
    private modalService: ModalService,
    private utilityService: UtilityService,
    private authService: AuthService,
    private studentService: StudentService) { }

  ngOnInit(): void {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 90) {
        $(".navbar-me").addClass("fixed-me");
      } else {
        $(".navbar-me").removeClass("fixed-me");
      }
    });
    // $('.dropdown-submenu > a').on("click", function (e) {
    //   $(this).parent().addClass('active');
    //   $(this).next('ul').toggle();
    //   e.stopPropagation();
    //   e.preventDefault();
    // });
 }

  public openLoginModal(userType: string): void {
    if (this.authService.isAuthenticated()) return; // if we have user object  in local storage don't open login modal
    this.utilityService.setUserType(userType);
    this.modalService.open(1, 1); // same component login for teacher and student
  }

  // public openTeacherLoginModal(): void {
  //   if(!this.authService.isAuthenticated()) return;
  //   this.utilityService.setUserType("instructor");
  //   this.modalService.open(1, 1); // same component login for teacher and student
  //   // this.modalService.getSuccessModal('Password Change Success', 'Your Password has been changed.');
  // }

  public refresh() {
    window.location.reload();
  }

  public openSearchBox(): void {
    $(".header-search").addClass('open');
  }

  public closeSearchBox(): void {
    $(".header-search").removeClass('open');
  }

  public dropdowntoggler(status: number): void {
    // if(status === 1) {
    //   this.moveUp = !this.moveUp;
    //   this.moveUp1 = false;
    //   this.moveUp2 = false;
    // }
    // else if(status === 2) {
    //   this.moveUp = false;
    //   this.moveUp1 = !this.moveUp1;
    //   this.moveUp2 = false;
    // }
    // else {
    //   this.moveUp = false;
    //   this.moveUp1 = false;
    //   this.moveUp2 = !this.moveUp2;
    // }

  }
  onClick(event) {
    this.showCarret = !this.showCarret;
  }

  public menuButton(): void {
    // $(".navbar-toggler").toggleClass('open');
    if ($('.navbar-toggler').hasClass('close')) {
      // console.log('function ok');
      $('.navegacion').css({ 'width': '100%', 'background': 'rgba(0,0,0,.5)' });
      $('.navbar-toggler').removeClass('close').addClass('open');
      $('body').addClass('menue-expended');
      $('.navegacion .menu').css({ 'right': '0px' });

    } else {
      $('.navegacion').css({ 'width': '0%', 'background': 'rgba(0,0,0,.0)' });
      $('.navbar-toggler').removeClass('open').addClass('close');
      $('body').removeClass('menue-expended');
      $('.navegacion .submenu').css({ 'right': '-320px' });
      $('.navegacion .menu').css({ 'right': '-320px' });

    }

  }

  // $('.navegacion .menu > .item-submenu a').click(function(){

  //   var position<a href="https://www.jqueryscript.net/menu/">Menu</a> = $(this).parent().attr('menu');
  //   console.log(positionMenu);

  //   $('.item-submenu[menu='+positionMenu+'] .submenu').css({'left':'0px'});

  // });

  // $('.navegacion .submenu li.go-back').click(function(){

  //   $(this).parent().css({'left':'-320px'});

  // });
}
