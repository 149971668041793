import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-homework-feedback',
  templateUrl: './homework-feedback.component.html',
  styleUrls: ['./homework-feedback.component.scss']
})
export class HomeworkFeedbackComponent implements OnInit {
  @Input() callerFeedback: boolean;
  @Input() callerSessionNotes: boolean = false;
  @Input() homeworkFeedbackFileName: any;
  @Input() homeworkFeedbackFileUrl: any;
  public fileIcon: string;
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    //get file icon according to file extension
    switch (this.homeworkFeedbackFileName.split('.').pop()) {
      case 'docx':
        this.fileIcon = 'file-word';
        break;
      case 'doc':
        this.fileIcon = 'file-word';
        break;
      case 'pdf':
        this.fileIcon = 'file-pdf';
        break;
      case 'ppt':
        this.fileIcon = 'file-powerpoint';
        break;
      case 'pptx':
        this.fileIcon = 'file-powerpoint';
        break;
      case 'xls':
        this.fileIcon = 'file-excel';
        break;
      case 'xlsx':
        this.fileIcon = 'file-excel';
        break;
      default:
        this.fileIcon = 'file-pdf';
        break;
    }
  }

  public async closeModal() {
    this.activeModal.close();
  }
}
