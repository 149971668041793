import { NgModule } from "@angular/core";
import { RouterModule, Routes, ExtraOptions } from "@angular/router";
import { DashboardComponent } from "./components/student-dashboard/register-student-dashboard/dashboard/dashboard.component";
import { DashboardComponent as TeacherDashboardComponent } from "./components/teacher-dashboard/dashboard/dashboard.component";
import { AuthGuardService } from "./services/auth-guard.service";
import { OneToOneTuitionListComponent } from "./components/student-dashboard/register-student-dashboard/one-to-one-tuition-list/one-to-one-tuition-list.component";
import { CourseListComponent } from "./components/student-dashboard/register-student-dashboard/course-list/course-list.component";
import { OneToOneTuitionDetailComponent } from "./components/student-dashboard/register-student-dashboard/one-to-one-tuition-detail/one-to-one-tuition-detail.component";
import { CourseDetailComponent } from "./components/student-dashboard/register-student-dashboard/course-detail/course-detail.component";
import { AboutUsComponent } from "./components/main-dashboard/about-us/about-us.component";
import { FaqsComponent } from "./components/main-dashboard/faqs/faqs.component";
import { LandingPageStudyMaterialComponent } from "./components/main-dashboard/landing-page-study-material/landing-page-study-material.component";
import { LandingPageLayoutComponent } from "./components/main-dashboard/landing-page/landing-page-layout/landing-page-layout.component";
import { LandingComponent } from "./components/main-dashboard/landing-page/landing/landing.component";
import { TeacherLandingPageComponent } from "./components/main-dashboard/teacher-landing-page/teacher-landing-page.component";
import { TermsAndConditionsComponent } from "./components/main-dashboard/terms-and-conditions/terms-and-conditions.component";
import { PrivacyPolicyComponent } from "./components/main-dashboard/privacy-policy/privacy-policy.component";
import { ContactUsComponent } from './components/main-dashboard/contact-us/contact-us.component';
import { ErrorComponentComponent } from './components/shared/error-component/error-component.component';
import { DataDeletionComponent } from './components/main-dashboard/data-deletion/data-deletion.component';

const routes: Routes = [
  {
    //empty path redirection
    path: "",
    component: LandingPageLayoutComponent,
    children: [
      {
        //empty path redirection
        path: "",
        pathMatch: "full",
        component: LandingComponent,
      },
      {
        //landing page study material
        path: "study-material/:id",
        component: LandingPageStudyMaterialComponent,
      },
      {
        //Teachers page on dashboard
        path: "teachers",
        component: TeacherLandingPageComponent,
      },
      {
        //group courses list
        path: "courses",
        data: {
          fromDashboard: false,
        },
        component: CourseListComponent,
      },
      {
        //group courses list
        path: "tuitions",
        data: {
          fromDashboard: false,
        },
        component: OneToOneTuitionListComponent,
      },
      {
        //course details
        path: "course/:id",
        data: {
          fromDashboard: false
        },
        component: CourseDetailComponent,
      },
      {
        //tuition details
        path: "tuition/:id",
        data: {
          fromDashboard: false
        },
        component: CourseDetailComponent,
      },
      {
        //about us
        path: "about-us",
        component: AboutUsComponent,
      },
      {
        //about us
        path: "faqs",
        component: FaqsComponent,
      },
      {
        path: "terms-and-conditions",
        component: TermsAndConditionsComponent,
      },
      {
        path: "privacy-policy",
        component: PrivacyPolicyComponent,
      },
      {
        path: "fb-data-deletion-instructions",
        component: DataDeletionComponent,
      },
      {
        path: "contact-us",
        component: ContactUsComponent,
      }
    ],
  },

  {
    path: "student-dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuardService],
    data: { userType: "student" },
    loadChildren: () =>
      import("./modules/student/student/student.module").then(
        (m) => m.StudentModule
      ),
  },
  {
    path: "teacher-dashboard",
    component: TeacherDashboardComponent,
    canActivate: [AuthGuardService],
    data: { userType: "instructor" },
    loadChildren: () =>
      import("./modules/teacher/teacher/teacher.module").then(
        (m) => m.TeacherModule
      ),
  },
  {
    path: '**',
    pathMatch: 'full',
    component: ErrorComponentComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  // imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
