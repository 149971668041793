import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removePhoneNumberSpaces'
})
export class RemovePhoneNumberSpacesPipe implements PipeTransform {

  transform(phoneNumber: string, ...args: unknown[]): unknown {
    // const countryCode = phoneNumber.slice(0, 3);
    // const simCode = phoneNumber.slice(4, 7);
    // const number = phoneNumber.slice(8, 15);
    // const finalNumber = countryCode  + simCode  + number;
    return phoneNumber.replace(/\s/g, "");

  }

}
