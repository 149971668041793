import { Pipe, PipeTransform } from '@angular/core';
import { max } from 'moment';

@Pipe({
  name: 'shortText'
})
export class ShortTextPipe implements PipeTransform {

  transform(value: string, maxLength: number): unknown {
    let resultString: string = "";
    if (value.length > maxLength) {
      resultString = value.slice(0, maxLength) + '...';
    } else {
      resultString = value;
    }
    return resultString;
  }

}
