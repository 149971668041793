import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { _ } from 'core-js';
import { ToastrService } from 'ngx-toastr';
import { LandingPageService } from 'src/app/services/landing-page.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  public callerDemo: boolean;
  public callerTuitionRequest: boolean;
  constructor(private activatedRoute: ActivatedRoute, private toastr: ToastrService, private router: Router, private utilityService: UtilityService, private landingPageService: LandingPageService, private metaTagService: Meta, private metaTitleService: Title) {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.callerDemo = (params.get("callerDemo") === 'true') ? true : false;
      this.callerTuitionRequest = (params.get("callerTuitionReq") === 'true') ? true : false;
      this.ngOnInit();
    });
  }

  public contactUsFormGroup: FormGroup = new FormGroup({
    'subject': new FormControl(null, [Validators.required, Validators.pattern(this.utilityService.nameRegex), Validators.minLength(2), Validators.maxLength(50)]),
    'name': new FormControl(null, [Validators.required, Validators.pattern(this.utilityService.nameRegex), Validators.minLength(2), Validators.maxLength(50)]),
    'email': new FormControl(null, [Validators.required, Validators.email, Validators.pattern(this.utilityService.emailRegex)]),
    'phone': new FormControl(null, [Validators.required, Validators.pattern(this.utilityService.phoneNumberRegex)]),
    'message': new FormControl(null, [Validators.required])
  });

  ngOnInit(): void {
    if (this.callerDemo === true && this.callerTuitionRequest === true) {
      this.contactFormControls.subject.setValue('Personalized 1 on 1 Tuition Request', { disabled: true });
      this.contactFormControls.subject.disable();
    } else {
      this.contactUsFormGroup.updateValueAndValidity();
      this.contactFormControls.subject.enable();
      this.contactUsFormGroup.reset();
    }

    //add tags for SEO
    this.metaTagService.updateTag({ name: 'keywords', content: 'Contact VIVID, Online classes' });
    this.metaTitleService.setTitle('Contact VIVID for Online classes and 1 on 1 tutions');
    this.metaTagService.updateTag({ name: 'description', content: 'For more information about online classes and 1 on 1 tutions, Contact VIVID' });
  }

  //on submit
  onSubmit() {
    this.contactUsFormGroup.markAllAsTouched();
    if (this.contactUsFormGroup.invalid) return;
    const data: any = {
      'subject': this.contactFormControls.subject.value,
      'email': this.contactFormControls.email.value,
      'name': this.contactFormControls.name.value,
      'phone': this.utilityService.addSpacesInPhoneNo(this.contactFormControls.phone.value),//add spaces to phone number
      'message': this.contactFormControls.message.value,
    };
    this.landingPageService.contactUs(data, this.callerDemo).subscribe(response => {
      this.toastr.success(`Your message has been sent. We'll get back to you shortly!`);
      this.contactUsFormGroup.reset();
    });
  }

  get contactFormControls() {
    return this.contactUsFormGroup.controls;
  }
}
