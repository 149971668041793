<section class="register-free-section">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-8 col-md-8 col-sm-12 pr-lg-0">
        <div class="media align-items-center">
          <div class="img-container">
            <img src="assets/images/student.svg" alt="" />
          </div>
          <div class="media-body">
            <h2 class="mt-0 text-white">
              {{
                forTuitionRequest
                  ? "Personalized 1 on 1 Tuition"
                  : "Manage Teaching and Learning"
              }}
            </h2>
            <p class="text-white font-light mb-0">
              {{
                forTuitionRequest
                  ? "Facing trouble with some concepts? Schedule a 1 on 1 session with the best online teachers on VIVID and get those grades you have always desired!"
                  : "TALEEMLY helps students and teachers organize assignments, boost collaboration, and foster better communication."
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12">
        <div class="personalized-button">
          <a
            (click)="openRegistrationModal('student')"
            class="btn btn-light btn-reg"
            >{{
              forTuitionRequest ? "Request 1 on 1 Tuition" : "Register Free"
            }}</a
          >
        </div>
      </div>
    </div>
  </div>
</section>
