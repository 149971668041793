import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { StudentService } from 'src/app/services/student.service';
import { StudyMaterialService } from 'src/app/services/study-material.service';

@Component({
  selector: 'app-courses-mobile-menu',
  templateUrl: './courses-mobile-menu.component.html',
  styleUrls: ['./courses-mobile-menu.component.scss']
})
export class CoursesMobileMenuComponent implements OnInit, OnChanges {
  public courseMenuList: any[];
  @Input() fetchGroupTuitionMenucallerTuition: boolean;
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private studentService: StudentService,
    private router: Router,
    public studyMaterialService: StudyMaterialService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.getMenu(this.fetchGroupTuitionMenucallerTuition);
  }

  public subMenu(e): void {
    let subMenu = e.target.nextSibling;
    subMenu.style.right = "0px";
  }

  public closeMenu(e): void {
    let parentMenu = e.target.parentNode.parentNode;
    parentMenu.style.right = "-320px";
  }

  public getMenu(fetchGroupTuitionMenu) {
    this.studentService.getCourseMenu(fetchGroupTuitionMenu).subscribe((response) => {
      this.courseMenuList = response.data.menu;
    });
  }

  public navigateToCourse(courseId) {
    this.clicked.next(); //close offpage mobile menu
    this.router.navigate(['/course', courseId], {
      state: { callerLandingPage: true },
    });
  }


}
