import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'changeTimeFormat'
})
export class ChangeDateFormatPipe implements PipeTransform {

  transform(timeString: string, receiving: boolean = true): unknown {
    const timeZoneOffset = new Date().getTimezoneOffset(); //getting current user timezone
    if (receiving) {
      return moment(timeString, 'HH:mm' || 'HH:mm:ss' || 'YYYY-MM-DDTHH:mm:ssZ').subtract(timeZoneOffset, "minute").format('hh:mm A');//-300
    } else {
      console.log(timeString, moment(timeString, 'h:mm:A').add(timeZoneOffset, "minute").format('HH:mm'))
      return moment(timeString, 'h:mm:A').add(timeZoneOffset, "minute").format('HH:mm');
    }
  }

}
