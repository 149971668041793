<div class="modal-body">
  <div class="student-login-box register">
    <div class="student-login mb-0 pb-0">
      <h3 class="text-center mb-4">{{ title }}</h3>
      <div
        [ngClass]="{
          'd-flex align-items-center ml-4': callerTeacherRegistration,
          'text-center': !callerTeacherRegistration
        }"
      >
        <img
          src="assets/images/icon_tick_success.svg"
          class="mt-4 mb-3 check-circle"
          [ngClass]="{
            'mr-3 my-auto': callerTeacherRegistration
          }"
          alt=""
        />
        <p class="pb-2">{{ message }}</p>
      </div>
      <div class="text-center mt-5">
        <a
          class="btn btn-gradient btn-done"
          [ngClass]="{
            'btn-block': callerTeacherRegistration
          }"
          (click)="closeModal()"
        >
          Done
        </a>
      </div>
    </div>
  </div>
</div>
