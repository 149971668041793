<header>
  <nav class="navbar navbar-expand-xl navbar-light custom-navbar navbar-me">
    <div class="container">
    <a class="navbar-brand mr-0" (click)="refresh()">
      <!-- <img src="assets/images/logo.svg" class="brand-name ml-2" alt="VIVID"> -->
      <img src="assets/icons/Logo-Final.png" class="brand-name ml-2 icon-2" alt="TALEEMLY">
    </a>
    <button (click)="status=!status" class="navbar-toggler close" type="button" data-toggle="offcanvas"
      data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation" (click)="menuButton()">
      <div id="nav-icon2">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>

    <div class="offcanvas-collapse navbar-collapse justify-content-center" [ngClass]="status ? 'open' : 'offcanvas-collapse'"
      id="navbarSupportedContent">
      <ul class="navbar-nav main-navbar-nav align-items-xl-center">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="'/'">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="'/about-us'">About us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link dropdown-toggle" [ngClass]="moveUp ? 'move-up': ' '" id="coursesDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Courses
          </a>
          <div class="dropdown-menu  custom-dropdown" aria-labelledby="navbarDropdown">
            <app-courses-menu></app-courses-menu>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="'/teachers'">
            Teachers
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            Free Study Material
          </a>
          <div class="dropdown-menu custom-dropdown " aria-labelledby="navbarDropdown">
            <app-study-material [callerLandingPage]="true"></app-study-material>
          </div>
        </li>
        <li class="nav-item">
          <a (click)="dropdowntoggler(1)" class="nav-link dropdown-toggle" [ngClass]="moveUp ? 'move-up': ' '" href="#"
            id="coursesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            1 on 1 Tuition
          </a>
          <div class="dropdown-menu custom-dropdown" aria-labelledby="navbarDropdown">
            <!-- <li><a tabindex="-1" href="#">HTML</a></li>
            <li class="dropdown-submenu">
              <a class="" tabindex="-1" href="#">New dropdown</a>
              <ul class="dropdown-menu">
                <li><a tabindex="-1" href="#">2nd level dropdown</a></li>
                <li><a tabindex="-1" href="#">2nd level dropdown</a></li>
                <li class="dropdown-submenu">
                  <a class="" href="#">Another dropdown</a>
                  <ul class="dropdown-menu">
                    <li><a href="#">3rd level dropdown</a></li>
                    <li><a href="#">3rd level dropdown</a></li>
                    <li class="dropdown-submenu">
                      <a class="" href="#">Another dropdown</a>
                      <ul class="dropdown-menu">
                        <li><a href="#">3rd level dropdown</a></li>
                        <li><a href="#">3rd level dropdown</a></li>
                        <li><a href="#">3rd level dropdown</a></li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li><a tabindex="-1" href="#">CSS</a></li> -->
            <app-tuition-menu></app-tuition-menu>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="'/faqs'">FAQs</a>
        </li>
      </ul>
    </div>
    <ul class="navbar-nav main-navbar-nav-sec ml-auto flex-row align-items-xl-center">
      <li class="nav-item">
        <!-- <a class="nav-link search-icon" (click)="openSearchBox()">
          <img src="assets/images/icon-feather-search-2.svg" class="cursor-pointer" alt="search-icon">
        </a> -->
      </li>
      <li class="nav-item login-btn">
        <div class="dropdown nav-link py-0">
          <button class="btn btn-login dropdown-toggle font-weight-light" type="button" id="dropdownMenuButton"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Login
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <!-- <a class="dropdown-item" href="#studentLogin" data-toggle="modal">Student Sign In</a> -->
            <a class="dropdown-item" (click)="openLoginModal('student')">Student Login</a>
            <a class="dropdown-item" (click)="openLoginModal('instructor')">Teacher Login</a>
          </div>
        </div>
      </li>
    </ul>

    <div class="header-search">
      <div class="container">
        <div class="row">
          <div class="col-sm-8 col-md-9 mx-auto">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Search..!!" aria-label=""
                aria-describedby="basic-addon2">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button">
                  <img src="assets/images/icon-feather-search-white.png" alt="">
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a href="#" class="close-btn" (click)="closeSearchBox()">
        <img src="assets/images/close.png" alt="">
      </a>
    </div>

    <!--
      Mobile Menu
     -->

    <!-- <header>
      <span id="button-menu" class="fa fa-bars"></span> -->
    <nav class="navegacion mble-menu">
      <ul class="menu">
        <li><a (click)="menuButton()" routerLinkActive="active" [routerLink]="'/'">Home</a>
        </li>
        <li><a (click)="menuButton()" routerLinkActive="active" [routerLink]="'/about-us'">About Us</a>
        </li>
        <li class="item-submenu" menu="1">
          <app-courses-mobile-menu (clicked)="menuButton()" [fetchGroupTuitionMenucallerTuition]="false"></app-courses-mobile-menu>
        </li>
        <li><a (click)="menuButton()" routerLinkActive="active" [routerLink]="'/teachers'">Teachers</a>
        </li>
        <li class="item-submenu" menu="1">
          <app-courses-mobile-menu (clicked)="menuButton()" [fetchGroupTuitionMenucallerTuition]="true"></app-courses-mobile-menu>
        </li>
        <li class="item-submenu" menu="1">
          <app-study-material-mobile-menu (clicked)="menuButton()" [callerLandingPage]="true"></app-study-material-mobile-menu>
        </li>
        <li><a (click)="menuButton()" routerLinkActive="active" [routerLink]="'/faqs'">FAQs</a>
        </li>
      </ul>
    </nav>
    <!-- </header> -->
  </div>
  </nav>
</header>
