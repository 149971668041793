import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import * as moment_timezone from 'moment-timezone';
import { AuthService } from "../services/auth.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { tap, finalize } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    public count = 0;
    constructor(private authService: AuthService, private spinner: NgxSpinnerService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // get token from service
        const userToken = this.authService.getAuthToken();

        //show spinner conditionallly
        let showSpinner:boolean;

        // Add required headers to API calls
        let headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
            "User-Current-Timezone": moment_timezone.tz.guess()
        });

        if (req.headers.get('skipContentType') && req.headers.get('skipContentType') === 'true') {
            headers = headers.delete('Content-Type');
        }
        //check if header contains spinner flag
         if (req.headers.get('showSpinner') === 'false') {
             console.log(req.headers.get('showSpinner'))
            showSpinner = false;
            headers = headers.delete('showSpinner');
        }else{
            showSpinner = true;
            this.spinner.show('main-spinner');
            this.count++;
        }

        // modify actual request and add Authorization headers
        const modifiedReq = req.clone({ headers });

        // use modified request
        return next.handle(modifiedReq).pipe(tap(
            // event => console.log(event),
            // error => console.log(error)
        ), finalize(() => {
                this.count--;
                if (this.count == 0) this.spinner.hide('main-spinner');
        })
        );
    }
}
