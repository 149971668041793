import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(date: string, formatType: string): unknown {
    if (date) {
      switch (formatType) {
        case 'fullDate': {
          return moment(date).format('ddd D MMM, YYYY');
        }
        case 'dayMonth': {
          return moment(date).format('ddd, D MMM');
        }
        case 'dayMonthYear': {
          return moment(date).format('D MMM, YYYY');
        }
        case 'monthDayYear': {
          return moment(date).format('ddd, D MMM');
        }
      }
    }
  }

}
