<div class="slybus-details">
  <p class="text-primary font-medium subject">{{ classTitle }}</p>
  <div class="details-inner">
    <p class="" [innerHTML]="syllabusDescription"></p>
    <div class="text-secondary">
      <div class="text-center download-pane">
        <img
          src="assets/images/Icon awesome-file-pdf.svg"
          class="mb-3"
          alt=""
        />
        <p class="font-small my-0">Download Syllabus</p>
        <div class="font-small sylabus-title">({{ syllabusTitle }})</div>
        <a
          target="_blank"
          [href]="syllabusLink"
          download
          class="btn btn-primary btn-sm btn-download"
        >
          Download
        </a>
      </div>
    </div>
  </div>
</div>
