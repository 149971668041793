<section class="top-rated-section">
  <div class="container">
    <div class="title-box">
      <h2 class="text-dark heading-2">Top Rated Teachers</h2>
      <a class="carousel-control-prev" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true">
        </span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true">
        </span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
      <div ngxSlickItem class="slide" *ngFor="let instructor of topRatedInstructorsList">
        <div class="img-container">
          <img [src]="instructor.instructor_profile.profile_picture || 'assets/images/avatar.svg'" class="d-block" alt="">
          <div class="img-overlay">
            <div class="overlay-text d-flex justify-content-between align-items-start">
              <div>
                <h5 class="text-white">{{instructor.name}}</h5>
                <span>{{instructor.instructor_profile.specialization}}</span>
              </div>
              <p class="text-primary mb-0">
                <img src="assets/images/Icon-ionic-ios-star.svg" class="star-icon" alt="">
                {{instructor.instructor_profile.rating}}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div ngxSlickItem class="slide">
        <div class="img-container">
          <img src="../../../../assets/images/two.png" class="d-block" alt="">
          <div class="img-overlay">
            <div class="overlay-text d-flex justify-content-between align-items-start">
              <div>
                <h5 class="text-white">Roberto Tremo</h5>
                <span>Maths</span>
              </div>
              <p class="text-primary mb-0">
                <img src="assets/images/Icon-ionic-ios-star.svg" class="star-icon" alt="">
                4.95</p>
            </div>
          </div>
        </div>
      </div>
      <div ngxSlickItem class="slide">
        <div class="img-container">
          <img src="../../../../assets/images/three.png" class="d-block" alt="">
          <div class="img-overlay">
            <div class="overlay-text d-flex justify-content-between align-items-start">
              <div>
                <h5 class="text-white">Pauline Johns</h5>
                <span>Physics</span>
              </div>
              <p class="text-primary mb-0">
                <img src="assets/images/Icon-ionic-ios-star.svg" class="star-icon" alt="">
                4.95</p>
            </div>
          </div>
        </div>
      </div>
      <div ngxSlickItem class="slide">
        <div class="img-container">
          <img src="../../../../assets/images/four.png" class="d-block" alt="">
          <div class="img-overlay">
            <div class="overlay-text d-flex justify-content-between align-items-start">
              <div>
                <h5 class="text-white">Ruby Smith</h5>
                <span>Chemistry</span>
              </div>
              <p class="text-primary mb-0">
                <img src="assets/images/Icon-ionic-ios-star.svg" class="star-icon" alt="">
                4.95</p>
            </div>
          </div>
        </div>
      </div>
      <div ngxSlickItem class="slide">
        <div class="img-container">
          <img src="../../../../assets/images/one.png" class="d-block" alt="">
          <div class="img-overlay">
            <div class="overlay-text d-flex justify-content-between align-items-start">
              <div>
                <h5 class="text-white">Ruby Garcia</h5>
                <span>English</span>
              </div>
              <p class="text-primary mb-0">
                <img src="assets/images/Icon-ionic-ios-star.svg" class="star-icon" alt="">
                4.95</p>
            </div>
          </div>
        </div>
      </div>
      <div ngxSlickItem class="slide">
        <div class="img-container">
          <img src="../../../../assets/images/two.png" class="d-block" alt="">
          <div class="img-overlay">
            <div class="overlay-text d-flex justify-content-between align-items-start">
              <div>
                <h5 class="text-white">Roberto Tremo</h5>
                <span>Maths</span>
              </div>
              <p class="text-primary mb-0">
                <img src="assets/images/Icon-ionic-ios-star.svg" class="star-icon" alt="">
                4.95</p>
            </div>
          </div>
        </div>
      </div>
      <div ngxSlickItem class="slide">
        <div class="img-container">
          <img src="../../../../assets/images/three.png" class="d-block" alt="">
          <div class="img-overlay">
            <div class="overlay-text d-flex justify-content-between align-items-start">
              <div>
                <h5 class="text-white">Pauline Johns</h5>
                <span>Physics</span>
              </div>
              <p class="text-primary mb-0">
                <img src="assets/images/Icon-ionic-ios-star.svg" class="star-icon" alt="">
                4.95</p>
            </div>
          </div>
        </div>
      </div>
      <div ngxSlickItem class="slide">
        <div class="img-container">
          <img src="../../../../assets/images/four.png" class="d-block" alt="">
          <div class="img-overlay">
            <div class="overlay-text d-flex justify-content-between align-items-start">
              <div>
                <h5 class="text-white">Ruby Smith</h5>
                <span>Chemistry</span>
              </div>
              <p class="text-primary mb-0">
                <img src="assets/images/Icon-ionic-ios-star.svg" class="star-icon" alt="">
                4.95</p>
            </div>
          </div>
        </div>
      </div> -->
    </ngx-slick-carousel>
    <!-- <div class="slider-box">
      <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="row">
              <div class="col-12 col-md-6 col-xl-3">
                <div class="img-container">
                  <img src="../../../../assets/images/one.png" class="d-block" alt="">
                  <div class="img-overlay">
                    <div class="overlay-text d-flex justify-content-between align-items-start">
                      <div>
                        <h5 class="text-white">Ruby Garcia</h5>
                        <span>English</span>
                      </div>
                      <p class="text-primary mb-0">
                        <img src="assets/images/Icon-ionic-ios-star.svg" class="star-icon" alt="">
                        4.95</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-3">
                <div class="img-container">
                  <img src="../../../../assets/images/two.png" class="d-block" alt="">
                  <div class="img-overlay">
                    <div class="overlay-text d-flex justify-content-between align-items-start">
                      <div>
                        <h5 class="text-white">Roberto Tremo</h5>
                        <span>Maths</span>
                      </div>
                      <p class="text-primary mb-0">
                        <img src="assets/images/Icon-ionic-ios-star.svg" class="star-icon" alt="">
                        4.95</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-3">
                <div class="img-container">
                  <img src="../../../../assets/images/three.png" class="d-block" alt="">
                  <div class="img-overlay">
                    <div class="overlay-text d-flex justify-content-between align-items-start">
                      <div>
                        <h5 class="text-white">Pauline Johns</h5>
                        <span>Physics</span>
                      </div>
                      <p class="text-primary mb-0">
                        <img src="assets/images/Icon-ionic-ios-star.svg" class="star-icon" alt="">
                        4.95</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-3">
                <div class="img-container">
                  <img src="../../../../assets/images/four.png" class="d-block" alt="">
                  <div class="img-overlay">
                    <div class="overlay-text d-flex justify-content-between align-items-start">
                      <div>
                        <h5 class="text-white">Ruby Smith</h5>
                        <span>Chemistry</span>
                      </div>
                      <p class="text-primary mb-0">
                        <img src="assets/images/Icon-ionic-ios-star.svg" class="star-icon" alt="">
                        4.95</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-12 col-md-6 col-xl-3">
                <div class="img-container">
                  <img src="../../../../assets/images/four.png" class="d-block" alt="">
                  <div class="img-overlay">
                    <div class="overlay-text d-flex justify-content-between align-items-start">
                      <div>
                        <h5 class="text-white">Ruby Garcia</h5>
                        <span>English</span>
                      </div>
                      <p class="text-primary mb-0">
                        <img src="assets/images/Icon-ionic-ios-star.svg" class="star-icon" alt="">
                        4.95</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-3">
                <div class="img-container">
                  <img src="../../../../assets/images/three.png" class="d-block" alt="">
                  <div class="img-overlay">
                    <div class="overlay-text d-flex justify-content-between align-items-start">
                      <div>
                        <h5 class="text-white">Ruby Garcia</h5>
                        <span>English</span>
                      </div>
                      <p class="text-primary mb-0">
                        <img src="assets/images/Icon-ionic-ios-star.svg" class="star-icon" alt="">
                        4.95</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-3">
                <div class="img-container">
                  <img src="../../../../assets/images/two.png" class="d-block" alt="">
                  <div class="img-overlay">
                    <div class="overlay-text d-flex justify-content-between align-items-start">
                      <div>
                        <h5 class="text-white">Ruby Garcia</h5>
                        <span>English</span>
                      </div>
                      <p class="text-primary mb-0">
                        <img src="assets/images/Icon-ionic-ios-star.svg" class="star-icon" alt="">
                        4.95</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-3">
                <div class="img-container">
                  <img src="../../../../assets/images/one.png" class="d-block" alt="">
                  <div class="img-overlay">
                    <div class="overlay-text d-flex justify-content-between align-items-start">
                      <div>
                        <h5 class="text-white">Ruby Garcia</h5>
                        <span>English</span>
                      </div>
                      <p class="text-primary mb-0">
                        <img src="assets/images/Icon-ionic-ios-star.svg" class="star-icon" alt="">
                        4.95</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

  </div>
</section>
