<div class="modal-header pb-0">
  <button type="button" class="close" (click)="closeModal()">
    <img _ngcontent-dnc-c114="" src="assets/images/close.png" alt="" />
  </button>
</div>
<div class="modal-body pt-1">
  <h5 class="modal-title" id="exampleModalLongTitle">Upload Homework</h5>
  <div class="upload-notes">
    <div class="pb-3 upload-btn-wrapper">
      <button
        class="btn btn-outline-primary"
        type="button"
        (click)="$event.target.blur(); file.click()"
      >
        {{ homeworkFileName ? "Upload New Homework" : "Upload File" }}
      </button>
      <form [formGroup]="homeworkFileFormGroup">
        <input
          type="file"
          name="myfile"
          (change)="onHomeworkSelected($event)"
          hidden
          formControlName="homeworkFile"
          type="file"
          accept=".doc, .docx, application/pdf, .ppt, .pptx"
          #file
        />
        <span
          class="text-danger d-block error-text font-smaller"
          *ngIf="
            homeworkFileFormGroup.controls.homeworkFile.touched &&
            homeworkFileFormGroup.controls.homeworkFile.errors?.required
          "
        >
          Please provide homework file.
        </span>
      </form>
      <!-- <span class="text-primary font-small pl-2">File Upload Complete</span> -->
      <div class="d-flex file-name" *ngIf="homeworkFileName">
        <label class="text-secondary ml-2">
          <a download target="_blank">
            <!-- file icon depending upon file extension -->
            <fa-icon [icon]="['fas', this.fileIcon]"></fa-icon>
            {{ homeworkFileName }}
          </a>
        </label>
        <img
          src="assets/images/icon-thick-cross.svg"
          class="cancel-icon ml-4"
          alt=""
          title="Delete Selected File"
          (click)="onFileRemove()"
        />
      </div>
    </div>
  </div>
  <button type="button" class="btn btn-gradient btn-send" (click)="onSave()">
    Save
  </button>
</div>
<div class="modal-footer"></div>
