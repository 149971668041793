import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { ModalService } from "src/app/services/modal.service";
import { UtilityService } from "src/app/services/utility.service";

@Component({
  selector: "app-become-teacher",
  templateUrl: "./become-teacher.component.html",
  styleUrls: ["./become-teacher.component.scss"],
})
export class BecomeTeacherComponent implements OnInit {
  constructor(
    private modalService: ModalService,
    private authService: AuthService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {}

  public openRegistrationModal(userType: string): void {
    if (this.authService.isAuthenticated()) return; // if we have user object  in local storage don't open login modal
    this.utilityService.setUserType(userType);
    this.modalService.open(2, 1); // same component login for teacher and student
  }
}
