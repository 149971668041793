<section class="hero-section">
  <div class="hero-box">
    <div class="content-pane">
      <div class="content-box">
        <h1 class="text-white heading-1">Go Smart, Go TALEEMLY!</h1>
        <div class="hero-detail">
          <p class="text-fullDark font-light main-paragraph">
            Revamp your education through online learning with TALEEMLY!
          </p>

          <p class="text-fullDark font-light main-paragraph">
            Find quality online tutors worldwide and ace those exams!
          </p>

          <p class="text-fullDark font-light main-paragraph">
            Explore all the courses TALEEMLY has to offer with Pakistan’s best
            online learning platform!
          </p>

          <div class="action-btns mt-3">
            <a
              [routerLink]="'/courses'"
              class="btn btn-fullDark btn-join btn-hero mr-2"
              >Join Group Courses</a
            >
            <a
              [routerLink]="['/contact-us']"
              [queryParams]="{ callerDemo: true, callerTuitionReq: true }"
              class="btn btn-fullDark btn-hero mr-2"
              >Join 1 on 1 Session</a
            >
          </div>
          <div class="action-btns">
            <a
              class="btn btn-outline-light btn-hero request-btn text-white"
              [routerLink]="['/contact-us']"
              [queryParams]="{ callerDemo: true, callerTuitionReq: false }"
              >Request a demo</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="slider-pane">
      <div class="slider-box">
        <div class="slider-box-inner">
          <div
            id="carouselExampleSlidesOnly"
            class="carousel slide carousel-fade"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  class="d-block w-100"
                  src="assets/images/img_01.png"
                  alt="First slide"
                />
              </div>
              <div class="carousel-item">
                <img
                  class="d-block w-100"
                  src="assets/images/img_02.png"
                  alt="Second slide"
                />
              </div>
              <div class="carousel-item">
                <img
                  class="d-block w-100"
                  src="assets/images/img_03.png"
                  alt="Third slide"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="services">
    <div class="container container-fluid-md">
      <div class="row justify-content-sm-center">
        <div class="col-lg-4 col-md-4 col-sm-6 d-flex">
          <div class="media align-items-center">
            <img
              class="mr-3 img-icon"
              src="assets/images/course.png"
              alt="Courses"
            />
            <div class="media-body">
              <h4 class="text-primary mt-0 heading-4">100 + Courses</h4>
              <span>Explore countless courses we offer</span>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6 d-flex">
          <div class="media align-items-center">
            <img
              class="mr-3 img-icon img-2"
              src="assets/images/teacher.png"
              alt="Teachers"
            />
            <div class="media-body">
              <h4 class="text-primary mt-0 heading-4">Teachers that Inspire</h4>
              <span>Teachers that develop minds</span>
            </div>
          </div>
        </div>
        <div
          class="
            col-lg-4 col-md-4 col-sm-6
            pr-lg-0
            d-flex
          "
        >
          <div class="media align-items-center">
            <img
              class="mr-3 img-icon lst-icon"
              src="assets/images/online-learning.png"
              alt="Online Learning"
            />
            <div class="media-body">
              <h4 class="text-primary mt-0 heading-4">Acing Exams</h4>
              <span
                >Gain access to guess papers, past papers and helping
                notes</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="available-on">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 col-md-8">
          <p>What if you had all these things within your fingertips?
            Visit our website today and enjoy a seamless transition towards
            online learning through your fingertips!
          </p>
        </div>
        <div
          class="
            col-lg-5 col-md-4
            d-flex
            justify-content-center
          "
        >
          <a href="#" class="mr-lg-4 mr-md-0 mr-0 mb-md-3">
            <img src="assets/images/appstore.svg" class="btn-download" alt="" />
          </a>
          <a href="#">
            <img
              src="assets/images/googleplay.svg"
              class="btn-download"
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
