<div class="modal-header pb-0">
  <button type="button" class="close" (click)="closeModal()">
    <img _ngcontent-dnc-c114="" src="assets/images/close.png" alt="" />
  </button>
</div>
<div class="modal-body pt-1">
  <h5 class="modal-title" id="exampleModalLongTitle">Homework Feedback</h5>
  <div class="form-label-group pb-3 mb-4">
    <input
      type="text"
      placeholder="Homework Marks"
      id="homeworkMarks"
      [formControl]="homeworkMarks"
      class="form-control"
      (keypress)="checkMarksInput($event)"
    />
    <label for="homeworkMarks">Homework Marks</label>
    <div>
      <span
        class="text-danger"
        *ngIf="homeworkMarks.touched && homeworkMarks.errors?.required"
      >
        Please enter homework marks.
      </span>
      <span
        class="text-danger"
        *ngIf="homeworkMarks.touched && homeworkMarks.errors?.pattern"
      >
        Please enter valid marks.
      </span>
      <span
        class="text-danger"
        *ngIf="homeworkMarks.touched && homeworkMarks.errors?.maxMarks"
      >
        Entered marks must not be greater than the total marks.
      </span>
    </div>
  </div>
  <div class="upload-notes">
    <div class="pb-3 upload-btn-wrapper">
      <button
        class="btn btn-outline-primary"
        type="button"
        (click)="file.click(); $event.target.blur()"
      >
        {{ homeworkFeedbackFile ? "Upload Other Feedback" : "Upload Feedback" }}
      </button>
      <input
        type="file"
        name="myfile"
        (change)="onHomeworkSelected($event)"
        [formControl]="homeworkFeedbackFileInput"
        hidden
        type="file"
        accept=".doc, .docx, application/pdf, .xls, .xlsx, .csv"
        #file
      />
      <span
        class="text-danger d-block"
        *ngIf="
          homeworkFeedbackFileInput.touched &&
          homeworkFeedbackFileInput.errors?.required
        "
      >
        Please upload the homework feedback.
      </span>
      <!-- <span class="text-primary font-small pl-2">File Upload Complete</span> -->
      <div class="d-flex file-name mt-2" *ngIf="homeworkFeedbackFile">
        <label class="text-secondary ml-2">
            <fa-icon [icon]="['fas', this.fileIcon]"></fa-icon>
            {{ homeworkFeedbackFile }}
        </label>
        <img
          src="assets/images/icon-thick-cross.svg"
          class="cancel-icon ml-4"
          alt=""
          title="Delete Selected File"
          (click)="onFileRemove()"
        />
      </div>
    </div>
  </div>
  <button type="button" class="btn btn-gradient btn-send" (click)="onSave()">
    Save
  </button>
</div>
<div class="modal-footer"></div>
