import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  constructor(private httpService: HttpService, private apiService: ApiService) { }

  //get subject list
  public getSubjectsList(){
    return this.httpService.get(`${this.apiService.get('getSubjectsList')}`);
  }

}
