import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'compactDayName'
})
export class CompactDayNamePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {

      case 'Sunday' || 'sunday':
        return 'Sun'
        break;
      case 'Monday' || 'monday':
        return 'Mon'
        break;
      case 'Tuesday' || 'tuesday':
        return 'Tue'
        break;
      case 'Wednesday' || 'wednesday':
        return 'Wed'
        break;
      case 'Thursday' || 'thursday':
        return 'Thurs'
        break;
      case 'Friday' || 'friday':
        return 'Fri'
        break;
      case 'Saturday' || 'saturday':
        return 'Sat'
        break;
    }
  }

}
