import { PlatformLocation } from '@angular/common';
import { Component, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { TestService } from 'src/app/services/test.service';
import * as moment from 'moment';
@Component({
  selector: 'app-mock-test-modal',
  templateUrl: './mock-test-modal.component.html',
  styleUrls: ['./mock-test-modal.component.scss']
})
export class MockTestModalComponent implements OnInit {
  public questionList: any[];
  public skippedQuestionList: any[] = [];
  public attemptStarted: boolean;
  public test: any;
  // public counterConfig: any = { done: this.forceSubmitAttempt() };
  @HostListener("window:beforeunload", ["$event"])
  // @HostListener('window:popstate', ['$event'])
  unloadNotification(event: any) {
    event.returnValue = "Are you sure to leave test?";
  }
  @HostListener('window:popstate', ['$event'])
  unloadNot(event: any) {
    // window.history.forward();
  }
  public counterConfig: any = {};
  public questionFormGroup: FormGroup = new FormGroup({});
  public currentQuestion: any;
  public currentQuestionIndex: number;
  public testAttemptId: any;
  public showResult: boolean = false;
  public result: any;
  public showFinish: boolean = false;
  public currentDate = moment();
  //refresh test list
  @Output() onTestAttempted: Subject<boolean> = new Subject<boolean>();

  @Input() testId: any; //test id
  @Input() isAttempted: any; //if test is partially attempted
  @Input() isCompleted: any; //if test is completed
  constructor(
    private activeModal: NgbActiveModal,
    location: PlatformLocation,
    private testService: TestService,
    private confirmDialogService: ConfirmationDialogService,
    private toastr: ToastrService
  ) {
    // location.onPopState(() => {
    //   if (!confirm('Are you sure to leave test?')) {
    //     location.pushState(window.history.state);
    //   }
    // });
  }

  ngOnInit(): void {
    this.getTestDetails(this.testId);
  }

  //close modal
  public closeModal(updateListing: boolean = false): void {
    if (updateListing) this.onTestAttempted.next(true);
    this.activeModal.close();
  }

  //get test details
  public getTestDetails(testId: any) {
    this.testService
      .getMockDetails(testId)
      .subscribe((response) => {
        this.test = response.data; //test details
        this.questionList = this.test.questions; //question list
        //if user is attempting quiz for the first time since he came to this worlld
        this.questionList.map((question) => {
          question.is_attempted = false;
        });
      });
  }

  // initial question form
  public initQuestionForm(indexOfQuestion) {
    this.currentQuestion = this.questionList[indexOfQuestion];
    if (this.questionList[this.currentQuestionIndex].is_attempted) {
      //if question is attemptted then show next un attempted question
      this.showNextQuestion();
    }
    let question = this.currentQuestion;
    this.questionFormGroup = new FormGroup({
      selected_choice_id: new FormControl(null, Validators.required),
    });
  }

  //start test
  public startAttempt() {
    //on api call response start time and show question
    this.testService
      .startMockTestAttempt({ quiz_id: this.testId })
      .subscribe((response) => {
        this.testAttemptId = response.data.attempt.id;
        // this.testAttemptId = 5;
        this.currentQuestionIndex = 0;
        this.initQuestionForm(this.currentQuestionIndex);
        this.attemptStarted = true;
        if (this.currentQuestionIndex + 1 === this.questionList.length)
          this.showFinish = true; //if question list only contains one question
      });
  }

  //submit question
  public submitQuestion(finish: boolean = false) {
    //on next pressed
    this.questionFormGroup.markAllAsTouched();
    if (this.questionFormGroup.controls.selected_choice_id.errors?.required) {
      //ifu ser hasn't selected any option
      this.toastr.error(
        "Please fill correct option first to proceed further.",
        "Error"
      );
      return;
    }
    const data = {
      student_quiz_attempt_id: this.testAttemptId,
      question_id: this.currentQuestion.id,
      choice_id: this.questionFormGroup.controls.selected_choice_id.value,
    };
    //submit question api call
    this.testService.submitMockTestQuestion(data).subscribe((response) => {
      //updating result object ::D bro
      this.result = response.data.attempt;
      this.result.isComplete = response.data.complete;
      if (finish) {
        if (this.skippedQuestionList.length) { //if user has some skipped questions then force submit quiz to disable attempt button
          this.forceSubmitAttempt();
          return;
        }
        this.result = response.data.attempt; //not calling force submit on last question
        this.result.isComplete = response.data.complete;
        this.showResult = true;
        this.attemptStarted = false;
      }
    }); //not waiting for response
    this.questionList[this.currentQuestionIndex].is_attempted = true; //changing flag of attempted question
    //show next question

    if (!finish) {
      if (this.skippedQuestionList.indexOf(this.currentQuestionIndex) + 1) {
        //if that eleement exists in skipped questions array
        this.skippedQuestionList.splice(
          this.skippedQuestionList.indexOf(this.currentQuestionIndex),
          1
        ); //remove question from skipped array
      }
      this.showNextQuestion();
    }
  }

  public showNextQuestion() {
    //displaying next question
    this.currentQuestionIndex += 1;
    if (this.currentQuestionIndex < this.questionList.length) {
      if (this.questionList[this.currentQuestionIndex].is_attempted) {
        //if question is attemptted then show next un attempted question
        this.showNextQuestion();
      }
      this.initQuestionForm(this.currentQuestionIndex);
      //check if its last index of question list then show finish button
      if (this.currentQuestionIndex + 1 === this.questionList.length)
        this.showFinish = true;
    } else {
      this.showResult = true;
      this.attemptStarted = false;
    }
  }

  //skip question
  public skipQuestion() {
    //on next pressed //push question to skipped questions array
    if (!(this.skippedQuestionList.indexOf(this.currentQuestionIndex) + 1)) {
      //if not already in skipped question list then add it
      this.skippedQuestionList.push(this.currentQuestionIndex);
      this.showNextQuestion();
    } else {
      this.showNextQuestion();
    }
  }
  //display skipped question
  public showSkippedQuestion(question: any, skippedArrayIndex: number) {
    //if current question is not attempted and user click on a skipped question to show then push that question to skipped questions array
    // this.skippedQuestionList.push(this.currentQuestionIndex);
    //then show skipped question
    this.currentQuestionIndex = question; // set current question index to this
    this.initQuestionForm(this.currentQuestionIndex); // initallize view of question
    //check if its not last index of question list then don't show finish button
    if (this.currentQuestionIndex + 1 < this.questionList.length)
      this.showFinish = false;
  }
  //complete test
  public async submitAttempt() {
    //show validation before final question dialog
    this.questionFormGroup.markAllAsTouched();
    if (this.questionFormGroup.controls.selected_choice_id.errors?.required) {
      //ifu ser hasn't selected any option
      this.toastr.error(
        "Please fill correct option first to proceed further.",
        "Error"
      );
      return;
    }
    const result = await this.confirmDialogService.getConfirmation(
      "Are you done ?",
      this.skippedQuestionList.length ? `Your test has skipped question(s), if it was skipped mistakenly you can click the <strong>Cancel</strong> button to continue the test OR you can click the <strong>Finish</strong> button to submit the test` : "Please confirm by clicking the finish button, if it was clicked unintentionally you can continue by clicking cancel.",
      "Finish",
      "cancel"
    );
    if (!result) return;
    this.submitQuestion(true);
  }

  //force susbmit attempt if duration is completed
  public forceSubmitAttempt() {
    this.testService.submitMockTest(this.testAttemptId).subscribe((response) => {
      this.result = response.data.attempt;
      this.result.isComplete = response.data.complete;
      this.showResult = true;
      this.attemptStarted = false;
    });
  }

  //download pdf
  public downloadTest(attemptId: any) {
    this.testService.getAttemptedMockTestPDF(attemptId).subscribe((response) => {
      window.open(response.data.file_path, "_blank");
    });
  }


}
