import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss'],
})
export class ChangePasswordModalComponent implements OnInit {
  public showOldPassword: boolean = false;
  public showNewPassword: boolean = false;
  public showNewPasswordConfirmation: boolean = false;
  public changePasswordFormGroup: FormGroup = new FormGroup({
    'old_password': new FormControl(null, Validators.required),
    'password': new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(this.utilityService.passwordRegex)]),
    'confirmPassword': new FormControl(null, [Validators.required])
  },
    {
      validators: this.utilityService.confirmValidator.bind(this) // check password and confirm password should match
    }
  );
  constructor(private activeModal: NgbActiveModal, private authService: AuthService, private toastr: ToastrService, private utilityService: UtilityService) { }
  ngOnInit(): void {
  }

  public closeModal(passwordChanged: boolean) {
    if (passwordChanged) {
      this.activeModal.close();
    } else {
      this.changePasswordFormGroup.reset();
      this.changePasswordFormGroup.markAsUntouched();
      this.activeModal.close();
    }
  }

  get changePasswordFormControl() {
    return this.changePasswordFormGroup.controls;
  }

  public onSubmit() {
    this.changePasswordFormGroup.markAllAsTouched();
    if (this.changePasswordFormGroup.invalid) return;
    const data = {
      'old_password': this.changePasswordFormGroup.controls.old_password.value,
      'new_password': this.changePasswordFormGroup.controls.password.value,
      'new_password_confirmation': this.changePasswordFormGroup.controls.confirmPassword.value
    };

    this.authService.changePassword(data).subscribe(response => {
      this.toastr.success('Password updated successfully.', 'Success');
      this.closeModal(true);
    },
      (error: HttpErrorResponse) => {
        if (!error.error.error_details) return;
        if ('old_password' in error.error.error_details) {
          this.changePasswordFormGroup.controls['old_password'].setErrors({ 'old_password': 'The Password is incorrect.' })
        }
      }
    )
  }
  //do not allow user to input space
  public checkSpaceInput(event) {
    return this.utilityService.doNotSpace(event);
  }
}
