import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ApiService } from './api.service';
import { Subject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  public cartDataSubject: Subject<object> = new Subject();
  public cartDataObservable: Observable<object> = this.cartDataSubject.asObservable();



  public addRemoveCartIemSubject: Subject<object> = new Subject();
  public addRemoveCartIemObservable: Observable<object> = this.addRemoveCartIemSubject.asObservable();


  constructor(private http: HttpService, private apiService: ApiService, private toastr: ToastrService) { }
  //api call to add course to cart
  public addCourseToCart(course, onlyGetData: boolean = false) {
    if (!course.available_seats) {
      this.toastr.error('Sorry! All seats for this course are already booked', 'Error');
      return;
    }
    const data: any = {
      "course_id": course.id,
      "course_price": course.price,
      "course_name": course.title,
      "course_category": course.category.category_type,
      "course_subjects": '',
      'course_start_date': course.start_date,
      'course_end_date': course.end_date
    };
    if (course.category.category_type === '1 on 1') {
      //1 on 1 category type
      data['course_title'] = course.title;
      data['course_instructor'] = course.classes[0].instructor_name;
      data['course_subjects'] = course.classes[0].subject.name;

    } else {//group classes
      data['course_title'] = course.title;
      //adding course subjects to data object
      for (let i = 0; i < course.classes.length; i++) {
        data['course_subjects'] += course.classes[i].subject.name;
        if (i < course.classes.length - 1) {// if subject is not the last subject
          data['course_subjects'] += ", ";
        }
      }

    }
    if (onlyGetData) return data;
    else return this.http.post(this.apiService.get("postCartItem"), data);
  }
  //api call to get list of courses
  public getCartItems() {
    return this.http.get(this.apiService.get("getCartItems"));
  }
  //api call to delete course from cart
  public removeCourseFromCart(courseId, validate: boolean = false) {
    let url = `${this.apiService.get("postCartItem")}/${courseId}`;
    if (validate) { url += '/validate' }
    // return this.http.delete(`${this.apiService.get("postCartItem")}/${courseId}` + validate ? `/validate` : ``);
    return this.http.delete(url);
  }

  //api call to get update item at checkout list of courses
  public getCheckoutItems() {
    return this.http.get(this.apiService.get("getUpdatedCartItems"));
  }

  //checkout buy course
  public checkout(data: any) {
    return this.http.post(this.apiService.get("checkoutCartItems"), data);
  }

  //empty cart list
  public emptyCartList() {
    this.cartDataSubject.next([]);
  }

  //add order
  public addNewOrder(data: any) {
    return this.http.post(this.apiService.get("addNewOrder"), data);
  }

  //verify foree pay order
  public verifyForeePayOrder(data: any) {
    return this.http.post(this.apiService.get("verifyForeePayOrder"), data);
  }

  //cancel order
  public cancelOrder(orderId: any) {
    return this.http.put(`${this.apiService.get("cancelOrder")}/${orderId}`, {});
  }

  //get order details
  public getOrderDetails(orderId: any) {
    return this.http.get(`${this.apiService.get("getOrderDetails")}/${orderId}`);
  }

  //enroll free course
  public enrollFreeCourse(data: any) {
    return this.http.post(this.apiService.get("enrollFreeCourse"), data);
  }
}
