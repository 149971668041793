<section class="packages-section">
  <div class="container">
    <div class="title-box d-flex justify-content-between align-items-center flex-wrap pt-5">
      <h2 class="text-primary heading-2">Our Top Courses</h2>
      <a [routerLink]="'/faqs'" class="btn btn-outline-primary btn-browse btn-faqs">FAQs</a>
    </div>
    <!-- Slider for pricing -->
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
      <div ngxSlickItem class="slide" *ngFor="let coursePlan of coursePlanList">
        <div class="card-wrapper card-wrapper-1">
          <div class="card full-class">
            <h3 class="">{{coursePlan.title}}</h3>
            <p class=" font-light text-center" [innerHTML]="coursePlan.description"></p>
            <h6 class="my-0 mb-1 font-light ">Starting</h6>
            <!-- <h2 class=""><span>PKR</span> 0 <span>/ month</span></h2> -->
            <h2 class=""><span *ngIf="!coursePlan.is_free">PKR</span> {{coursePlan.is_free ? 'FREE' : coursePlan.price}}
            </h2>
            <div class="text-center">
              <button (click)="openLoginModal('student')" type="button" class="btn btn-gradient">Start Now</button>
            </div>
          </div>
        </div>
      </div>
    </ngx-slick-carousel>
    <div class="plans">
      <div class="all-plans offers">
        <h3 class="mb-3 ">All Plans are inclusive of</h3>

      </div>
      <div class="offers">
        <ul class="offers-list">
          <li>
            <p>
              <img src="assets/images/check-gray.svg" class="mr-4" alt="">
              24/7 course access
            </p>
          </li>
          <li>
            <p>
              <img src="assets/images/check-gray.svg" class="mr-4" alt="">
              Join 2000+ members
            </p>
          </li>
          <li>
            <p>
              <img src="assets/images/check-gray.svg" class="mr-4" alt="">
              Access to new work plans
            </p>
          </li>
        </ul>
      </div>
      <div class="offers">
        <ul class="offers-list">
          <li>
            <p>
              <img src="assets/images/check-gray.svg" class="mr-4" alt="">
              Support from Tutors
            </p>
          </li>
          <li>
            <p>
              <img src="assets/images/check-gray.svg" class="mr-4" alt="">
              Entire course library
            </p>
          </li>
          <li>
            <p>
              <img src="assets/images/check-gray.svg" class="mr-4" alt="">
              Assets included per course
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
