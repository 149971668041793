import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { ModalService } from 'src/app/services/modal.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  //form instantiation
  public forgotPasswordFormGroup: FormGroup = new FormGroup({
    'phone': new FormControl(null, [Validators.required, Validators.pattern(this.utilityService.phoneNumberRegex)]),
  });

  constructor(
    private utilityService: UtilityService,
    private authService: AuthService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private activeModal: NgbActiveModal
  ) { }

  get forgotPasswordFormControls() {
    return this.forgotPasswordFormGroup.controls;
  }

  ngOnInit(): void {
  }

  //on phone number submission
  public onSubmit() {
    //if user submitted without entering required fields
    this.forgotPasswordFormGroup.markAllAsTouched();
    if (this.forgotPasswordFormGroup.invalid) return;
    const data = {
      'phone': this.utilityService.addSpacesInPhoneNo(this.forgotPasswordFormControls.phone.value),
      type: this.utilityService.getUserType()
    };

    //api call to forgot password to verify phone number
    this.authService.forgotPassword(data).subscribe(response => {
      //success message
      this.toastr.success('OTP is sent to ' + data.phone, 'Success');
      //closing previous modal and openning the next step modal with properties
      //close active modal
      this.activeModal.close();
      let modalRef;
      //wait for closing model to complete animation
      setTimeout(() => {
        modalRef = this.modalService.open(6, 1);
        modalRef.componentInstance.caller = 'forgotPassword';
        modalRef.componentInstance.phoneNo = response.data.phone;
      }, 1000);


    },
      (error: HttpErrorResponse) => {
        if ('phone' in error.error.error_details) {
          this.forgotPasswordFormGroup.controls['phone'].setErrors({ 'notRegistered': error.error.error_details.phone });
        }
      }
    );

  }

  //close modal
  public closeModal(): void {
    this.activeModal.close();
  }

  //allow user to only input numbers + and spaces
  public checkPhoneInput(event) {
    return this.utilityService.allowNumberAndSpaceOnly(event);
  }

  //
  public openLoginModal() {
    this.closeModal();
    //wait for closing model to complete animation
    setTimeout(() => { this.modalService.open(1, 1) }, 800);
  }

  public addPrefix() {
    if (!this.forgotPasswordFormControls.phone.value) {
      this.forgotPasswordFormControls.phone.setValue("+92");
    }
  }
}
