import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
// import { CookieService } from 'angular2-cookie/services/cookies.service';
import * as crypto from 'crypto-js';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './api.service';
import { HttpService } from './http.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  public allowedHomeworkFileTypes: string[] = ["application/pdf", "application/msword", "application/vnd.ms-powerpoint", "application/msword", "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/kswps", "application/kset", "application/vnd.ms-excel", "text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
  private socialMediaRegistrationFlag: boolean = false;
  public socialMediaUserData: any;
  public socialMediaType: string;
  public userType: string;
  public quillEditorInstances: any[];
  private secretKeyForEncryption: string = "someKeyForEncryption";
  constructor(
    private toastr: ToastrService, 
    private cookieService: CookieService, 
    private http: HttpService, 
    private apiService: ApiService) { }

  // public phoneNumberRegex = /^(\+92 )-\d{3}-{0,1}\d{7}$/;
  public phoneNumberRegex = /^(\+92)3[0-9][0-9](?!0{7})\d{7}$/;
  public otpRegex = /^[1-9]\d{3}$/;
  public nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
  public passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[`.~()^+=_;:'"@$!%*#?&/\/-])[A-Za-z\d`.~()^+=_;:'"$@$!%*#?&/\/-]{8,20}$/;
  // public passwordRegex = /^(?=.*[\d])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,20}$/;
  // public passwordRegex = /^(?:.*[a-zA-Z])(?:=.*[0-9])(?=.*[!@#\$%\^&\*])(?:.{8,})$/;
  // public passwordRegex = /^$/;
  // (?=.*[a-z][A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})
  public emailRegex = /^\w+([\._%+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  //leading trailing spaces
  // public leadingTrailingSpaces = /^(?:[A-Za-z0-9])([A-Za-z0-9\s]*)(?:[A-Za-z0-9])$/;

  // public leadingTrailingSpaces = /^[a-zA-Z0-9!@#\$?%\^\&*\)\(+=.,_-]([a-zA-Z0-9!@#\$?%\^\&*\)\(+=.,_-\s]*)[a-zA-Z0-9!@#\$?,%\^\&*\)\(+=._-]$/;
  public leadingTrailingSpaces = /^[A-Za-z0-9!@#\$?'/"%\^\&*\)\(+=.,_–-]+(?:\s+[A-Za-z0-9!@#\$?'/"%\^\&*\)\(+=.,_–-]+)*$/;
  public leadingTrailingSpacesForTest = /^\S+(?:\s+\S+)*$/;
  public numbersleadingTrailingSpaces = /^\d{1,4}$|(?=^.{1,5}$)^\d+\.\d{0,3}$/;
  public numbersleadingTrailingSpacesV2 = /^\d+(\.\d{1,2})?$/;

  public confirmValidator(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;
    // console.log("validator called", group.errors, group.controls.password.value, group.controls.confirmPassword.value);
    if (pass === confirmPass) {
      return null;
    } else {
      return { notSame: true };
    }
  }

  public setSocialMediaRegistrationFlag(flag: boolean): void {
    this.socialMediaRegistrationFlag = flag;
  }

  public getSocialMediaRegistrationFlag(): boolean {
    return this.socialMediaRegistrationFlag;
  }

  public getUserType(): string {
    return this.userType;
  }
  //set user when user select login as teacher or student
  public setUserType(type: string): void {
    this.userType = type;
  }

  //allow user to only input numbers + and spaces
  public allowNumberAndSpaceOnly(event): boolean {
    return (event.charCode >= 48 && event.charCode <= 57) || event.keyCode == 43;
  }
  //check marks input
  public checkMarksInput(event) {
    //return only numbers and .
    return (event.charCode >= 48 && event.charCode <= 57) || event.key === '.';
  }

  //allow user to only input numbers + and spaces
  public doNotSpace(event): boolean {
    return !(event.keyCode == 32 || event.keyCode == 9);
  }

  //add spaces between phone number
  public addSpacesInPhoneNo(phoneNumber: string): string { //not addding spaces for now
    // const countryCode = phoneNumber.slice(0, 3);
    // const simCode = phoneNumber.slice(3, 6);
    // const number = phoneNumber.slice(6, 13);
    // const finalNumber = countryCode + ' ' + simCode + ' ' + number;
    // return finalNumber;
    return phoneNumber;
  }

  //remove spaces between phone number
  public removeSpacesInPhoneNo(phoneNumber: string): string {
    const countryCode = phoneNumber.slice(0, 3);
    const simCode = phoneNumber.slice(4, 7);
    const number = phoneNumber.slice(8, 15);
    const finalNumber = countryCode + simCode + number;
    console.log(finalNumber);
    return finalNumber;
  }

  //check number input
  public isNumber(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }
  //when a user selected a photo
  public onPhotoSelected(event: any) {

    // get selected file
    const file: File = event.target.files[0];

    // do not move forward if file is empty/null
    if (!file) {
      return;
    }



    // get selected file type
    const fileType = file.type.toLowerCase();

    // do not move forward if file type is not supported
    if (
      !(
        fileType === "image/png" ||
        fileType === "image/jpg" ||
        fileType === "image/jpeg"
      )
    ) {
      this.toastr.error("File type not supported.", "Error");
      return;
    }

    // add selected file in form data
    // this.data.append("profile_picture", file, file.name);
    return {
      file: file,
      fileName: file.name,
    };

  }

  //get month for two dates provided
  public getMonths(date1, date2) {
    let months = moment(date2).diff(date1, 'months');
    let weeks = moment(date2).diff(date1, 'weeks');
    let days = moment(date2).diff(date1, 'days');
    let result: string;
    if (months > 0) {
      result = months + ' Month(s)';
    } else if (weeks > 0 && weeks < 4) {
      result = weeks + ' Week(s)';
    } else {
      if (days === 0) {
        days = 1;
      }
      result = days + ' Day(s)';
    }
    return result;
  }

  //encrypt the secret
  public encrypt(value: string): string {
    let _key = crypto.enc.Utf8.parse(this.secretKeyForEncryption);
    let _iv = crypto.enc.Utf8.parse(this.secretKeyForEncryption);
    let encrypted = crypto.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: crypto.mode.ECB,
      padding: crypto.pad.Pkcs7
    });
    return encrypted.toString();
  }
  //decrypt the secret
  public decrypt(value: string) {
    let _key = crypto.enc.Utf8.parse(this.secretKeyForEncryption);
    let _iv = crypto.enc.Utf8.parse(this.secretKeyForEncryption);
    let decrypted = crypto.AES.decrypt(
      value, _key, {
      keySize: 16,
      iv: _iv,
      mode: crypto.mode.ECB,
      padding: crypto.pad.Pkcs7
    }).toString(crypto.enc.Utf8);
    return decrypted.slice(1, decrypted.length - 1); //""secret"" removing extra qoutes
  }

  //set cookies for seamless login
  public setCookies(email: string, secret: string) {
    //set cookies for seamless login
    this.cookieService.set('email', email, { expires: moment().add(15, 'minutes').toDate() });
    this.cookieService.set('secret', this.encrypt(secret), { expires: moment().add(15, 'minutes').toDate() });
  }

  //get stored cookies
  public getCookies(): any {
    //get cookies for seamless login
    return { 'email': this.cookieService.get('email'), 'password': this.decrypt(this.cookieService.get('secret')) };
  }

  public checkHomeworkFile(fileType): boolean {
    return (this.allowedHomeworkFileTypes.filter(type => fileType === type).length) ? true : false;
  }

  //get modal from number to remove circular dependencies
  // public getModalFromEnum(modalNumber: number) {
  //   switch (modalNumber) {
  //     case 1:
  //       return LoginComponent;
  //       break;
  //     case 2:
  //       return RegistrationComponent;
  //       break;
  //     case 3:
  //       return ForgotPasswordComponent;
  //       break;
  //     case 4:
  //       return SocialMediaRegisterComponent;
  //       break;
  //     case 5:
  //       return NewPasswordComponent;
  //       break;
  //     case 6:
  //       return OtpVerificationComponent;
  //       break;
  //     case 7:
  //       return RegistrationFinalStepComponent;
  //       break;
  //     case 8:
  //       return ChangePasswordModalComponent;
  //       break;
  //     case 9:
  //       return SuccessModalComponent;
  //       break;
  //     case 10:
  //       return CheckoutParentComponent;
  //       break;
  //     case 11:
  //       return TeacherLoginComponent;
  //       break;
  //     case 12:
  //       return ConfirmationModalComponent;
  //       break;
  //   }
  // }

  // format the time slots according the API payload format on teacher registration final step and teacher profile edit
  public formatTime(time: string[]) {
    let finalTimeResult = [];
    for (let index = 0; index < time.length; index++) {
      let element: any = time[index];
      let timeString: string[] = element.name.split(' - ');
      let startTime = timeString[0];
      let endTime = timeString[1];
      let result = {
        'start_time': moment(startTime, 'h A').format('hh:mm A'),
        'end_time': moment(endTime, 'h A').format('hh:mm A')
      };
      finalTimeResult.push(result);
    }
    return finalTimeResult;
  }

  //get teachers class attendance 
  public getAttendance(details: any, callerStudent: boolean) {
    if (details) { //get attendance from pivot details 
      let data: any = this.jsonDecode(details);
      //class performance
      console.log(data);
      return callerStudent ? (('course_sessions_percentage' in data) ? data?.course_sessions_percentage !== -1 ? data?.course_sessions_percentage + ' %' : 'N/A' : 'N/A') : (('attendance_percentage' in data) ? data?.attendance_percentage + ' %' : 'N/A');
    } else {
      return 'N/A';
    }
  }

  //json parse
  public jsonDecode(item) {
    return JSON.parse(item);
  }

  public textEditorChange(control: FormControl | AbstractControl, minLenth: number, maxLength: number) {
    if (this.removeHTML(control.value).length >= minLenth && this.removeHTML(control.value).length <= maxLength) {
      if (control.hasError('minlength') || control.hasError('maxlength')) {
        delete control.errors['minlength'];
        delete control.errors['maxlength'];
        control.setErrors({ required: true });
      }
    } else if (!this.removeHTML(control.value).length) { //if zero length
      if (control.hasError('minlength') || control.hasError('maxlength')) {
        delete control.errors['minlength'];
        delete control.errors['maxlength'];
        control.setErrors({ required: true });
      }
    } else {
      control.setErrors({ minlength: true, maxlength: true });
    }
  }
  //remove html tags to calculate min max length of description
  public removeHTML(str) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
  }

  //get public url form private url
  public getPublicUrl(data: any) {
    return this.http.post(this.apiService.get("getPublicUrl"), data);
  }

  public async registerForeePayAsync(): Promise<any> {
    return new Promise((resolve, reject) => {
      const script: HTMLScriptElement = document.createElement("script");
      // script.type = "text/javascript";
      script.src = "https://checkout-sandbox.riteidentity.com/checkout.js​";
      // script.crossOrigin = "anonymous";
      script.async = true;
      script.onload = () => resolve(true);
      script.onerror = error => reject(error);
      document.head.appendChild(script);
    });
  }
}
