import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class StudyMaterialService {

  constructor(private httpService: HttpService, private apiService: ApiService) { }

  public getStudyMaterialMenu(): Observable<any> {
    return this.httpService.get(this.apiService.get("getStudentMaterialMenu"));
  }

  public getStudyMaterial(id:any): Observable<any> {
    return this.httpService.get(`${this.apiService.get("getStudentMaterial")}${id}`);
  }
}
