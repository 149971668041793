<div class="modal-header pt-0 pb-0 pr-0">
  <button type="button" class="close" (click)="closeModal()" aria-label="Close">
    <span aria-hidden="true">
      <img src="assets/images/close.png" />
    </span>
  </button>
</div>
<div class="modal-body">
  <div class="add-new-test-box">
    <h3 class="ml-4">Add New Test</h3>
    <div class="add-new-test">
      <div class="title-pane bg-danger d-flex justify-content-between align-items-center flex-wrap">
        <span>Details</span>
        <div>
          <span class="pr-3">Total Questions:
            {{ totalQuestions || 0 }} &nbsp;&nbsp;&nbsp;|</span>
          <span>Total Points: {{ totalMarks || 0 }}</span>
        </div>
      </div>
      <form [formGroup]="testInfoFormGroup">
        <div class="row test-detail">
          <div class="col-lg-6">
            <div class="info">
              <div class="form-group form-label-group">
                <input type="text" placeholder="Title" id="" formControlName="title" aria-describedby=""
                  ng-reflect-name="points" class="form-control question-field" />
                <label class="text-primary">Test Title</label>
                <span class="text-white d-block font-smaller error-text" *ngIf="
                    testInfoFormControls.title.touched &&
                    testInfoFormControls.title.errors?.required
                  ">
                  Please enter test title.
                </span>
                <span class="text-danger d-block error-text error-text-question" *ngIf="
                    testInfoFormControls.title
                      ?.touched &&
                    (testInfoFormControls.title?.errors
                      ?.maxlength ||
                      testInfoFormControls.title
                        ?.errors?.minlength) &&
                    !testInfoFormControls.title?.errors
                      ?.pattern
                  ">
                  Title must be between 1 to 250 characters.
                </span>
                <span class="text-danger d-block error-text error-text-question" *ngIf="
                    testInfoFormControls.title.touched &&
                    testInfoFormControls.title.errors?.title
                  ">
                  Title is already taken.
                </span>
                <span class="text-white d-block font-smaller error-text" *ngIf="
                    testInfoFormControls.title.touched &&
                    testInfoFormControls.title.errors?.pattern
                  ">
                  Please provide a valid test title.
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="info">
              <div class="form-group form-label-group">
                <input type="text" class="form-control question-field" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1"
                  formControlName="due_date" [min]="minDate" [max]="maxDate" (keydown.backspace)="false"
                  (keypress)="keypressEvent($event)" placeholder="Due Date" />
                <label class="text-primary">Due Date</label>
                <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                <span class="text-white d-block font-smaller error-text" *ngIf="
                    testInfoFormControls.due_date.touched &&
                    testInfoFormControls.due_date.errors?.required
                  ">
                  Please select due date.
                </span>
                <span class="text-danger d-block error-text error-text-question" *ngIf="
                    testInfoFormControls.due_date.touched &&
                    testInfoFormControls.due_date.errors?.due_date
                  ">
                  {{ testInfoFormControls.due_date.errors?.due_date }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="info">
              <div class="form-group form-label-group">
                <input type="text" placeholder="Duration" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2"
                  formControlName="duration" [max]="timePickerMaxTime" [min]="timePickerMinTime"
                  (keydown.backspace)="false" (keypress)="keypressEvent($event)" class="form-control question-field"
                  #duration />
                <label class="text-primary">Duration</label>
                <owl-date-time [pickerType]="'timer'" [stepMinute]="5" [startAt]="timePickerStartAt"
                  [hour12Timer]="false" #dt2 (keypress)="(false)" (afterPickerClosed)="updateStartAtView()">
                </owl-date-time>
                <span class="text-white d-block font-smaller error-text" *ngIf="
                    testInfoFormControls.duration.touched &&
                    testInfoFormControls.duration.errors?.required
                  ">
                  Please select duration.
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="questionTypeFormGroup">
        <div class="select-pane">
          <h6 class="text-primary font-light pl-2">
            Select Question Type and Point
          </h6>
          <div class="d-flex mt-3 flex-wrap align-items-start">
            <div class="select-box mr-3 mt-2">
              <select class="form-control" [ngClass]="{ 'cursor-pointer': !showQuestionStructure }"
                formControlName="type" [attr.disabled]="showQuestionStructure ? true : null">
                <option [ngValue]="null">Select Question Type</option>
                <option *ngFor="let questionType of questionTypeList" value="{{ questionType.value }}">
                  {{ questionType.name }}
                </option>
              </select>
            </div>

            <div class="input-box mr-3 mt-2">
              <div class="form-group input-group">
                <input type="text" placeholder="- - -" formControlName="points" id="fpoints"
                  (keypress)="checkPointsInput($event)"
                  class="form-control points-field ng-untouched ng-pristine ng-invalid" ng-reflect-name="points" />
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">(Points)</span>
                </div>

              </div>
              <span class="d-block font-smaller text-danger" *ngIf="
                  questionTypeFormControls.points.touched &&
                  questionTypeFormControls.points.errors?.pattern
                ">
                Please provide valid points.
              </span>
              <span class="d-block font-smaller text-danger" *ngIf="
                  questionTypeFormControls.points.touched &&
                  questionTypeFormControls.points.errors?.max
                ">
                Points should be less than 100.
              </span>
            </div>

            <!-- <div class="form-group mb-0 mr-3 mt-2">
              <input type="number" placeholder="- - -" formControlName="points" id="fpoints"
              (keypress)="checkPointsInput($event)"
              class="form-control points-field ng-untouched ng-pristine ng-invalid" ng-reflect-name="points" />
                  <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">(Points)</span>
                </div>
              <span class="d-block font-smaller text-danger" *ngIf="
                  questionTypeFormControls.points.touched &&
                  questionTypeFormControls.points.errors?.pattern
                "
              >
                Please provide valid points.
              </span>
              <span class="d-block font-smaller text-danger" *ngIf="
                  questionTypeFormControls.points.touched &&
                  questionTypeFormControls.points.errors?.max
                "
              >
                Points should be less than 100.
              </span>
            </div> -->
            <button class="btn btn-test-point cursor-pointer mt-2"
              [ngClass]="{ 'disabled-pointer': questionTypeFormGroup.invalid }" (click)="onQuestionTypeFormSubmit()"
              [attr.disabled]="showQuestionStructure ? true : null">
              <img src="../../../../../../assets/images/right-arrow.png" alt="" />
            </button>
          </div>
        </div>
      </form>
      <div class="question-pane" *ngIf="questionList?.length">
        <div class="title-pane pr-0 py-0" *ngIf="showQuestionStructure">
          <h6 class="text-primary">Question</h6>
          <div>
            <!-- <a class="btn btn-outline-primary mr-1 btn-add-qustn" (click)="onAddNewQuestion()"
              *ngIf="currentQuestion.id">
              <span class="icon mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="15.64" height="15.64" viewBox="0 0 15.64 15.64">
                  <path id="Icon_ionic-md-add-circle" data-name="Icon ionic-md-add-circle"
                    d="M11.2,3.375a7.82,7.82,0,1,0,7.82,7.82A7.82,7.82,0,0,0,11.2,3.375Zm4.023,8.61H11.985v3.233H10.405V11.985H7.172V10.405h3.233V7.172h1.579v3.233h3.233Z"
                    transform="translate(-3.375 -3.375)" />
                </svg>
              </span>
              Add New Question
            </a> -->

          </div>
        </div>
        <div class="question" *ngFor="
            let question of questionList
              | paginate: { itemsPerPage: 1, currentPage: currentPageNo };
            let k = index
          ">
          <div *ngIf="showQuestionStructure">
            <form [formGroup]="multipleChoiceQuestionFormGroup">
              <div class="form-group">
                <input hidden type="text" placeholder="Question Title" formControlName="question"
                  ng-reflect-name="points" class="form-control question-field test-question-field" />
                  <p class="form-control question-field test-question-field" [appMath]="multipleChoiceQuestionFormGroup.controls.question.value"
                  *ngIf="multipleChoiceQuestionFormGroup.controls.question.value; else question_placeholder" (click)="openTextEditor(multipleChoiceQuestionFormGroup?.controls?.question, 'Question Title')"></p>
                <ng-template #question_placeholder><p class="placeholder" (click)="openTextEditor(multipleChoiceQuestionFormGroup?.controls?.question, 'Question Title')">Question Title</p>
                </ng-template>
                <!-- <div class="ck-editor-parent-test-component">
                  <ckeditor [config]="{
                    toolbar: [
                    'MathType',
                    'ChemType'
                    ],
                    placeholder:'Question Title'
                  }" [editor]="Editor"
                    class="form-control question-field test-question-field" formControlName="question"></ckeditor>
                </div> -->
                <span class="ml-5 text-danger d-block error-text" *ngIf="
                    multipleChoiceQuestionFormGroup?.controls?.question
                      ?.touched &&
                    multipleChoiceQuestionFormGroup?.controls?.question?.errors
                      ?.required
                  ">
                  Please provide question title.
                </span>
                <span class="text-danger d-block error-text error-text-question" *ngIf="
                    multipleChoiceQuestionFormGroup?.controls?.question
                      ?.touched &&
                    (multipleChoiceQuestionFormGroup?.controls?.question?.errors
                      ?.maxlength ||
                      multipleChoiceQuestionFormGroup?.controls?.question
                        ?.errors?.minlength) &&
                    !multipleChoiceQuestionFormGroup?.controls?.question?.errors
                      ?.pattern
                  ">
                  Question title must be between 4 to 50 characters.
                </span>
                <span class="text-danger ml-3 d-block error-text font-smaller" *ngIf="
                multipleChoiceQuestionFormGroup?.controls?.question.touched &&
                multipleChoiceQuestionFormGroup?.controls?.question.errors?.question
                ">
                  {{multipleChoiceQuestionFormGroup?.controls?.question.errors?.question}}
                </span>
                <span class="text-danger d-block error-text error-text-question" *ngIf="
                    multipleChoiceQuestionFormGroup?.controls?.question
                      ?.touched &&
                    multipleChoiceQuestionFormGroup?.controls?.question?.errors
                      ?.pattern
                  ">
                  Please provide a valid question title.
                </span>
                <span class="question-no"> {{ currentPageNo }} </span>
                <div class="field-actions">
                  <button class="btn-open-editor" title="Open Text Editor"
                    (click)="openTextEditor(multipleChoiceQuestionFormGroup?.controls?.question, 'Question Title')">
                    <fa-icon class="mr-1" [icon]='["fas", "align-center"]'></fa-icon>
                  </button>
                  <button class="btn-reset" title="Delete Question" (click)="deleteQuestion(question, currentPageNo)">
                    <img src="../../../../../../assets/images/Icon feather-trash-2.svg" alt="" />
                  </button>
                </div>
              </div>
              <div class="add-option-pane">
                <h6 class="text-primary">Responses</h6>
                <a (click)="onOptionAdd(currentPageNo - 1)" *ngIf="question.type === 'Multiple Choice'"
                  class="add text-primary cursor-pointer"
                  [ngClass]="{ 'disabled-link': question.choices.length === 4 }">
                  <span class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.64" height="15.64" viewBox="0 0 15.64 15.64">
                      <path id="Icon_ionic-md-add-circle" data-name="Icon ionic-md-add-circle"
                        d="M11.2,3.375a7.82,7.82,0,1,0,7.82,7.82A7.82,7.82,0,0,0,11.2,3.375Zm4.023,8.61H11.985v3.233H10.405V11.985H7.172V10.405h3.233V7.172h1.579v3.233h3.233Z"
                        transform="translate(-3.375 -3.375)" style="fill: inherit;" />
                    </svg>
                  </span>
                  <span>Add Response</span>
                </a>
              </div>
              <div class="row" formArrayName="choices">
                <div class="col-lg-6" *ngFor="let choice of choices.controls; let i = index">
                  <div class="form-group">
                    <input hidden type="text" placeholder="{{ 'Option ' + (i + 1) }}" formControlName="{{ i }}"
                      class="form-control question-field" />
                    <p class="form-control question-field" [appMath]="choices.controls[i].value"
                      *ngIf="choices.controls[i].value; else placeholder" (click)="openTextEditor(choices.controls[i], 'Option '+ (i+1), question.type === 'Multiple Choice')"></p>
                    <ng-template #placeholder><p class="placeholder" (click)="openTextEditor(choices.controls[i], 'Option '+ (i+1), question.type === 'Multiple Choice')">{{ 'Option ' + (i + 1) }}</p>
                    </ng-template>
                    <div class="field-actions">
                      <button class="btn-open-editor" *ngIf="question.type === 'Multiple Choice'"
                        title="Open Text Editor" (click)="openTextEditor(choices.controls[i], 'Option '+ (i+1))">
                        <fa-icon class="mr-1" [icon]='["fas", "align-center"]'></fa-icon>
                      </button>
                      <div class="check-elem">
                        <div class="form-check">
                          <input class="form-check-input" type="radio" [formControl]="
                              multipleChoiceQuestionFormGroup.controls
                                .correct_choice_index
                            " value="{{ i }}" [attr.name]="
                              multipleChoiceQuestionFormGroup.controls
                                .correct_choice_index
                            " [attr.id]="'question_' + k + '_' + i" />
                          <label class="form-check-label" [attr.for]="'question_' + k + '_' + i">
                          </label>
                          <!-- <label>
                            <input type="radio"
                              [formControl]="multipleChoiceQuestionFormGroup.controls.correct_choice_index">abc
                          </label> -->

                          <!-- <span
                            class="text-danger d-block error-text"
                            *ngIf="
                              multipleChoiceQuestionFormGroup?.controls?.title?.touched &&
                              multipleChoiceQuestionFormGroup?.controls?.title?.errors?.required
                            "
                          >
                            Please provide question title.
                          </span> -->
                        </div>
                      </div>
                      <button class="btn-reset" *ngIf="question.type === 'Multiple Choice'" title="Delete Response"
                        (click)="onOptionDelete(currentPageNo - 1, i)">
                        <img src="../../../../../../assets/images/Icon feather-trash-2.svg" alt="" />
                      </button>
                      <span class="text-danger ml-5 d-block error-text" *ngIf="
                          multipleChoiceQuestionFormGroup?.controls?.choices.get(
                            i.toString()
                          )?.touched &&
                          multipleChoiceQuestionFormGroup?.controls?.choices.get(
                            i.toString()
                          )?.errors?.required
                        ">
                        Please provide option title.
                      </span>
                      <span class="text-danger ml-5 d-block error-text" *ngIf="
                        multipleChoiceQuestionFormGroup?.controls?.choices.get(
                          i.toString()
                        )?.touched &&
                        multipleChoiceQuestionFormGroup?.controls?.choices.get(
                          i.toString()
                        )?.errors?.pattern
                        ">
                        Please provide a valid option.
                      </span>
                      <span class="text-danger ml-5 d-block error-text" *ngIf="
                      multipleChoiceQuestionFormGroup?.controls?.choices.get(
                        i.toString()
                      )?.touched &&
                      multipleChoiceQuestionFormGroup?.controls?.choices.get(
                        i.toString()
                      )?.errors?.duplicateChoice
                      ">
                        Please enter unique options.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="submit-pane">
            <div class="row">
              <div class="col-12">
                <div class="pagination-pane" *ngIf="showQuestionStructure">
                  <!-- (pageChange)="currentPageNo = $event" -->
                  <pagination-controls (pageChange)="onPageNoChange($event)"></pagination-controls>
                </div>
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="">
                <a class="btn btn-outline-primary btn-add-qustn mt-2" *ngIf="showQuestionStructure"
                  (click)="onQuestionSubmit()">
                  <!-- <span class="icon mr-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.64" height="15.64" viewBox="0 0 15.64 15.64">
                      <path id="Icon_ionic-md-add-circle" data-name="Icon ionic-md-add-circle"
                        d="M11.2,3.375a7.82,7.82,0,1,0,7.82,7.82A7.82,7.82,0,0,0,11.2,3.375Zm4.023,8.61H11.985v3.233H10.405V11.985H7.172V10.405h3.233V7.172h1.579v3.233h3.233Z"
                        transform="translate(-3.375 -3.375)" />
                    </svg>
                  </span> -->
                  Save & add new question
                </a>
              </div>
              <div class="">
                <div class="btn-pane">
                  <button type="submit" class="btn btn-gray-fill mr-2 mt-2" (click)="onTestInfoSave(false)">
                    Save as Draft
                  </button>
                  <button type="submit" class="btn btn-gradient mt-2" (click)="onTestInfoSave(true)">
                    Save & Publish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>