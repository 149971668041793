<section>
    <div class="faqs-content">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="content">
                        <h2 class="title">
                            FAQs
                        </h2>
                        <div class="content">
                            <div id="accordion">
                                <div *ngFor="let faq of faqsArray; let i = index;">
                                    <div class="card" *ngIf="(i===1 && (!this.user || this.user && this.isStudent)) || i!==1">
                                        <div class="card-header">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse"
                                                    [attr.data-target]="'#'+'abcd'+faq.id" aria-expanded="true"
                                                    data-parent="#accordion" [attr.aria-controls]="'abcd'+faq.id">
                                                    {{faq.question}}
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="{{'abcd'+faq.id}}" class="collapse" aria-labelledby="headingOne"
                                            data-parent="#accordion">
                                            <div class="card-body" *ngIf="i!==1 && i!==7 && i!==9">
                                                {{faq.answer}}
                                            </div>
                                            <div class="card-body"
                                                *ngIf="i===1">
                                                Please click on this <a [routerLink]="courseRoute">link</a> to find
                                                relevant
                                                details of the classes being offered.
                                            </div>
                                            <div class="card-body" *ngIf="i===7">
                                                Please check out this <a [routerLink]="teachersRoute">link</a> to see
                                                details
                                                regarding our teachers.
                                            </div>
                                            <div class="card-body" *ngIf="i===9">
                                                You can view our refund policy <a
                                                    [routerLink]="privacyPolicyRoute">here</a>.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>