import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "./api.service";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class LandingPageService {
  constructor(
    private httpService: HttpService,
    private apiService: ApiService
  ) {}

  //course plans
  public getCoursePlans(): Observable<any> {
    return this.httpService.get(this.apiService.get("getCoursePlans"));
  }

  ///get available classes
  public getAvailableClasses(): Observable<any> {
    return this.httpService.get(this.apiService.get("getAvailableClasses"));
  }

  //get top rating instructors
  public getTopRatedInstructors(): Observable<any> {
    return this.httpService.get(this.apiService.get("getTopRatedInstructors"));
  }

  //get instructors list
  public getInstructorsList(queryParams: any) {
    return this.httpService.get(
      `${this.apiService.get("getInstructorsList")}`,
      queryParams
    );
  }

  //get grades || subjects as string
  public getString(
    instructor: any,
    subjectsList?: boolean,
    addComma: boolean = true
  ): string {
    if (instructor && instructor.instructor_profile) {
      let list = subjectsList
        ? instructor.instructor_subjects
        : instructor.instructor_grades;
      const gradeNames: string[] = list.map(
        (s: any) => {
          let name: string;
          name = `${subjectsList ? s.subject_name : s.grade_name}`;
          return name;
        }
      );
      return addComma ? gradeNames.join(", ") : gradeNames.join(" ");
    }

    return "";
  }

  public contactUs(data:any, callerDemo:boolean): Observable<any> {
    return this.httpService.post(this.apiService.get(callerDemo ? "requestDemo":"contactUs"), data);
  }
}
