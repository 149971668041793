import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { GradeService } from "src/app/services/grade.service";
import { SubjectService } from "src/app/services/subject.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";
import { ListService } from "src/app/services/list.service";

@Component({
  selector: "app-search-box",
  templateUrl: "./search-box.component.html",
  styleUrls: ["./search-box.component.scss"],
})
export class SearchBoxComponent implements OnInit, OnChanges {
  @Input() callerTuition: boolean = false;
  @Input() callerLandingPageTeachers: boolean = false;
  @Input() callerDashboard: boolean = false;
  @Output() searchEvent: EventEmitter<any> = new EventEmitter();
  public showFilters: boolean = false;
  public gradeList: any = [];
  public subjectList: any = [];
  public user: any;
  public experienceList: any = [];
  public qualificationList: any = [];
  public monthsList: any = [
    { number: 1, name: "January" },
    { number: 2, name: "February" },
    { number: 3, name: "March" },
    { number: 4, name: "April" },
    { number: 5, name: "May" },
    { number: 6, name: "June" },
    { number: 7, name: "July" },
    { number: 8, name: "August" },
    { number: 9, name: "September" },
    { number: 10, name: "October" },
    { number: 11, name: "November" },
    { number: 12, name: "December" },
  ];
  public timeList: any = [
    { time: "null", time_bracket: "All" },
    { time: 0, time_bracket: "12 AM - 1  AM" },
    { time: 1, time_bracket: "1 AM - 2 AM" },
    { time: 2, time_bracket: "2 AM - 3 AM" },
    { time: 3, time_bracket: "3 AM - 4 AM" },
    { time: 4, time_bracket: "4 AM - 5 AM" },
    { time: 5, time_bracket: "5 AM - 6 AM" },
    { time: 6, time_bracket: "6 AM - 7 AM" },
    { time: 7, time_bracket: "7 AM - 8 AM" },
    { time: 8, time_bracket: "8 AM - 9 AM" },
    { time: 9, time_bracket: "9 AM - 10 AM" },
    { time: 10, time_bracket: "10 AM - 11 AM" },
    { time: 11, time_bracket: "11 AM - 12 PM" },
    { time: 12, time_bracket: "12 PM - 1 PM" },
    { time: 13, time_bracket: "1 PM - 2 PM" },
    { time: 14, time_bracket: "2 PM - 3 PM" },
    { time: 15, time_bracket: "3 PM - 4 PM" },
    { time: 16, time_bracket: "4 PM - 5 PM" },
    { time: 17, time_bracket: "5 PM - 6 PM" },
    { time: 18, time_bracket: "6 PM - 7 PM" },
    { time: 19, time_bracket: "7 PM - 8 PM" },
    { time: 20, time_bracket: "8 PM - 9 PM" },
    { time: 21, time_bracket: "9 PM - 10 PM" },
    { time: 22, time_bracket: "10 PM - 11 PM" },
    { time: 23, time_bracket: "11 PM - 12 PM" },
  ];
  public searchFormGroup: FormGroup = new FormGroup({
    grade: new FormControl(null),
    subject: new FormControl(null),
  });

  constructor(
    private gradeService: GradeService,
    private subjectService: SubjectService,
    private authService: AuthService,
    private listService: ListService
  ) { }

  ngOnInit(): void { }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      simpleChanges.callerLandingPage ||
      simpleChanges.callerTuition
    ) {
      this.getGradesList();
      this.getSubjectsList();

      //if caller is one on one tuition list add addition control
      if (this.callerLandingPageTeachers) {
        this.addFormControl("qualification");
        this.addFormControl("experience");
        this.getExperienceList();
        this.getQualificationList();
      } else {
        this.addFormControl("month");
        this.addFormControl("keyword");
        if (this.callerDashboard) {
          this.user = this.authService.getCurrentUser();
          this.searchFormGroup.controls.grade.setValue(this.user?.student_profile?.grade_id || null)
        }
        if (this.callerTuition) {
          this.addFormControl("time");
        }
      }

      //show filters as all form controls are initiallized
      this.showFilters = true;
    }
  }

  //add form control
  public addFormControl(controlName: string) {
    this.searchFormGroup.addControl(controlName, new FormControl(null));
  }

  //fetch subjects list
  public getSubjectsList() {
    this.subjectService.getSubjectsList().subscribe((response) => {
      this.subjectList = response.data.subjects;
    });
  }

  //ge experience list
  public getExperienceList() {
    this.experienceList = this.listService.getExperienceList();
  }

  //get qualifications list from api
  public getQualificationList() {
    this.listService.getQualificationsList().subscribe((response) => {
      this.qualificationList = response.data.qualifications;
    });
  }

  //fetch grade list
  public getGradesList() {
    this.gradeService.getGrades().subscribe((response) => {
      this.gradeList = response.data.grades;
      this.searchFormGroup.controls.grade.setValue(
        this.callerLandingPageTeachers ? null : this.callerDashboard ? this.user?.student_profile?.grade_id || null : null
      );
    });
  }

  //emit event to the caller component
  public onSubmit() {
    if (
      !this.callerLandingPageTeachers &&
      this.SearchBoxControls.keyword.value === ""
    ) {
      this.SearchBoxControls.keyword.setValue(null);
    }
    this.searchEvent.emit(this.searchFormGroup.value);
  }
  //search form controls
  get SearchBoxControls() {
    return this.searchFormGroup.controls;
  }
  //reset the advance search filter to initial state
  public resetSearchFilters(event) {
    event.preventDefault();
    this.searchFormGroup.reset();
    this.SearchBoxControls.grade.setValue(
      this.callerDashboard ? this.user?.student_profile?.grade_id || null : null
    );
    if (this.callerLandingPageTeachers || !this.callerDashboard) {
      this.searchEvent.emit(this.searchFormGroup.value);
    }

  }
}
