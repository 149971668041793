import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CartService } from 'src/app/services/cart.service';
import { ModalService } from 'src/app/services/modal.service';
import { isObject } from 'util';

@Component({
  selector: 'app-checkout-parent',
  templateUrl: './checkout-parent.component.html',
  styleUrls: ['./checkout-parent.component.scss']
})
export class CheckoutParentComponent implements OnInit {
  //add to cart input
  @Input() course: any;
  //proceed to pay input
  @Input() courses: any;
  //payment step
  @Input() step: number;
  //order id
  @Input() orderId: number;
  constructor(private toastrService: ToastrService, private activeModal: NgbActiveModal, private modalService: ModalService, private cartService: CartService) { }

  ngOnInit(): void {
    this.updateStep(this.step);
  }


  public closeModal() {
    this.removeClass("max-width-modal");
    this.activeModal.close();
  }

  //update step on event emitter by child components
  public updateStep(step: any) {
    if (isObject(step)) {
      //if event emitted from proceed to pay
      this.step = step.number; //step = 3
      this.courses = step.courses; // navigate to success screen
    } else {
      this.step = step;
    }
    //toggle class depending upon modal
    if (this.step === 2) { // add full width class
      document.body.classList.add('max-width-modal');
    } else {
      this.removeClass("max-width-modal");
    }
  }

  //remove class if addded to body
  public removeClass(className: string) {
    if (document.body.classList.contains(className)) {
      document.body.classList.remove(className);
    }
  }
}
