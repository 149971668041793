import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ForgotPasswordComponent } from '../components/auth/forgot-password/forgot-password.component';
import { LoginComponent } from '../components/auth/login/login.component';
import { NewPasswordComponent } from '../components/auth/new-password/new-password.component';
import { OtpVerificationComponent } from '../components/auth/otp-verification/otp-verification.component';
import { RegistrationFinalStepComponent } from '../components/auth/registration-final-step/registration-final-step.component';
import { RegistrationComponent } from '../components/auth/registration/registration.component';
import { SocialMediaRegisterComponent } from '../components/auth/social-media-register/social-media-register.component';
import { TeacherRegistrationFinalStepComponent } from '../components/auth/teacher-registration-final-step/teacher-registration-final-step.component';
import { MockTestModalComponent } from '../components/landing-page/mock-test-modal/mock-test-modal.component';
import { ConfirmationModalComponent } from '../components/shared/confirmation-modal/confirmation-modal.component';
import { SuccessModalComponent } from '../components/shared/success-modal/success-modal.component';
import { CheckoutParentComponent } from '../components/student-dashboard/enroll-modals/checkout-parent/checkout-parent.component';
import { HomeworkFeedbackComponent } from '../components/student-dashboard/homework-feedback/homework-feedback.component';
import { NotesComponent } from '../components/student-dashboard/login-student-dashboard/notes/notes.component';
import { RatingModalComponent } from '../components/student-dashboard/login-student-dashboard/session-details/rating-modal/rating-modal.component';
import { TeacherFeedbackComponent } from '../components/student-dashboard/login-student-dashboard/teacher-feedback/teacher-feedback.component';
import { TestAttemptModalComponent } from '../components/student-dashboard/login-student-dashboard/test-details/test-attempt-modal/test-attempt-modal.component';
import { VideoPlayerComponent } from '../components/student-dashboard/login-student-dashboard/video-player/video-player.component';
import { ChangePasswordModalComponent } from '../components/student-dashboard/my-profile/change-password-modal/change-password-modal.component';
import { UploadHomeworkComponent } from '../components/student-dashboard/upload-homework/upload-homework.component';
import { AddNewHomeWorkComponent } from '../components/teacher-dashboard/class-detail/add-new-home-work/add-new-home-work.component';
import { AddNewTestComponent } from '../components/teacher-dashboard/class-detail/test-details/add-new-test/add-new-test.component';
import { MathTextEditorComponent } from '../components/teacher-dashboard/class-detail/test-details/add-new-test/math-text-editor/math-text-editor.component';
import { HomeworkFeedbackComponent as TeacherHomeworkFeedbackModal } from '../components/teacher-dashboard/modals/homework-feedback/homework-feedback.component';
import { SessionFeedbackComponent } from '../components/teacher-dashboard/modals/session-feedback/session-feedback.component';
import { UploadNotesComponent } from '../components/teacher-dashboard/modals/upload-notes/upload-notes.component';
import { UtilityService } from './utility.service';
@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public confrimModalOpen: boolean = false;
  public teacherSyllabusNotes: boolean = false;
  public isTestAttemptOpen: boolean;
  constructor(private modalService: NgbModal, private ngbModalConfig: NgbModalConfig, private utilityService: UtilityService, private platformLocation: PlatformLocation) {
    // modalService.activeInstances.subscribe(instance=>{
    //   instance.forEach((element) => {
    //     let componentName:string = element?._contentRef?.componentRef?.componentType?.name || '';
    //       this.isTestAttemptOpen = (componentName ===  'TestAttemptModalComponent') ?  true : false;
    //       if(this.isTestAttemptOpen) return;
    //   });
    // });
    platformLocation.onPopState((state) => {
      if (this.isTestAttemptOpen) {
        platformLocation.forward();
      } else {
        modalService.dismissAll();
        // window.history.pushState(state, 'ok');
        // window.history.forward();
      }
    });

    // ngbModalConfig.backdrop = 'static';
    // ngbModalConfig.keyboard = false;
    let _self = this;
    NgbModalRef.prototype["c"] = NgbModalRef.prototype.close;
    NgbModalRef.prototype.close = async function (reason: string) {
      if (_self.confrimModalOpen) {
        _self.confrimModalOpen = false;
        this["c"](reason);
        Promise.resolve();
      } else if (_self.teacherSyllabusNotes) {
        _self.teacherSyllabusNotes = false;
        document.querySelector("ngb-modal-window")?.classList?.add("modal-close-fade");
        setTimeout(() => {
          this["c"](reason);
          Promise.resolve();
        }, 200);
      }
      else {
        document.querySelector("ngb-modal-window")?.classList?.add("modal-close");
        setTimeout(() => {
          this["c"](reason);
          Promise.resolve();
        }, 400);
      }
    };


    NgbModalRef.prototype["d"] = NgbModalRef.prototype.dismiss;
    NgbModalRef.prototype.dismiss = function (reason: string) {
      document.querySelector("ngb-modal-window")?.classList?.add("modal-close");
      document.querySelector(".modal-backdrop").classList.add("modal-close");
      document.querySelector(".modal").classList.add("modal-close");
      setTimeout(() => {
        this["d"](reason);
      }, 400);
    };
  }

  //override ngbModal open method to open method
  public open(modalNumber: number, modalType: number, config: any = { backdrop: 'static', static: true, keyboard: false, ignoreBackdropClick: true }) {
    let modal = this.modalService.open(this.getModalFromEnum(modalNumber), config);
    let instance = (modal as any)._windowCmptRef.instance;
    setTimeout(() => {
      switch (modalType) {
        case 1:
          instance.windowClass = 'auth-modals';
          this.confrimModalOpen = false;
          break;
        case 2:
          instance.windowClass = 'enroll-modals';
          this.confrimModalOpen = false;
          break;
        case 3:
          instance.windowClass = 'confirm-modals';
          this.confrimModalOpen = true;
          break;
        case 4:
          instance.windowClass = 'teacher-session-modal';
          this.confrimModalOpen = false;
          break;
        case 5:
          instance.windowClass = 'notes-modal';
          this.confrimModalOpen = true;
          break;
        case 6:
          instance.windowClass = 'video-modal';
          this.confrimModalOpen = false;
          break;
        case 7:
          instance.windowClass = 'syllabus-modal';
          this.teacherSyllabusNotes = true;
          break;
        case 8:
          instance.windowClass = 'session-notes-modal';
          this.teacherSyllabusNotes = true;
          break;
        case 9:
          instance.windowClass = 'feedback-modal';
          this.teacherSyllabusNotes = true;
          break;
        case 10:
          instance.windowClass = 'homework-modal';
          this.confrimModalOpen = false;
          break;
        case 11:
          instance.windowClass = 'test-modal';
          this.confrimModalOpen = false;
          break;
        case 12:
          instance.windowClass = 'rating-modal';
          this.confrimModalOpen = false;
          break;
      }
    }, 0);
    return modal;
  }

  //get confirmation
  public async getSuccessModal(title: string, message: string, callerTeacherRegistration: boolean = false) {
    const modalRef = this.open(9, 1, { size: 'sm', backdrop: 'static', static: true, keyboard: false }); //9 for success modal
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.callerTeacherRegistration = callerTeacherRegistration;
    const confirmationResult = await modalRef.result.then(result => { return result });
    return confirmationResult ? true : false;
  }

  // get modal from number to remove circular dependencies
  public getModalFromEnum(modalNumber: number) {
    this.isTestAttemptOpen = (modalNumber === 24 || modalNumber === 26) ? true : false;
    console.log(modalNumber, this.isTestAttemptOpen);
    switch (modalNumber) {
      case 1:
        return LoginComponent;
        break;
      case 2:
        return RegistrationComponent;
        break;
      case 3:
        return ForgotPasswordComponent;
        break;
      case 4:
        return SocialMediaRegisterComponent;
        break;
      case 5:
        return NewPasswordComponent;
        break;
      case 6:
        return OtpVerificationComponent;
        break;
      case 7:
        return RegistrationFinalStepComponent;
        break;
      case 8:
        return ChangePasswordModalComponent;
        break;
      case 9:
        return SuccessModalComponent;
        break;
      case 10:
        return CheckoutParentComponent;
        break;
      case 11:
        // return TeacherLoginComponent;
        break;
      case 12:
        return ConfirmationModalComponent;
        break;
      case 13:
        return TeacherFeedbackComponent;
        break;
      case 14:
        return NotesComponent;
        break;
      case 15:
        return VideoPlayerComponent;
        break;
      case 16:
        return TeacherRegistrationFinalStepComponent;
        break;
      case 17:
        return SessionFeedbackComponent;
        break;
      case 18:
        return TeacherHomeworkFeedbackModal;
        break;
      case 19:
        return UploadNotesComponent;
        break;
      case 20:
        return AddNewHomeWorkComponent;
        break;
      case 21:
        return UploadHomeworkComponent; //student homework submission
        break;
      case 22:
        return HomeworkFeedbackComponent; //student homework feedback
        break;
      case 23:
        return AddNewTestComponent;
        break;
      case 24:
        return TestAttemptModalComponent;
        break;
      case 25:
        return RatingModalComponent;
        break;
      case 26:
        return MockTestModalComponent;
        break;
      case 27:
        return MathTextEditorComponent;
        break;
    }
  }

}
