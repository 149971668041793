import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationFinalStepComponent } from '../registration-final-step/registration-final-step.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NewPasswordComponent } from '../new-password/new-password.component';
import { AuthService } from 'src/app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/app/services/modal.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss'],
})
export class OtpVerificationComponent implements OnInit {
  @Input() caller: string; // which component called this component
  @Input() email: string; //it is  email
  @Input() phoneNo: string; //it is  phone no
  @Output() resendOTPEvent: EventEmitter<any> = new EventEmitter();
  public title: string;
  //form instantiation
  public otpVerificationFormGroup: FormGroup = new FormGroup({
    'otp': new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(this.utilityService.otpRegex)]), //four digit otp password
  });

  constructor(
    private modalService: ModalService,
    private authService: AuthService,
    private activeModal: NgbActiveModal,
    private confirmDialogService: ConfirmationDialogService,
    private toastr: ToastrService,
    private utilityService: UtilityService
  ) {

  }

  ngOnInit(): void {
    if (this.caller === 'registration') {
      this.title = 'Continue Registration';
    } else {
      this.title = 'OTP'
    }
  }

  get otpVerificationFormControls() {
    return this.otpVerificationFormGroup.controls;
  }

  //registration final step Modal
  public openFinalStepModal() {
    //close active modal
    this.activeModal.close();
    //wait for closing model to complete animation
    setTimeout(() => { this.modalService.open(7, 1) }, 1000);
  }

  //new password Modal
  public openNewPasswordModal() {
    //close active modal
    this.activeModal.close();
    //wait for closing model to complete animation
    setTimeout(() => { this.modalService.open(5, 1) }, 1000);
  }

  public onSubmit() {
    ///if user tries to submit form without filling all field correctly
    this.otpVerificationFormGroup.markAllAsTouched();
    if (this.otpVerificationFormGroup.invalid) return;
    const data = {
      'otp': this.otpVerificationFormControls.otp.value,
    };

    if (this.caller === 'registration') {
      //modify data object according to caller component
      data['email'] = this.email;
      //open registration next step modal
      this.authService.verifyOTP(data, 'registration').subscribe(response => {
        //close active modal
        this.activeModal.close();
        let modalRef;
        //wait for closing model to complete animation
        setTimeout(() => {
          //next navigation determination whether student is registering or student
          let modalNumber: number = (this.utilityService.userType === 'student') ? 7 : 16
          modalRef = this.modalService.open(modalNumber, 1);
          //setting input properties for the next step
          modalRef.componentInstance.email = this.email;
        }, 1000);
      }, (error) => {
        this.otpVerificationFormGroup.reset();
      })
    } else {
      //modify data object according to caller component
      data['phone'] = this.phoneNo;
      //open new password modal
      this.authService.verifyOTP(data, 'forgotPassword').subscribe(response => {
        //close active modal
        this.activeModal.close();
        let modalRef;
        //wait for closing model to complete animation
        setTimeout(() => {
          modalRef = this.modalService.open(5, 1);
          //setting input properties for the next step
          modalRef.componentInstance.phone = this.phoneNo;
          modalRef.componentInstance.otp = data.otp;
        }, 1000);
      }, (error) => {
        this.otpVerificationFormGroup.reset();
      });
    }
  }
  //resend API call
  public resendOTP() {
    const data = {};
    if (this.caller === 'registration') {
      data['email'] = this.email;
      data['for'] = 'phone'; //phone because we are get otp after registration screen
    } else {
      data['phone'] = this.phoneNo;
      data['for'] = 'password'; //password because we are get otp after forgot password screen
    }
    this.authService.resendOTP(data).subscribe(response => {
      this.toastr.success(response.message, 'Success');
    })
  }



  //close modal
  public async closeModal() {
    const result = await this.confirmDialogService.getConfirmation('Exit', 'Are you sure you want to exit ?');
    if (result) {//if user select yes on confirmation
      this.activeModal.close();
    }
  }

  public checkNumber(number) {
    return this.utilityService.isNumber(number);
  }

}
