<div class="modal-header pb-0">
  <button type="button" class="close" (click)="closeModal()">
    <img _ngcontent-jcx-c114="" src="assets/images/close.png" alt="" />
  </button>
</div>
<div class="modal-body pt-1">
  <h5 class="modal-title" id="exampleModalLongTitle">
    {{title}}
  </h5>
  <form [formGroup]="feedbackFormGroup">
    <div class="feedback-text ck-editor-parent">
      <ckeditor [config]="{
            toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            'bulletedList',
            'numberedList',
            'indent',
            'undo',
            'redo',
            'MathType',
            'ChemType'
            ]
          }" [editor]="Editor" formControlName="feedbackFormControl" placeholder="Type your option here."></ckeditor>
          <!-- <div #editorElement></div> -->
        </div>
    <button type="button" (click)="onOptionUpdate()" class="btn btn-gradient btn-send">
      Done
    </button>
  </form>
</div>
<!-- <div class="modal-footer">
  
  </div> -->