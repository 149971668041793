import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  @Input() notesLink: string;
  @Input() fileName;
  public fileIcon: any;
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.getFileIcon();
  }

  closeModal() {
    this.activeModal.close()
  }
  public getFileIcon() {
    switch (this.fileName.split('.').pop()) {
      case 'docx':
        this.fileIcon = 'file-word';
        break;
      case 'doc':
        this.fileIcon = 'file-word';
        break;
      case 'pdf':
        this.fileIcon = 'file-pdf';
        break;
      case 'ppt':
        this.fileIcon = 'file-powerpoint';
        break;
      case 'pptx':
        this.fileIcon = 'file-powerpoint';
        break;

      default:
        break;
    }
  }
}
