import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TeacherService } from 'src/app/services/teacher.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { FormGroup, FormControl, Validators, RequiredValidator } from '@angular/forms';
import { UtilityService } from 'src/app/services/utility.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-add-new-home-work',
  templateUrl: './add-new-home-work.component.html',
  styleUrls: ['./add-new-home-work.component.scss'],
})
export class AddNewHomeWorkComponent implements OnInit {

  public date = moment();
  // public minDate =  new Date(2020, 9, 5, 0, 0);
  public minDate = new Date(this.date.get('year'), this.date.get('month'), this.date.get('D'), 0, 0);

  //homework id if openned in edit mode
  @Input() homeworkId: any;
  //class details to post along homework || die microservices :((((
  @Input() classDetails: any;
  @Input() editMode: boolean = false;
  public randFormId: string;
  //file icon to display with file name
  public fileIcon: string;
  //homework file
  public fileUrl: string;
  public fileName: string;
  public formData: FormData = new FormData();
  public homeworkFormGroup: FormGroup = new FormGroup({
    'title': new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(50), Validators.pattern(this.utilityService.leadingTrailingSpaces)]),
    'due_date': new FormControl(null),
    'total_marks': new FormControl(null, [Validators.minLength(1), Validators.maxLength(3), Validators.pattern(this.utilityService.numbersleadingTrailingSpaces)]),
    'homework_file': new FormControl(null),
  });
  //refresh homework list
  public onHomeworkAddedChanged: Subject<object> = new Subject<object>();

  //edit detail for edit mode
  public homework: any;
  constructor(
    private toastr: ToastrService,
    private activeModal: NgbActiveModal,
    private teacherService: TeacherService,
    private utilityService: UtilityService,
    private confirmDialogService: ConfirmationDialogService) { }


  ngOnInit(): void {
    //random form id
    this.randFormId = '_' + Math.random().toString(36).substr(2, 9);

    if (this.editMode) this.getHomeworkDetails(this.homeworkId);

    console.log(this.classDetails);
  }

  //close active modal
  public closeModal() {
    this.activeModal.close();
  }

  get formControls() {
    return this.homeworkFormGroup.controls;
  }

  //don't allow '-'
  public checkMarksInput(event) {
    //return only numbers
    return (event.charCode >= 48 && event.charCode <= 57);
  }


  // on homework form submit
  public onSubmit(doPublish: boolean) {
    //if user entered invalid data return
    if (doPublish) {
      this.formControls.due_date.setValidators([Validators.required]);
      this.formControls.due_date.updateValueAndValidity();
      this.formControls.total_marks.setValidators([Validators.required]);
      this.formControls.total_marks.updateValueAndValidity();
      if (this.editMode && this.fileUrl) this.formControls.homework_file.setValidators(null);
      else this.formControls.homework_file.setValidators([Validators.required]);
      this.formControls.homework_file.updateValueAndValidity();
    } else {
      this.formControls.due_date.setValidators(null);
      this.formControls.due_date.updateValueAndValidity();
      this.formControls.total_marks.setValidators([Validators.minLength(1), Validators.maxLength(5), Validators.pattern(this.utilityService.numbersleadingTrailingSpaces)]);
      this.formControls.total_marks.updateValueAndValidity();
      this.formControls.homework_file.setValidators(null);
      this.formControls.homework_file.updateValueAndValidity();
    }
    this.homeworkFormGroup.markAllAsTouched();
    if (!this.homeworkFormGroup.valid) return;
    const data: any = {
      "title": this.formControls.title.value,
      "due_date": this.formControls.due_date.value ? moment(this.formControls.due_date.value).format('YYYY-MM-DD') : null,
      "total_marks": this.formControls.total_marks.value,
      "course_id": this.classDetails.courses[0]?.id,
      "course_name": this.classDetails.courses[0]?.title,
      "class_id": this.classDetails.id,
      "class_name": this.classDetails.title,
      "subject_id": this.classDetails.subject.id,
      "subject_name": this.classDetails.subject.name,
      "published": doPublish ? 1 : 0,
      "assignment_instructions_file": this.fileUrl,
      "assignment_instructions_file_name": this.fileName
    };
    if (this.editMode) data.id = this.homeworkId;

    this.teacherService.createHomework(data).subscribe(response => {
      //refresh list in homework listing
      this.onHomeworkAddedChanged.next({ fetchList: true, updateStats: doPublish });
      //show success message
      this.toastr.success(this.editMode ? (doPublish ? 'Homework is published successfully.' : 'Homework is updated successfully.') : (doPublish ? 'Homework is published successfully.' : 'Homework is added successfully'), 'Success');
      this.activeModal.close();
    },
      (error) => {
        if (error.error.error_details.due_date) {
          this.formControls.due_date.setErrors({ due_date: error.error.error_details.due_date });
        }
      });
  }


  //on homework file selected post to repository
  public onHomeworkFileSelected(event: any) {
    let file = event.target.files[0];
    this.fileName = event.target.files[0].name;
    this.getFileIcon();
    if (!file) return;
    let fileType = file.type.toLowerCase();
    console.log(fileType,file.type);
    //check file type
    if (!this.utilityService.checkHomeworkFile(fileType)) {
      this.toastr.error('File type not supported.', 'Error');
      this.fileName = null;
      this.formControls.homework_file.reset();
      return;
    }
    let fileSize = Math.round(file.size / 4096);
    if (fileSize > 4096) {
      this.toastr.error('Please Select File Less than 4MB.', 'Error');
      this.formControls.file.reset();
      return;
    }
    const formData: FormData = new FormData();
    formData.append('upload_file', file, file.name);
    formData.append('upload_type', 'HOMEWORK_FILE');
    formData.append('rand_form_id', this.randFormId);

    if (this.editMode) formData.append('entity_id', this.classDetails.id);

    //send api call to upload instuctor resume
    this.teacherService.uploadHomeworkFile(formData).subscribe(response => {
      // // this.resumeUrl = response.data.resume_url;
      this.fileName = response.data.file_original_name;
      this.fileUrl = response.data.file_path;
    });
  }

  //remove selected sllabus file
  public async onFileRemove() {
    this.fileName = '';
    this.fileUrl = '';
    this.formControls.homework_file.reset();
    this.formControls.homework_file.setValidators([Validators.required]);
    this.formControls.homework_file.updateValueAndValidity();
  }

  //get homework details for editmode
  public getHomeworkDetails(homeworkId: any) {
    this.teacherService.getHomework(homeworkId).subscribe(response => {
      let homework = response.data;
      //populate form with all information
      this.formControls.title.setValue(homework.title);
      this.formControls.due_date.setValue(homework.due_date);
      this.formControls.total_marks.setValue(homework.total_marks);
      this.fileUrl = homework.assignment_instructions_file;
      this.fileName = homework.assignment_instructions_file_name;
      //reset file control validator
      this.formControls.homework_file.setValidators(null);
      this.formControls.homework_file.updateValueAndValidity();
    })
  }

  public getFileIcon() {
    switch (this.fileName.split('.').pop()) {
      case 'docx':
        this.fileIcon = 'file-word';
        break;
      case 'doc':
        this.fileIcon = 'file-word';
        break;
      case 'pdf':
        this.fileIcon = 'file-pdf';
        break;
      case 'ppt':
        this.fileIcon = 'file-powerpoint';
        break;
      case 'pptx':
        this.fileIcon = 'file-powerpoint';
        break;
      case 'xls':
        this.fileIcon = 'file-excel';
        break;
      case 'xlsx':
        this.fileIcon = 'file-excel';
        break;
      default:
        break;
    }
  }
}
