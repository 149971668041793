import { Component, OnInit } from "@angular/core";
import { LandingPageService } from "src/app/services/landing-page.service";

@Component({
  selector: "app-top-rated-teacher",
  templateUrl: "./top-rated-teacher.component.html",
  styleUrls: ["./top-rated-teacher.component.scss"],
})
export class TopRatedTeacherComponent implements OnInit {
  public topRatedInstructorsList: any[];
  constructor(private landingPageService: LandingPageService) {
    landingPageService.getTopRatedInstructors().subscribe((instructors) => {
      this.topRatedInstructorsList = instructors.data;
    });
  }

  ngOnInit(): void {}
  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: false,
    arrows: true,
    centerPadding: "10px",
    prevArrow:
      '<a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev"><img src="../../../../assets/images/arrow-left-circle.svg" alt=""></a>',
    nextArrow:
      '<a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next"><img src="../../../../assets/images/arrow-left-circle.svg" alt=""></a>',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: false,
          // centerPadding: '150px',
        },
      },
      {
        breakpoint: 993,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          // centerPadding: '150px',
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // centerPadding: '60px',
          // centerMode: true,
        },
      },
    ],
  };
}
