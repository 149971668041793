import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-session-details',
  templateUrl: './session-details.component.html',
  styleUrls: ['./session-details.component.scss']
})
export class SessionDetailsComponent implements OnInit {
  @Input() class: any;
  public classSessions: any[];
  public sessionsToDisplay: any[] = [];
  public showLoadMore: boolean = false;
  public showCompress: boolean = false;
  public totalSessions: number;
  public currentChunkOfSessions: number = 0;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.class.currentValue) {
      this.classSessions = this.class.class_sessions;
      this.getChunkedSessions();
    }
  }

  public getChunkedSessions() {
    this.currentChunkOfSessions += 6;
    if (
      this.classSessions.length > 6 &&
      this.currentChunkOfSessions < this.classSessions.length
    ) {
      this.showLoadMore = true;
      this.sessionsToDisplay = this.classSessions.slice(
        0,
        this.currentChunkOfSessions
      );
    } else {
      if (this.classSessions.length > 6) this.showCompress = true;
      this.showLoadMore = false;
      this.sessionsToDisplay = this.classSessions.slice(
        0,
        this.classSessions.length
      );
    }
  }

  public compressData() {
    this.currentChunkOfSessions = 0;
    this.showLoadMore = true;
    this.showCompress = false;
    this.getChunkedSessions();
  }

}
