import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GradeService } from 'src/app/services/grade.service';
import { BoardService } from 'src/app/services/board.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from 'src/app/services/utility.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoginComponent } from '../login/login.component';
import { ModalService } from 'src/app/services/modal.service';
import { async } from 'rxjs/internal/scheduler/async';

@Component({
  selector: 'app-registration-final-step',
  templateUrl: './registration-final-step.component.html',
  styleUrls: ['./registration-final-step.component.scss'],
})
export class RegistrationFinalStepComponent implements OnInit {
  @Input() email; //get email from previous registration step
  public boardsList = [];
  public gradesList = [];
  public registrationFinalStepFormGroup: FormGroup = new FormGroup({
    'grade': new FormControl(null, [Validators.required]),
    'board': new FormControl(null, [Validators.required]),
    'schoolName': new FormControl(null, [Validators.required, Validators.pattern(this.utilitySerive.leadingTrailingSpaces)]),
  });

  constructor(
    private modalService: ModalService,
    private gradesService: GradeService,
    private boardsService: BoardService,
    private userService: UserService,
    private router: Router,
    private toastr: ToastrService,
    private utilitySerive: UtilityService,
    private activeModal: NgbActiveModal,
    private confirmDialogService: ConfirmationDialogService,
    private authService: AuthService
  ) {
    this.gradesService.getGrades().subscribe(response => {
      this.gradesList = response.data.grades;
      // if (this.gradesList.length >= 1) {
      //   this.registrationFinalStepFormGroup.controls.grade.setValue(this.gradesList[0].id);
      // }
    });
    this.boardsService.getBoards().subscribe(response => {
      this.boardsList = response.data.boards;
      // if (this.boardsList.length >= 1) {
      //   this.registrationFinalStepFormGroup.controls.board.setValue(this.boardsList[0].id);
      // }
    });
  }

  get registrationFinalStepFormControl() {
    return this.registrationFinalStepFormGroup.controls;
  }

  ngOnInit(): void {
  }

  public onSubmit() {
    //if user submitted without entering required fields
    this.registrationFinalStepFormGroup.markAllAsTouched();
    if (this.registrationFinalStepFormGroup.invalid) return;
    const data = {
      'grade_id': this.registrationFinalStepFormControl.grade.value.id, // grade id
      'board_id': this.registrationFinalStepFormControl.board.value.id, //board id
      'grade_name': this.registrationFinalStepFormControl.grade.value.name, // grade name
      'board_name': this.registrationFinalStepFormControl.board.value.name, //board name
      'school_name': this.registrationFinalStepFormControl.schoolName.value, //school name
      'email': this.email
    };
    this.userService.registerUserProfile(data).subscribe(async (response) => {
      //close active modal
      this.activeModal.close();
      await setTimeout(async () => {
        await this.modalService.getSuccessModal('Thanks For Registering', 'Registeration Completed');
        //set current user and token in local storage
        if (this.utilitySerive.getSocialMediaRegistrationFlag()) {
          if (this.utilitySerive.socialMediaType === 'facebook') {
            this.authService.loginUserFacebook(this.utilitySerive.socialMediaUserData).subscribe(response => {
              //redirect to dashboard if user is already registered
              //set current user and token in local storage
              this.authService.setUserDataToken(response.body);
              //close active modal
              this.activeModal.close();
            });
          } else {
            this.authService.loginUserGoogle(this.utilitySerive.socialMediaUserData).subscribe(response => {
              //redirect to dashboard if user is already registered
              //set current user and token in local storage
              this.authService.setUserDataToken(response.body);
              //close active modal
              this.activeModal.close();
            });
          }
        } else {
          //registration normal flow
          //direct to dashboard by calling auth token api
          this.authService.loginUser(this.utilitySerive.getCookies()).subscribe(response => {
            //close active modal
            this.activeModal.close();
            //set current user and token in local storage
            this.authService.setUserDataToken(response);
          });
        }
      }, 1000);


    });

  }

  //close modal
  public async closeModal() {
    const result = await this.confirmDialogService.getConfirmation('Exit', 'Are you sure you want to exit ?');
    if (result) {//if user select yes on confirmation
      this.activeModal.close();
    }
  }

}
