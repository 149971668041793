<div class="modal-header pb-0">
  <button type="button" class="close" (click)="closeModal()">
    <span aria-hidden="true">
      <img src="assets/images/close.png" alt="" />
    </span>
  </button>
</div>
<div class="modal-body pt-1">
  <div class="student-login-box done-registration">
    <div class="student-login">
      <h3 class="">Add New Homework</h3>
      <form [formGroup]="homeworkFormGroup">
        <div class="login-box mb-4">
          <div class="form-label-group mb-0">
            <input
              type="text"
              placeholder="Homework Title"
              formControlName="title"
              class="form-control"
            />
            <label for="fname">Title</label>
            <div>
              <span
                class="text-danger error-text font-smaller"
                *ngIf="
                  formControls.title.touched &&
                  formControls.title.errors?.required
                "
              >
                Please enter homework title.
              </span>
              <span
                class="text-danger error-text font-smaller"
                *ngIf="
                  formControls.title.touched &&
                  (formControls.title.errors?.maxlength ||
                    formControls.title.errors?.minlength) &&
                  !formControls.title.errors?.pattern
                "
              >
                Title must be between 4 to 50 characters.
              </span>
              <span
                class="text-danger error-text"
                *ngIf="
                  formControls.title.touched &&
                  formControls.title.errors?.pattern
                "
              >
                Please provide a valid homework title.
              </span>
            </div>
          </div>
          <div class="form-label-group input-group mb-0">
            <input
              class="form-control"
              [owlDateTime]="dt1"
              [owlDateTimeTrigger]="dt1"
              formControlName="due_date"
              [min]="minDate"
              (keypress)="(false)"
              placeholder="Due Date"
            />
            <label for="exampleFormControlSelect1">Due Date</label>
            
            <div class="input-group-append">
              <button class="btn btn-calendar calendar p-0" type="button"><img src="../../../../../assets/images/icon_calendar.svg">
                <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time></button>
            </div>
            <div class="col-12 p-0">
            <span
              class="text-danger error-text font-smaller"
              *ngIf="
                formControls.due_date.touched &&
                formControls.due_date.errors?.required
              "
            >
              Please provide due date.
            </span>
            </div>
            <span
              class="text-danger error-text font-smaller"
              *ngIf="
                formControls.due_date.touched &&
                formControls.due_date.errors?.due_date
              "
            >
              Due date can be within a week from course end date.
            </span>
          </div>
          <div class="form-label-group mb-0">
            <input
              type="text"
              [ngClass]="{
                '':
                  formControls.total_marks?.touched &&
                  formControls.total_marks?.errors?.required
              }"
              class="form-control"
              placeholder="Total Marks"
              formControlName="total_marks"
              (keypress)="checkMarksInput($event)"
            />
            <label for="grade">Total Marks</label>
            <span
              class="text-danger error-text font-smaller"
              *ngIf="
                formControls.total_marks.touched &&
                formControls.total_marks.errors?.required
              "
            >
              Please provide total marks.
            </span>
            <span
              class="text-danger error-text"
              *ngIf="
                formControls.total_marks.touched &&
                (formControls.total_marks.errors?.maxlength ||
                  formControls.total_marks.errors?.minlength) &&
                !formControls.total_marks.errors?.pattern
              "
            >
              Marks must be between 1 to 3 digits.
            </span>
            <span
              class="text-danger error-text"
              *ngIf="
                formControls.total_marks.touched &&
                formControls.total_marks.errors?.pattern
              "
            >
              Marks must contain 1 to 3 digits.
            </span>
          </div>
          <div class="form-group pt-3 mb-0 upload-btn-wrapper">
            <label class="btn cursor-pointer btn-outline-primary" *ngIf="!fileUrl" for="attache_doc">
              Attach Document
            </label>
            <input
              *ngIf="!fileUrl"
              type="file"
              name="myfile"
              id="attache_doc"
              formControlName="homework_file"
              (change)="onHomeworkFileSelected($event)" hidden
            />
            <div class="form-group file-name pt-1 pl-0" *ngIf="fileUrl">
              <label
                for="filename"
                class="text-secondary ml-2"
                ngbTooltip="{{ fileName }}"
                placement="bottom"
                [disableTooltip]="fileName.length < 40"
                ><fa-icon [icon]="['fas', this.fileIcon]"></fa-icon>
                {{ fileName | shortText: 40 }}</label
              >
              <img
                src="assets/images/icon_close.svg"
                class="cancel-icon ml-4"
                alt=""
                title="Delete Selected File"
                (click)="onFileRemove()"
              />
            </div>
            <!-- <span class="text-primary font-small pl-2">file upload complete</span> -->
            <span
              class="text-danger error-text d-block font-smaller"
              *ngIf="
                formControls.homework_file.touched &&
                formControls.homework_file.errors?.required
              "
            >
              Please provide homework file.
            </span>
          </div>
        </div>
        <button
          type="submit"
          class="btn btn-outline-primary btn-block mb-4"
          (click)="onSubmit(false)"
        >
          Save
        </button>
        <button
          type="submit"
          class="btn btn-gradient btn-block mb-4"
          (click)="onSubmit(true)"
        >
          Save & Publish
        </button>
      </form>
    </div>
  </div>
</div>
