<div class="session-details">
     <p class="text-success font-medium mb-2">All Sessions</p>
    <div class="card all-sessions">
      <div class="table-responsive">
        <table class="table">
          <thead class="bg-success text-white">
            <tr>
              <th scope="col">Lesson Topic</th>
              <th scope="col">Time</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let session of sessionsToDisplay">
              <td>{{ session.title }}</td>
              <td>
                {{ class.start_time | changeTimeFormat12Hour }} -
                {{ class.end_time | changeTimeFormat12Hour }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-center py-2" *ngIf="showLoadMore">
          <a
            (click)="getChunkedSessions()"
            class="cursor-pointer arrow-icon"
            title="Show More"
          >
            <img src="assets/images/Icon-ionic-ios-arrow-down.svg" alt="" />
          </a>
        </div>
        <div class="text-center py-2" *ngIf="showCompress">
          <a
            (click)="compressData()"
            class="cursor-pointer"
            title="Compress List"
          >
            <img src="assets/images/icon-ionic-ios-arrow-up.svg" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
  