<div class="modal-header pb-0">
  <button type="button" class="close" (click)="closeModal(false)">
    <span aria-hidden="true">
      <img src="assets/images/close.png" alt="" />
    </span>
  </button>
</div>
<div class="modal-body pt-1">
  <div class="student-login-box register">
    <div class="student-login mb-0">
      <h3 class="mb-5">Change Password</h3>
      <form [formGroup]="changePasswordFormGroup" (submit)="onSubmit()" autocomplete="new-password">
        <div class="login-box mb-4">
          <div class="form-label-group password-box pb-3 mb-0">
            <input autocomplete="new-password" [type]="showOldPassword ? 'text' : 'password'"
              placeholder="Enter Old Password" formControlName="old_password" class="form-control"
              (keypress)="checkSpaceInput($event)" />
            <label>Old Password</label>
            <a (click)="showOldPassword = !showOldPassword" class="eye-icon">
              <img src="../../../../assets/images/hide.svg" alt="eye" [hidden]="showOldPassword" />
              <img src="../../../../assets/images/show.svg" alt="eye" [hidden]="!showOldPassword" />
            </a>
            <div class="validation-text">
              <span class="text-danger" *ngIf="
                  changePasswordFormControl.old_password.touched &&
                  changePasswordFormControl.old_password.errors?.old_password
                ">
                {{ changePasswordFormControl.old_password.errors.old_password }}
              </span>
              <span class="text-danger" *ngIf="
                  changePasswordFormControl.old_password.touched &&
                  changePasswordFormControl.old_password.errors?.required
                ">
                Please provide Old Password.
              </span>
            </div>
          </div>
          <div class="form-label-group password-box pb-3 mb-0">
            <input [type]="showNewPassword ? 'text' : 'password'" placeholder="Enter New Password"
              formControlName="password" class="form-control" (keypress)="checkSpaceInput($event)" />
            <label>New Password</label>
            <a (click)="showNewPassword = !showNewPassword" class="eye-icon">
              <img src="../../../../assets/images/hide.svg" alt="eye" [hidden]="showNewPassword" />
              <img src="../../../../assets/images/show.svg" alt="eye" [hidden]="!showNewPassword" />
            </a>
            <div class="validation-text">
              <span class="text-danger" *ngIf="
                  changePasswordFormControl.password.touched &&
                  changePasswordFormControl.password.errors?.required
                ">
                Please provide New Password.
              </span>
              <span class="text-danger" *ngIf="
                  changePasswordFormControl.password.touched &&
                  (changePasswordFormControl.password.errors?.minlength ||
                    changePasswordFormControl.password.errors?.maxLength ||
                    changePasswordFormControl.password.errors?.pattern)
                ">
                Password must contain 8-20 characters including letters, numbers
                & special characters.
              </span>
            </div>
          </div>
          <div class="form-label-group password-box pb-3 mb-0">
            <input [type]="showNewPasswordConfirmation ? 'text' : 'password'" placeholder="Enter Confirm Password"
              formControlName="confirmPassword" class="form-control" (keypress)="checkSpaceInput($event)" />
            <label>Confirm Password</label>
            <a (click)="
                showNewPasswordConfirmation = !showNewPasswordConfirmation
              " class="eye-icon">
              <img src="../../../../assets/images/hide.svg" alt="eye" [hidden]="showNewPasswordConfirmation" />
              <img src="../../../../assets/images/show.svg" alt="eye" [hidden]="!showNewPasswordConfirmation" />
            </a>
            <div class="validation-text">
              <span class="text-danger" *ngIf="
                  changePasswordFormControl.confirmPassword.touched &&
                  changePasswordFormControl.confirmPassword.errors?.required
                ">
                Please provide Confirm Password.
              </span>
              <span class="text-danger" *ngIf="
                  changePasswordFormControl.confirmPassword.touched &&
                  changePasswordFormGroup.errors?.notSame &&
                  changePasswordFormControl.confirmPassword.value
                ">
                The specified passwords do not match.
              </span>
            </div>
          </div>
        </div>
        <button data-target="#successModal" data-toggle="modal" data-dismiss="modal"
          class="btn btn-gradient btn-block mb-4">
          Submit
        </button>
      </form>
    </div>
  </div>
</div>