import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public user; //current user object
  public isStudent: boolean;
  public callerTuitionRequest: boolean;
  constructor(private authService: AuthService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.callerTuitionRequest = (params.get("callerTuitionReq") === 'true') ? true : false;
    });
  }

  ngOnInit(): void {
    this.user = this.authService.getCurrentUser();
    this.isStudent = this.user?.type === "student" ? true : false;
    this.authService.userDataObservable.subscribe((user) => {
      this.user = user;
    });
  }

}
