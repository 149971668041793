import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'changeTimeFormat12Hour'
})
export class ChangeTimeFormat12HourPipe implements PipeTransform {
  transform(timeString: any, shortTime: boolean = true, recieving: boolean = true): string {
    const timeZoneOffset = new Date().getTimezoneOffset(); //getting current user timezone
    // console.log(timeString);
    // console.log(moment(timeString, 'HH:mm:ss').add(timeZoneOffset, "minute").format('h:mm A'));
    let format = '';
    if (shortTime) {
      format = 'hA';
    } else {
      format = 'hh:mm A'
    }
    if (timeString || timeString === 0){
      if (recieving) {
        return moment(timeString, 'HH:mm:ss').subtract(timeZoneOffset, "minute").format('h A');//-300
      } else {
        console.log(moment(timeString, 'H').add(timeZoneOffset, "minute").format('H'));
        return moment(timeString, 'H').add(timeZoneOffset, "minute").format('H');
      }
    }else{
      return;
    }
    // var H = +timeString.substr(0, 2);
    // var h = (H % 12) || 12;
    // var ampm = H < 12 ? "AM" : "PM";
    // timeString = h + timeString.substr(2, 3) + ampm;
    // return timeString;
  }

}
