import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { ModalService } from 'src/app/services/modal.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  public totalAmount: number = 0;
  public cartList: any[] = [];
  @Output() showBadge: EventEmitter<number> = new EventEmitter();
  constructor(private modalService: ModalService, private cartService: CartService, private toastr: ToastrService, private confirmDialog: ConfirmationDialogService, private utilityService: UtilityService) { }

  ngOnInit(): void {
    //initially adding cart items
    this.getCartItems();
    //listenning to changes in cart
    this.cartService.cartDataObservable.subscribe((cartItems: any[]) => {
      this.cartList = cartItems;
      this.showBadge.next(this.cartList.length);
      this.calculateTotalAmount();
    })
  }

  public getCartItems() {
    this.cartService.getCartItems().subscribe(response => {
      this.cartList = response.data.cart_items;
      this.showBadge.next(this.cartList.length);
      this.calculateTotalAmount();
    })
  }

  public calculateTotalAmount() {
    this.totalAmount = 0;
    this.cartList.forEach(element => {
      this.totalAmount += Number(element.course_price);
    });
  }

  public async removeCourseFromCart(itemId, courseId) {
    //  //get confirmation before logout
    //  const removeItemFlag = await this.confirmDialog.getConfirmation('Remove Course','Are you sure you want to delete course from cart?');
    //  //if user selected no;
    //  if(!removeItemFlag){
    //    event.stopPropagation();
    //    return;
    //   }
    this.cartService.removeCourseFromCart(itemId).subscribe(response => {
      this.cartList = response.data.cart_items;
      this.showBadge.next(this.cartList.length);
      this.calculateTotalAmount();
      //emitting event to all courses
      this.cartService.addRemoveCartIemSubject.next({ course_id: courseId, added_to_cart: false });
      // event.stopPropagation();
      this.toastr.success('Course is deleted successfully!', 'Success');
    });
  }

  public openProceedToPayModal() {
    const modalRef = this.modalService.open(10, 2);
    modalRef.componentInstance.step = 2;
  }

  public getDurationOfCourse(date1, date2) {
    let date_1 = new Date(date1);
    let date_2 = new Date(date2);
    return this.utilityService.getMonths(date_1, date_2);
  }
}
