<section class="subscribe-section">
  <div class="container">
    <div class="subscribe-box">
      <div class="title">
        <h3 class="font-light text-white heading-3">To Get Latest News & Further Updates</h3>
        <p class="text-white">Subscribe Our Newsletter</p>
      </div>
      <a (click)="subscribeNewsletter()" class="btn btn-sub">Subscribe</a>
    </div>
  </div>
</section>
