import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalService } from "src/app/services/modal.service";
import { StudentService } from "src/app/services/student.service";
import { UtilityService } from "src/app/services/utility.service";
import { ChangeTimeFormat12HourPipe } from "src/app/utilities/change-time-format12-hour.pipe";
import { ConfirmationDialogService } from "src/app/services/confirmation-dialog.service";
import * as moment from "moment";
import { CartService } from "src/app/services/cart.service";
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: "app-one-to-one-tuition-list",
  templateUrl: "./one-to-one-tuition-list.component.html",
  styleUrls: ["./one-to-one-tuition-list.component.scss"],
  providers: [ChangeTimeFormat12HourPipe],
})
export class OneToOneTuitionListComponent implements OnInit {
  public params: any = {
    page_size: 8,
    page: 1,
  };
  public totalPages: number;
  public maxLimitReached: boolean = false;
  public zeroSearchResults: boolean = false;
  public coursesCount: number = 0;
  public searchTriggered: boolean = false;
  public showLoadMoreButton: boolean = false;
  public showSearchTriggeredMessage: boolean = false;
  @ViewChild("searchButton") seacrhButton: ElementRef; //to close dropdown on search button fired
  public callerDashboard: boolean;
  public proposedTuitions = [];
  constructor(
    private studentService: StudentService,
    private modalService: ModalService,
    private cartService: CartService,
    private confirmDialogService: ConfirmationDialogService,
    private router: Router,
    private utilityService: UtilityService,
    private timeConversion: ChangeTimeFormat12HourPipe,
    private activatedRoute: ActivatedRoute,
    private metaTagService: Meta, 
    private metaTitleService: Title
  ) {
    this.callerDashboard = activatedRoute.snapshot.data.fromDashboard;
  }
  ngOnInit(): void {
    //addd tags

    this.metaTagService.updateTag({ name: 'keywords', content: '1 on 1 tutions' });
    this.metaTitleService.setTitle('Get Special 1 on 1 online tutions with the Best Online teachers');
    this.metaTagService.updateTag({ name: 'description', content: 'VIVID provides the facility of 1 on 1 tutions and classes facility in the time of the pandemic.' });
    //api call to fetch random proposed courses
    this.getTuitions();

    //listen to remove cart item event
    this.cartService.addRemoveCartIemObservable.subscribe((obj: any) => {
      // {course_id:1, added_to_cart:true}
      this.proposedTuitions.forEach((tuition: any) => {
        if (obj.course_id === tuition.id) {
          tuition.added_to_cart = obj.added_to_cart;
        }
      });
    });
  }

  public getTuitions() {
    //set course count to zero to hide course count upon api call
    this.coursesCount = 0;
    //to not show load more button upon api call
    this.showLoadMoreButton = false;
    this.studentService.getTuitionList(this.params, this.callerDashboard).subscribe((response) => {
      //updating page number locally
      this.params.page += 1;
      //setting data array
      this.proposedTuitions = [
        ...this.proposedTuitions,
        ...response.data.courses.data,
      ];
      //setting total pages
      this.totalPages = response.data.courses.last_page;
      //setting max pages flag
      if (this.params.page > this.totalPages) {
        this.maxLimitReached = true;
        this.showLoadMoreButton = false;
      } else {
        this.showLoadMoreButton = true;
      }

      if (response.data.courses.total === 0) {
        //message manuplation of number of results fetched
        this.zeroSearchResults = true;
        this.showLoadMoreButton = false;
      } else {
        this.zeroSearchResults = false;
        this.coursesCount = response.data.courses.total;
      }

      if (this.searchTriggered) {
        //close dropdown if search fired
        this.seacrhButton.nativeElement.click();
        this.searchTriggered = !this.searchTriggered;
      }
    });
  }

  public loadMore() {
    if (!this.maxLimitReached) {
      this.getTuitions();
    } else {
      return;
    }
  }

  public jsonDecode(item) {
    return JSON.parse(item);
  }

  //function call on event emiited by search box
  public fetchSearchResults(searchData: any) {
    //initially hide loadmore button
    this.zeroSearchResults = false;

    //flag to show search results count
    this.searchTriggered = true;
    // to show no matching results
    this.showSearchTriggeredMessage = true;

    if (searchData.grade != null) {
      this.params.grade = searchData.grade;
    } else {
      delete this.params["grade"];
    }
    if (searchData.subject != null) {
      this.params.subject = searchData.subject;
    } else {
      delete this.params["subject"];
    }
    if (searchData.month != null) {
      this.params.month = searchData.month;
    } else {
      delete this.params["month"];
    }
    if (searchData.keyword != null) {
      this.params.keyword = searchData.keyword;
    } else {
      delete this.params["keyword"];
    }
    if (
      (searchData.time || searchData.time === 0) &&
      searchData.time != "null"
    ) {
      //change time format to gmt
      this.params.hour = this.timeConversion.transform(
        searchData.time,
        true,
        false
      );
    } else {
      delete this.params["hour"];
    }
    //if any of search parameter provided then reseting page number parameter and sending api call
    if (
      searchData.grade ||
      searchData.subject ||
      searchData.month ||
      searchData.time ||
      searchData.time === 0 ||
      searchData.keyword
    ) {
      this.params.page = 1;
      //empty previous records
      this.proposedTuitions = [];
      //calling api
      this.getTuitions();
      //reseting max records flag
      this.maxLimitReached = false;
    }
  }

  public async addToCart(course) {
    if (!this.callerDashboard) {
      this.utilityService.setUserType("student");
      this.modalService.open(1, 1);
      return;
    }
    if (!course.available_seats) return;
    if (!moment(course.start_date).isBefore(moment())) {
      if (course.is_free) {
        this.studentService.enrollFreeCourse(course);
      } else {
        const modalRef = this.modalService.open(10, 2);
        modalRef.componentInstance.course = course;
        modalRef.componentInstance.step = 1; //1 for add to cart step
      }
    } else {
      const result = await this.confirmDialogService.getConfirmation(
        "Note",
        "This course has already started and you may have missed some of its sessions. Do you still want to enroll in this course ?"
      );
      if (!result) return;
      if (course.is_free) {
        this.studentService.enrollFreeCourse(course);
      } else {
        const modalRef = this.modalService.open(10, 2);
        modalRef.componentInstance.course = course;
        modalRef.componentInstance.step = 1; //1 for add to cart step
      }
    }
  }

  public navigateToCourseDetail(tuitionId) {
    this.router.navigate(["student-dashboard/tuition", tuitionId]);
  }

  public getDurationOfCourse(date1, date2) {
    let date_1 = new Date(date1);
    let date_2 = new Date(date2);
    return this.utilityService.getMonths(date_1, date_2);
  }
}
