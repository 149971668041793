import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/services/auth.service";
import { ModalService } from 'src/app/services/modal.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SocialMediaService } from "src/app/services/social-media.service";
import { UtilityService } from "src/app/services/utility.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public showPassword: boolean = false;
  public userType: string;
  //form instance
  public loginFormGroup: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email, Validators.pattern(this.utilityService.emailRegex)]),
    password: new FormControl(null, [Validators.required]),
  });

  constructor(
    private authService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private socialLoginService: SocialMediaService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private utilityService: UtilityService,
    private activeModal: NgbActiveModal  ) {
    this.userType = utilityService.userType;
  }

  //get form controls
  get loginFormControl() {
    return this.loginFormGroup.controls;
  }

  //social logins using angular social package
  public socialLogin(platform: string) {
    if (platform === "facebook") {
      //user coming from facebook
      this.socialLoginService.loginWithFacebook().then((response) => {
        const email = response.email;
        const data = {
          accessToken: response.authToken,
          from: 'web',
          type: this.utilityService.getUserType()
        };
        this.authService.loginUserFacebook(data).subscribe(
          (response) => {
            if (response.status === 201) {
              //if a user is new and comming first time using social media then redirect him to registration screen
              this.toastr.success('Complete the Registration', 'Success');
              this.utilityService.setSocialMediaRegistrationFlag(true); //set social media registration flag
              this.utilityService.socialMediaUserData = data; //set access token and email to redirect user to dashbaord upon sucessfull registration
              this.utilityService.socialMediaType = "facebook"; //set social media type
              this.openSocialMediaRegistrationModal(email);
            } else {
              //redirect to dashboard if user is already registered
              //close active modal
              this.activeModal.close();
              //set current user and token in local storage
              this.authService.setUserDataToken(response.body);
            }
          },
          (error) => {
            //redirect to appropriate screen
            this.utilityService.setSocialMediaRegistrationFlag(true); //set social media registration flag
            this.utilityService.socialMediaUserData = data; //set access token and email to redirect user to dashbaord upon sucessfull registration
            this.utilityService.socialMediaType = "facebook"; //set social media type
            this.handleLoginRedirection(error.error);
          }
        );
      },
        (error) => {
          //user cross the dialog
          return;
        });
    } else {
      //user coming from google
      this.socialLoginService.loginWithGoogle().then((response) => {
        const email = response.email;
        const data = {
          accessToken: response.idToken,
          from: 'web',
          type: this.utilityService.getUserType()
        };
        this.authService.loginUserGoogle(data).subscribe(
          //user already registered and mobile verified
          (response) => {
            if (response.status === 201) {
              //if a user is new and comming first time using social media then redirect him to registration screen
              this.toastr.success('Complete the Registration', 'Success');
              this.utilityService.setSocialMediaRegistrationFlag(true); //set social media registration flag
              this.utilityService.socialMediaUserData = data; //set access token and email to redirect user to dashbaord upon sucessfull registration
              this.utilityService.socialMediaType = "google"; //set social media type
              this.openSocialMediaRegistrationModal(email);
            } else {
              //redirect to dashboard if user is already registered
              //close active modal
              this.activeModal.close();
              //set current user and token in local storage
              this.authService.setUserDataToken(response.body);
            }
          },
          (error) => {
            //redirect to appropriate screen
            this.utilityService.setSocialMediaRegistrationFlag(true); //set social media registration flag
            this.utilityService.socialMediaUserData = data; //set access token to redirect user to dashbaord upon sucessfull registration
            this.utilityService.socialMediaType = "google"; //set social media type
            //redirect to appropriate screen
            this.handleLoginRedirection(error.error);
          }
        );
      },
        (error) => {
          return;
        });
    }
  }
  googleSignin(googleWrapper: any) {
    googleWrapper.click();
  }

  ngOnInit() { }

  //open registration modal
  public openRegistrationModal(): void {
    this.utilityService.setSocialMediaRegistrationFlag(false);
    //close active modal
    this.activeModal.close();
    //wait for closing model to complete animation
    setTimeout(() => { this.modalService.open(2, 1); }, 800);
  }

  //open forgot password modal
  public openForgotPasswordModal(): void {
    //close active modal
    this.activeModal.close();
    //wait for closing model to complete animation
    setTimeout(() => { this.modalService.open(3, 1); }, 800);
  }

  //login normal flow form submit function
  public onSubmit() {
    //consider form fields to be touched
    this.loginFormGroup.markAllAsTouched();
    if (this.loginFormGroup.invalid) return; //if form data is invalid
    //data object
    const data = {
      email: this.loginFormGroup.value.email,
      password: this.loginFormGroup.value.password,
      type: this.utilityService.getUserType()
    };
    //api call to login
    this.authService.loginUser(data).subscribe(
      (response) => {
        if (response) {
          //closing other models
          //close active modal
          this.activeModal.close();
          //set current user and token in local storage
          this.authService.setUserDataToken(response);
        } else {
          //show error
        }
      },
      (error: HttpErrorResponse) => {
        //redirection
        this.handleLoginRedirection(error.error);
        // if (error.error.data.reason === 'instructor_profile_not_set' || error.error.data.reason === 'request_pending' || error.error.data.reason === 'request_rejected') return;
        //show toast of incomplete mobile verification
        if (error.error.data.reason === 'phone_unverified') this.utilityService.setCookies(data.email, data.password);
      }
    );
  }

  //redirect to OTP screen if a user is not mobile verified
  public redirectToOTPScreen(error: any, email: string) {
    //check if the phone key exist in data
    // if ("phone" in response.error.data) {
    //show error message
    this.toastr.error('Your Mobile Number is not verified yet. Please verify your Mobile Number first.', 'Error')
    //close active modal
    this.activeModal.close();
    let modalRef;
    //wait for closing model to complete animation
    setTimeout(() => {
      modalRef = this.modalService.open(6, 1);
      modalRef.componentInstance.caller = "registration";
      modalRef.componentInstance.phoneNo = error.data.phone;
      modalRef.componentInstance.email = email;
    }, 800);

  }

  public setUserData(user: any) {
    // set token in local storage
    this.authService.setAuthToken(user.data.access_token);

    //set current user
    this.authService.setCurrentUser(user.data.user);
    //closing other models
    //close active modal
    this.activeModal.close();
    //navigate to dashboard on successfull login
    //wait for modal to complete animation
    setTimeout(() => { this.router.navigate(["student-dashboard"]); }, 800);
  }

  //if user select social media auth and isn't registered yet
  public openSocialMediaRegistrationModal(email: string) {
    //closing other modals
    //close active modal
    this.activeModal.close();
    let modalRef;
    //wait for modal to complete animation
    setTimeout(() => {
      modalRef = this.modalService.open(4, 1);
      //setting required data for the component
      modalRef.componentInstance.email = email;
    }, 800);


  }

  //close modal
  public async closeModal() {
    this.activeModal.close();
    // const result = await this.confirmDialogService.getConfirmation('Exit', 'Are you sure you want to exit ?');
    // // if (result) {//if user select yes on confirmation
    // //     this.activeModal.close();
    // // }
  }

  //do not allow user to input space
  public checkSpaceInput(event) {
    return this.utilityService.doNotSpace(event);
  }

  public handleLoginRedirection(error: any) {

    if (error.data && error.data.reason) {
      if (error.data.reason === 'account_inactive') {
        this.toastr.error(error.message, 'Contact Administration');// if account is inactive
        return;
      } else if (error.data.reason === 'invalid_credentials') { //invalid credentials don't movve any where
        this.toastr.error(error.message, 'Invalid Credentials');
        return;
      } else if (error.data.reason === 'missing_phone') {// if user is from social media but haven't provided mobile phone
        this.toastr.error('Your registraton is not complete. Please provide Mobile Number.', 'Error')
        //user is unregistered
        this.openSocialMediaRegistrationModal(error.data.email);
      } else if (error.data.reason === 'phone_unverified') {
        this.redirectToOTPScreen(error, error.data.email);
      } else if (error.data.reason === 'already_registered') { //different login social media student trying to login with teacher dialog
        this.toastr.error(error.message, 'Invalid Credentials');
      }
      else if (error.data.reason === 'instructor_profile_not_set') {
        //navigate to teacher registration 3rd step
        this.toastr.error('Please complete your registration.', 'Registration Incomplete');
        //close active modal
        this.activeModal.close();
        setTimeout(() => {
          const modalRef = this.modalService.open(16, 1); //open teacher registration final step
          //setting input properties for the next step
          modalRef.componentInstance.email = error.data.email;
        }, 1000);
        return;
      } else if (error.data.reason === 'request_pending') {
        // this.toastr.error(error.message, 'Error');
        //close active modal
        this.activeModal.close();
        setTimeout(async () => {
          this.modalService.getSuccessModal(
            'Admin Approval Required',
            'Someone from administration will contact you shortly to complete your onboarding process',
            true);
        }, 1000);
        return;
      } else if (error.data.reason === 'request_rejected') {
        this.toastr.error(error.message, 'Error');
        return;
      }
    } else {
      this.socialLoginService.signOut();
      this.toastr.error(error.message, 'Error');
    }
  }
}
