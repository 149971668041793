<div class="drop-tabs" *ngIf="menuList?.length !== 0">
  <!-- category -->
  <div class="class-tab">
    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
      <a class="nav-link" id="mat-tab" [ngClass]="{active: activeMenu === 'menu_' + menu.id}" data-toggle="pill"
        (click)="menuSelected(menu.sub_menu, menu.direct_study_materials, 'menu_' + menu.id,  1, $event)" role="tab"
        *ngFor="let menu of menuList" aria-controls="mat" aria-selected="true">{{menu.title}}
        <!-- <svg _ngcontent-qrl-c221="" xmlns="http://www.w3.org/2000/svg" width="11.918" height="6.814" viewBox="0 0 11.918 6.814" class="arrow-forward"><path _ngcontent-qrl-c221="" d="M12.149,16.006l4.506-4.51a.848.848,0,0,1,1.2,0,.859.859,0,0,1,0,1.206l-5.106,5.109a.85.85,0,0,1-1.174.025L6.436,12.707a.852.852,0,0,1,1.2-1.206Z" transform="translate(-6.188 -11.246)" class="a"></path></svg> -->
      </a>
    </div>
  </div>
  <!-- level -->
  <div class="course-tab" *ngIf="subMenuOneList?.length || categoryStudyMaterialList?.length">
    <div class="tab-content" id="v-pills-tabContent">
      <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="fsc-tab">
        <div class="course-tab-pane">
          <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <a class="nav-link" [ngClass]="{active: activeSubOneMenu === 'sub_one_menu_' + menu.id}"
              (click)="menuSelected(menu.sub_menu, menu.direct_study_materials, 'sub_one_menu_' + menu.id, 2, $event)"
              *ngFor="let menu of subMenuOneList" role="tab" aria-selected="true">{{menu.title}}
              <svg class="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="11.918" height="6.814"
                viewBox="0 0 11.918 6.814">
                <path class="a"
                  d="M12.149,16.006l4.506-4.51a.848.848,0,0,1,1.2,0,.859.859,0,0,1,0,1.206l-5.106,5.109a.85.85,0,0,1-1.174.025L6.436,12.707a.852.852,0,0,1,1.2-1.206Z"
                  transform="translate(-6.188 -11.246)" />
              </svg>
            </a>
            <div class="subject-box">
              <div class="subject-details">
                <ul>
                  <li *ngFor="let studyMaterial of categoryStudyMaterialList">
                    <a class="nav-link cursor-pointer"
                      [ngClass]="{active: activeStudyMaterial === '1_' + studyMaterial.id}"
                      (click)="openStudyMaterialDetails(studyMaterial.id, '1_'+studyMaterial.id, 1, studyMaterial)">{{studyMaterial.title}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- sub level one -->
  <div class="course-tab" *ngIf="subMenuTwoList?.length || subLevelOneStudyMaterialList?.length">
    <div class="tab-content" id="v-pills-tabContent">
      <div class="tab-pane fade show active" id="mat" role="tabpanel" aria-labelledby="mat-tab">
        <div class="course-tab-pane">
          <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <a class="nav-link" data-toggle="pill" [ngClass]="{active: activeSubTwoMenu === 'sub_two_menu_' + menu.id}"
              role="tab"
              (click)="menuSelected(menu.sub_menu, menu.direct_study_materials, 'sub_two_menu_' + menu.id ,3, $event)"
              *ngFor="let menu of subMenuTwoList" aria-controls="engineering" aria-selected="true">{{menu.title}}
              <svg _ngcontent-qrl-c221="" xmlns="http://www.w3.org/2000/svg" width="11.918" height="6.814"
                viewBox="0 0 11.918 6.814" class="arrow-forward">
                <path _ngcontent-qrl-c221=""
                  d="M12.149,16.006l4.506-4.51a.848.848,0,0,1,1.2,0,.859.859,0,0,1,0,1.206l-5.106,5.109a.85.85,0,0,1-1.174.025L6.436,12.707a.852.852,0,0,1,1.2-1.206Z"
                  transform="translate(-6.188 -11.246)" class="a"></path>
              </svg>
            </a>
            <div class="subject-box">
              <div class="subject-details">
                <ul>
                  <li *ngFor="let studyMaterial of subLevelOneStudyMaterialList">
                    <a [ngClass]="{active: activeStudyMaterial === '2_' + studyMaterial.id}"
                      class="nav-link cursor-pointer"
                      (click)="openStudyMaterialDetails(studyMaterial.id, '2_'+studyMaterial.id, 2, studyMaterial)">{{studyMaterial.title}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="tab-pane fade" id="fsc" role="tabpanel" aria-labelledby="fsc-tab">
        <div class="course-tab-pane">
          <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <a class="nav-link" [routerLink]="['study-material']" id="engineering-tab" data-toggle="pill"
              href="#engineering" role="tab" aria-controls="engineering" aria-selected="true">
              Matric
              <svg class="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="11.918" height="6.814"
                viewBox="0 0 11.918 6.814">
                <path class="a"
                  d="M12.149,16.006l4.506-4.51a.848.848,0,0,1,1.2,0,.859.859,0,0,1,0,1.206l-5.106,5.109a.85.85,0,0,1-1.174.025L6.436,12.707a.852.852,0,0,1,1.2-1.206Z"
                  transform="translate(-6.188 -11.246)" />
              </svg>
            </a>
            <a class="nav-link active" id="medical-tab" data-toggle="pill" href="#medical" role="tab"
              aria-controls="medical" aria-selected="false">
              F`sc
              <svg class="arrow-forward" xmlns="http://www.w3.org/2000/svg" width="11.918" height="6.814"
                viewBox="0 0 11.918 6.814">
                <path class="a"
                  d="M12.149,16.006l4.506-4.51a.848.848,0,0,1,1.2,0,.859.859,0,0,1,0,1.206l-5.106,5.109a.85.85,0,0,1-1.174.025L6.436,12.707a.852.852,0,0,1,1.2-1.206Z"
                  transform="translate(-6.188 -11.246)" />
              </svg>
            </a>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <!-- sub level two -->
  <div class="course-tab" *ngIf="subMenuThreeList?.length || subLevelTwoStudyMaterialList?.length">
    <div class="tab-content" id="v-pills-tabContent">
      <div class="tab-pane fade show active" id="mat" role="tabpanel" aria-labelledby="mat-tab">
        <div class="course-tab-pane">
          <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <a class="nav-link" data-toggle="pill"
              [ngClass]="{active: activeSubThreeMenu === 'sub_three_menu_' + menu.id}" role="tab"
              (click)="menuSelected(menu?.sub_menu, menu.direct_study_materials, 'sub_three_menu_' + menu.id ,4, $event)"
              *ngFor="let menu of subMenuThreeList" aria-controls="engineering" aria-selected="true">{{menu.title}}
              <svg _ngcontent-qrl-c221="" xmlns="http://www.w3.org/2000/svg" width="11.918" height="6.814"
                viewBox="0 0 11.918 6.814" class="arrow-forward">
                <path _ngcontent-qrl-c221=""
                  d="M12.149,16.006l4.506-4.51a.848.848,0,0,1,1.2,0,.859.859,0,0,1,0,1.206l-5.106,5.109a.85.85,0,0,1-1.174.025L6.436,12.707a.852.852,0,0,1,1.2-1.206Z"
                  transform="translate(-6.188 -11.246)" class="a"></path>
              </svg>
            </a>
            <div class="subject-box">
              <div class="subject-details">
                <ul>
                  <li *ngFor="let studyMaterial of subLevelTwoStudyMaterialList">
                    <a class="nav-link cursor-pointer"
                      [ngClass]="{active: activeStudyMaterial === '3_' + studyMaterial.id}"
                      (click)="openStudyMaterialDetails(studyMaterial.id, '3_'+studyMaterial.id, 3, studyMaterial)">{{studyMaterial.title}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- study material -->
  <div class="subject-tab">
    <div class="tab-content">
      <div class="tab-pane fade" [ngClass]="{'show active': lastLevelStudyMaterialList?.length}">
        <div class="subject-box">
          <div class="subject-details">
            <ul>
              <li *ngFor="let studyMaterial of lastLevelStudyMaterialList">
                <a class="cursor-pointer" [ngClass]="{active: activeStudyMaterial === '4_' + studyMaterial.id}"
                  (click)="openStudyMaterialDetails(studyMaterial.id, '4_'+studyMaterial.id, 4, studyMaterial)">{{studyMaterial.title}}</a>
              </li>
            </ul>
          </div>
          <!-- <div class="subject-details">
            <p class="cls-name">Class 12</p>
            <ul>
              <li>
                <a href="#">Physics</a>
              </li>
              <li>
                <a href="#">Chemistry</a>
              </li>
              <li>
                <a href="#">Biology</a>
              </li>
              <li>
                <a href="#">Mathematics</a>
              </li>
              <li>
                <a href="#">English</a>
              </li>
              <li>
                <a href="#">Urdu</a>
              </li>
            </ul>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<p class="text-center" *ngIf="menuList?.length === 0">
  No study material available yet.
</p>
<div class="bottom-gradient">
</div>