import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// import { AuthService } from 'angularx-social-login';
// import { AuthService } from '@abacritt/angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { ModalService } from 'src/app/services/modal.service';
import { UserService } from 'src/app/services/user.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  public studentRegistration: boolean;
  //form instantiation
  public registrationFormGroup: FormGroup = new FormGroup({
    'name': new FormControl(null, [Validators.required, Validators.pattern(this.utilityService.nameRegex), Validators.minLength(2), Validators.maxLength(50)]),
    'email': new FormControl(null, [Validators.required, Validators.email, Validators.pattern(this.utilityService.emailRegex)]),
    'phone': new FormControl(null, [Validators.required, Validators.pattern(this.utilityService.phoneNumberRegex)]),
    // 'phone': new FormControl(null, [Validators.required]),
    'password': new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(this.utilityService.passwordRegex)]),
    'confirmPassword': new FormControl(null, [Validators.required])
  }, {
    validators: this.utilityService.confirmValidator.bind(this) // check password and confirm password should match
  }
  );


  get registerFormControl() {
    return this.registrationFormGroup.controls;
  }

  constructor(
    private modalService: ModalService,
    private router: Router,
    // private authService: AuthService,
    private userService: UserService,
    private toastr: ToastrService,
    private utilityService: UtilityService,
    private activeModal: NgbActiveModal,
    private confirmDialogService: ConfirmationDialogService,
  ) {
    //different API call on user type
    this.studentRegistration = (this.utilityService.userType === "student") ? true : false;
  }

  ngOnInit(): void {
  }

  //to open OTP modal
  public openOtpModal() {
    //close active modal
    this.activeModal.close();
    this.modalService.open(6, 1);
  }

  //on registration step one form submission
  public onSubmit(): void {
    //if user tries to submit form without filling all field correctly
    this.registrationFormGroup.markAllAsTouched();
    if (this.registrationFormGroup.invalid) return;

    const data: any = {
      'name': this.registrationFormGroup.value.name,
      'email': this.registrationFormGroup.value.email,
      'phone': this.utilityService.addSpacesInPhoneNo(this.registrationFormGroup.value.phone),//add spaces to phone number
      'password': this.registrationFormGroup.value.password,
      'password_confirmation': this.registrationFormGroup.value.confirmPassword
    };
    //api call
    this.userService.registerUser(data, this.studentRegistration).subscribe(response => {
      //success
      //closing previous modal and openning the next step modal with properties
      //close active modal
      this.activeModal.close();

      //set cookies for seamless login :D
      this.utilityService.setCookies(data.email, data.password);

      let modalRef;
      //wait for closing model to complete animation
      setTimeout(() => {
        modalRef = this.modalService.open(6, 1);
        modalRef.componentInstance.caller = 'registration';
        modalRef.componentInstance.email = response.data.email;
        modalRef.componentInstance.phoneNo = data.phone;
      }, 1000);
    },
      (error: HttpErrorResponse) => {
        //showing server side errors under specific form fields
        if ('email' in error.error.error_details) {
          this.registrationFormGroup.controls['email'].setErrors({ 'alreadyTaken': error.error.error_details.email });
        }
        if ('phone' in error.error.error_details) {
          this.registrationFormGroup.controls['phone'].setErrors({ 'alreadyTaken': error.error.error_details.phone });
        }
      }
    );
  }

  //close modal
  public async closeModal() {
    if (!this.registrationFormGroup.pristine) {
      //if user changed something ehm
      const result = await this.confirmDialogService.getConfirmation('Exit', 'Are you sure you want to exit ?');
      if (!result) return;
      this.activeModal.close();
    } else {
      this.activeModal.close();
    }
  }

  //allow user to only input numbers + and spaces
  public checkPhoneInput(event) {
    return this.utilityService.allowNumberAndSpaceOnly(event);
  }

  //do not allow user to input space
  public checkSpaceInput(event) {
    return this.utilityService.doNotSpace(event);
  }

  //open login modal
  public openLoginModal() {
    this.activeModal.close();
    setTimeout(() => {
      this.modalService.open(1, 1);
    }, 800);
  }

  public addPrefix() {
    if (!this.registerFormControl.phone.value) {
      this.registerFormControl.phone.setValue("+92");
    }
  }
}
