import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAlignCenter, faArrowLeft, faBook, faCheck, faFileExcel, faFilePdf, faFilePowerpoint, faFileWord, faInfoCircle, faStar } from '@fortawesome/free-solid-svg-icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime-ex';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SocketIoModule } from 'ngx-socket-io';
import { NgxSpinnerModule } from "ngx-spinner";
import { MockTestModalComponent } from 'src/app/components/landing-page/mock-test-modal/mock-test-modal.component';
import { RegisterFreeComponent } from 'src/app/components/main-dashboard/landing-page/register-free/register-free.component';
import { SubscribeComponent } from 'src/app/components/main-dashboard/landing-page/subscribe/subscribe.component';
import { ClassDetailsUnenrolledComponent } from 'src/app/components/shared/class-details-unenrolled/class-details-unenrolled.component';
import { FooterComponent } from 'src/app/components/shared/footer/footer.component';
import { SessionDetailsComponent } from 'src/app/components/shared/session-details/session-details.component';
import { SylabusDetailsComponent } from 'src/app/components/student-dashboard/login-student-dashboard/sylabus-details/sylabus-details.component';
import { CourseDetailComponent } from 'src/app/components/student-dashboard/register-student-dashboard/course-detail/course-detail.component';
import { CourseListComponent } from 'src/app/components/student-dashboard/register-student-dashboard/course-list/course-list.component';
import { OneToOneTuitionDetailComponent } from 'src/app/components/student-dashboard/register-student-dashboard/one-to-one-tuition-detail/one-to-one-tuition-detail.component';
import { OneToOneTuitionListComponent } from 'src/app/components/student-dashboard/register-student-dashboard/one-to-one-tuition-list/one-to-one-tuition-list.component';
import { SearchBoxComponent } from 'src/app/components/student-dashboard/search-box/search-box.component';
import { TitleBoxComponent } from 'src/app/components/student-dashboard/title-box/title-box.component';
import { ChangeDateFormatPipe } from 'src/app/utilities/change-date-format.pipe';
import { ChangeTimeFormat12HourPipe } from 'src/app/utilities/change-time-format12-hour.pipe';
import { CompactDayNamePipe } from 'src/app/utilities/compact-day-name.pipe';
import { DateFormatPipe } from 'src/app/utilities/date-format.pipe';
import { MathDirective } from 'src/app/utilities/math.directive';
import { RemovePhoneNumberSpacesPipe } from 'src/app/utilities/remove-phone-number-spaces.pipe';
import { ShortTextPipe } from 'src/app/utilities/short-text.pipe';
import { ConfirmationModalComponent } from '../../../components/shared/confirmation-modal/confirmation-modal.component';
import { CoursesMobileMenuComponent } from '../../../components/shared/courses-mobile-menu/courses-mobile-menu.component';
import { ErrorComponentComponent } from '../../../components/shared/error-component/error-component.component';
import { StudyMaterialContentComponent } from '../../../components/shared/study-material-content/study-material-content.component';
import { StudyMaterialMobileMenuComponent } from '../../../components/shared/study-material-mobile-menu/study-material-mobile-menu.component';
import { StudyMaterialComponent } from '../../../components/shared/study-material/study-material.component';
import { SuccessModalComponent } from '../../../components/shared/success-modal/success-modal.component';
import { CommaSpacePipe } from '../../../utilities/comma-space.pipe';
import { FormatTimePipe } from '../../../utilities/format-time.pipe';

const MY_MOMENT_FORMATS = {
  datePickerInput: { year: 'numeric', month: 'short', day: 'numeric' },
  timePickerInput: { hour: 'numeric', minute: 'numeric', hour12: false },
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};
@NgModule({
  declarations: [
    FooterComponent,
    ConfirmationModalComponent,
    SuccessModalComponent,
    SubscribeComponent,
    CompactDayNamePipe,
    RemovePhoneNumberSpacesPipe,
    ChangeTimeFormat12HourPipe,
    ChangeDateFormatPipe,
    DateFormatPipe,
    ShortTextPipe,
    CommaSpacePipe,
    StudyMaterialComponent,
    SearchBoxComponent,
    FormatTimePipe,
    StudyMaterialContentComponent,
    CourseListComponent,
    OneToOneTuitionListComponent,
    OneToOneTuitionDetailComponent,
    CourseDetailComponent,
    TitleBoxComponent,
    ClassDetailsUnenrolledComponent,
    SylabusDetailsComponent,
    SessionDetailsComponent,
    MockTestModalComponent,
    CoursesMobileMenuComponent,
    StudyMaterialMobileMenuComponent,
    RegisterFreeComponent,
    ErrorComponentComponent,
    MathDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgbModule,
    SocketIoModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FontAwesomeModule,
    NgMultiSelectDropDownModule.forRoot(),
    LazyLoadImageModule,
    SlickCarouselModule
  ],
  exports: [
    NgxSpinnerModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    FooterComponent,
    ConfirmationModalComponent,
    SuccessModalComponent,
    SubscribeComponent,
    CompactDayNamePipe,
    RemovePhoneNumberSpacesPipe,
    ChangeTimeFormat12HourPipe,
    SearchBoxComponent,
    ChangeDateFormatPipe,
    DateFormatPipe,
    ShortTextPipe,
    NgbModule,
    SocketIoModule,
    CommaSpacePipe,
    StudyMaterialComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgMultiSelectDropDownModule,
    FormatTimePipe,
    StudyMaterialContentComponent,
    CourseListComponent,
    OneToOneTuitionListComponent,
    OneToOneTuitionDetailComponent,
    CourseDetailComponent,
    TitleBoxComponent,
    LazyLoadImageModule,
    ClassDetailsUnenrolledComponent,
    SylabusDetailsComponent,
    SessionDetailsComponent,
    SlickCarouselModule,
    MockTestModalComponent,
    CoursesMobileMenuComponent,
    StudyMaterialMobileMenuComponent,
    RegisterFreeComponent,
    MathDirective
  ],
  // entryComponents: [ConfirmationModalComponent],
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }
  ]
})
export class SharedModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(faCheck, faBook, faArrowLeft, faAlignCenter, faStar, faFilePdf, faFileExcel, faFileWord, faFilePowerpoint, faInfoCircle);
  }
}
