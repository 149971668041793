<div class="modal-header pb-0">
  <button type="button" class="close" (click)="closeModal()">
    <img _ngcontent-jcx-c114="" src="assets/images/close.png" alt="" />
  </button>
</div>
<div class="modal-body pt-1">
  <h5 class="modal-title" id="exampleModalLongTitle">
    Feedback
  </h5>
  <form [formGroup]="feedbackFormGroup">
    <div class="feedback-text ck-editor-parent">
      <ckeditor
        [config]="{
          toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'blockQuote',
            'indent',
            'undo',
            'redo'
          ]
        }"
        (change)="textEditorChange($event)"
        [editor]="Editor"
        formControlName="feedbackFormControl"
        placeholder="Type your feedback here."
      ></ckeditor>
      <span
        class="text-danger"
        *ngIf="
          feedbackFormGroup.controls.feedbackFormControl.touched &&
          feedbackFormGroup.controls.feedbackFormControl.errors?.required
        "
      >
        Please provide feedback.
      </span>
      <span
        class="text-danger"
        *ngIf="
          feedbackFormGroup.controls.feedbackFormControl.touched &&
          (feedbackFormGroup.controls.feedbackFormControl.errors?.minlength ||
            feedbackFormGroup.controls.feedbackFormControl.errors?.maxLength) &&
            !feedbackFormGroup.controls.feedbackFormControl.errors?.required
        "
      >
        Feedback must be between 4 to 1000 characters.
      </span>
    </div>
    <button
      type="button"
      (click)="submitFeedback()"
      class="btn btn-gradient btn-send"
    >
      Send
    </button>
  </form>
</div>
<!-- <div class="modal-footer">

</div> -->
