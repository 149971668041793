<div class="content-wrapper">
    <div class="privacy-content">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="content">
                     <h2 class="title">Legal Terms and Conditions</h2>
                     
                    <h4>Acceptance of Terms and Conditions:</h4>
                    <p>By using GOVIVID website, you are agreeing to terms which will come into effect as soon as you first
                        start using the site. In case you do not or wish not to agree to the terms, please do not access
                        the information provided on the site.
                    </p>
                    <p>The website is an evolving resource and the terms and conditions of usage may change from time to time.
                        You are advised to continue checking the terms of usage regularly in case you wish to remain aware of
                        any policy changes that may take place.
                    </p>

                    <h4>Lawful Usage</h4>
                    <p>Learners must use the GOVIVID website for lawful purposes only and in no way affect the right of usage
                        of other learners. Foul language, unethical advice and comments that incite hatred or violence of any
                        kind or cause offense to other readers with different opinions, beliefs or sentiments are strictly
                        not allowed, are prohibited and discouraged.
                    </p>

                    <h4>Content Rights</h4>
                    <p>All types of content – text, video, images, info-graphics, designs, and layouts – as well as other types
                        of intellectual property are the sole property of GOVIVID website, unless particularly specified.
                        Learners are welcome to use, cite, refer, post, broadcast, disseminate, share and publicize the content
                        as long it is for personal or non-commercial usage.
                    </p>

                    <h4 class="text-green">Any commercial use of the content on GOVIVID requires prior permission from GOVIVID
                        management.</h4>

                    <h4>Limitation of Liability/Disclaimer:</h4>
                    <p>While The Academia endeavors to go all lengths to provide the most authentic and relevant information to its
                        readers, <b>GOVIVID</b> does not accept any responsibility for any errors or omissions or inaccuracy of information.
                        <b>GOVIVID</b> is not responsible for the completeness, currency or reliability of the information and material
                        published on the website and disclaims liability for any losses or damages resulting from the use of information
                        on the website. You and you only have the responsibility of the consequences of usage of information provided
                        on <b>GOVIVID</b> website
                    </p>

                    <h4>Indemnity</h4>
                    <p>In case you fail to comply with <b>GOVIVID</b> terms and conditions, <b>GOVIVID</b> reserves the right to
                        take appropriate legalaction against the violators and claim all damages, costs, judgments and expenses that
                        it may incur
                    </p>

                    <h4>Banned Content: </h4>
                    <p>In order to promote a safe and secure environment for all our students www.govivid.pk has prohibited the usage
                        or posting of the following materials on the website:
                    </p>

                    <ul>
                        <li>Sexual content, racism, hatred or offending content.</li>
                        <li>Advertisements of content other than.</li>
                        <li>Fake information</li>
                        <li>Plagiarized content</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
