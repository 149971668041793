import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { ConfirmationDialogService } from "src/app/services/confirmation-dialog.service";
import { NotificationService } from "src/app/services/notification.service";
import * as moment from "moment";
import { UtilityService } from "src/app/services/utility.service";
declare var $: any;
@Component({
  selector: "app-dashboard-header",
  templateUrl: "./dashboard-header.component.html",
  styleUrls: ["./dashboard-header.component.scss"],
})
export class DashboardHeaderComponent implements OnInit {
  openNav = false;
  public user; //current user object
  public cartCount: number;
  public isStudent: boolean;
  public showNotificationBadge: boolean = false;
  public notificationLimit: number = 5;
  public notificationList: object[] = [];
  public notificationCount: number = 0;
  public showDropdown: boolean = false;
  @ViewChild("dropdownMenu") dropdownMenu: ElementRef;
  constructor(
    private authService: AuthService,
    private utilityService: UtilityService,
    private confirmDialog: ConfirmationDialogService,
    private router: Router,
    private notificationService: NotificationService
  ) {
    this.user = this.authService.getCurrentUser();
    this.isStudent = this.user.type === "student" ? true : false;
    this.authService.userDataObservable.subscribe((user) => {
      this.user = user;
    });

    this.notificationService.connect();

    //listen to notifications
    this.notificationService.notificationDataObservable.subscribe(
      (notification: any) => {
        // console.log("Dashboard header : ", notification);
        if (notification.fromDb) {
          this.notificationList.push(notification);
        } else {
          this.notificationList.unshift(notification);
        }
        //update notification badge count
        if (!notification.web_read_at) {
          this.notificationCount += 1;
        }
      }
    );

    this.notificationService
      .getNotificationsFromDb()
      .subscribe((notification) => {
        notification.data.forEach((element) => {
          this.notificationList.push(element);
          //update notification badge count
          if (!element.web_read_at) {
            this.notificationCount += 1;
          }
        });
      });
    // //subject to change when notification implementedu
    // this.showNotificationBadge = true;
  }

  ngOnInit(): void {
    //listen to auth service socket disconnect subject
    this.authService.sockectDisconnectObservable.subscribe(response=>{
      this.notificationService.disconnect();
    })

    this.authService.userDataObservable.subscribe((user) => {
      //listenning to user object changes
      this.user = user;
    });

    // sticky header on scroll
    $(window).scroll(function () {
      if ($(this).scrollTop() > 90) {
        $(".navbar-me").addClass("fixed-me");
      } else {
        $(".navbar-me").removeClass("fixed-me");
      }
    });
  }

  public jsonDecode(item) {
    return JSON.parse(item);
  }

  public cartItemsChanged(cartItemCount: number) {
    this.cartCount = cartItemCount;
  }

  //logout function
  public async onLogout() {
    //get confirmation before logout
    const logoutFlag = await this.confirmDialog.getConfirmation(
      "Logout",
      "Are you sure you want to Logout?"
    );
    //if user selected no;
    if (!logoutFlag) return;
    //need to wire up api when its reeady
    this.menuButton(); //hide menu
    this.notificationService.disconnect();
    this.authService.logoutUser().subscribe((response) => {
      this.authService.logoutRedirect();
    });
  }

  public refresh() {
    window.location.reload();
  }

  public navigateToNotification(notification) {
    let tabId: number;
    if (
      notification.payload.type === 8 ||
      notification.payload.type === 7 ||
      notification.payload.type === 16 ||
      notification.payload.type === 14 ||
      notification.payload.type === 17
    ) {
      //session tab navigation
      tabId = 3;
    } else if (
      notification.payload.type === 5 ||
      notification.payload.type === 1 ||
      Number(notification.payload.type) === 12
    ) {
      // homework
      tabId = 4;
    } else if (
      notification.payload.type === 4 ||
      notification.payload.type === 2 ||
      notification.payload.type === 6 ||
      notification.payload.type === 19 ||
      Number(notification.payload.type) === 15
    ) {
      //test notification published  type === 19 if student attempted a test
      tabId = 5;
    } else if (notification.payload.type === 10 || notification.payload.type === 20) { //successfully enrolled to course notification // course class edit notification
      // this.user.student_profile.is_enrolled_to_course = 1;
      // this.authService.setCurrentUser(this.user);
      tabId = 1;
    } else if (
      notification.payload.type === 3 ||
      notification.payload.type === 18 ||
      notification.payload.type === 21 
    ) {
      //disenroll notification
      //welcome notification navigate to dashboard
    } else if (notification.payload.type === 13) {
      //student submitted assignment
      tabId = 4;
    }

    //navigate to notification
    // if (notification.payload.type === 3) return; //welcome notification
    if (notification.payload.type === 3 || notification.payload.type === 18 || notification.payload.type === 21) {
      //18 number session is about to start navigate to dashbaord || type 3 is welcome notification
      this.router.navigate([
        this.user.type === "student"
          ? "student-dashboard"
          : "teacher-dashboard",
      ]);
    } else {
      //navigate to dashboard either teacher or student
      if ("instructor" in notification?.payload?.metadata) {
        //// teacher notifications
        this.router.navigate(
          [
            "teacher-dashboard/my-classes",
            notification.payload.metadata.class_id,
            notification.payload.metadata.course_id,
          ],
          { queryParams: { tabId: tabId } }
        );
      } else {
        //if its not a teacher notification //more notifcation types will add up here to do navigation
        let queryParams: any = {
          classId: notification.payload.metadata.class_id,
          tabId: tabId
        };
        if (Number(notification.payload.type) === 16) {queryParams.sessionId = notification.payload.metadata.lecture_id;}
        this.router.navigate(
          [
            (Number(notification.payload.type) === 22) ?  "student-dashboard/course" : "student-dashboard/enrolled-course", //notification type 22 for new course published depending on grade
            notification.payload.metadata.course_id,
          ],
          {
            queryParams: queryParams,
          }
        );
      }
    }
    // this.router.navigate(['dashboard/enrolled-course', notification.payload.metadata.course_id, notification.payload.metadata.class_id, notification.payload.type]);
    //do not call api if notification is allready read
    if (notification.web_read_at) return;
    //call to mark notification as read
    this.notificationService
      .readNotification({ id: notification.id, device_type: "web" })
      .subscribe((response) => {
        //updating notification object locally
        notification.web_read_at = moment().format();
        this.notificationCount -= 1;
      });
  }
  public acc = document.getElementsByClassName("drop-link");
  public toggledropdown() { }
  public seeAllNotifications(event, compress: boolean) {
    //to stop clossing the notification dropdown
    event.stopPropagation();
    if (!compress) {
      if (this.notificationList.length > 5) {
        this.notificationLimit = this.notificationList.length;
      }
    } else {
      this.notificationLimit =
        this.notificationList.length > 5 ? 5 : this.notificationList.length;
    }
  }

  public menuButton(): void {
    // $(".navbar-toggler").toggleClass('open');

    if ($(".navbar-toggler").hasClass("close")) {
      $(".navegacion").css({ width: "100%", background: "rgba(0,0,0,.5)" });
      $(".navbar-toggler").removeClass("close").addClass("open");
      $(".navegacion .menu").css({ right: "0px" });
    } else {
      $(".navegacion").css({ width: "0%", background: "rgba(0,0,0,.0)" });
      $(".navbar-toggler").removeClass("open").addClass("close");
      $(".navegacion .submenu").css({ right: "-320px" });
      $(".navegacion .menu").css({ right: "-320px" });
    }
  }

  public subMenu(e): void {
    let subMenu = e.target.nextSibling;
    subMenu.style.right = "0px";
  }

  public closeMenu(e): void {
    let parentMenu = e.target.parentNode.parentNode;
    parentMenu.style.right = "-320px";
  }
}
