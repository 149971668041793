import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpService } from './http.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TestService {

  constructor(private apiService: ApiService, private http: HttpService, private toastr: ToastrService) { }

  //add new test
  public addNewTest(data: any): Observable<any> {
    return this.http.post(`${this.apiService.get(`addNewTest`)}`, data);
  }

  //add new question
  public addNewQuestion(data: any, editMode: boolean, questionId: any): Observable<any> {
    if (editMode) return this.http.put(`${this.apiService.get('addNewQuestion')}/${questionId}`, data);
    else return this.http.post(this.apiService.get(`addNewQuestion`), data);
  }

  //delete question
  public deleteQuestion(questionId: any): Observable<any> {
    return this.http.delete(`${this.apiService.get('deleteQuestion')}/${questionId}`);
  }

  //get test listing
  public getStudentTestListing(courseId: any, classId: any): Observable<any> {
    return this.http.get(`${this.apiService.get('getStudentTestListing')}course/${courseId}/class/${classId}`);
  }

  //get test listing
  public getInstructorTestListing(courseId: any, classId: any): Observable<any> {
    return this.http.get(`${this.apiService.get('getInstructorTestListing')}course/${courseId}/class/${classId}`);
  }

  //get test details
  public getTestDetails(testId: any, alreadyAttempted?: boolean): Observable<any> {
    if (alreadyAttempted) return this.http.get(`${this.apiService.get('getAttemptedTest')}/${testId}`);
    else return this.http.get(`${this.apiService.get('getTest')}/${testId}`);
  }


  //start test attempt
  public startTestAttempt(data: any): Observable<any> {
    return this.http.post(`${this.apiService.get('attemptTest')}`, data);
  }

  //submit test
  public submitTest(attemptId: any): Observable<any> {
    return this.http.patch(`${this.apiService.get('submitTest')}/${attemptId}`, {});
  }
  //submit question
  public submitQuestion(data: any): Observable<any> {
    return this.http.post(`${this.apiService.get('submitQuestion')}`, data, false, { showSpinner: false });
  }

  //get detail of already attempted test
  public getAttemptedTest(testId: any): Observable<any> {
    return this.http.get(`${this.apiService.get('getAttemptedTest')}/${testId}`);
  }


  //get pdf of attempted test
  public getAttemptedTestPDF(attemptId: any): Observable<any> {
    return this.http.get(`${this.apiService.get('getAttemptedTestPDF')}${attemptId}/download`);
  }

  //get mock details
  public getMockDetails(testId: any): Observable<any> {
    return this.http.get(`${this.apiService.get('getMockTest')}/${testId}`);
  }


  //start test attempt
  public startMockTestAttempt(data: any): Observable<any> {
    return this.http.post(`${this.apiService.get('attemptMockTest')}`, data);
  }

  //submit test
  public submitMockTest(attemptId: any): Observable<any> {
    return this.http.patch(`${this.apiService.get('submitMockTest')}/${attemptId}`, {});
  }
  //submit question
  public submitMockTestQuestion(data: any): Observable<any> {
    return this.http.post(`${this.apiService.get('submitMockTestQuestion')}`, data, false, { showSpinner: false });
  }

  //get pdf of attempted test
  public getAttemptedMockTestPDF(attemptId: any): Observable<any> {
    return this.http.get(`${this.apiService.get('getAttemptedMockTestPDF')}${attemptId}/download`);
  }

}
