import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private auth: AuthService, private router: Router) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    let userType = route.data.userType as string;
    console.log(userType);
    // if user is not logged in
    if (!this.auth.isAuthenticated()) {
      // redirect to login component
      this.router.navigate([""]);

      return false;
    }
    //check if user type is matching the route
    let checkRouteMatch: any = this.auth.checkUserType(userType);
    if (!checkRouteMatch.sameUser) {
      //redirect to correct path
      let route = (checkRouteMatch.userType === 'student') ? 'student-dashboard' : 'teacher-dashboard';
      this.router.navigate([route]);
      return false;
    } else {
      return true;
    }
  }
}
