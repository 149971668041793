<section class="recomended-course">
  <div class="container">
    <div class="title-box d-flex justify-content-between align-items-center">
      <h3 class="heading-2">Available Classes</h3>
      <!-- <a href="#" class="btn btn-outline-primary btn-browse">Browse Course</a> -->
    </div>
    <div class="row course-row">
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 course-center" *ngFor="let class of availableClasses|slice:0:3" >
        <div class="course-pane">
          <div class="trigno text-md-right course">
            <span class="badge badge-pill badge-dark grade-badge">{{class.grade.name}}</span>
            <div class="back-img">
              <div class="img-container ml-lg-auto">
                <img [lazyLoad]="class.media || 'assets/images/live.png'" alt="" >
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between flex-wrap course-content">
            <div class="course-heading">
              <h6 class="mb-0">{{class.title}}</h6>
              <p class="m-0 font-small">{{class.subject.name}}</p>
            </div>
            <!-- <p> <img src="assets/images/red-eye.png" class="mr-2 font-small" alt="Eye">245 </p> -->
          </div>
          <div class="live-class d-flex justify-content-between align-items-center flex-wrap">
            <a>
              <img src="assets/images/live.png" alt="Live">
              <span class="my-0 pt-1 font-small">Live Classes</span>
            </a>
            
            <span class="badge badge-pill time-badge time-badge-blue font-small">{{class.duration}} mins</span>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-4 col-md-4 col-sm-6 col-12 course-center">
        <div class="course-pane">
          <div class="eng text-md-right course">
            <span class="badge badge-pill badge-dark grade-badge">Grade 10</span>
            <div class="back-img">
              <div class="img-container ml-lg-auto">
                <img src="assets/images/grl-1.png" alt="">
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between flex-wrap course-content">
            <div class="course-heading">
              <h6 class="mb-0">English</h6>
              <p class="m-0 font-small">Compulsory</p>
            </div>
            <p> <img src="assets/images/red-eye.png" class="mr-2 font-small" alt="Eye">124 </p>
          </div>
          <div class="live-class d-flex justify-content-between align-items-center flex-wrap">
            <a href="#">
              <img src="assets/images/live.png" alt="Live">
              <span class="my-0 pt-1 font-small">Live Classes</span>
            </a>
            
            <span class="badge badge-pill time-badge time-badge-green font-small">45 mins</span>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-6 col-12 course-center">
        <div class="course-pane">
          <div class="vibration text-md-right course">
            <span class="badge badge-pill badge-dark grade-badge">Grade 10</span>
            <div class="back-img">
              <div class="img-container ml-lg-auto">
                <img src="assets/images/grl-1.png" alt="">
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between flex-wrap course-content">
            <div class="course-heading">
              <h6 class="mb-0">Vibrations</h6>
              <p class="m-0 font-small">Physics</p>
            </div>
            <p> <img src="assets/images/red-eye.png" class="mr-2 font-small" alt="Eye">310 </p>
          </div>
          <div class="live-class d-flex justify-content-between align-items-center flex-wrap">
            <a href="#">
              <img src="assets/images/live.png" alt="Live">
              <span class="my-0 pt-1 font-small">Live Classes</span>
            </a>
            
            <span class="badge badge-pill time-badge time-badge-gray font-small">45 mins</span>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</section>
