import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ListService {
  public occupationList = [
    "Full-Time Teacher",
    "Freelancer",
    "Working Professional",
    "College Student",
    "Not Working",
    "Other",
  ];

  public timeList: any = [
    { id: 24, name: "Full Time" },
    { id: 0, name: "12 AM - 1 AM" },
    { id: 1, name: "1 AM - 2 AM" },
    { id: 2, name: "2 AM - 3 AM" },
    { id: 3, name: "3 AM - 4 AM" },
    { id: 4, name: "4 AM - 5 AM" },
    { id: 5, name: "5 AM - 6 AM" },
    { id: 6, name: "6 AM - 7 AM" },
    { id: 7, name: "7 AM - 8 AM" },
    { id: 8, name: "8 AM - 9 AM" },
    { id: 9, name: "9 AM - 10 AM" },
    { id: 10, name: "10 AM - 11 AM" },
    { id: 11, name: "11 AM - 12 PM" },
    { id: 12, name: "12 PM - 1 PM" },
    { id: 13, name: "1 PM - 2 PM" },
    { id: 14, name: "2 PM - 3 PM" },
    { id: 15, name: "3 PM - 4 PM" },
    { id: 16, name: "4 PM - 5 PM" },
    { id: 17, name: "5 PM - 6 PM" },
    { id: 18, name: "6 PM - 7 PM" },
    { id: 19, name: "7 PM - 8 PM" },
    { id: 20, name: "8 PM - 9 PM" },
    { id: 21, name: "9 PM - 10 PM" },
    { id: 22, name: "10 PM - 11 PM" },
    { id: 23, name: "11 PM - 12 AM" },
  ];

  public experienceList: any = [
    { id: 1, name: "Less than a Year" },
    { id: 2, name: "Less than Two Years" },
    { id: 3, name: "Less than Three Years" },
    { id: 4, name: "Less than Four Years" },
    { id: 5, name: "More than Five years" },
  ];

  constructor(
    private httpService: HttpService,
    private apiService: ApiService
  ) {}

  public getQualificationsList() {
    return this.httpService.get(this.apiService.get("getQualifications"));
  }

  public getSubjectsList() {
    return this.httpService.get(this.apiService.get("getSubjects"));
  }

  public getOccupationList() {
    return this.occupationList;
  }

  public getTimeList() {
    return this.timeList;
  }

  public getExperienceList() {
    return this.experienceList;
  }
}
