import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import * as moment_timezone from 'moment-timezone';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { GradeService } from 'src/app/services/grade.service';
import { ListService } from 'src/app/services/list.service';
import { ModalService } from 'src/app/services/modal.service';
import { UserService } from 'src/app/services/user.service';
import { UtilityService } from 'src/app/services/utility.service';
import { countries } from '../../../utilities/cities';

@Component({
  selector: 'app-teacher-registration-final-step',
  templateUrl: './teacher-registration-final-step.component.html',
  styleUrls: ['./teacher-registration-final-step.component.scss'],
})
export class TeacherRegistrationFinalStepComponent implements OnInit {
  //floating label movement array 
  public floatingLabelDown: string[] = [];
  //get email from last registration step
  @Input() email: string;
  public occupationList: string[];
  public subjectList: any[];
  public qualificationList: any[];
  public gradeList: any[];
  public experienceList: any[];
  public timeList: any[];
  public countriesList = countries;
  public resumeUrl: string;
  //set max date
  public currentYear = (new Date).getFullYear();
  public currentMonth = (new Date).getMonth();
  public currentDay = (new Date).getDate();
  public maxDate = new Date(this.currentYear - 18, this.currentMonth, this.currentDay);

  public citiesList: any[] = this.countriesList["Pakistan"];
  public resumeFileName: string;
  public dropdownSettings: IDropdownSettings = {};
  public weekdayTimeDropDownSettings: IDropdownSettings = {};
  public weekendTimeDropDownSettings: IDropdownSettings = {};
  public gradesDropDownSettings: IDropdownSettings = {};
  public fileIconSrc: string;
  //formGroup
  //form instantiation
  public teacherRegistrationFinalStepFormGroup: FormGroup = new FormGroup({
    'dateOfBirth': new FormControl(null, Validators.required),
    'qualification': new FormControl(null, [Validators.required]),
    'specialization': new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern(this.utilityService.leadingTrailingSpaces)]),
    'subjects': new FormControl(null, [Validators.required]),
    'grades': new FormControl(null, [Validators.required]),
    'weekDaysTimings': new FormControl(null),
    'weekendTimings': new FormControl(null),
    'teachingExperience': new FormControl(null, [Validators.required]),
    'city': new FormControl(null, [Validators.required]),
    'currentOccupation': new FormControl(null, [Validators.required]),
    'resume': new FormControl(null),
  });
  constructor(
    private modalService: ModalService,
    private gradesService: GradeService,
    private userService: UserService,
    private toastr: ToastrService,
    private activeModal: NgbActiveModal,
    private confirmDialogService: ConfirmationDialogService,
    private listService: ListService,
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void {

    //basic multiselect drop down config
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true
    };

    //configuration for weekday timing multiselect
    this.weekdayTimeDropDownSettings = {
      ...this.dropdownSettings,
      enableCheckAll: false,
      itemsShowLimit: 2
    };

    //configuration for weekend timing multiselect
    this.weekendTimeDropDownSettings = {
      ...this.dropdownSettings,
      enableCheckAll: false,
      itemsShowLimit: 2
    };

    //configuration for grades and subjects multiselect
    this.gradesDropDownSettings = {
      ...this.dropdownSettings,
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All'
    }
    //get occupation list (maintained at client side)
    this.occupationList = this.listService.getOccupationList();

    //get time list
    this.timeList = this.listService.timeList;
    //get experience list
    this.experienceList = this.listService.getExperienceList();

    //get qualifications list from api
    this.listService.getQualificationsList().subscribe(response => {
      this.qualificationList = response.data.qualifications;
    });

    //get subject list from api
    this.listService.getSubjectsList().subscribe(response => {
      this.subjectList = response.data.subjects;
    });

    this.gradesService.getGrades().subscribe(response => {
      this.gradeList = response.data.grades;
    });

  }


  public async closeModal() {
    const result = await this.confirmDialogService.getConfirmation('Exit', 'Are you sure you want to exit ?');
    if (result) {//if user select yes on confirmation
      this.activeModal.close();
    }
  }

  public onSubmit() {
    this.teacherRegistrationFinalStepFormGroup.markAllAsTouched();
    if (this.teacherRegistrationFinalStepFormGroup.invalid) return;

    const data = {
      'date_of_birth': moment(this.formControls.dateOfBirth.value).format('YYYY-MM-DD'),
      'qualification_id': this.formControls.qualification.value,
      'specialization': this.formControls.specialization.value,
      'subjects': this.formControls.subjects.value,
      'grades': this.formControls.grades.value,
      'weekdays_full_time': this.checkFullTimeFlag(this.formControls.weekDaysTimings.value, true),
      'weekdays_timings': this.checkFullTimeFlag(this.formControls.weekDaysTimings.value, false),
      'weekend_full_time': this.checkFullTimeFlag(this.formControls.weekendTimings.value, true),
      'weekend_timings': this.checkFullTimeFlag(this.formControls.weekendTimings.value, false),
      'teaching_experience': this.formControls.teachingExperience.value,
      'occupation': this.formControls.currentOccupation.value,
      'city': this.formControls.city.value,
      'country': 'Pakistan',
      'email': this.email,
      'resume_url': this.resumeUrl,
      'resume_file_name': this.resumeFileName,
      'end_user_time_zone': moment_timezone.tz.guess() //guess user time zome like USA/America
    };

    if (!this.resumeUrl) {
      delete data.resume_url;
      delete data.resume_file_name;
    }

    //api call to register instructor profile
    this.userService.registerInstructorProfile(data).subscribe(response => {
      this.activeModal.close();
      setTimeout(async () => {
        this.modalService.getSuccessModal(
          'Admin Approval Required',
          'Someone from administration will contact you shortly to complete your onboarding process',
          true);
      }, 1000);
    })
  }

  public checkFullTime(timeObject, weekdays: boolean, selected: boolean) {
    if (timeObject.id === 24) {
      if (selected) {
        if (weekdays) {
          //if user selected full time after selecting multiple time slots so making only checked one for weekdays
          this.formControls.weekDaysTimings.reset([timeObject]);
          // changing the config to limit the selection to ONE for weekdays
          this.weekdayTimeDropDownSettings = Object.assign({}, this.weekdayTimeDropDownSettings, { limitSelection: 1 });
        } else {
          //if user selected full time after selecting multiple time slots so making only checked one for weekends
          this.formControls.weekendTimings.reset([timeObject]);
          // changing the config to limit the selection to ONE for weekends
          this.weekendTimeDropDownSettings = Object.assign({}, this.weekendTimeDropDownSettings, { limitSelection: 1 });
        }
      } else {
        if (weekdays) {
          //reverting only one selection to unlimited for weekdays
          this.weekdayTimeDropDownSettings = Object.assign({}, this.weekdayTimeDropDownSettings, { limitSelection: -1 });
        } else {
          //reverting only one selection to unlimited for weekend days
          this.weekendTimeDropDownSettings = Object.assign({}, this.weekendTimeDropDownSettings, { limitSelection: -1 });
        }
      }
    }
  }

  get formControls() {
    return this.teacherRegistrationFinalStepFormGroup.controls;
  }

  public onResumeSelected(event) {
    let file = event.target.files[0];
    this.resumeFileName = event.target.files[0].name;
    if (!file) return;
    let fileType = file.type.toLowerCase();
    let fileSize = file.size;
    //check file type
    if (!(
      fileType === "application/pdf" ||
      fileType === "application/msword" ||
      fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      fileType === "application/kswps"
    )) {
      this.toastr.error('File type not supported.', 'Error');
      this.resumeFileName = '';
      return;
    }
    if ((Math.round(fileSize / 1024)) > 4096) {// calculating filesize in kb's 2048 kb ~ 2 mb
      this.toastr.error('File size should be less than 4 MB.', 'File Size Exceeded');
      this.resumeFileName = '';
      return;
    }
    this.getFileIcon();//get file icon
    const formData: FormData = new FormData();
    formData.append('resume', file, file.name);
    formData.append('email', this.email);

    //send api call to upload instuctor resume
    this.userService.uploadInstructorResume(formData).subscribe(response => {
      this.resumeUrl = response.data.resume_url;
      this.resumeFileName = response.data.file_original_name;
    });
  }

  // format the time slots according the API payload format
  public formatTime(time: string[]) {
    let finalTimeResult = [];
    for (let index = 0; index < time.length; index++) {
      let element: any = time[index];
      let timeString: string[] = element.name.split(' - ');
      let startTime = timeString[0];
      let endTime = timeString[1];
      let result = {
        'start_time': moment(startTime, 'h A').format('hh:mm A'),
        'end_time': moment(endTime, 'h A').format('hh:mm A')
      };
      finalTimeResult.push(result);
    }
    return finalTimeResult;
  }

  //date format to send to api
  public getNormalizedDate(dateOfBirth) {
    if (!dateOfBirth) return; //if user hasn't selected any date
    const month = dateOfBirth.month.toString();
    const day = dateOfBirth.day.toString();
    const year = dateOfBirth.year.toString();
    const date = new Date(`${month}-${day}-${year}`);
    return new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));
  }

  //check if user hasn't selected full time and if not then format the time
  public checkFullTimeFlag(timeSlots: any[], fullTimeFlag: boolean) {
    if (!timeSlots) {
      return fullTimeFlag ? 0 : [];
    };
    let fullTime: boolean = (timeSlots.length === 1 && timeSlots[0].id === 24) ? true : false;
    if (fullTimeFlag) {
      return fullTime ? 1 : 0;
    } else {
      return fullTime ? [] : this.utilityService.formatTime(timeSlots);
    }
  }

  public onDateSelected(date) {
    var age = 18;
    var setDate = new Date(date.year + age, date.month - 1, date.day);
    var currdate = new Date();
    if (setDate >= currdate) {
      //set validation errror for calendar that instructor is below 18
      this.formControls.dateOfBirth.setErrors({ 'ageLimit': 'You must be 18 years old or over' });
    }
  }

  public async onFileRemove() {
    //get confirmation before deleting selected file
    // const result = await this.confirmDialogService.getConfirmation('Delete Resume', 'Are you sure to delete selected file?');

    // if (result) {
    this.resumeFileName = null;
    this.resumeUrl = null;
    this.formControls.resume.reset();
    // } else {
    //   return;
    // }
  }

  //add floating label class
  public addClassToLabel(controlName: string) {

    switch (controlName) {
      case 'subjects':
        this.floatingLabelDown.push('subjects');
        break;
      case 'grades':
        this.floatingLabelDown.push('grades');
        break;
      case 'weekDaysTimings':
        this.floatingLabelDown.push('weekDaysTimings');
        break;
      case 'weekendTimings':
        this.floatingLabelDown.push('weekendTimings');
        break;
    }
  }

  //check down label
  public checkDownLabel(controlName: string) {

    if (this.floatingLabelDown.find(control => control === controlName)) {
      return true;
    } else {
      return false;
    }
  }

  //get file icon for resume
  public getFileIcon() {
    if (!this.resumeFileName) return;
    switch (this.resumeFileName.split(".").pop()) {
      case "docx":
        this.fileIconSrc = "assets/images/doc-ms-word.svg";
        break;
      case "doc":
        this.fileIconSrc = "assets/images/doc-ms-word.svg";
        break;
      case "pdf":
        this.fileIconSrc = "assets/images/doc-pdf.svg";
        break;
      case "txt":
        this.fileIconSrc = "assets/images/doc-txt.svg";
        break;
      default:
        break;
    }
  }
}
